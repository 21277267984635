import React from 'react'
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../context';
import { useAuth } from '../../auth';

const ThreeImgBackground = ({ type }) => {

    const { planType } = useParams();
    const { currentUser } = useAuth();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const navigate = useNavigate();

    const onClickSubscribe = () => {
        if (!currentUser) {
            if (type === "Platinum") {
                sessionStorage.setItem("subscriptionID", 2);
                localStorage.setItem("retrunurl", `/subscription-order-summary`)
            }
            else if (type === "Premium") {
                sessionStorage.setItem("subscriptionID", 11);
                localStorage.setItem("retrunurl", `/subscription-order-summary`)
            }
            else if (type === "Super") {
                sessionStorage.setItem("subscriptionID", 12);
                localStorage.setItem("retrunurl", `/subscription-order-summary`)
            }
            localStorage.setItem("retrunurl", `/subscription-order-summary`)
            // setShowLloginPopup(true);
            const currentUrl = encodeURIComponent(window.location.href);
            window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
        }
        else {
            if (type === "Platinum") {
                sessionStorage.setItem("subscriptionID", 2);
                navigate(`/subscription-order-summary`)
            }
            else if (type === "Premium") {
                sessionStorage.setItem("subscriptionID", 11);
                navigate(`/subscription-order-summary`)
            }
            else if (type === "Super") {
                sessionStorage.setItem("subscriptionID", 12);
                navigate(`/subscription-order-summary`)
            }
        }
    }

    return (
        <div className='ThreeImg--Background'>
            <img src="/assets/img/planDetails/bg1.png" loading='lazy' />
            <img src="/assets/img/planDetails/bg2.png" loading='lazy' />
            <picture>
                <source media="(min-width:500px)" srcset="/assets/img/planDetails/bg3.png" />
                <img src="/assets/img/planDetails/bg3Mobile.png" loading='lazy' />
            </picture>


            <div className='Content--Area'>
                <h6 className='my-md-5'>Affordable Healing, Unmatched Support: Your Mental Wellness Journey Begins</h6>
                <button onClick={() => onClickSubscribe()} className='my-md-5'>Start your Journey</button>
            </div>
        </div>
    )
}

export default ThreeImgBackground