import React, { useEffect, useState } from "react";
import "../styles/Modules.css";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import Sidebar from "./Sidebar";
import { useNavigate, useParams } from "react-router-dom/dist";
import { addBookmarkEntry, getCourseContent } from "../_request";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import SidebarMobile from "./SidebarMobile";
import { Helmet } from "react-helmet";
import { Skeleton } from "@mui/material";

const Modules = () => {
  const [isBookmarked, setBookmarked] = useState(false);
  const [courseContent, setCourseContent] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentLevelId, setCurrentLevelId] = useState(id);
  const [bookmarkDataStatus, setBookmarkDataStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const body = { level_id: currentLevelId };
    getCourseContent(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setCourseContent(resp?.data);
        setIsLoading(false);
      } else {
        console.error(resp?.ResponseMessage);
      }
    });
  }, [currentLevelId, bookmarkDataStatus]);

  const handleClickOnLevel = (item) => {
    if (item.type === "video") {
      navigate(`/program-internal/module/${item?.id}`);
    } else if (item.type === "audio") {
      navigate(`/program-internal/module/${item?.id}`);
    } else if (item.type === "checksheet") {
      navigate(`/program-internal/module/daily-practice/${item?.id}`);
    } else if (item.type === "journal") {
      navigate(`/program-internal/module/my-journal/${item?.id}`);
    } else if (item.type === "quiz") {
      navigate(`/program-internal/module/quiz/${item?.id}`);
    } else if (item.type === "stella_assessment") {
      navigate(`/program-internal/talk-to-stella?assessmentID=${item?.id}`);
    } else if (item.type === "html") {
      navigate(`/program-internal/module/article/${item?.id}`);
    } else if (item.type === "mood_monitor") {
      navigate(`/program-internal/module/mood-monitor?id=${item?.id}`);
    }
  };

  const handleNextModule = () => {
    if (courseContent?.next_level) {
      setCurrentLevelId(courseContent?.next_level?.id);
      window.location.href = `${window.location.origin}/program-internal/program-view/${courseContent?.next_level?.id}`;
    }
  };

  const handlePreviousModule = () => {
    if (courseContent?.prev_level) {
      setCurrentLevelId(courseContent?.prev_level?.id);
      window.location.href = `${window.location.origin}/program-internal/program-view/${courseContent?.prev_level?.id}`;
    }
  };

  const handleBookmarkClick = (item) => {
    const body = { sub_level_id: item.id };
    addBookmarkEntry(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setBookmarkDataStatus(resp?.data);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
    setBookmarked(!isBookmarked);
  };

  return (
    <section style={{ display: "flex", overflow: "hidden" }}>
      <Helmet>
        <title>Program | Module</title>
      </Helmet>
      {isLoading ? (
        <>
          <section className="d-none d-md-flex">
            <Skeleton variant="rectangular" width={300} height={1080} />
          </section>
          <section className="modules-section">
            <div>
              <div className="modules-container">
              <Skeleton
                  variant="rounded"
                  width="20%"
                  height={40}
                  className="rounded-3"
                />
                <Skeleton
                  variant="rounded"
                  width="40%"
                  height={40}
                  className="rounded-3 my-2"
                />
                <div className="sections-name d-flex gap-2 align-items-center justify-content-between">
                  <Skeleton
                    variant="rounded"
                    width="15%"
                    height={40}
                    className="rounded-3"
                  />
                  <Skeleton
                    variant="rounded"
                    width={25}
                    height={25}
                    className=""
                  />
                </div>

                <div className="modules-sections">

                  <div className="modules-sections-items my-2">
                  {
                    Array.from(new Array(4)).map((_, i) => (
                      <Skeleton variant="rounded" width="100%" height={80} className="rounded-3 my-2" />  
                    ))
                  }
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <div>
                    <Skeleton variant="circular" width={35} height={35} className="rounded-full" />
                  </div>
                  <div>
                    <Skeleton variant="circular" width={35} height={35} className="rounded-full" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className="modules-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="modules-section">
            <div>
              <div className="modules-bg-img">
                <img src="/assets/img/course_internal_journey/module_bg_img" alt="" loading="lazy" />
              </div>
              <div className="modules-container">
                <div className="modules-heading">
                  {courseContent?.course_name} {"  "}
                  <span className="">
                    {"  "}
                    {">"} {courseContent?.name}
                  </span>
                </div>

                <div className="modules-sub-heading">{courseContent?.name}</div>
                <hr style={{ color: "#E0E0E0" }} />

                <div className="modules-sections">
                  <div className="modules-sections-heading">Sections</div>

                  <div className="modules-sections-items">
                    {courseContent?.customLevels?.map((item, i) => {
                      if (item.type !== "worksheet") {
                        return (
                          <div className="modules-sections-item align-items-center">
                            <div
                              className="d-flex align-items-center gap-2"
                              style={{ flex: 1 }}
                              onClick={() => handleClickOnLevel(item)}
                            >
                              <span className="">
                                {(item?.type === "video" && (
                                  <VideocamOutlinedIcon />
                                )) ||
                                  (item?.type === "audio" && (
                                    <HeadphonesOutlinedIcon />
                                  )) ||
                                  (item?.type === "mood monitor" && (
                                    <SentimentSatisfiedOutlinedIcon />
                                  )) ||
                                  (item?.type === "checkSheet" && (
                                    <MenuBookIcon />
                                  )) || <DescriptionOutlinedIcon />}
                              </span>
                              <div className="modules-text">{item?.name}</div>
                              <div>
                                {item?.completed ? (
                                  <img
                                    src="/assets/img/course_internal_journey/completed.png"
                                    alt=""
                                    width={20}
                                    height={20}
                                    className="module-completed-icon"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div onClick={() => handleBookmarkClick(item)}>
                              {(!item?.bookmark && (
                                <BookmarkBorderOutlinedIcon
                                  className={`modules-bookmark ${isBookmarked ? "bookmarked" : ""
                                    }`}
                                />
                              )) || <BookmarkIcon color="#541cf5" />}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>

                <div className="modules-btn">
                  {courseContent?.prev_level && (
                    <div className="prev-btn " onClick={handlePreviousModule}>
                      <div className="modules-prev-text">Previous Module</div>
                      <div className="prev-arrow">
                        <img
                          src="/assets/img/course_internal_journey/modules/arrow.png"
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                          style={{ transform: 'rotate(180deg)' }}
                        />
                      </div>
                    </div>
                  )}
                  {courseContent?.next_level && (
                    <div
                      className="next-btn ms-auto"
                      onClick={handleNextModule}
                    >
                      <div className="modules-prev-text">Next Module</div>
                      <div className="next-arrow">
                        <img
                          src="/assets/img/course_internal_journey/modules/arrow.png"
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default Modules;
