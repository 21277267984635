import React, { useState, useEffect, useRef } from 'react'
import { ButtonGroup, Col, Form, Row } from 'react-bootstrap'
import './RegisterAsProfessional.css'
import { Autocomplete, Box, MenuItem, Stack, TextField, Checkbox, Link, LinearProgress, Button } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { usOnBoardingExpertProfessionalDetails, usOnBoardingExpertProfileDetail, usOnBoardingExpert_RegisterAs, usOnBoarding_Area_Interest, usOnBoarding_Languages, usOnBoarding_LicenseState, usOnBoarding_LicenseType, usOnBoarding_MentalDisorderTypeList, usOnBoarding_TreatmentList } from '../core/_request';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import moment from 'moment/moment';
import { storage } from '../../firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import CloseIcon from '@mui/icons-material/Close';
import { enqueueSnackbar } from 'notistack';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RegisterAsProfessionalProfessionalDetailTab = (props) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState();
    const [percent, setPercent] = useState();
    const [registerAsList, setRegisterAsList] = useState([]);
    const [area_interestList, setArea_InterestList] = useState([]);
    const [languagesList, setLanguagesList] = useState([]);
    const [licenseStateList, setLicenseStateList] = useState([]);
    const [licenseTypeList, setLicenseTypeList] = useState([]);
    const [treatmentList, setTreatmentList] = useState([]);
    const [mentalDisorderList, setMentalDisorderList] = useState([]);
    const [schoolOrTrainingDocumentPercent, setSchoolOrTrainingDocumentPercent] = useState();
    const [schoolOrTrainingDocumentLoading, setSchoolOrTrainingDocumentLoading] = useState();
    const [cvFilePercent, setCVFilePercent] = useState();
    const [cVFileLoading, setCVFileLoading] = useState();
    const [boardCertificationPercent, setBoardCertificationPercent] = useState();
    const [boardCertificationLoading, setBoardCertificationLoading] = useState();
    const [licenseFilePercent, setLicenseFilePercent] = useState();
    const [licenseFileLoading, setLicenseFileLoading] = useState();
    const [additionalCertificatePercent, setAdditionalCertificatePercent] = useState();
    const [additionalCertificateLoading, setAdditionalCertificateLoading] = useState();
    const [isSpecify_license_type, setIsSpecify_license_type] = useState(true);
    const [isMentalDisorders, setIsMentalDisorders] = useState(true);
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z ]+$/;
    const ALPHANUMERIC_DASH_REGEX = /^[0-9a-zA-Z.]*$/;
    const currentYear = new Date().getFullYear();
    const trainingCertificate = useRef();
    const [trainingCertificateDocDetail, setTrainingCertificateDocDetail] = useState();
    const cvRef = useRef();
    const [cvDocDetail, setCvDocDetail] = useState();
    const boardCertificateRef = useRef();
    const [boardCertificateDocDetail, setBoardCertificateDocDetail] = useState();
    const licenseDocRef = useRef();
    const [licenseDocDetail, setLicenseDocDetail] = useState();
    const additionalCertificatesRef = useRef();
    const [additionalCertificatesDetail, setAdditionalCertificateDetail] = useState([]);
    const [uploadProgress, setUploadProgress] = useState([]);
    const yearsOfExp = Array.from({ length: 51 }, (_, i) => i); // Generates an array [0, 1, 2, ..., 50]


    // const [selectedYear, setSelectedYear] = useState('');

    const years = Array.from(new Array(70), (val, index) => currentYear - index); // Generate an array of past 50 years

    const handleYearChange = (event) => {
        setData({ ...data, graduation_year: event.target.value });
        // setSelectedYear(event.target.value);
    };

    useEffect(() => {
        var userId = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);
        var body = { "user_id": userId }
        usOnBoardingExpert_RegisterAs().then(resp => setRegisterAsList(resp?.data?.register_as));
        usOnBoarding_Languages().then(resp => setLanguagesList(resp?.data?.languages));
        usOnBoarding_LicenseState(body).then(resp => setLicenseStateList(resp?.data?.states));
        usOnBoarding_LicenseType().then(resp => setLicenseTypeList(resp?.data?.license_type));
        usOnBoarding_TreatmentList().then(resp => setTreatmentList(resp?.data?.treatments));
        usOnBoarding_MentalDisorderTypeList().then(resp => setMentalDisorderList(resp?.data?.mental_disorders));
    }, []);

    const onRegisterAsChange = (value) => {
        var sData = JSON.parse(localStorage.getItem("User_Registered_Info"))?.professional;
        var setdatavalue = {
            user_id: sData?.user_id,
            register_as_id: sData?.register_as_id,
            school: sData?.school,
            graduation_year: sData?.graduation_year,
            CVFile: sData?.resume,
            BoardCertification: sData?.board_certification,
            licensed_state_ids: licenseStateList?.filter(p => sData?.licensed_state_ids?.find(x => x === p?.id)),
            license_type_id: sData?.license_type_id,
            license_type: sData?.license_type,
            ExpirationDate: sData?.license_expiry_date,
            LicenseFile: sData?.license,
            treatment_ids: treatmentList?.filter(p => sData?.treatment_ids?.find(x => x === p?.id)),
            mental_disorders_id: sData?.mental_disorders_ids,
            mental_disorder: sData?.mental_disorder,
            AdditionalCertificate: sData?.additional_certificates,
            NpiNumber: sData?.npi_number,
            about_experience: sData?.about_experience,
            languages: languagesList?.filter(p => sData?.language_ids?.find(x => x === p?.id)),
            experience: sData?.experience,
            schoolOrTrainingDocument: sData?.documents,
            areaOfInterest_2: sData?.area_of_interest_ids,
        }
        var item = registerAsList?.find(o => o.id == value);

        if (item) {
            var Area_Interest_body = {
                "register_as_id": value
            }
            usOnBoarding_Area_Interest(Area_Interest_body).then(resp => {
                setArea_InterestList(resp?.data?.area_of_interests);
                setData({ ...setdatavalue, Id: value, RegisterAs: item.name, Register_fields: JSON.parse(JSON.stringify(item?.fields)), areaOfInterest: area_interestList?.filter(p => resp?.data?.area_of_interests?.area_of_interest_ids?.find(x => x === p?.id)) });
            });
        }
        else {
            setData(setdatavalue)
        }
    }

    console.log(data?.Register_fields, "Register_fields")

    useEffect(() => {
        setData({ ...data, areaOfInterest: area_interestList?.filter(p => data?.areaOfInterest_2?.find(x => x === p?.id)) });
    }, [area_interestList]);

    const onLicenseStateChange = (event, values) => {
        setData({ ...data, licensed_state_ids: values?.map((value) => value.id) })
    }

    const onLicenseTypeChange = (value) => {
        var licenseType = licenseTypeList?.filter(o => o?.id === value);
        if (licenseType[0]?.name === 'Other') {
            setIsSpecify_license_type(false);
        }
        else {
            setIsSpecify_license_type(true);
        }
        setData({ ...data, license_type_id: value })
    }

    const onTreamentChange = (event, values) => {
        setData({ ...data, treatment_ids: values })
    }

    const onMentalDisordersChange = (value) => {
        var DisorderList = mentalDisorderList.filter(o => o.id === value);
        if (DisorderList[0].name === 'Other') {
            setIsMentalDisorders(false);
        }
        else {
            setIsMentalDisorders(true);
        }
        setData({ ...data, mental_disorders_id: value })
    }

    const handleFileUpload = async (event) => {
        setLoading(true);

        if (event.target.files[0].size > 5e6) {
            // toast("File Size Exceeds");
            enqueueSnackbar("File Size Exceeds.", { variant: "warning" });
            setLoading(false);
            return false;
        } else {
            var file = event.target.files[0];
            setTrainingCertificateDocDetail(file)
            if (file?.type === "image/png" || file?.type === "image/jpg" || file?.type === "image/jpeg" || file?.type === "application/pdf") {
                var dirName = moment().format("MMMM_YYYY");
                const storageRef = ref(storage, dirName + "/" + file.name)
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        setSchoolOrTrainingDocumentPercent(percent);
                    },
                    (err) =>
                        // toast(err)
                        enqueueSnackbar(err, { variant: "error" }),
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            setData({ ...data, schoolOrTrainingDocument: url })
                            setSchoolOrTrainingDocumentLoading(false);
                        });
                    });
            }
            else {
                // toast("Incorrect  file format.")
                enqueueSnackbar("Incorrect  file format.", { variant: "error" });
            }
        }
    }

    const handleClearButtonClick = () => {
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) {
            fileInput.value = '';
        }
        setData({ ...data, schoolOrTrainingDocument: null });
    };

    const handleCVFileUpload = async (event) => {
        setLoading(true);

        if (event.target.files[0].size > 5e6) {
            // toast("File Size Exceeds", {
            //     toastId: 27
            // });
            enqueueSnackbar("File Size Exceeds.", { variant: "warning" });
            setLoading(false);
            return false;
        } else {
            var file = event.target.files[0];
            setCvDocDetail(file);
            if (file?.type === "application/pdf") {
                var dirName = moment().format("MMMM_YYYY");
                const storageRef = ref(storage, dirName + "/" + file.name)
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        setCVFilePercent(percent);
                    },
                    (err) =>
                        // toast(err, {
                        //     toastId: 28
                        // }),
                        enqueueSnackbar(err, { variant: "error" }),
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            // toast("File uploaded Successfully.", {
                            //     toastId: 29
                            // });
                            enqueueSnackbar("File uploaded Successfully.", { variant: "success" });
                            setData({ ...data, CVFile: url })
                            setCVFileLoading(false);
                        });
                    });
            }
            else {
                // toast("Incorrect  file format.", {
                //     toastId: 30
                // })
                enqueueSnackbar("Incorrect  file format.", { variant: "error" });
            }
        }
    }

    const handleBoardCertificationFileUpload = async (event) => {
        setLoading(true);

        if (event.target.files[0].size > 5e6) {
            // toast("File Size Exceeds", {
            //     toastId: 31
            // });
            enqueueSnackbar("File Size Exceeds.", { variant: "warning" });
            setLoading(false);
            return false;
        } else {
            var file = event.target.files[0];
            setBoardCertificateDocDetail(file);
            if (file?.type === "image/png" || file?.type === "image/jpg" || file?.type === "image/jpeg" || file?.type === "application/pdf") {
                var dirName = moment().format("MMMM_YYYY");
                const storageRef = ref(storage, dirName + "/" + file.name)
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        setBoardCertificationPercent(percent);
                    },
                    (err) =>
                        // toast(err, {
                        //     toastId: 32
                        // }),
                        enqueueSnackbar(err, { variant: "error" }),
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            // toast("File uploaded Successfully.", {
                            //     toastId: 33
                            // });
                            enqueueSnackbar("File uploaded Successfully.", { variant: "success" });
                            setData({ ...data, BoardCertification: url })
                            setBoardCertificationLoading(false);
                        });
                    });
            }
            else {
                // toast("Incorrect  file format.", {
                //     toastId: 34
                // })
                enqueueSnackbar("Incorrect  file format.", { variant: "error" });
            }
        }
    }

    const handleLicenseFileUpload = async (event) => {
        setLoading(true);

        if (event.target.files[0].size > 5e6) {
            // toast("File Size Exceeds", {
            //     toastId: 35
            // });
            enqueueSnackbar("File Size Exceeds.", { variant: "warning" });
            setLoading(false);
            return false;
        } else {
            var file = event.target.files[0];
            setLicenseDocDetail(file);
            if (file?.type === "image/png" || file?.type === "image/jpg" || file?.type === "image/jpeg" || file?.type === "application/pdf") {
                var dirName = moment().format("MMMM_YYYY");
                const storageRef = ref(storage, dirName + "/" + file.name)
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        setLicenseFilePercent(percent);
                    },
                    (err) =>
                        // toast(err, {
                        //     toastId: 36
                        // }) 
                        enqueueSnackbar(err, { variant: "error" }),
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            // toast("File uploaded Successfully.", {
                            //     toastId: 37
                            // });
                            enqueueSnackbar("File uploaded Successfully.", { variant: "success" });
                            setData({ ...data, LicenseFile: url })
                            setLicenseFileLoading(false);
                        });
                    });
            }
            else {
                // toast("Incorrect  file format.", {
                //     toastId: 38
                // })
                enqueueSnackbar("Incorrect  file format.", { variant: "error" });
            }
        }
    }

    // const handleAdditionalCertificateFileUpload = async (event) => {
    //     setLoading(true);

    //     if (event.target.files[0].size > 5e6) {
    //         // toast("File Size Exceeds", {
    //         //     toastId: 39
    //         // });
    //         enqueueSnackbar("File Size Exceeds.", { variant: "warning" });
    //         setLoading(false);
    //         return false;
    //     } else {
    //         var file = event.target.files[0];
    //         setAdditionalCertificateDetail(file);
    //         if (file?.type === "image/png" || file?.type === "image/jpg" || file?.type === "image/jpeg" || file?.type === "application/pdf") {
    //             var dirName = moment().format("MMMM_YYYY");
    //             const storageRef = ref(storage, dirName + "/" + file.name)
    //             const uploadTask = uploadBytesResumable(storageRef, file);
    //             uploadTask.on(
    //                 "state_changed",
    //                 (snapshot) => {
    //                     const percent = Math.round(
    //                         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //                     );
    //                     setAdditionalCertificatePercent(percent);
    //                 },
    //                 (err) =>
    //                     // toast(err, {
    //                     //     toastId: 40
    //                     // }),
    //                     enqueueSnackbar(err, { variant: "error" }),
    //                 () => {
    //                     getDownloadURL(uploadTask.snapshot.ref).then((url) => {
    //                         // toast("File uploaded Successfully.", {
    //                         //     toastId: 41
    //                         // });
    //                         enqueueSnackbar("File uploaded Successfully.", { variant: "success" });
    //                         setData({ ...data, AdditionalCertificate: url })
    //                         setAdditionalCertificateLoading(false);
    //                     });
    //                 });
    //         }
    //         else {
    //             // toast("Incorrect  file format.", {
    //             //     toastId: 42
    //             // })
    //             enqueueSnackbar("Incorrect  file format.", { variant: "error" });
    //         }
    //     }
    // }
    const handleAdditionalCertificateFileUpload = async (event) => {
        setLoading(true);

        const files = event.target.files;
        let uploadProgressArray = [...uploadProgress];
        let certificatesArray = [...additionalCertificatesDetail];

        for (let i = 0; i < files?.length; i++) {
            const file = files[i];

            if (file.size > 5e6) {
                enqueueSnackbar("File Size Exceeds.", { variant: "warning" });
                setLoading(false);
                return false;
            }

            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "application/pdf") {
                const dirName = moment().format("MMMM_YYYY");
                const storageRef = ref(storage, dirName + "/" + file.name);
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        uploadProgressArray[i] = percent;
                        setUploadProgress([...uploadProgressArray]);
                    },
                    (err) => enqueueSnackbar(err.message, { variant: "error" }),
                    async () => {
                        const url = await getDownloadURL(uploadTask.snapshot.ref);
                        enqueueSnackbar("File uploaded Successfully.", { variant: "success" });

                        certificatesArray.push({ fileName: file.name, fileUrl: url });
                        setAdditionalCertificateDetail([...certificatesArray]);
                        setUploadProgress([...uploadProgressArray]);
                    }
                );
            } else {
                enqueueSnackbar("Incorrect file format.", { variant: "error" });
            }
        }

        setLoading(false);
    };
    console.log("additionalCertificatesDetail", additionalCertificatesDetail)

    const onClickProfileData = () => {
        if (data?.RegisterAs === "Mental Health and Wellness Coach") {
            if (
                data?.languages?.length === 0
            ) {
                // toast("Required Languages", {
                //     toastId: 43
                // });
                enqueueSnackbar("Required Languages.", { variant: "warning" });
                return false;
            } else if (
                data?.experience === undefined ||
                data?.experience === null ||
                data?.experience === ""
            ) {
                // toast("Required Experience", {
                //     toastId: 44
                // });
                enqueueSnackbar("Required Experience.", { variant: "warning" });
                return false;
            } else if (
                data?.areaOfInterest?.length === 0
            ) {
                // toast("Required Area of Interest", {
                //     toastId: 45
                // });
                enqueueSnackbar("Required Area of Interest.", { variant: "warning" });
                return false;
            } else if (
                data?.about_experience === undefined ||
                data?.about_experience === null ||
                data?.about_experience === ""
            ) {
                // toast("Required Professional Experience and Specialization", {
                //     toastId: 46
                // });
                enqueueSnackbar("Required Professional Experience and Specialization.", { variant: "warning" });
                return false;
            } else if (
                data?.about_experience?.length > 2000
            ) {
                // toast("Please enter experience under 250 words", {
                //     toastId: 70
                // });
                enqueueSnackbar("Please enter experience under 250 words.", { variant: "warning" });
                return false;
            } else {
                var user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);
                var body = {
                    "user_id": user_id,
                    "register_as_id": data?.Id,
                    "language_ids": data?.languages?.map(o => o?.id),
                    "experience": data?.experience,
                    "documents": [data?.schoolOrTrainingDocument ?? ""],
                    "area_of_interest_ids": data?.areaOfInterest?.map(o => o?.id) ?? data?.areaOfInterest,
                    "about_experience": data?.about_experience,
                }
                usOnBoardingExpertProfessionalDetails(body).then(resp => {
                    if (resp?.code === 200) {
                        // toast('Professional Details Saved Successfully', {
                        //     toastId: 47
                        // });
                        enqueueSnackbar("Professional Details Saved Successfully", { variant: "success" });
                        localStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));
                        localStorage.setItem("RegisterAs", data?.Id);
                        props.onNext();
                    }
                    else {
                        // toast(resp?.data?.error ?? resp?.message, {
                        //     toastId: 48
                        // })
                        enqueueSnackbar(resp?.data?.error ?? resp?.message, { variant: "error" });
                    }
                })
            }
        } else if (data?.RegisterAs === "Therapist") {
            if (
                data?.languages?.length === 0
            ) {
                // toast("Required Languages", {
                //     toastId: 43
                // });
                enqueueSnackbar("Required Languages", { variant: "warning" });
                return false;
            } else if (
                data?.experience === undefined ||
                data?.experience === null ||
                data?.experience === ""
            ) {
                // toast("Required Experience", {
                //     toastId: 44
                // });
                enqueueSnackbar("Required Experience", { variant: "warning" });
                return false;
            } else if (
                data?.school === undefined ||
                data?.school === null ||
                data?.school === ""
            ) {
                // toast("Required School", {
                //     toastId: 49
                // });
                enqueueSnackbar("Required School", { variant: "warning" });
                return false;
            } else if (
                data?.graduation_year === undefined ||
                data?.graduation_year === null ||
                data?.graduation_year === ""
            ) {
                // toast("Required Graduation Year", {
                //     toastId: 50
                // });
                enqueueSnackbar("Required Graduation Year", { variant: "warning" });
                return false;
            } else if (
                data?.graduation_year > currentYear
            ) {
                // toast("Graduation year cannot be greater than the current year", {
                //     toastId: 51
                // });
                enqueueSnackbar("Graduation year cannot be greater than the current year", { variant: "warning" });
                return false;
            } else if (
                data?.CVFile === undefined ||
                data?.CVFile === null ||
                data?.CVFile === ""
            ) {
                // toast("Required CV/Resume", {
                //     toastId: 51
                // });
                enqueueSnackbar("Required CV/Resume", { variant: "warning" });
                return false;
            } else if (
                data?.BoardCertification === undefined ||
                data?.BoardCertification === null ||
                data?.BoardCertification === ""
            ) {
                // toast("Required Board Certification", {
                //     toastId: 52
                // });
                enqueueSnackbar("Required Board Certification", { variant: "warning" });
                return false;
                // } else if (
                //     data?.licensed_state_ids?.length === 0
                // ) {
                //     // toast("Required State Where you are practice", {
                //     //     toastId: 53
                //     // });
                //     enqueueSnackbar("Required State Where you are practice", { variant: "warning" });
                //     return false;
            } else if (
                data?.license_type_id === undefined ||
                data?.license_type_id === null ||
                data?.license_type_id === ""
            ) {
                // toast("Required License Type", {
                //     toastId: 54
                // });
                enqueueSnackbar("Required License Type", { variant: "warning" });
                return false;
            } else if (
                data?.isSpecify_license_type === true &&
                data?.license_type === undefined ||
                data?.license_type === null ||
                data?.license_type === ""
            ) {
                // toast("Please Specify license type", {
                //     toastId: 54
                // });
                enqueueSnackbar("Please Specify license type", { variant: "warning" });
                return false;
            } else if (
                data?.ExpirationDate === undefined ||
                data?.ExpirationDate === null ||
                data?.ExpirationDate === ""
            ) {
                // toast("Required Expiration Date", {
                //     toastId: 55
                // });
                enqueueSnackbar("Required Expiration Date", { variant: "warning" });
                return false;
            } else if (
                data?.LicenseFile === undefined ||
                data?.LicenseFile === null ||
                data?.LicenseFile === ""
            ) {
                // toast("Required License Document", {
                //     toastId: 56
                // });
                enqueueSnackbar("Required License Document", { variant: "warning" });
                return false;
            } else if (
                data?.treatment_ids === undefined ||
                data?.treatment_ids === null ||
                data?.treatment_ids === ""
            ) {
                // toast("Required What do You Treat?", {
                //     toastId: 57
                // });
                enqueueSnackbar("Required What do You Treat?", { variant: "warning" });
                return false;
            } else if (
                data?.NpiNumber === undefined ||
                data?.NpiNumber === null ||
                data?.NpiNumber === ""
            ) {
                // toast("Required NPI Number", {
                //     toastId: 59
                // });
                enqueueSnackbar("Required NPI Number", { variant: "warning" });
                return false;
            } else if (
                data?.NpiNumber?.length < 10
            ) {
                // toast("Enter valid NPI Number", {
                //     toastId: 98
                // });
                enqueueSnackbar("Required valid NPI Number", { variant: "warning" });
                return false;
            } else if (
                data?.about_experience === undefined ||
                data?.about_experience === null ||
                data?.about_experience === ""
            ) {
                // toast("Required Professional Experience and Specialization", {
                //     toastId: 60
                // });
                enqueueSnackbar("Required Professional Experience and Specialization", { variant: "warning" });
                return false;
            } else if (
                data?.about_experience?.length > 2000
            ) {
                // toast("Please enter experience under 250 words", {
                //     toastId: 70
                // });
                enqueueSnackbar("Please enter experience under 250 words", { variant: "warning" });
                return false;
            } else {
                var user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);
                var vbody = {
                    "user_id": user_id,
                    "register_as_id": data?.Id,
                    "school": data?.school,
                    "graduation_year": parseInt(data?.graduation_year),
                    "resume": data?.CVFile,
                    "qualification": data?.qualification,
                    "experience": data?.experience,
                    "language_ids": data?.languages?.map(o => o?.id),
                    "board_certification": data?.BoardCertification,
                    "licensed_state_ids": data?.licensed_state_ids,
                    "license_type_id": data?.license_type_id,
                    "license_type": data?.license_type,
                    "license_expiry_date": data?.ExpirationDate,
                    "license": data?.LicenseFile,
                    "treatment_ids": data?.treatment_ids?.map(o => o?.id),
                    "mental_disorder": data?.mental_disorder,
                    "additional_certificates": [data?.AdditionalCertificate ?? ""],
                    "npi_number": data?.NpiNumber,
                    "about_experience": data?.about_experience,
                }
                usOnBoardingExpertProfessionalDetails(vbody).then(resp => {
                    if (resp?.code === 200) {
                        // toast('Professional Details Saved Successfully', {
                        //     toastId: 61
                        // });
                        enqueueSnackbar("Professional Details Saved Successfully.", { variant: "success" });
                        localStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));
                        localStorage.setItem("RegisterAs", data?.Id);
                        props.onNext();
                    }
                    else {
                        // toast(resp?.data?.error ?? resp?.message, {
                        //     toastId: 62
                        // })
                        enqueueSnackbar(resp?.data?.error ?? resp?.message, { variant: "error" });
                    }
                })
            }
        } else if (data?.RegisterAs === "Psychologist") {
            if (
                data?.languages?.length === 0
            ) {
                // toast("Required Languages", {
                //     toastId: 43
                // });
                enqueueSnackbar("Required Languages", { variant: "warning" });
                return false;
            } else if (
                data?.experience === undefined ||
                data?.experience === null ||
                data?.experience === ""
            ) {
                // toast("Required Experience", {
                //     toastId: 44
                // });
                enqueueSnackbar("Required Experience", { variant: "warning" });
                return false;
            } else if (
                data?.school === undefined ||
                data?.school === null ||
                data?.school === ""
            ) {
                // toast("Required School", {
                //     toastId: 49
                // });
                enqueueSnackbar("Required School", { variant: "warning" });
                return false;
            } else if (
                data?.graduation_year === undefined ||
                data?.graduation_year === null ||
                data?.graduation_year === ""
            ) {
                // toast("Required Graduation Year", {
                //     toastId: 50
                // });
                enqueueSnackbar("Required Graduation Year", { variant: "warning" });
                return false;
            } else if (
                data?.graduation_year > currentYear
            ) {
                // toast("Graduation year cannot be greater than the current year", {
                //     toastId: 51
                // });
                enqueueSnackbar("Graduation year cannot be greater than the current year", { variant: "warning" });
                return false;
            } else if (
                data?.CVFile === undefined ||
                data?.CVFile === null ||
                data?.CVFile === ""
            ) {
                // toast("Required CV/Resume", {
                //     toastId: 51
                // });
                enqueueSnackbar("Required CV/Resume", { variant: "warning" });
                return false;
            } else if (
                data?.BoardCertification === undefined ||
                data?.BoardCertification === null ||
                data?.BoardCertification === ""
            ) {
                // toast("Required Board Certification", {
                //     toastId: 52
                // });
                enqueueSnackbar("Required Board Certification", { variant: "warning" });
                return false;
                // } else if (
                //     data?.licensed_state_ids?.length === 0
                // ) {
                //     // toast("Required State Where you are practice", {
                //     //     toastId: 53
                //     // });
                //     enqueueSnackbar("Required State Where you are practice", { variant: "warning" });
                //     return false;
            } else if (
                data?.license_type_id === undefined ||
                data?.license_type_id === null ||
                data?.license_type_id === ""
            ) {
                // toast("Required License Type", {
                //     toastId: 54
                // });
                enqueueSnackbar("Required License Type", { variant: "warning" });
                return false;
                // } else if (
                //     data?.isSpecify_license_type === true &&
                //     data?.license_type === undefined ||
                //     data?.license_type === null ||
                //     data?.license_type === ""
                // ) {
                //     // toast("Please Specify license type", {
                //     //     toastId: 54
                //     // });
                //     enqueueSnackbar("Please Specify license type", { variant: "warning" });
                //     return false;
            } else if (
                data?.ExpirationDate === undefined ||
                data?.ExpirationDate === null ||
                data?.ExpirationDate === ""
            ) {
                // toast("Required Expiration Date", {
                //     toastId: 55
                // });
                enqueueSnackbar("Required Expiration Date", { variant: "warning" });
                return false;
            } else if (
                data?.LicenseFile === undefined ||
                data?.LicenseFile === null ||
                data?.LicenseFile === ""
            ) {
                // toast("Required License Document", {
                //     toastId: 56
                // });
                enqueueSnackbar("Required License Document", { variant: "warning" });
                return false;
            } else if (
                data?.treatment_ids === undefined ||
                data?.treatment_ids === null ||
                data?.treatment_ids === ""
            ) {
                // toast("Required What do You Treat?", {
                //     toastId: 57
                // });
                enqueueSnackbar("Required What do You Treat?", { variant: "warning" });
                return false;
            } else if (
                data?.NpiNumber === undefined ||
                data?.NpiNumber === null ||
                data?.NpiNumber === ""
            ) {
                // toast("Required NPI Number", {
                //     toastId: 59
                // });
                enqueueSnackbar("Required NPI Number", { variant: "warning" });
                return false;
            } else if (
                data?.NpiNumber?.length < 10
            ) {
                // toast("Enter valid NPI Number", {
                //     toastId: 98
                // });
                enqueueSnackbar("Required valid NPI Number", { variant: "warning" });
                return false;
            } else if (
                data?.about_experience === undefined ||
                data?.about_experience === null ||
                data?.about_experience === ""
            ) {
                // toast("Required Professional Experience and Specialization", {
                //     toastId: 60
                // });
                enqueueSnackbar("Required Professional Experience and Specialization", { variant: "warning" });
                return false;
            } else if (
                data?.about_experience?.length > 2000
            ) {
                // toast("Please enter experience under 250 words", {
                //     toastId: 70
                // });
                enqueueSnackbar("Please enter experience under 250 words", { variant: "warning" });
                return false;
            } else {
                var user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);
                var vbody = {
                    "user_id": user_id,
                    "register_as_id": data?.Id,
                    "school": data?.school,
                    "graduation_year": parseInt(data?.graduation_year),
                    "resume": data?.CVFile,
                    "qualification": data?.qualification,
                    "experience": data?.experience,
                    "language_ids": data?.languages?.map(o => o?.id),
                    "board_certification": data?.BoardCertification,
                    "licensed_state_ids": data?.licensed_state_ids,
                    "license_type_id": data?.license_type_id,
                    "license_type": data?.license_type,
                    "license_expiry_date": data?.ExpirationDate,
                    "license": data?.LicenseFile,
                    "treatment_ids": data?.treatment_ids?.map(o => o?.id),
                    "mental_disorder": data?.mental_disorder,
                    "additional_certificates": [data?.AdditionalCertificate ?? ""],
                    "npi_number": data?.NpiNumber,
                    "about_experience": data?.about_experience,
                }
                usOnBoardingExpertProfessionalDetails(vbody).then(resp => {
                    if (resp?.code === 200) {
                        // toast('Professional Details Saved Successfully', {
                        //     toastId: 61
                        // });
                        enqueueSnackbar("Professional Details Saved Successfully.", { variant: "success" });
                        localStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));
                        localStorage.setItem("RegisterAs", data?.Id);
                        props.onNext();
                    }
                    else {
                        // toast(resp?.data?.error ?? resp?.message, {
                        //     toastId: 62
                        // })
                        enqueueSnackbar(resp?.data?.error ?? resp?.message, { variant: "error" });
                    }
                })
            }
        } else {
            // toast("Please select your profession.", {
            //     toastId: 63
            // })
            enqueueSnackbar("Please select your profession.", { variant: "warning" });
        }
    }

    const onChangeAutoLanguages = (event, values) => {
        setData({ ...data, languages: values })
    }

    const onChangeAutoAreaOfInterest = (event, values) => {
        setData({ ...data, areaOfInterest: values })
    }

    const handelSkip = () => {
        props.onNext();
    }

    return (
        <div>
            <div className='d-flex align-items-center gap-3 py-3 rounded-top px-3' style={{ background: "#DCD0EB" }}>
                <img src='/assets/img/work_black_24dp 2.png' alt='' />
                <p className='font-inter font-w500 text-black' style={{ color: "#21231E", fontSize: "24px" }}>Professional Details</p>
            </div>
            <div>
                <Row className="mx-3 my-2">
                    {/* <p className='font-inter font-w600 fs-6 p-2' style={{ color: "#21231E" }}>Personal Details</p> */}
                    <TextField
                        required
                        id="outlined-select-currency"
                        select
                        label="What would you like to register as?"
                        className='my-2'
                        value={data?.register_as_id}
                        onChange={(e) => onRegisterAsChange(e.target.value)}
                    >
                        {registerAsList?.map((item, index) => <MenuItem key={index} value={item?.id}>{item?.name}</MenuItem>)}
                    </TextField>
                </Row>
                <div className="d-flex gap-2 mx-3 my-3">
                    {data?.Register_fields?.language_ids &&
                        <Stack spacing={3} className='col-7'>
                            <Autocomplete
                                multiple
                                limitTags={2}
                                id="checkboxes-tags-demo"
                                options={languagesList}
                                defaultValue={data?.languages ?? null}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option?.name}
                                onChange={onChangeAutoLanguages}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            required
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option?.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Languages*" placeholder="Languages" />
                                )} />
                        </Stack>
                    }
                    {data?.Register_fields?.experience &&
                        // <TextField
                        //     onKeyPress={(event) => {
                        //         if (!/[0-9]/.test(event.key)) {
                        //             event.preventDefault();
                        //         }
                        //     }}
                        //     inputProps={{ minLength: 1, maxLength: 2 }}
                        //     required
                        //     value={data?.experience}
                        //     id="outlined-select-currency"
                        //     label="Experience (In Years)"
                        //     className='col-3'
                        //     onChange={(e) => setData({ ...data, experience: e.target.value })}
                        // />
                        <TextField
                            select
                            required
                            value={data?.experience}
                            id="outlined-select-experience"
                            label="Years of Experience"
                            className='col-5'
                            onChange={(e) => setData({ ...data, experience: e.target.value })}
                        >
                            {yearsOfExp?.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </TextField>
                    }
                </div>
            </div>
            <div className="mx-3 my-2">
                {data?.Register_fields?.documents &&
                    <p className='font-inter font-w600' style={{ color: "#21231E", fontSize: "14px" }}>
                        Please upload any relevant documents of relevant schooling, training, and/or training certifications (Optional)
                    </p>
                }
                <Row className=''>
                    {data?.Register_fields?.documents &&
                        <Form.Group as={Col} className="position-relative mb-3 mt-2 col-4">
                            {
                                schoolOrTrainingDocumentLoading &&
                                <>
                                    <div className="file-upload">
                                        <div className="upload-icon h-100 col-2">
                                            <UploadFileRoundedIcon />
                                        </div>
                                        <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                                            <LinearProgress value={schoolOrTrainingDocumentPercent} />
                                        </div>
                                    </div>
                                </>
                                ||
                                (trainingCertificateDocDetail ?
                                    <>
                                        <div className="file-upload">
                                            <div className="upload-icon h-100 col-2">
                                                <UploadFileRoundedIcon />
                                            </div>
                                            <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                                                <p className="font-inter"
                                                    style={{
                                                        color: "#6A6C6A",
                                                        fontSize: 14
                                                    }}>
                                                    {trainingCertificateDocDetail.name}
                                                </p>
                                                <CloseIcon onClick={() => setTrainingCertificateDocDetail(null)} />
                                            </div>
                                        </div>
                                    </>
                                    :

                                    <>
                                        <Form.Control
                                            multiple
                                            size='md'
                                            accept="image/*,.pdf"
                                            type="file"
                                            required
                                            name="file"
                                            placeholder='Upload ID Proof'
                                            onChange={handleFileUpload}
                                            className='file-input d-none'
                                            ref={trainingCertificate}
                                        />
                                        <div className="file-upload" onClick={() => trainingCertificate.current.click()}>
                                            <div className="upload-icon h-100 col-2">
                                                <UploadFileRoundedIcon />
                                            </div>
                                            <div className="h-100 d-flex align-items-center w-100 ps-3">
                                                <p className="font-inter"
                                                    style={{
                                                        color: "#6A6C6A",
                                                        fontSize: 14
                                                    }}>
                                                    Upload
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {/* {data?.schoolOrTrainingDocument &&
                                <Link onClick={handleClearButtonClick}>Clear</Link>
                            } */}
                            <Form.Label className='font-inter font-w500' style={{ color: "#6A6C6A", fontSize: "14px" }}>
                                Only .jpg, .jpeg, .png, .pdf allowed up to 5mb
                            </Form.Label>
                        </Form.Group>
                    }
                </Row>
                <Row>
                    {data?.Register_fields?.areas_of_interest &&
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={area_interestList}
                            value={data?.areaOfInterest ?? null}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option?.name}
                            onChange={onChangeAutoAreaOfInterest}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option?.name}
                                </li>
                            )}
                            style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField required {...params} label="Areas of interest" placeholder="Areas of interest" />
                            )} />
                    }
                </Row>
            </div>

            <Row className="mx-3 my-2 gap-3">
                {data?.Register_fields?.school &&
                    <TextField
                        inputProps={{ minLength: 10, maxLength: 100 }}
                        required
                        id="outlined-select-currency"
                        label="School"
                        className='col-7'
                        value={data?.school}
                        onChange={(e) => setData({ ...data, school: e.target.value })}
                    />
                }
                {data?.Register_fields?.graduation_year &&
                    // <TextField
                    //     required
                    //     id="outlined-select-currency"
                    //     label="Graduation/Doctorate Year"
                    //     className='col-4'
                    //     inputProps={{ minLength: 4, maxLength: 4, min: 1960 }}
                    //     onKeyPress={(event) => {
                    //         if (!/[0-9]/.test(event.key)) {
                    //             event.preventDefault();
                    //         }
                    //     }}
                    //     value={data?.graduation_year}
                    //     onChange={(e) => {
                    //         const year = e.target.value;
                    //         if (year <= currentYear) {
                    //             setData({ ...data, graduation_year: year });
                    //         } else {
                    //             toast("Graduation year cannot be greater than the current year", {
                    //                 toastId: 51
                    //             });
                    //         }
                    //     }}
                    // />

                    <TextField
                        select
                        label="Year of completion"
                        className='col-4'
                        onChange={handleYearChange}
                        helperText="Graduation/Doctorate"
                        variant="outlined"
                        value={data?.graduation_year}
                    // onChange={(e) => {
                    //     const year = e.target.value;
                    //     if (year <= currentYear) {
                    //         setData({ ...data, graduation_year: year });
                    //     } else {
                    //         // toast("Graduation year cannot be greater than the current year", {
                    //         //     toastId: 51
                    //         // });
                    //         enqueueSnackbar("Graduation year cannot be greater than the current year", { variant: 'warning' })
                    //     }
                    // }}
                    >
                        {years?.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </TextField>
                }
            </Row>

            <div className='d-flex gap-2 mx-3 my-2'>
                {data?.Register_fields?.resume &&
                    <div className="col-5">
                        <p className='font-inter font-w600 px-3' style={{ color: "#21231E", fontSize: "14px" }}>
                            Please upload your CV/Resume <br />
                            <br />
                            {" "}
                        </p>
                        <div className='d-flex gap-2 mx-2 my-2'>
                            <Form.Group as={Col} className="position-relative mb-3">
                                {
                                    cVFileLoading &&
                                    <div className="file-upload">
                                        <div className="upload-icon h-100 col-2">
                                            <UploadFileRoundedIcon />
                                        </div>
                                        <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                                            <LinearProgress value={cvFilePercent} />
                                        </div>
                                    </div>
                                    ||
                                    (cvDocDetail ?
                                        <>
                                            <div className="file-upload">
                                                <div className="upload-icon h-100 col-2">
                                                    <UploadFileRoundedIcon />
                                                </div>
                                                <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                                                    <p className="font-inter"
                                                        style={{
                                                            color: "#6A6C6A",
                                                            fontSize: 14
                                                        }}>
                                                        {cvDocDetail.name}
                                                    </p>
                                                    <CloseIcon onClick={() => setCvDocDetail(null)} />
                                                </div>
                                            </div>
                                        </>
                                        :

                                        <>
                                            <Form.Control
                                                multiple
                                                type="file"
                                                accept=".pdf"
                                                required
                                                name="file"
                                                placeholder='CV/Resume'
                                                onChange={handleCVFileUpload}
                                                className='d-none'
                                                ref={cvRef}
                                            />
                                            <div className="file-upload" onClick={() => cvRef.current.click()}>
                                                <div className="upload-icon h-100 col-2">
                                                    <UploadFileRoundedIcon />
                                                </div>
                                                <div className="h-100 d-flex align-items-center w-100 ps-3">
                                                    <p className="font-inter"
                                                        style={{
                                                            color: "#6A6C6A",
                                                            fontSize: 14
                                                        }}>
                                                        Upload
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                <Form.Label className='font-inter font-w500' style={{ color: "#6A6C6A", fontSize: "14px", whiteSpace: "nowrap" }}>
                                    Only .pdf allowed up to 5mb
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                }
                {data?.Register_fields?.board_certification &&
                    <div className="col-7">
                        <p className='font-inter font-w600 px-3' style={{ color: "#21231E", fontSize: "14px" }}>
                            Please upload your board certification (Masters for Therapist/Doctorate & Doctorate for Psychologist)
                        </p>
                        <div className='d-flex gap-2 mx-2 my-2'>
                            <Form.Group as={Col} className="position-relative mb-3">
                                {
                                    schoolOrTrainingDocumentLoading &&
                                    <>
                                        <div className="file-upload">
                                            <div className="upload-icon h-100 col-2">
                                                <UploadFileRoundedIcon />
                                            </div>
                                            <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                                                <LinearProgress value={schoolOrTrainingDocumentPercent} />
                                            </div>
                                        </div>
                                    </>
                                    ||
                                    (boardCertificateDocDetail ?
                                        <>
                                            <div className="file-upload">
                                                <div className="upload-icon h-100 col-2">
                                                    <UploadFileRoundedIcon />
                                                </div>
                                                <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                                                    <p className="font-inter"
                                                        style={{
                                                            color: "#6A6C6A",
                                                            fontSize: 14
                                                        }}>
                                                        {boardCertificateDocDetail.name}
                                                    </p>
                                                    <CloseIcon onClick={() => setBoardCertificateDocDetail(null)} />
                                                </div>
                                            </div>
                                        </>
                                        :

                                        <>
                                            <Form.Control
                                                multiple
                                                type="file"
                                                required
                                                accept="image/*,.pdf"
                                                name="file"
                                                placeholder='Upload Board Certificate'
                                                onChange={handleBoardCertificationFileUpload}
                                                ref={boardCertificateRef}
                                                className='d-none'
                                            />
                                            <div className="file-upload" onClick={() => boardCertificateRef.current.click()}>
                                                <div className="upload-icon h-100 col-2">
                                                    <UploadFileRoundedIcon />
                                                </div>
                                                <div className="h-100 d-flex align-items-center w-100 ps-3">
                                                    <p className="font-inter"
                                                        style={{
                                                            color: "#6A6C6A",
                                                            fontSize: 14
                                                        }}>
                                                        Upload
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                <Form.Label className='font-inter font-w500' style={{ color: "#6A6C6A", fontSize: "14px", whiteSpace: "nowrap" }}>
                                    Only .jpg, .jpeg, .png, .pdf allowed up to 5mb
                                </Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                }
            </div>
            <div className="px-2 my-2">
                <div className='d-flex gap-2 mx-2 my-2'>
                    {data?.Register_fields?.licensed_state_ids &&
                        <Stack spacing={3} className='my-2 col-4'>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={licenseStateList}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option?.name}
                                onChange={onLicenseStateChange}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            required
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option?.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="State where you are licensed to practice?*" placeholder="State where you are licensed to practice?" />
                                )} />
                        </Stack>
                    }
                    {data?.Register_fields?.license_type_id &&
                        <>
                            <TextField
                                required
                                id="outlined-select-currency1"
                                select
                                label="Choose License Type"
                                className='my-2 col-4'
                                onChange={(e) => onLicenseTypeChange(e.target.value)}
                                value={data?.license_type_id}
                            >
                                {licenseTypeList?.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
                            </TextField>
                            <TextField
                                required
                                disabled={isSpecify_license_type}
                                id="outlined"
                                onKeyPress={(event) => {
                                    if (!/[0-9a-zA-Z]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                label="Specify other licence type"
                                className='my-2 w-75'
                                value={data?.license_type}
                                onChange={
                                    (e) => setData({ ...data, license_type: e.target.value })
                                }
                            />
                        </>
                    }
                </div>
            </div>
            <div className="mx-2 my-2">
                <div className='d-flex gap-3 mx-2 my-2'>
                    {data?.Register_fields?.license_expiry_date &&
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Date of expiration *"
                                className='col-4'
                                defaultValue={dayjs(data?.ExpirationDate)}
                                minDate={dayjs()}
                                onChange={(newValue) => setData({ ...data, ExpirationDate: moment(newValue.toDate()).format("YYYY-MM-DD") })}
                            />
                        </LocalizationProvider>
                    }
                    {data?.Register_fields?.license &&
                        <Form.Group as={Col} className="position-relative mb-3">
                            {/* <label className='font-inter font-w600' style={{ color: "#21231E", fontSize: "14px" }}>Upload your license</label> */}
                            {
                                licenseFileLoading &&
                                <>
                                    <div className="file-upload">
                                        <div className="upload-icon h-100 col-2">
                                            <UploadFileRoundedIcon />
                                        </div>
                                        <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                                            <LinearProgress value={licenseFilePercent} />
                                        </div>
                                    </div>
                                </>
                                ||
                                (licenseDocDetail ?
                                    <>
                                        <div className="file-upload">
                                            <div className="upload-icon h-100 col-2">
                                                <UploadFileRoundedIcon />
                                            </div>
                                            <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                                                <p className="font-inter"
                                                    style={{
                                                        color: "#6A6C6A",
                                                        fontSize: 14
                                                    }}>
                                                    {licenseDocDetail.name}
                                                </p>
                                                <CloseIcon onClick={() => setLicenseDocDetail(null)} />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <Form.Control
                                            multiple
                                            type="file"
                                            accept="image/*,.pdf"
                                            required
                                            name="file"
                                            placeholder='Upload License'
                                            onChange={handleLicenseFileUpload}
                                            ref={licenseDocRef}
                                            className='d-none'
                                        />
                                        <div className="file-upload" onClick={() => licenseDocRef.current.click()}>
                                            <div className="upload-icon h-100 col-2">
                                                <UploadFileRoundedIcon />
                                            </div>
                                            <div className="h-100 d-flex align-items-center w-100 ps-3">
                                                <p className="font-inter"
                                                    style={{
                                                        color: "#6A6C6A",
                                                        fontSize: 14
                                                    }}>
                                                    Upload
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            <Form.Label className='font-inter font-w500' style={{ color: "#6A6C6A", fontSize: "14px", whiteSpace: "nowrap" }}>
                                Only .jpg, .jpeg, .png, .pdf allowed up to 5mb
                            </Form.Label>
                        </Form.Group>
                    }
                </div>
            </div>
            <div className="px-2 my-2">
                <div className='d-flex gap-2 mx-2 my-2'>
                    {data?.Register_fields?.treatment_ids &&
                        <Stack spacing={3} className='my-2 col-4'>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={treatmentList}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option?.name}
                                onChange={onTreamentChange}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            required
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option?.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="What do you treat?*" placeholder="What do you treat?" />
                                )} />
                        </Stack>
                    }
                    {data?.Register_fields?.qualification &&
                        <TextField
                            required
                            id="outlined"
                            label="Qualification"
                            className='my-2 w-75'
                            value={data?.qualification}
                            onChange={(e) => setData({ ...data, qualification: e.target.value })}
                        />
                    }
                </div>
            </div>
            <div className="px-2 my-2">
                {data?.Register_fields?.additional_certificates &&
                    <p className='font-inter font-w600 px-2' style={{ color: "#21231E", fontSize: "14px" }}>
                        Upload any additional certifications (Optional)
                    </p>
                }
                <div className='d-flex gap-2 mx-2 my-2'>
                    {data?.Register_fields?.additional_certificates &&
                        <Form.Group as={Col} className="position-relative mb-3 mt-2">
                            {
                                additionalCertificateLoading &&
                                <>
                                    <div className="file-upload">
                                        <div className="upload-icon h-100 col-2">
                                            <UploadFileRoundedIcon />
                                        </div>
                                        <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                                            <LinearProgress value={additionalCertificatePercent} />
                                        </div>
                                    </div>
                                </>
                                ||
                                (
                                    // additionalCertificatesDetail ?
                                    // <>
                                    //     <div className="file-upload">
                                    //         <div className="upload-icon h-100 col-2">
                                    //             <UploadFileRoundedIcon />
                                    //         </div>
                                    //         <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                                    //             <p className="font-inter"
                                    //                 style={{
                                    //                     color: "#6A6C6A",
                                    //                     fontSize: 14
                                    //                 }}>
                                    //                 {additionalCertificatesDetail?.name}
                                    //             </p>
                                    //             <CloseIcon onClick={() => setAdditionalCertificateDetail(null)} />
                                    //         </div>
                                    //     </div>
                                    //     <Button>
                                    //         <AddIcon />
                                    //     </Button>
                                    // </>
                                    // :
                                    <>
                                        <Form.Control
                                            multiple
                                            type="file"
                                            required
                                            name="file"
                                            accept="image/*,.pdf"
                                            placeholder='Upload Additional certifications'
                                            className='my-2 d-none'
                                            onChange={handleAdditionalCertificateFileUpload}
                                            ref={additionalCertificatesRef}
                                        />
                                        <div className="file-upload" onClick={() => additionalCertificatesRef.current.click()}>
                                            <div className="upload-icon h-100 col-2">
                                                <UploadFileRoundedIcon />
                                            </div>
                                            <div className="h-100 d-flex align-items-center w-100 ps-3">
                                                <p className="font-inter"
                                                    style={{
                                                        color: "#6A6C6A",
                                                        fontSize: 14
                                                    }}>
                                                    Upload
                                                </p>
                                            </div>
                                            <Button variant="outlined" className='m-1' sx={{ borderColor: '#21231E', aspectRatio: 1 }}>
                                                <AddIcon sx={{ color: '#21231E' }} />
                                            </Button>
                                        </div>
                                    </>
                                )
                            }
                            <Form.Label className='font-inter font-w500' style={{ color: "#6A6C6A", fontSize: "14px", whiteSpace: "nowrap" }}>
                                Only .jpg, .jpeg, .png, .pdf allowed up to 5mb
                            </Form.Label>
                            {additionalCertificatesDetail?.length > 0 &&
                                additionalCertificatesDetail?.map((item) =>
                                    <div className="d-flex align-items-center justify-content-between w-100 my-2">
                                        <p className="font-inter"
                                            style={{
                                                color: "#6A6C6A",
                                                fontSize: 14
                                            }}>
                                            {item?.fileName}
                                        </p>
                                        <CloseIcon onClick={() => setAdditionalCertificateDetail(null)} />
                                    </div>
                                )}
                        </Form.Group>
                    }
                    {data?.Register_fields?.npi_number &&
                        <TextField
                            required
                            inputProps={{ minLength: 10, maxLength: 10, mask: "xxx-xx-xxxx" }}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            id="outlined"
                            label="Provide your NPI number"
                            className='my-2 w-75'
                            value={data?.NpiNumber}
                            onChange={(e) => setData({ ...data, NpiNumber: e.target.value })}
                        />
                    }
                </div>
            </div>
            {
                data?.Register_fields?.about_experience &&
                <Row className="mx-2 my-2">
                    <p className='font-inter font-w600' style={{ color: "#21231E", fontSize: "14px" }}>
                        Write about your professional experience and specialization
                    </p>
                    <Form.Group className="mb-3 col-12" controlId="exampleForm.ControlTextarea1">
                        {/* <Form.Control as="textarea"
                            maxLength={2000}
                            onKeyDown={(event) => {
                                if (!/[0-9a-zA-Z. -&%+/,'"]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            placeholder="This text will appear on the client's screen"
                            rows={3}
                            value={data?.about_experience}
                            onChange={(e) => setData({ ...data, about_experience: e.target.value })}
                        />
                        <Form.Label className='ms-auto font-inter font-w600 col-12' style={{ textAlign: "end", color: "#6A6C6A", fontSize: "16px" }}>Approx 200 to 250 Words</Form.Label> */}
                        <TextField
                            maxRows={4}
                            onKeyDown={(event) => {
                                if (!/[0-9a-zA-Z. -&%+/,'"]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            id="outlined-multiline-static"
                            label="This text will appear on the client's screen"
                            multiline
                            value={data?.about_experience}
                            rows={4}
                            onChange={(e) => setData({ ...data, about_experience: e.target.value })}
                            className='w-100 mt-3'
                        />
                    </Form.Group>
                </Row>
            }

            <div className='d-flex align-items-center justify-content-end my-5'>
                <ButtonGroup className='d-flex flex-row align-items-center justify-content-end gap-4 me-3 my-3'>
                    <Button
                        className='rounded-3 border-0 text-white'
                        style={{ backgroundColor: "#73589B", whiteSpace: "nowrap", textTransform: 'none' }}
                        onClick={onClickProfileData}>
                        Next
                    </Button>
                </ButtonGroup>
            </div>
            <ToastContainer />
        </div >
    )
}

export default RegisterAsProfessionalProfessionalDetailTab
