import React, { useEffect, useState } from "react";
import "./Booking.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ReadMoreModal from "./ReadMoreModal";
import StripeCheckout from "react-stripe-checkout";
import {
  getExpertbyId_WebService,
  AddAppointmentUserService,
  AddUserPackageService,
  UserDetailsService,
  AddTransactionService,
  UpdateTransactionService,
  subscription_subscribe_odoo,
} from "../../components/core/_request";
import { SubscriptionPacks } from "../../components/subscriptions/core/request";
import {
  getCountryCode,
  getCurrencyCode,
  formatDate,
} from "../../components/core/commonFun";
import { useAuth } from "../../auth";
import moment from "moment";
import { useSnackbar } from "notistack";

const BookingPreview = () => {
  let { id } = useParams();
  const { currentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const countryCode = getCountryCode();
  const { enqueueSnackbar } = useSnackbar();
  const publishable_key = process.env.REACT_APP_STRIPE_KEY;
  const appointment_Details = location?.state?.appointmentData;
  const insurance_Details = location?.state?.insuranceDetails;
  const discount_percentage = location?.state?.discount_percentage;
  const [packsData, setPacksData] = useState({});
  const [expertDetailsData, setExpertDetailsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [mainAmountAfterCoupone, setMainAmountAfterCoupone] = useState();
  const [optionData, setOptionData] = useState();
  // const [dataLayerJSON, setDataLayerJSON] = useState(null);
  const [amountToPay, setAmountToPay] = useState(0);

  useEffect(() => {
    var body = { id: id };
    SubscriptionPacks(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        var price = resp?.data?.packs?.find((p) => p?.id === parseInt(id))
          ?.available[0];
        setOptionData(price);
        setPacksData(resp?.data);
      }
    });
    getExpertbyId_WebService(id).then((resp) => {
      var data = resp.data;
      if (countryCode == "IN")
        data["countryAmount"] = data.Hourly_Consultaion_Fee_amount;
      else data["countryAmount"] = data.USD_Amount;
      setExpertDetailsData(data);
      console.log(resp.data);
    });
  }, []);

  const [bookingInformation, setBookingInformation] = useState({
    type: "Video Session",
    date: "20 March 2024",
    time: "09:00 AM (IST)",
    phone: "+91 9876543210",
    duration: "60 Min Individual Session",
    insuranceBenefits: true,
    insuranceList: [
      {
        isSelected: true,
        insuranceName: "HDFC Ergo Health Insurance",
        verfied: true,
        memberID: "28768947657",
        policy: "4094294300",
        color: "green",
      },
      {
        isSelected: false,
        insuranceName: "Tata General Insurance Co. Ltd.",
        verfied: true,
        memberID: "28768947657",
        policy: "4094294300",
        color: "gray",
      },
    ],
  });

  const [billDetails, setBillDetails] = useState(null);
  const [readMoreModalOpen, setReadMoreModalOpen] = useState(false);
  const searchBarParams = new URLSearchParams(window.location.search);
  let insuranceBenefits = searchBarParams.get("query");

  useEffect(() => {
    let payable_amount;
    if (insurance_Details) {
      let cover_amount =
        expertDetailsData?.USD_Amount - insurance_Details?.payment;
      payable_amount = expertDetailsData?.USD_Amount - cover_amount;
      setAmountToPay(payable_amount);
    } else if (discount_percentage) {
      payable_amount =
        expertDetailsData?.USD_Amount -
        (expertDetailsData?.USD_Amount / 100) * discount_percentage;
      setAmountToPay(payable_amount);
    } else {
      setAmountToPay(expertDetailsData?.USD_Amount);
    }
  }, [discount_percentage, expertDetailsData]);

  useEffect(() => {
    if (insuranceBenefits === "true") {
      setBillDetails({
        withInsurance: true,
        name: "John Doe",
        email: "johnDoe@tmail.com",
        mobile: "0987654321",
        insuranceCompany: "HDFC Life Insurance",
        memberID: "28768947657",
        policy: "4094294300",
        totalCharge: "$100.00",
        coveredAmount: "$70.00",
        deductible: "$10.00",
        copay: "$10.00",
        coinsurance: "$10.00",
        billAmount: "$30.00",
      });
    } else {
      setBillDetails({
        withInsurance: false,
        name: "John Doe",
        email: "johnDoe@tmail.com",
        mobile: "0987654321",
        billAmount: "$100.00",
      });
    }
  }, [insuranceBenefits]);

  const addTransaction = (
    response,
    AddUserPackageData,
    amountToPay,
    UserDetails,
    Indianpackagewithprice
  ) => {
    let rendId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      "_web";
    var body = {
      ExpertId: appointment_Details?.selectSlot?.Expert,
      PackageId: AddUserPackageData.PackageId,
      TransactionId: rendId,
      //Amount:this.state.Indianpackagewithprice.Amount,
      Amount: amountToPay,
      //Coupon: this.state.CouponCode,
      isMarkAutoRenew: false,
      TransactionType: "Credit Card",
      PaymentGateway_OrderId: "",
      Billing_Address_Master: {
        TransactionId: rendId,
        Name: UserDetails.Name,
        Email: UserDetails.Emailid,
        Mobile: UserDetails.MobileNo,
        //Address:this.state.UserDetails.Address.City+", "+this.state.UserDetails.Address.Country+", "+this.state.UserDetails.Address.Pin+" "
      },
      // DiscountAmount: (countryCode == "IN" ? (Indianpackagewithprice.Amount - amountToPay) : 0),
      DiscountAmount: 0,
      PaymentGateway_OrderId: response?.razorpay_payment_id,
    };

    AddTransactionService(body).then((item) => {
      var tbody = { TransactionId: rendId, Status: "Completed" };
      UpdateTransactionService(tbody).then((tranRes) => {
        if (tranRes.ResponseCode === 200) {
          navigate("/booking/status", {
            state: {
              appointment_Details: appointment_Details,
              expertDetails: expertDetailsData,
              resposeData: tranRes.data,
            },
          });
        } else {
          navigate("/booking/status", {
            state: {
              appointment_Details: appointment_Details,
              expertDetails: expertDetailsData,
              resposeData: "Transaction Failed",
            },
          });
        }
        // navigate("/payment-success");
      });
    });
  };

  const Stripe_Payment = async (token) => {
    setLoading(true);

    try {
      const body = {
        stripeToken: token.id,
        amount: amountToPay,
        currency: "USD",
        ExpertId: appointment_Details?.selectSlot?.Expert, //Expert_ID
        AppointmentDate: moment(appointment_Details?.selectedDate).format(
          "YYYY-MM-DD"
        ),
        App_StartTime: appointment_Details?.selectSlot?.Time_Duration,
        CallMode: "Video_Call",
        CallTime: appointment_Details?.callTime,
      };

      const AddAppointmentresp = await AddAppointmentUserService(body);

      if (AddAppointmentresp.ResponseCode === 200) {
        const tbody = {
          ExpertId: appointment_Details?.selectSlot?.Expert, //Expert_ID
          PackageName: "Pay As You go - USA",
          Country: currentUser.Country,
          Type: expertDetailsData?.RegisterYourselfAs,
          HSTAmount: 0,
          AppointmentCount: 0,
          DurationInMonths: 0,
          Amount: amountToPay,
        };

        const resp = await AddUserPackageService(tbody);

        const UserDetails = await UserDetailsService().then(
          (resp) => resp.data
        );
        const response = {
          razorpay_payment_id: AddAppointmentresp?.data?.PaymentId,
        };

        addTransaction(
          response,
          resp.data,
          amountToPay,
          UserDetails,
          appointment_Details?.data2
        );
      } else {
        setLoading(false);
        enqueueSnackbar(AddAppointmentresp.ResponseMessage, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error in Stripe_Payment:", error);
      setLoading(false);
      enqueueSnackbar("An error occurred during payment processing.", {
        variant: "error",
      });
    }
  };

  const bookFreeAppointmentHandler = async () => {
    try {
      const body = {
        amount: amountToPay,
        currency: "USD",
        ExpertId: appointment_Details?.selectSlot?.Expert,
        AppointmentDate: moment(appointment_Details?.selectedDate).format(
          "YYYY-MM-DD"
        ),
        App_StartTime: appointment_Details?.selectSlot?.Time_Duration,
        CallMode: "Video_Call",
        CallTime: appointment_Details?.callTime,
      };

      const AddAppointmentresp = await AddAppointmentUserService(body);

      if (AddAppointmentresp.ResponseCode === 200) {
        const tbody = {
          ExpertId: appointment_Details?.selectSlot?.Expert,
          PackageName: "Pay As You go - USA",
          Country: currentUser.Country,
          Type: expertDetailsData?.RegisterYourselfAs,
          HSTAmount: 0,
          AppointmentCount: 0,
          DurationInMonths: 0,
          Amount: amountToPay,
        };

        const resp = await AddUserPackageService(tbody);

        const UserDetails = await UserDetailsService().then(
          (resp) => resp.data
        );
        const response = {
          razorpay_payment_id: AddAppointmentresp?.data?.PaymentId,
        };

        addTransaction(
          response,
          resp.data,
          amountToPay,
          UserDetails,
          appointment_Details?.data2
        );
      } else {
        setLoading(false);
        enqueueSnackbar(AddAppointmentresp.ResponseMessage, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error in Stripe_Payment:", error);
      setLoading(false);
      enqueueSnackbar("An error occurred during payment processing.", {
        variant: "error",
      });
    }
  };

  return (
    <div className="Booking--Page">
      <div className="Booking--Container">
        <div className="Booking--Container__Left d-flex flex-column justify-content-between">
          <div className="Expert--Info">
            <div className="d-flex align-items-center gap-3 Expert--Info__Header">
              <img
                src={expertDetailsData?.ProfileImage}
                alt={expertDetailsData?.Name}
              />

              <div>
                <h3>{expertDetailsData?.Name}</h3>
                <p>{expertDetailsData?.RegisterYourselfAs}</p>
              </div>
            </div>

            <div className="Expert--Info__Content">
              <div className="d-flex align-items-center gap-2">
                <AssignmentOutlinedIcon />
                <p>{`${expertDetailsData?.Experience} Years`}</p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <LanguageOutlinedIcon />
                <p>{expertDetailsData?.Languages}</p>
              </div>
              <div className="d-flex align-items-start gap-2 Content--Qualifications">
                <p>
                  <span>Qualifications:</span>{" "}
                  {expertDetailsData?.Qualifications}
                </p>
              </div>
            </div>
          </div>
          {amountToPay > 0 ? (
            <>
              {" "}
              {(loading && (
                <div role="button" className="PrimaryCTA">
                  Processing.... wait
                </div>
              )) || (
                <StripeCheckout
                  token={Stripe_Payment}
                  stripeKey={publishable_key}
                  name={currentUser?.Name}
                  description="Book your slot with UWC expert"
                  image="https://app.uwc.world/Content/adminlogo.png" // the pop-in header image (default none)
                  ComponentClass="div"
                  panelLabel="Pay" // prepended to the amount in the bottom pay button
                  amount={
                    Math.round(
                      mainAmountAfterCoupone ?? optionData?.price_usd
                    ) * 100
                  } // cents
                  currency={"USD"}
                  email={currentUser?.Emailid}
                  locale="auto"
                  allowRememberMe
                >
                  <div role="button" className="PrimaryCTA">
                    Confirm Booking
                  </div>
                </StripeCheckout>
              )}
            </>
          ) : (
            <div
              role="button"
              className="PrimaryCTA"
              onClick={bookFreeAppointmentHandler}
            >
              Confirm Booking
            </div>
          )}
        </div>

        <div className="Booking--Container__Right">
          <div className="Booking--Info">
            <div className="Info--Content">
              <h6>{bookingInformation?.type}</h6>
              <div className="Info--Grid">
                <div className="d-flex align-items-start gap-2">
                  <CalendarMonthOutlinedIcon />
                  <p>{formatDate(appointment_Details?.selectedDate)}</p>
                </div>
                <div className="d-flex align-items-start gap-2">
                  <AccessTimeOutlinedIcon />
                  <p>{appointment_Details?.selectSlot?.Time_Duration}</p>
                </div>
                <div className="d-flex align-items-start gap-2">
                  <PeopleAltOutlinedIcon />
                  <p>{`${appointment_Details?.callTime} Min Individual Session`}</p>
                </div>
              </div>
            </div>

            {/* <button className="Edit--Button">
              <EditOutlinedIcon />
            </button> */}
          </div>

          <div className="Bill--Container">
            <div>
              <p>Bill to: </p>
              <div>
                <div>
                  {billDetails !== null &&
                    (billDetails.withInsurance == true ? (
                      <>
                        <div className="lh-lg">
                          <div className="d-flex justify-content-between">
                            <div className="bill-key">Name</div>
                            <div className="bill-value">{currentUser.Name}</div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="bill-key">Email</div>
                            <div className="bill-value">
                              {currentUser.Emailid}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="bill-key">Mobile</div>
                            <div className="bill-value">
                              {currentUser.MobileNo}
                            </div>
                          </div>
                          {insurance_Details && (
                            <div className="d-flex justify-content-between">
                              <div className="bill-key">Insurance Company</div>
                              <div className="bill-value">
                                {insurance_Details?.insuring_company}
                              </div>
                            </div>
                          )}

                          {/* <div className="d-flex justify-content-between">
                            <div className="bill-key">Policy Number</div>
                            <div className="bill-value">
                              {billDetails.policy}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="bill-key">Member ID</div>
                            <div className="bill-value">
                              {billDetails.memberID}
                            </div>
                          </div> */}
                          <div className="d-flex justify-content-between">
                            <div className="bill-key">Total Charge</div>
                            <div className="bill-value">
                              {`$ ${expertDetailsData?.USD_Amount}`}
                            </div>
                          </div>
                          {insurance_Details && (
                            <div className="d-flex justify-content-between">
                              <div className="bill-key">
                                Insurance Cover Amount
                              </div>
                              <div className="bill-value">
                                {`$ ${
                                  Number(expertDetailsData?.USD_Amount) -
                                  Number(insurance_Details.payment)
                                }`}
                              </div>
                            </div>
                          )}

                          {/* <div className="d-flex justify-content-between">
                            <div className="bill-key">Deductible</div>
                            <div className="bill-value">
                              {billDetails.deductible}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="bill-key">Co-pay</div>
                            <div className="bill-value">
                              {billDetails.copay}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="bill-key">Co-Insurance</div>
                            <div className="bill-value">
                              {billDetails.coinsurance}
                            </div>
                          </div> */}
                          <div className="d-flex justify-content-between">
                            <div className="bill-key fw-semibold">
                              Total amount to be paid*
                            </div>
                            <div className="bill-value fw-semibold">
                              {`$ ${amountToPay}`}
                            </div>
                          </div>
                        </div>
                        <div className="disclaimer-text my-3">
                          *Insurance payments may adjust post-service, possibly
                          affecting your balance. We'll communicate any changes
                          promptly.{" "}
                          <span
                            className="read-more-text"
                            onClick={() => setReadMoreModalOpen(true)}
                          >
                            read more
                          </span>
                          <ReadMoreModal
                            open={readMoreModalOpen}
                            setOpen={setReadMoreModalOpen}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="lh-lg">
                        <div className="d-flex justify-content-between">
                          <div className="bill-key">Name</div>
                          <div className="bill-value">{currentUser.Name}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="bill-key">Email</div>
                          <div className="bill-value">
                            {currentUser.Emailid}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="bill-key">Mobile</div>
                          <div className="bill-value">
                            {currentUser.MobileNo}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="bill-key fw-semibold">
                            Total amount to be paid*
                          </div>
                          <div className="bill-value fw-semibold">
                            {`$ ${amountToPay}`}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPreview;
