
import React from 'react'
import Search from '../components/Search'
import { Helmet } from 'react-helmet'

function home() {
  return (
    <div style={{ background: "#F7F2FF", minHeight: "100vh", margin: "0" }}>
      <Helmet>
        <title>
          Find a Professional | Mental Wellness Support | United We Care
        </title>
      </Helmet>
      <Search />
    </div>

  )
}

export default home;
