import { Input } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";
import { useAuth } from "../../../auth";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getToken } from "../APIS/clientAPIS";
import Cookies from "js-cookie";

//JWT
import jwtDecode from "jwt-decode";
import { handleBrokenImage } from "../../../lib/utils";
import { validate_token } from "../../core/_request";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, userType, logout, login, setCurrentUser } = useAuth();
  const [bearerToken, setBearerToken] = useState(
    sessionStorage.getItem("token")
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const cookie_token = Cookies.get("authToken")

  useEffect(() => {
    if (bearerToken == undefined || bearerToken == null || bearerToken == "") {
      let isMounted = true; // Flag to check if the component is still mounted

      getToken(currentUser?.Emailid).then((res) => {
        setBearerToken(res);
      });
    }
  }, [bearerToken]);

  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log("SIGNED IN");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
    validateUserByToken();
  }, []);

  const validateUserByToken = () => {
    if (cookie_token && !currentUser) {
      validate_token(cookie_token).then((resp) => {
        if (resp?.ResponseCode === 200) {
          login(resp?.data);
          setCurrentUser(resp?.data?.userdetails)
          let now = new Date();
          let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
          let firstName = resp?.data?.userdetails?.Name.split(" ")
            .slice(0, 1)
            .join("");
          document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
          document.cookie = "userType=expert/user;";
          window.location.reload();
        }
      }).catch((error) => {
        console.error("Error in validate_token with token_url:", error);
      });
    }
  }

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    // Perform any profile-related actions here
    navigate("/expert/settings");
    handleDropdownClose();
  };

  const handleLogoutClick = () => {
    logout();
    handleDropdownClose();
    Cookies.remove("authUserRole", {
      domain: ".unitedwecare.com",
      path: "/",
      // secure: true,
      HttpsOnly: true,
    });
    Cookies.remove("authToken", {
      domain: ".unitedwecare.com",
      path: "/",
      // secure: true,
      HttpsOnly: true,
    });
    window.location.href = "https://www.unitedwecare.com/";
  };

  const profileImage = currentUser?.ProfileImage?.replace("https://app.uwc.worldhttps://", "https://");

  return (
    <div style={{ background: "rgb(115, 88, 155)" }}>
      <Navbar key={"sm"} expand={"sm"} style={{ height: "75px" }}>
        <Container fluid className="justify-content-between px-5">
          <div className="d-flex align-items-center gap-5">
            <Navbar.Brand className="d-none d-md-block">
              <a
                href="https://www.unitedwecare.com/"
                target="_blank"
                className="text-decoration-none"
              >
                <img
                  src="/assets/img/kareify/UWC logo.png"
                  alt=""
                  height={50}
                  style={{ filter: "brightness(50)" }}
                />
              </a>
            </Navbar.Brand>
            <Navbar.Brand className="d-block d-md-none">
              <Link to="/" className="text-decoration-none">
                <img
                  src="/assets/img/kareify/UWC logo.png"
                  alt=""
                  height={20}
                  style={{ filter: "brightness(50)" }}
                />
              </Link>
            </Navbar.Brand>
            {/* <div>
                            <img src='/assets/img/Mask group(22).png' alt='' />
                            <Input className='font-inter'
                                style={{ color: "white", whiteSpace: "nowrap", width: "400px" }}
                                defaultValue="Search Clients, Appointments, Worksheet etc..." inputProps='' />
                        </div> */}
          </div>
          <Nav className="gap-4 align-items-baseline align-items-md-center justify-content-end">
            <Link to="/expert/appointments">
              <img src="/assets/img/Mask group(19).png" alt="" />
            </Link>
            <Link to="/expert/chat">
              <img src="/assets/img/Mask group(20).png" alt="" />
            </Link>
            <Link to="/expert/notifications">
              <img src="/assets/img/Mask group(21).png" alt="" />
            </Link>
            <div>
              <Button onClick={handleDropdownOpen}>
                <img
                  src={profileImage}
                  onError={(e) => {
                    const newLink = handleBrokenImage(profileImage);
                    e.target.src = newLink;
                  }}
                  width={45}
                  height={45}
                  alt=""
                  style={{
                    borderRadius: "50%",
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                />
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDropdownClose}
              >
                <MenuItem onClick={handleProfileClick} sx={{ width: "200px" }}>
                  Profile
                </MenuItem>
                <MenuItem onClick={handleLogoutClick} sx={{ width: "200px" }}>
                  Logout
                </MenuItem>
              </Menu>

              {/* Use Link component to navigate to "/dashboard/settings" */}
              {/* <Link to="/dashboard/settings">Go to Settings</Link> */}
            </div>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
