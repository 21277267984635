import React, { useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getExpertRating } from "./core/_request";
import { Skeleton } from "@mui/material";

const DetailAboutTab = (props) => {
  const { data, loading } = props;
  console.log("data", data);
  const [readMoreExperties, setReadMoreExperties] = useState();
  const [readMoreAboutMeText, setReadMoreAboutMeText] = useState();
  // console.log("data.rting", data?.ratingData?.length)

    // console.log("data", data)
    var ExpertisesArrayCount = data?.Expertise?.split(',').length - 4
    var ExpertisesArray = data?.Expertise?.split(',').length > 4 ? data?.Expertise?.split(',').slice(0, 4) : data?.Expertise?.split(',');
    var ExpertisesArrayAll = data?.Expertise?.split(',')
    var LanguagesArray = data?.Languages?.split(',')
    var userReview = data?.ratingData != undefined && data?.ratingData.length > 0 ? data?.ratingData[0] : null;
    console.log("userReview", data?.Languages)

    return (
      <div>
        {readMoreAboutMeText ? (
          <p className="font-inter fs-6 lh-base align-text-justify my-3">
            {data?.AboutYourProfessionalExperience && data?.AboutYourProfessionalExperience.length > 200 ? (
              <span className="font-inter fs-6" style={{ color: "#494747" }}>
                {data.AboutYourProfessionalExperience}
              </span>
            ) : (
              data?.AboutYourProfessionalExperience
            )}
          </p>
        ) : (
          <p className="font-inter fs-6 lh-base align-text-justify">
            {!loading ? (
              data?.AboutYourProfessionalExperience && data.AboutYourProfessionalExperience.length > 300 ? (
                <>
                  <span className="font-inter fs-6" style={{ color: "#494747" }}>
                    {data.AboutYourProfessionalExperience.slice(0, 300)}{" "}
                  </span>
                  <button
                    className="load-more border-0 py-1 font-w500"
                    style={{ background: "none" }}
                    onClick={() => setReadMoreAboutMeText(true)}
                  >
                    Load More...
                  </button>
                </>
              ) : (
                data?.AboutYourProfessionalExperience
              )
            ) : (
              <div>
                <Skeleton variant="text" width="90%" />
                <Skeleton variant="text" width="90%" />
                <Skeleton variant="text" width="90%" />
              </div>
            )}
          </p>
        )}
        <p className="font-inter fs-5 font-w500 my-3 text-black">
          Speaks
          <br />
          {!loading && LanguagesArray ? (
            LanguagesArray.map((item, index) => (
              <span key={index} className="font-inter fs-6 lh-base font-w400" style={{ color: "#494747" }}>
                {item}{index !== LanguagesArray.length - 1 ? ", " : ""}
              </span>
            ))
          ) : (
            <div className="d-flex flex-row gap-1">
              <Skeleton variant="text" width="10%" />
              <Skeleton variant="text" width="10%" />
              <Skeleton variant="text" width="10%" />
            </div>
          )}
        </p>
        {data?.licensedStateArray && (
          <div style={{ overflow: "auto" }}>
            <p className="font-inter fs-5 font-w500 my-2 mt-4 text-black d-flex align-items-center flex-wrap lh-lg">
              Licensed to practice in States
            </p>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {data.licensedStateArray.map((state, index) => (
                <span key={index} className="font-inter font-w500 fs-6 rounded-2 me-2 my-1 px-1 py-md-1 my-md-0" style={{ border: "1px solid var(--primary-color)", color: "#494747" }}>
                  {state}
                </span>
              ))}
            </div>
          </div>
        )}
        {data?.ratingData >= 3 && (
          <div className='my-3'>
            <p className='font-inter fs-3 font-w500 text-black d-flex align-items-center gap-3'>
              <img src='/assets/img/image 3.png' alt='' />
              {data?.Rating}
              <span className='font-inter fs-6 font-w400' style={{ color: "#494747" }}>
                ({data?.ratingData?.length} reviews)
              </span>
            </p>
            <div className='d-flex align-items-center gap-3 my-3'>
              <img src='/assets/img/Ellipse 1.png' alt='' />
              <p className='font-inter fs-6 font-w500 text-black text-capitalize lh-sm'>
                {userReview?.UserName} <br />
                <span className='font-inter fs-6 font-w400' style={{ color: "#494747" }}>
                  {userReview?.UserCountry}
                </span>
              </p>
            </div>
            <p className='font-inter fs-6'>
              {userReview?.Message}
            </p>
            {data?.ratingData?.length >= 1 && (
              <Link to="#" onClick={() => props.changeTab("reviews")} className='d-flex align-items-center text-decoration-none gap-3'>
                <p className='font-inter font-w400 fs-6' style={{ color: "#494747" }}>
                  All Reviews ({data?.ratingData?.length})
                </p>
                <img src='/assets/img/Vector(18).png' alt='' />
              </Link>
            )}
          </div>
        )}
      </div>
    );
  };
  
  export default DetailAboutTab;
