import React, { useState, useEffect } from "react";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useAuth } from "../../../../../../auth";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

const insuranceCompaniesStaticList = [
  "Aditya Birla Health Insurance",
  "Bajaj Allianz General Insurance",
  "Care Health Insurance",
  "Cholamandalam MS General Insurance",
  "Go Digit General Insurance",
  "HDFC ERGO General Insurance",
  "ICICI Lombard General Insurance",
  "IFFCO Tokio General Insurance",
  "Kotak Mahindra General Insurance",
  "Liberty General Insurance",
  "ManipalCigna Health Insurance",
  "Max Bupa Health Insurance",
  "Niva Bupa Health Insurance",
  "Reliance General Insurance",
  "Royal Sundaram General Insurance",
  "SBI General Insurance",
  "Star Health and Allied Insurance",
  "The New India Assurance",
  "Tata AIG General Insurance",
  "United India Insurance",
];

const AddNewPolicyModal = ({ open, setOpen, handleAddPolicy }) => {
  const { currentUser } = useAuth();
  const userName = currentUser?.Name?.trim()?.split(" ");
  const [company, setCompany] = useState("");
  const [memberID, setMemberID] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // const isFormFilled = company && memberID && policyNumber;
  const isFormFilled = company && memberID;

  useEffect(() => {
    getInsuranceCompanies();
  }, []);

  const getInsuranceCompanies = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PAYOR_BASE_URL}/insurance/payor/get-payor-list?start=1&step=903`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      console.log("getInsuranceCompanies data", data);
      setInsuranceCompanies(data.payors);
    } catch (error) {
      console.error("Error fetching insurance companies:", error);
    }
  };

  const addPolicy = async () => {
    setLoading(true);
    try {
      const data = {
        member_id: memberID,
        insuring_company: company?.payor_name,
        payor_identifier: company?.payor_id,
        first_name: userName[0],
        last_name: userName.length >= 2 ? userName[userName.length - 1] : "*",
        // policy_number: policyNumber,
        is_primary_member: checked,
      };

      const response = await fetch(
        `${process.env.REACT_APP_PAYOR_BASE_URL}/insurance/payor/add-insurance/${currentUser?.UserId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Policy added successfully:", result);

      // Call the function to update the existing policy array with the new policy data
      handleAddPolicy({ ...data, insurance_id: result.insurance_id });
      setLoading(false);
      setOpen(false);
      setCompany("");
    } catch (error) {
      console.error("Error adding policy:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="Add-Policy-Modal"
    >
      <DialogContent>
        <h6 style={{ color: "#1e1e1e", fontSize: "22px" }}>Add Policy</h6>
        <p
          style={{
            color: "#344054",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          Enter your policy details
        </p>
      </DialogContent>

      <DialogContent>
        <FormLabel className="fw-normal text-black">
          Insurance Company Name <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <FormControl fullWidth className="my-2">
          {/* <InputLabel>Select Insurance Company</InputLabel> */}
          <Select
            hiddenLabel
            id="demo-simple-select"
            value={company}
            onChange={(event) => {
              setCompany(event.target.value);
            }}
            required
          >
            {insuranceCompanies.map((company) => (
              <MenuItem key={company} value={company}>
                {company?.payor_name.length > 50
                  ? `${company?.payor_name.slice(0, 50)}...`
                  : company?.payor_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth className="my-2">
          <FormLabel className="fw-normal text-black">
            Member Id <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <TextField
            hiddenLabel
            type="number"
            placeholder="Enter Member ID"
            onChange={(e) => setMemberID(e.target.value)}
            required
          />
        </FormControl>

        {/* <FormControl fullWidth className="my-2">
          <FormLabel className="fw-normal text-black">
            Policy Number <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <TextField
            hiddenLabel
            type="number"
            placeholder="Enter Policy Number"
            onChange={(e) => setPolicyNumber(e.target.value)}
            required
          />
        </FormControl> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "20px 0px",
          }}
        >
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          <p>
            Are you the primary member <span style={{ color: "red" }}>*</span>
          </p>
        </div>

        <Button
          fullWidth
          disabled={!isFormFilled || loading}
          className="PrimaryCTA mt-4"
          onClick={addPolicy}
        >
          {loading ? (
            <CircularProgress
              disableShrink
              style={{ width: "30px", height: "30px", color: "#73589b" }}
            />
          ) : (
            "Add"
          )}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewPolicyModal;
