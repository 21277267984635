import React from "react";
import { Badge, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import { AppointmentService } from "./core/_request";
import { handleBrokenImage } from "../lib/utils";

const AppointmentCard = (props) => {
  const { item } = props;
  console.log("item", item);
  const { currentUser } = useAuth();
  const onClickCallNow = (url) => {
    var request = {
      AppointmentId: item.ID,
      type: currentUser.UserType == 3 ? "Expert" : "User",
    };
    AppointmentService(request);
    window.open(url, "_blank");
  };

  const navigate = useNavigate();

  const onChatClick = () => {
    navigate(`/dashboard/chat/${item.ID}`);
  };

  return (
    <div>
      <div
        className="container bg-white d-flex align-items-center justify-content-between py-2 px-4 w-100"
        style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12" }}
      >
        <div className="d-flex flex-column flex-md-row align-items-center gap-3 gap-md-5 w-100">
          <div
            className="d-flex flex-row flex-md-column align-items-baseline justify-content-between gap-3 gap-md-0 content col-12 col-md-2"
            // style={{ width: "max-content" }}
          >
            <Badge
              pill
              bg={
                (item.Status == "Canceled" && "danger") ||
                (item.Status == "Open" && "success") ||
                (item.Status == "Completed" && "success") ||
                "warning"
              }
            >
              {item?.Status}
            </Badge>
            <p className="fs-6 font-w600 text-black">{item?.AppDate}</p>
            <p className="fs-6 font-w600 text-black">
              {item?.AppTime} ({item?.TimeZone || "IST"})
            </p>
          </div>
          <div className="vr d-none d-md-block"></div>
          <div className="d-flex gap-3 align-items-center py-md-2 w-100">
            <img
              src={item?.ProfileImage}
              onError={(e) => {
                const newLink = handleBrokenImage(item?.ProfileImage);
                e.target.src = newLink
            }}
              style={{ width: 100, height: 100, borderRadius: "50%" }}
              alt=""
            />
            <div className="d-flex flex-column justify-content-center">
              <h6>{item?.Name || "Professional"}</h6>
              <p>{item.ExpertType}</p>
            </div>
          </div>
          <div className="d-md-none flex-row justify-content-between align-items-center w-100 d-flex gap-1">
            <Link to={`${item.ID}`} className="text-decoration-none">
              <button
                className="rounded-5 p-2 text-white border-0"
                style={{
                  whiteSpace: "nowrap",
                  background: "var(--button-primary-color)",
                }}
              >
                View More
              </button>
            </Link>
            {item?.Status == "Open" && (
              <button
                onClick={() => onClickCallNow(item?.Meeting_link)}
                className="rounded-5 border-0 px-3 py-2 text-white"
                style={{
                  background: "var(--button-primary-color)",
                  whiteSpace: "nowrap",
                }}
              >
                Join Now
              </button>
            )}
            <button
              className="rounded-5 text-white border-0 p-2"
              style={{ background: "var(--button-primary-color)" }}
              onClick={onChatClick}
            >
              Chat
            </button>
          </div>
        </div>
        <div className="d-md-flex flex-row gap-3 align-items-center d-none justify-content-end">
          <Link to={`${item.ID}`} className="text-decoration-none">
            <button
              className="rounded-5 text-white p-2 border-0"
              style={{
                whiteSpace: "nowrap",
                background: "var(--button-primary-color)",
              }}
            >
              View More
            </button>
          </Link>
          {item?.Status == "Open" && (
            <button
              onClick={() => onClickCallNow(item?.Meeting_link)}
              style={{
                background: "var(--button-primary-color)",
                whiteSpace: "nowrap",
                width: "max-content",
              }}
              className="rounded-5 text-white p-2 border-0"
            >
              Join Now
            </button>
          )}
          <button
            className="rounded-5 text-white p-2 border-0"
            style={{ background: "var(--button-primary-color)" }}
            onClick={onChatClick}
          >
            Chat
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCard;
