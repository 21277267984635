import React, { useEffect, useState, useContext, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";

//ICONS
import SearchIcon from "@mui/icons-material/Search";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import StopIcon from "@mui/icons-material/Stop";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import MicIcon from "@mui/icons-material/Mic";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Button from "@mui/material/Button";

//SpeechRecognition
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import SessionMedicalPrescriptionTable from "../../tables/SessionMedicalPrescriptionTable";

import SessionLabAdviceTable from "../../tables/SessionLabAdviceTable";
import { set } from "lodash";
import ArrayRichTextEditor from "../../richText/sessionDashboard/ArrayRichTextEditor";
import { AppContext } from "../../../../context";
import { getTranscript, getTranslation } from "../../APIS/clientAPIS";
import { useAuth } from "../../../../auth";

const SessionPrescriptionTabItem = ({
  isWebsocketDataRecieved,
  prescriptionData,
  sessionStatus,
  labtestData,
}) => {
  const [medicineText, setMedicineText] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isRecording, setIsRecording] = useState(false);

  const [audioRequestData, setAudioRequestData] = useState(null);
  const [showMic, setShowMic] = useState(true);
  const [showStopMic, setShowStopMic] = useState(false);
  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const [autoGenPopup, setAutoGenPopup] = useState(false);
  const { expertDashboardData } = useContext(AppContext);
  const [base64String, setBase64String] = useState(null);
  //console.log("inputValue", inputValue);
  const { chief_complaints, vitals, diagnosis, advise, medicines } =
    prescriptionData[0];
  // const textbox = [chief_complaints, vitals, diagnosis, advise];
  // Speech Recognition Setup
  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const recorderControls = useAudioRecorder();

  const { currentUser } = useAuth();

  const socketRef = useRef(null);

  useEffect(() => {
    //console.log("expertDashboardData", expertDashboardData);
    const sendAudioData = async () => {
      const base64String = await convertBlobToBase64(
        recorderControls.recordingBlob
      );
      // console.log("BLOB", recorderControls.recordingBlob);

      getTranscript(
        expertDashboardData.session_data.appointment_details.appointment_id,
        expertDashboardData.session_data.appointment_details.user_id,
        expertDashboardData.session_data.appointment_details.expert_id,
        expertDashboardData.session_data.appointment_details.appointment_date,
        expertDashboardData.session_data.appointment_details.booking_date,
        base64String
      ).then((res) => {
        //console.log("RES", res)
        getTranslation(
          expertDashboardData.session_data.appointment_details.appointment_id,
          expertDashboardData.session_data.appointment_details.user_id,
          expertDashboardData.session_data.appointment_details.expert_id,
          expertDashboardData.session_data.appointment_details.appointment_date,
          expertDashboardData.session_data.appointment_details.booking_date,
          res.transcript
        );
      });
    };
    if (
      recorderControls.recordingBlob !== undefined &&
      recorderControls.recordingBlob !== null
    ) {
      sendAudioData();
    }

    // console.log("finalTranscript", finalTranscript);
    // console.log("interimTranscript", interimTranscript);
    // console.log("transcript", transcript);
  }, [recorderControls.recordingBlob]);

  // useEffect(() => {

  // }, [isWebsocketDataRecieved])

  //console.log("listening", listening)

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // console.log("reader.result", reader.result)
        resolve(reader.result.split("data:audio/webm;codecs=opus;base64,")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleRecordingStart = () => {
    recorderControls.startRecording();
    //SpeechRecognition.startListening();
    setIsRecording(true);
  };

  const handleRecordingStop = async () => {
    recorderControls.stopRecording();
    setIsRecording(false);
    setIsRecordingStopped(true);
    //SpeechRecognition.stopListening();
  };

  const handleRecordingComplete = async (blob) => {
    setAudioRequestData(blob);
  };
  const handleAutoGenerate = () => {
    setAutoGenPopup(!autoGenPopup);
  };
  useEffect(() => {
    setAutoGenPopup(isWebsocketDataRecieved);
  }, [isWebsocketDataRecieved]);

  const element = document.querySelector(".autogenerated-prescription");


  return (
    <div>
      <div className="d-flex justify-content-between">
        {!expertDashboardData?.session_data?.submitted && <div className="d-flex gap-2">
          {/* <button
            className="PrimaryCTA"
            onClick={handleAutoGenerate}
            disabled={!isWebsocketDataRecieved}
          >
            <AutoFixHighIcon color="#FFF" />
            Auto Generate Prescription from transcript
          </button> */}
          {isRecording ? (
            <button style={{ maxWidth: "fit-content", right: "30px", bottom: "30px", position: "fixed", zIndex: "1000" }} className=" PrimaryCTA" onClick={handleRecordingStop}>
              <StopIcon color="#FFF" />
              Stop voice recording
            </button>
          ) : (
            <button style={{ maxWidth: "fit-content", right: "30px", bottom: "30px", position: "fixed", zIndex: "1000" }} className="    PrimaryCTA" onClick={handleRecordingStart}>
              <MicIcon color="#FFF" />
              Generate prescription from voice
            </button>
          )}

          {/* {recorderControls.recordingBlob !== undefined && (
            <audio
              className="audio-player"
              src={URL.createObjectURL(recorderControls.recordingBlob)}
              controls={true}
              controlsList="nodownload"
            />
          )} */}
        </div>}
        {/* hidden for developmen purpose*/}
        <div className="d-none gap-3 align-items-center">
          <button
            style={{
              border: "none",
              borderRadius: "5px",
              background: "#FFF",
              color: "#73589b",
            }}
          >
            <DownloadForOfflineIcon style={{ fontSize: "30px" }} />
          </button>
          <button
            style={{
              border: "none",
              borderRadius: "5px",
              background: "#FFF",
              color: "#73589b",
            }}
          >
            <LocalPrintshopIcon style={{ fontSize: "30px" }} />
          </button>
        </div>
      </div>

      <div style={{ visibility: "hidden", position: "absolute" }}>
        <AudioRecorder
          onRecordingComplete={(blob) => handleRecordingComplete(blob)}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          downloadOnSavePress={false}
        />
      </div>
      {/* <div className="AutoComplete--Container mt-3 d-flex align-items-center">
        <SearchIcon />
        <Autocomplete
          disablePortal
          fullWidth
          id="combo-box-demo"
          options={top100Films}
          // onChange={(e) => handleMediceText(e)}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              onChange={handleInputChange}
              onKeyDown={(e) => handleKeyDown(e)}
              label="Search Medicines"
            />
          )}
        />

        {showMic && (
          <button onClick={handleRecordingStart}>
            <KeyboardVoiceIcon />
          </button>
        )}
        {showStopMic && (
          <button onClick={handleRecordingStop}>
            <StopIcon />
          </button>
        )}
      </div> */}

      <div className="MedicinePrescription--Table mt-3 position-relative">
        {autoGenPopup ? (
          <SessionMedicalPrescriptionTable
            medData={medicines}
            aiGenerated={true}
            sessionStatus={sessionStatus}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
          />
        ) : (
          <SessionMedicalPrescriptionTable
            medData={medicines}
            aiGenerated={false}
            sessionStatus={sessionStatus}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
          />
        )}
      </div>

      <div className="PrescriptionOtherData--Container mt-5 ">
        <div className="OtherData--Item position-relative">
          {autoGenPopup ? (
            <ArrayRichTextEditor
              initialText={
                chief_complaints.map((item) => item.blob).join("\n") || ""
              }
              name={"Chief Complaints"}
              fieldName={"chief_complaints"}
              sessionStatus={sessionStatus}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
              aiGenerated={true}
            />
          ) : (
            <ArrayRichTextEditor
              aiGenerated={false}
              name={"Chief Complaints"}
              fieldName={"chief_complaints"}
              initialText={
                chief_complaints.map((item) => item.blob).join("\n") || ""
              }
              sessionStatus={sessionStatus}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
            />
          )}
        </div>
        <div className="OtherData--Item position-relative">
          {autoGenPopup ? (
            <ArrayRichTextEditor
              name={"Vitals"}
              fieldName={"vitals"}
              initialText={vitals.map((item) => item.blob).join("\n") || ""}
              aiGenerated={true}
              sessionStatus={sessionStatus}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
            />

          ) : (
            <ArrayRichTextEditor
              name={"Vitals"}
              fieldName={"vitals"}
              aiGenerated={false}
              initialText={vitals.map((item) => item.blob).join("\n") || ""}
              sessionStatus={sessionStatus}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
            />
          )}
        </div>
        <div className="OtherData--Item position-relative">
          {autoGenPopup ? (
            <ArrayRichTextEditor
            name={"Diagnosis"}
            fieldName={"diagnosis"}
              initialText={diagnosis.map((item) => item.blob).join("\n") || ""}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
              sessionStatus={sessionStatus}
              aiGenerated={true}
            />
          ) : (
            <ArrayRichTextEditor
            name={"Diagnosis"}
            fieldName={"diagnosis"}
              aiGenerated={false}
              initialText={diagnosis.map((item) => item.blob).join("\n") || ""}
              sessionStatus={sessionStatus}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
            />
          )}
        </div>

        <div className="OtherData--Item position-relative">
          {autoGenPopup ? (
             <ArrayRichTextEditor
             name={"Advise/Comments"}
             fieldName={"advise"}
              aiGenerated={true}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
              initialText={advise.map((item) => item.blob).join("\n") || ""}
              sessionStatus={sessionStatus}
            />
          ) : (
            <ArrayRichTextEditor
            name={"Advise/Comments"}
            fieldName={"advise"}
              aiGenerated={false}
              initialText={advise.map((item) => item.blob).join("\n") || ""}
              sessionStatus={sessionStatus}
              isWebsocketDataRecieved={isWebsocketDataRecieved}
            />
          )}
        </div>
      </div>

      <div className="LabAdvice--Table mt-5">
        {autoGenPopup ? (
          <SessionLabAdviceTable
            aiGenerated={true}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
            sessionStatus={sessionStatus}
            genTest={labtestData}
          />
        ) : (
          <SessionLabAdviceTable
            aiGenerated={false}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
            sessionStatus={sessionStatus}
            genTest={labtestData}
          />
        )}
      </div>
    </div>
  );
};

export default SessionPrescriptionTabItem;
