import { Helmet } from 'react-helmet';
import MyAppointmentDetails from '../../components/user/MyAppointmentDetails';

const MyAppointmentsPage = () => {
    return (
        <>
            <Helmet>
                <title>
                    View Your Appointment Details | United We Care Dashboard
                </title>
            </Helmet>
            <MyAppointmentDetails />
        </>
    )
}

export default MyAppointmentsPage;