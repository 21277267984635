import React, { useState, useContext, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
//SpeechRecognition
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { getTranscript, getTranslation } from "../../APIS/clientAPIS";
//Text Field
import TextField from "@mui/material/TextField";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
// import { data } from "jquery";

import { AppContext } from "../../../../context";

const SessionNote = ({ isWebsocketDataRecieved, sessionNoteData }) => {
  const { expertDashboardData, setExpertDashboardData } =
    useContext(AppContext);

  const initialSessionNote =
    expertDashboardData?.session_data?.session_note || [];
  const [sessionNote, setSessionNote] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  useEffect(() => {
    setSessionNote(initialSessionNote);
  }, [expertDashboardData?.session_data?.session_note]);

  //audio recording

  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const [audioRequestData, setAudioRequestData] = useState(null);
  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const recorderControls = useAudioRecorder();

  useEffect(() => {
    //console.log("expertDashboardData", expertDashboardData);
    const sendAudioData = async () => {
      const base64String = await convertBlobToBase64(
        recorderControls.recordingBlob
      );
      // console.log("BLOB", recorderControls.recordingBlob);

      getTranscript(
        expertDashboardData.session_data.appointment_details.appointment_id,
        expertDashboardData.session_data.appointment_details.user_id,
        expertDashboardData.session_data.appointment_details.expert_id,
        expertDashboardData.session_data.appointment_details.appointment_date,
        expertDashboardData.session_data.appointment_details.booking_date,
        base64String
      ).then((res) => {
        //console.log("RES", res)
        getTranslation(
          expertDashboardData.session_data.appointment_details.appointment_id,
          expertDashboardData.session_data.appointment_details.user_id,
          expertDashboardData.session_data.appointment_details.expert_id,
          expertDashboardData.session_data.appointment_details.appointment_date,
          expertDashboardData.session_data.appointment_details.booking_date,
          res.transcript
        );
      });
    };
    if (
      recorderControls.recordingBlob !== undefined &&
      recorderControls.recordingBlob !== null
    ) {
      sendAudioData();
    }

    // console.log("finalTranscript", finalTranscript);
    // console.log("interimTranscript", interimTranscript);
    // console.log("transcript", transcript);
  }, [recorderControls.recordingBlob]);
  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // console.log("reader.result", reader.result)
        resolve(reader.result.split("data:audio/webm;codecs=opus;base64,")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  const handleRecordingStart = () => {
    recorderControls.startRecording();
    //SpeechRecognition.startListening();
    setIsRecording(true);
  };

  const handleRecordingStop = async () => {
    recorderControls.stopRecording();
    setIsRecording(false);
    setIsRecordingStopped(true);
    //SpeechRecognition.stopListening();
  };

  const handleRecordingComplete = async (blob) => {
    setAudioRequestData(blob);
  };

  //option selection
  const handleSelectChange = (event, questionIndex) => {
    const updatedSessionNote = sessionNote.map((item, index) => {
      if (index === questionIndex && item.question_type === "dropdown") {
        const updatedOptions = item.options.map((op, opIndex) => ({
          ...op,
          selected: opIndex === event.target.value,
        }));
        return {
          ...item,
          options: updatedOptions,
        };
      }
      return item;
    });
    setSessionNote(updatedSessionNote);

    setExpertDashboardData((prevData) => ({
      ...prevData,
      session_data: {
        ...prevData.session_data,
        session_note: updatedSessionNote,
      },
    }));

    setSelectedOptionIndex(event.target.value);
  };

  const handleTextChange = (event, questionIndex) => {
    const updatedSessionNote = sessionNote.map((item, index) => {
      if (index === questionIndex && item.question_type === "text") {
        const updatedOptions = item.options.map((op) => ({
          ...op,
          option_text: event.target.value,
        }));
        return {
          ...item,
          options: updatedOptions,
        };
      }
      return item;
    });
    setSessionNote(updatedSessionNote);
    setExpertDashboardData((prevData) => ({
      ...prevData,
      session_data: {
        ...prevData.session_data,
        session_note: updatedSessionNote,
      },
    }));
  };

  const handleMultiSelectChange = (event, questionIndex, optionIndex) => {
    const updatedSessionNote = sessionNote.map((item, index) => {
      if (index === questionIndex && item.question_type === "multi-select") {
        const updatedOptions = item.options.map((op, opIndex) => ({
          ...op,
          selected:
            opIndex === optionIndex ? event.target.checked : op.selected,
        }));
        return {
          ...item,
          options: updatedOptions,
        };
      }
      return item;
    });
    setSessionNote(updatedSessionNote);
    setExpertDashboardData((prevData) => ({
      ...prevData,
      session_data: {
        ...prevData.session_data,
        session_note: updatedSessionNote,
      },
    }));
  };

  const handleBooleanChange = (event, questionIndex, optionIndex) => {
    const updatedSessionNote = sessionNote.map((item, index) => {
      if (index === questionIndex && item.question_type === "boolean") {
        const updatedOptions = item.options.map((op, opIndex) => ({
          ...op,
          selected: opIndex === optionIndex && event.target.checked,
        }));
        return {
          ...item,
          options: updatedOptions,
        };
      }
      return item;
    });

    console.log(updatedSessionNote);
    setSessionNote(updatedSessionNote);

    setExpertDashboardData((prevData) => ({
      ...prevData,
      session_data: {
        ...prevData.session_data,
        session_note: updatedSessionNote,
      },
    }));
  };

  // console.log("session Note:", sessionNote);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        margin: "auto",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(115, 88, 155, 0.1)",
        borderRadius: "10px",
      }}
    >
      <div className="d-flex justify-content-between">
        {!expertDashboardData?.session_data?.submitted && (
          <div className="d-flex gap-2">
           
            {isRecording ? (
              <button
                style={{
                  maxWidth: "fit-content",
                  right: "30px",
                  bottom: "30px",
                  position: "fixed",
                  zIndex: "1000",
                }}
                className="PrimaryCTA"
                onClick={handleRecordingStop}
              >
                <StopIcon color="#FFF" />
                Stop voice recording
              </button>
            ) : (
              <button
                style={{
                  maxWidth: "fit-content",
                  right: "30px",
                  bottom: "30px",
                  position: "fixed",
                  zIndex: "1000",
                }}
                className="PrimaryCTA"
                onClick={handleRecordingStart}
              >
                <MicIcon color="#FFF" />
                Generate prescription from voice
              </button>
            )}
          </div>
        )}
      </div>
      <div
        style={{
          margin: "5px 0",
          width: "100%",
          padding: "0 20px",
          minHeight: "20vh",
        }}
      >
        {sessionNote.map((item, index) => (
          <div key={index} style={{ marginTop: "20px", marginBottom: "20px" }}>
            <p style={{ fontSize: "16px", fontWeight: "600", font: "Roboto" }}>
              {item.question_name}
            </p>
            {item.question_type === "dropdown" && (
              <FormControl fullWidth>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  label="Select"
                  className="Table-span-3"
                  value={item.options.findIndex((op) => op.selected)}
                  onChange={(e) => handleSelectChange(e, index)}
                  disabled={expertDashboardData?.session_data?.submitted}
                >
                  {item.options.map((op, opIndex) => (
                    <MenuItem value={opIndex} key={opIndex}>
                      <h6>{op.option_text}</h6>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {item.question_type === "text" && (
              <TextField
                id="outlined-multiline-static"
                multiline
                fullWidth
                rows={4}
                value={item.options[0].option_text}
                onChange={(e) => handleTextChange(e, index)}
                disabled={expertDashboardData?.session_data?.submitted}
              />
            )}

            {item.question_type === "multi-select" && (
              <FormGroup
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridColumnGap: "10px",
                }}
              >
                {item.options.map((op, opIndex) => (
                  <FormControlLabel
                    key={opIndex}
                    control={
                      <Checkbox
                        checked={op.selected}
                        onChange={(e) =>
                          handleMultiSelectChange(e, index, opIndex)
                        }
                        disabled={expertDashboardData?.session_data?.submitted}
                      />
                    }
                    label={op.option_text}
                  />
                ))}
              </FormGroup>
            )}

            {item.question_type === "boolean" && (
              <FormControl>
                <RadioGroup>
                  {item.options.map((op, opIndex) => (
                    <FormControlLabel
                      key={opIndex}
                      value={op.option_text}
                      control={
                        <Radio
                          checked={op.selected}
                          onChange={(e) =>
                            handleBooleanChange(e, index, opIndex)
                          }
                          disabled={
                            expertDashboardData?.session_data?.submitted
                          }
                        />
                      }
                      label={op.option_text}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SessionNote;
