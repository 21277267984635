import React, { useEffect, useState } from "react";
import { Modal, ToggleButton } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./modal.css";
import { FeedBackForm, FeedbackSubmit, getUserDetail } from "../core/_request";
import { useParams } from "react-router-dom";
import { MySubscriptionService } from "./core/_request";
import { getAuth, signInAnonymously } from "firebase/auth";
import Feedback from "./Feedback";

const OnFeedback = ({ handleClose, show, appointmentId, feedBackStatusBool, expertDataP }) => {
  const { id } = useParams();
  const [yesSelected, setYesSelected] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [displayNameBool, setDisplayNameBool] = useState("");
  const [feedbackShow, setFeedbackShow] = useState(false);
  const [step, setStep] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userStatus, setUserStatus] = useState("");
  const [feedbackAnswers, setFeedbackAnswers] = useState([]);
  const [showFirstModal, setShowFirstModal] = useState(show);
  const [showSecondModal, setShowSecondModal] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth).catch((error) => {
      console.error("Firebase signInAnonymously failed:", error.code, error.message);
    });

    const fetchData = async () => {
      try {
        const resp = await MySubscriptionService();
        if (feedBackStatusBool) {
          setData(resp?.data?.find((o) => o.ID == appointmentId));
        } else {
          setData(resp?.data?.find((o) => o.ID == id));
        }
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      }
    };

    fetchData();
  }, [id, appointmentId, feedBackStatusBool]);

  useEffect(() => {
    setYesSelected(true);
    setDisplayNameBool("");
    setSelectedOption(null);
    setShowFirstModal(show);
    setShowSecondModal(false);
  }, [show]);

  const handleFeedbackSubmit = async () => {
    setIsLoading(true);
    try {
      const user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
      const feedbackData = {
        user_id,
        user_type: "user",
        appointment_id: feedBackStatusBool ? appointmentId : id,
        session_status: "incompleted",
        qa_list: Object.keys(selectedAnswers).map((questionId) => ({
          question_id: questionId,
          answer: selectedAnswers[questionId],
        })),
      };

      const response = await FeedbackSubmit(feedbackData);
      console.log("feedback....", response);
      handleClose();
    } catch (error) {
      console.error("Error submitting feedback:", error);
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  const handleToggleChange = (value, step) => {
    setStep(step);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
        const feedbackbody = {
          user_type: "user",
          user_id,
          appointment_id: feedBackStatusBool ? appointmentId : id,
        };

        const response = await getUserDetail(feedbackbody);
        setUserStatus(response?.data[0]?.session_status);
        setFeedbackAnswers(response?.data[0]?.qa_list || []);
        if (response?.data?.message === "No Feedback Available !") {
          setCheckboxChecked(true);
        } else if (response?.data[0]?.session_status === "completed") {
          if (response?.data[0]?.qa_list?.length > 0) {
            setCheckboxChecked(true);
            setShowSecondModal(true);
            setShowFirstModal(false);
          }
        } else {
          setStep(2);
          const feedbackFormResponse = await FeedBackForm({
            user_type: "user",
            user_id,
            session_status: "incompleted",
          });
          setQuestions(feedbackFormResponse?.data?.template_data);
          setYesSelected(false);
          setDisplayNameBool(false);
        }
      } catch (error) {
        console.error("Error fetching questions or user details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (show) {
      fetchUserData();
    }
  }, [show, appointmentId, id, feedBackStatusBool]);

  const handleProceed = async (type) => {
    try {
      const user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
      if (type === "no") {
        setStep(2);
        const feedbackFormResponse = await FeedBackForm({
          user_type: "user",
          user_id,
          session_status: "incompleted",
        });
        setQuestions(feedbackFormResponse?.data?.template_data);
      } else if (type === "yes") {
        setStep(1);
        setShowFirstModal(false);
        setShowSecondModal(true);
      }
    } catch (error) {
      console.error("Error fetching feedback form:", error);
    }
  };

  const handleAnswerSelect = (questionId, optionId) => {
    setSelectedOption(optionId);
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: optionId,
    }));
  };

  useEffect(() => {
    if (displayNameBool) {
      setStep(1);
      setShowFirstModal(false);
      setShowSecondModal(true);
    }
  }, [displayNameBool]);

  const handleSelectYes = () => {
    setDisplayNameBool(true);
    setStep(1);
    setShowFirstModal(false);
    setShowSecondModal(true);
  };

  return (
    <>
      <Modal
        show={showFirstModal}
        onHide={handleClose}
        centered
        dialogClassName={step === 1 ? "custom-modal1" : "custom-modal"}
        className={step === 1 ? "modal2 p-0 " : "modal1 p-0 "}
      >
        <Modal.Header className="modal-content" closeButton></Modal.Header>
        <div className="text-center small-header">
          Your feedback is important
        </div>
        {step === 1 && (
          <Modal.Body className="p-5">
            <h6 className="step1text text-center w-100">
              Was your session completed successfully?
            </h6>
            <div className="d-flex align-items-center justify-content-center gap-3 my-3">
              <ToggleButton
                type="radio"
                name="radio"
                className="rounded-pill toggle-button"
                variant="primary"
                onClick={handleSelectYes}
                checked={yesSelected}
                style={{
                  border: displayNameBool !== "" && displayNameBool ? "2px solid #00FF00" : "none",
                  background: "#FFF8E5",
                  color: "#B54100",
                  width: "65px",
                  height: "35px",
                  outline: "none",
                  boxShadow: "none",
                }}
              >
                <p className="font-helvetica fs-6 text-center toggle-label" style={{ color: "#134700" }}>
                  Yes
                </p>
              </ToggleButton>

              <ToggleButton
                type="radio"
                name="radio"
                className="rounded-pill toggle-button"
                variant="primary"
                checked={!yesSelected}
                style={{
                  border: !displayNameBool && displayNameBool !== "" ? "2px solid #B54708" : "none",
                  background: "#FFF8E5",
                  color: "#B54100",
                  width: "65px",
                  height: "35px",
                  outline: "none",
                  boxShadow: "none",
                }}
                onClick={() => {
                  handleToggleChange(false, 2);
                  setDisplayNameBool(false);
                  handleProceed("no");
                }}
              >
                <p className="font-helvetica fs-6 text-center toggle-label" style={{ color: "#B54708" }}>
                  No
                </p>
              </ToggleButton>
            </div>
          </Modal.Body>
        )}
        {step === 2 && (
          <Modal.Body className="p-5">
            <h6 className="fs font-helvetica font-w700 text-center">
              Was your session completed successfully?
            </h6>
            <div className="d-flex align-items-center justify-content-center gap-4 mb-3 p-3">
              <ToggleButton
                type="radio"
                name="radio"
                className="rounded-pill toggle-button"
                variant="primary"
                onClick={() => {
                  handleToggleChange(true, 1);
                  setDisplayNameBool(true);
                }}
                checked={yesSelected}
                style={{
                  border: displayNameBool ? "2px solid #00FF00" : "none",
                  background: "#FFF8E5",
                  color: "#B54100",
                  width: "65px",
                  height: "35px",
                  outline: "none",
                  boxShadow: "none",
                }}
              >
                <p className="font-helvetica fs-6 text-center toggle-label" style={{ color: "#134700" }}>
                  Yes
                </p>
              </ToggleButton>

              <ToggleButton
                type="radio"
                name="radio"
                className="rounded-pill toggle-button"
                variant="primary"
                checked={!yesSelected}
                style={{
                  border: !displayNameBool ? "2px solid #B54708" : "none",
                  background: "#FFF8E5",
                  color: "#B54100",
                  width: "65px",
                  height: "35px",
                  outline: "none",
                  boxShadow: "none",
                }}
                onClick={() => {
                  handleToggleChange(false, 1);
                  setDisplayNameBool(false);
                }}
              >
                <p className="font-helvetica fs-6 text-center toggle-label" style={{ color: "#B54708" }}>
                  No
                </p>
              </ToggleButton>
            </div>

            {questions.map((question) => (
              <div key={question.question_id} className="question-container d-flex flex-column justify-content-center">
                <p className="question-text">{question.question}</p>
                {question.options.map((option) => (
                  <div key={option.option_id} className="option-container">
                    <input
                      type="radio"
                      id={`option-${option.option_id}`}
                      name={`question-${question.question_id}`}
                      value={option.option_id}
                      defaultChecked={
                        feedbackAnswers && feedbackAnswers.length > 0
                          ? option.name === feedbackAnswers[0]?.answer
                          : false
                      }
                      onChange={() => handleAnswerSelect(question.question_id, option.name)}
                      className="ellipse"
                    />
                    <label htmlFor={`option-${option.option_id}`} className="font-text">
                      {option.name}
                    </label>
                  </div>
                ))}
              </div>
            ))}

            {feedbackAnswers && feedbackAnswers.length == 0 && (
              <div className="w-100">
                <button
                  className={`PrimaryCTA w-75 submit-margin my-4 d-block mx-auto ${selectedOption === null ? 'custom-disabled' : ''}`}
                  onClick={handleFeedbackSubmit}
                  disabled={selectedOption === null}
                >
                  {isLoading ? (
                    <img src="/assets/img/loading.gif" alt="Unitedwecare" style={{ height: 26, width: 26 }} />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            )}
          </Modal.Body>
        )}
      </Modal>
      {showSecondModal && (
        <Feedback
          feedbackAnswers={feedbackAnswers}
          feedbackshow={showSecondModal}
          appointmentId={appointmentId}
          handlefeedback={() => setShowSecondModal(false)}
          handleClose={handleClose}
          expertDataP={expertDataP}
        />
      )}
    </>
  );
};

export default OnFeedback;
