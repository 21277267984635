import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./SignUp.css";
import { useAuth } from "../auth";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { updateDisclaimerUser } from "./core/_request";

const DisclaimerModal = (props) => {
  const [iAgree, setIAgree] = useState();
  const { login, setCurrentUser, currentUser, userType } = useAuth();
  const navigate = useNavigate();
  const params = new URLSearchParams(window ? window.location.search : {});
  
  const authToken = localStorage.getItem("Authorization")?.split("Bearer ")[1];

  const onTalkToStella = () => {
    window.open(
      `https://stella.unitedwecare.com/?token=${authToken}&appURL=${encodeURI(
        `https://us.unitedwecare.com`
      )}`
    );
  };

  const onSubmit = () => {
    let body = {
      user_id: currentUser?.UserId,
    };

    updateDisclaimerUser(body).then((resp2) => {
      if (resp2?.response_code === 200) {
        console.log("Successfully Register.");
      }
    });
    if (
      sessionStorage.getItem("Login-Invoked") !== undefined &&
      sessionStorage.getItem("Login-Invoked") !== null &&
      sessionStorage.getItem("Login-Invoked").includes("/stella") &&
      currentUser.UserType == userType.ENDUSER
    ) {
      onTalkToStella();
      navigate("/");
      sessionStorage.removeItem("Login-Invoked");
    } else {
      var retrunurl_local = localStorage.getItem("retrunurl");
      const retrunurl = params.get("retrunurl") || retrunurl_local;
      //console.log("retrunurl", retrunurl)
      if (retrunurl) {
        //window.location.href=retrunurl;
        localStorage.removeItem("retrunurl");
        navigate(retrunurl);
      } else {
        // navigate("/onboarding-stella");
        // navigate('/')
        // window.location.reload();
        props?.onHide();
      }
    }
  };

  return (
    <div className="disclaimer--containers">
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="disclaimer--popup"
      >
        <Modal.Body>
          <h4>Disclaimer</h4>
          <div className="disclaimer-points">
            <p
              dangerouslySetInnerHTML={{
                __html: props?.disclaimer?.disclaimer || props?.disclaimerData,
              }}
            ></p>
          </div>
          <FormControlLabel
            control={<Checkbox onClick={() => setIAgree(!iAgree)} />}
            label={
              <span className="disclaimer--checkbox-label">
                I accept United We Care's{" "}
                <a
                  href="https://legal.unitedwecare.com/us/terms-and-conditions"
                  target="_blank"
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  href="https://legal.unitedwecare.com/us/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </span>
            }
          />
          <Button disabled={!iAgree} onClick={() => onSubmit()}>
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DisclaimerModal;
