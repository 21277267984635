import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Table } from 'react-bootstrap';
import { usOnBoarding_scheduling_timeslots, usOnBoarding_Save_scheduling_timeslots } from '../core/_request';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { enqueueSnackbar } from 'notistack';
import Checkbox from '@mui/material/Checkbox';

const RegisterAsProfessionalSchedulingTab = (props) => {
    const [timeslots, setTimeSlots] = useState([]);
    const [sunday_list, setSunday_List] = useState([]);
    const [monday_list, setMonday_List] = useState([]);
    const [tuesday_list, setTuesday_List] = useState([]);
    const [wednesday_list, setWednesday_List] = useState([]);
    const [thursday_list, setThursday_List] = useState([]);
    const [friday_list, setFriday_List] = useState([]);
    const [saturday_list, setSaturday_List] = useState([]);
    const [totalHours, setTotalHours] = useState(0);

    var RegisterAs = localStorage.getItem("RegisterAs");
    var user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);

    useEffect(() => {
        var body = { "user_id": user_id };
        usOnBoarding_scheduling_timeslots(body).then(resp => {
            setTimeSlots(resp?.data?.slots);
        });
    }, [user_id, RegisterAs]);

    useEffect(() => {
        var sData = JSON.parse(localStorage.getItem("User_Registered_Info"));
        setSunday_List(sData?.scheduling?.sunday);
        setMonday_List(sData?.scheduling?.monday);
        setTuesday_List(sData?.scheduling?.tuesday);
        setWednesday_List(sData?.scheduling?.wednesday);
        setThursday_List(sData?.scheduling?.thursday);
        setFriday_List(sData?.scheduling?.friday);
        setSaturday_List(sData?.scheduling?.saturday);
    }, [user_id]);

    const handleCheckboxChange = (e, item, dayList, setDayList) => {
        if (e.target.checked) {
            setDayList(prevState => [
                ...prevState || [],
                item?.id
            ]);
        } else {
            const newInitialList = dayList?.filter((list) => list !== item?.id);
            setDayList(newInitialList);
        }
    };

    const handleNextOnClick = () => {
        var user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);
        var total_slots = sunday_list?.length + monday_list?.length + tuesday_list?.length + wednesday_list?.length + thursday_list?.length + friday_list?.length + saturday_list?.length;

        if (total_slots < 10) {
            enqueueSnackbar("Choose a minimum of 10 slots weekly.", { variant: "warning" });
            return false;
        }

        var body = {
            "user_id": user_id,
            "sunday": sunday_list,
            "monday": monday_list,
            "tuesday": tuesday_list,
            "wednesday": wednesday_list,
            "thursday": thursday_list,
            "friday": friday_list,
            "saturday": saturday_list,
        };
        usOnBoarding_Save_scheduling_timeslots(body).then(resp => {
            if (resp?.code === 200) {
                enqueueSnackbar("Schedule Details Saved Successfully.", { variant: "success" });
                localStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));
                props.onNext();
            } else {
                enqueueSnackbar(resp?.data?.error ?? resp?.message, { variant: "error" });
            }
        });
    };

    const handleSkip = () => {
        props.onNext();
    };

    return (
        <div>
            <div className='d-flex align-items-center gap-3 py-3 rounded-top px-3' style={{ background: "#DCD0EB" }}>
                <img src='/assets/img/Vector(26).png' alt='' />
                <p className='font-inter font-w500 text-black' style={{ color: "#21231E", fontSize: "24px" }}>Scheduling</p>
            </div>
            <div className='m-3'>
                <h6 className='font-inter font-w500 fs-4' style={{ color: "#21231E" }}>Tentative Schedule</h6>
                <p className='font-inter ' style={{ color: "#21231E", fontSize: "14px" }}>You can change it any time (choose a minimum of 10 slots weekly)</p>
            </div>
            <div className='col-12 m-3 p-3'>
                <p className='font-inter font-w600 lh-lg' style={{ color: "#21231E" }}>Select days and time to add them</p>
                <div style={{ height: "500px", overflowY: "scroll" }}>
                    <Table responsive="sm">
                        <thead className='sticky-top bg-white' style={{ zIndex: 100 }}>
                            <tr className=''>
                                <th className='w-25'></th>
                                <th>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>S</div>
                                    </div>
                                </th>
                                <th className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>M</div>
                                    </div>
                                </th>
                                <th className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>T</div>
                                    </div>
                                </th>
                                <th className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>W</div>
                                    </div>
                                </th>
                                <th className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>T</div>
                                    </div>
                                </th>
                                <th className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>F</div>
                                    </div>
                                </th>
                                <th className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>S</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody className='col-12 schedule--tableBody' style={{ height: '300px', overflowY: 'scroll', display: '' }}>
                            {
                                timeslots?.map((item, index) =>
                                    <tr key={index}>
                                        <td className='px-2'>
                                            <div className='d-flex align-items-center justify-content-center rounded-3' style={{ height: "60px", width: "auto", border: "1px solid #21231E" }}>
                                                {item?.name}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Checkbox value={item?.id} checked={sunday_list?.filter(o => o === item.id).length > 0} onChange={(e) => handleCheckboxChange(e, item, sunday_list, setSunday_List)} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Checkbox value={item?.id} checked={monday_list?.filter(o => o === item.id).length > 0} onChange={(e) => handleCheckboxChange(e, item, monday_list, setMonday_List)} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Checkbox value={item?.id} checked={tuesday_list?.filter(o => o === item.id).length > 0} onChange={(e) => handleCheckboxChange(e, item, tuesday_list, setTuesday_List)} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Checkbox value={item?.id} checked={wednesday_list?.filter(o => o === item.id).length > 0} onChange={(e) => handleCheckboxChange(e, item, wednesday_list, setWednesday_List)} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Checkbox value={item?.id} checked={thursday_list?.filter(o => o === item.id).length > 0} onChange={(e) => handleCheckboxChange(e, item, thursday_list, setThursday_List)} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Checkbox value={item?.id} checked={friday_list?.filter(o => o === item.id).length > 0} onChange={(e) => handleCheckboxChange(e, item, friday_list, setFriday_List)} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Checkbox value={item?.id} checked={saturday_list?.filter(o => o === item.id).length > 0} onChange={(e) => handleCheckboxChange(e, item, saturday_list, setSaturday_List)} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end my-5'>
                <ButtonGroup className='d-flex flex-row align-items-center justify-content-end gap-4 me-3 my-3'>
                    {/* <Button onClick={handleSkip} className='rounded-3 bg-white' style={{ border: "1px solid #73589B", color: 'var(--primary-color)' }}>
                        Skip
                    </Button> */}
                    <Button onClick={handleNextOnClick} className='rounded-3 border-0 text-white' style={{ backgroundColor: 'var(--primary-color)', whiteSpace: "nowrap" }}>
                        Next
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

export default RegisterAsProfessionalSchedulingTab;
