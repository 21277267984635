import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { AppContext } from "../../../context";
//ICONS
import DeleteIcon from "@mui/icons-material/Delete";
import AutogeneratedReviewBar from "../component/AutogeneratedReviewBar";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { is } from "immutable";
// Mock data for Autocomplete fields
const drugs = [{ label: "Drug 1" }, { label: "Drug 2" }]; // Replace with actual data
const frequencies = [{ label: "Once a day" }, { label: "Twice a day" }]; // Replace with actual data
const takenWithOptions = [{ label: "With food" }, { label: "Without food" }]; // Replace with actual data
const routeOptions = [{ label: "Oral" }, { label: "Intravenous" }]; // Replace with actual data

const SessionMedicalPrescriptionTable = ({
  medData,
  aiGenerated,
  isWebsocketDataRecieved,
  sessionStatus
}) => {
  const [tableRows, setTableRows] = useState([{
    drug: "",
    dosage: "",
    form: "",
    route: "",
    frequency: "",
    strength: "",
    duration: "",
  }]);
  const { expertDashboardData, setExpertDashboardData } =
    useContext(AppContext);
  const [generated, setGenerated] = useState(false);
  useEffect(() => {

    setGenerated(aiGenerated);

  }, [aiGenerated]);

  useEffect(() => {
    if (isWebsocketDataRecieved) {
      setTableRows(expertDashboardData?.session_data?.prescribed_medicines)
    }
  }, [isWebsocketDataRecieved])

 
  // console.log("ExpertDashboardData", expertDashboardData);
  // console.log("tableRows", tableRows);

  const handleInputChange = (rowIndex, field, value) => {
    setTableRows((prevRows) => {
      const updatedRows = prevRows.map((row, index) =>
        index === rowIndex ? { ...row, [field]: value } : row
      );

      setExpertDashboardData((prevData) => ({
        ...prevData,
        session_data: {
          ...prevData.session_data,
          prescribed_medicines: updatedRows,
        },
      }));

      return updatedRows;
    });
  };


  const addNewRow = () => {
    const newRow = {
      drug: "",
      dosage: "",
      form: "",
      route: "",
      frequency: "",
      strength: "",
      duration: "",
    };
    setTableRows([...tableRows, newRow]);
    setExpertDashboardData((prevData) => ({
      ...prevData,
      session_data: {
        ...prevData.session_data,
        prescribed_medicines: [...tableRows, newRow],
      },
    }));
  };

  const handleDeleteRow = (rowIndex) => {
    const updatedRows = tableRows.filter((_, index) => index !== rowIndex);
    setTableRows(updatedRows);
    setExpertDashboardData((prevData) => ({
      ...prevData,
      session_data: {
        ...prevData.session_data,
        prescribed_medicines: updatedRows,
      },
    }));
  };

  const onTickClick = () => {
    setGenerated(false);
  };

  const onCrossClick = () => {
    setTableRows([{
      drug: "",
      dosage: "",
      form: "",
      route: "",
      frequency: "",
      strength: "",
      duration: "",
    }]);
    setExpertDashboardData((prevData) => ({
      ...prevData,
      session_data: {
        ...prevData.session_data,
        prescribed_medicines: [],
      },
    }));
  }

  return (
    <div style={{ border: "1px solid rgba(0, 0, 0, 0.1)", borderRadius: "8px" }} className={
      generated
        ? "session-box-blink SessionPrescription--Table p-3 generated-prescription-container"
        : "SessionPrescription--Table p-3 generated-prescription-container"
    } 
    // className=" SessionPrescription--Table p-3 generated-prescription-container "
    >
     {(generated && !sessionStatus) && (
        <div className="m-3">
          <span style={{ color: "#305FB8", width: "fit-content", border: "1px solid", backgroundColor: "rgba(48, 95, 184,0.2)"}} className="d-flex fw-bold px-3 gap-2 align-items-center   rounded-5 p-1">
            <img
              alt=""
              src="/assets/img/icons/sparkle_blue.svg"
              width={16}
              className=""
              color="#305FB8"
            />
            <span>AI Generated</span>
          </span>

        </div>
      )}
      <div className="p-2">
        {!generated ? (
          <div className="Table-span-3 d-flex align-items-center">
            {isWebsocketDataRecieved ? (
              <button
                onClick={() => setGenerated(true)}
                className=" border-0 mx-1"
                style={{ backgroundColor: "white" }}
                disabled={expertDashboardData?.session_data?.submitted}
              >
                <AutoFixHighIcon
                  sx={{ color: "#6C46A0", width: "30px", height: "30px" }}
                />
              </button>
            ) : (
              <div
                style={{ marginRight: "8px", marginLeft: "5px" }}
                className="d-flex align-items-center justify-content-center my-3"
              >
                <img
                  alt=""
                  src="/assets/img/icons/sparkling.png"
                  width={15}
                  className="animate-pulse"
                />
              </div>
            )}
            <h6>Prescription Medicines</h6>
          </div>
        ) : (
          <h6>Prescription Medicines</h6>
        )}
        <div className="Table--Head gap-2">
          <div className="Table-span-3">Medicine Name</div>
          <div className="Table-span-1">Dose</div>
          <div className="Table-span-2">Frequency</div>
          <div className="Table-span-2">Taken with</div>
          <div className="Table-span-2">Route</div>
          <div className="Table-span-2">Instructions</div>
        </div>
        {tableRows && tableRows.map((row, index) => (
          <div className="Table--Body gap-2 mt-2" key={index}>
            <div className="Table-span-3">
              <TextField
                fullWidth
                value={row.drug}
                onChange={(e) =>
                  handleInputChange(index, "drug", e.target.value)
                }
                disabled={expertDashboardData?.session_data?.submitted}
                label="name"
              />
            </div>
            <div className="Table-span-1">
              <TextField
                fullWidth
                value={row.dosage}
                onChange={(e) =>
                  handleInputChange(index, "dosage", e.target.value)
                }
                label="Dose"
                disabled={expertDashboardData?.session_data?.submitted}
              />
            </div>
            <div className="Table-span-2">
              <TextField
                fullWidth
                value={row.frequency}
                onChange={(e) =>
                  handleInputChange(index, "frequency", e.target.value)
                }
                label="Frequency"
                disabled={expertDashboardData?.session_data?.submitted}
              />
            </div>
            <div className="Table-span-2">
              <TextField
                fullWidth
                value={row.takenWith}
                onChange={(e) =>
                  handleInputChange(index, "takenWith", e.target.value)
                }
                label="Taken With"
                disabled={expertDashboardData?.session_data?.submitted}
              />
            </div>
            <div className="Table-span-2">
              <TextField
                fullWidth
                value={row.route}
                onChange={(e) =>
                  handleInputChange(index, "route", e.target.value)
                }
                label="Route"
                disabled={expertDashboardData?.session_data?.submitted}
              />
            </div>
            <div className="Table-span-2 d-flex align-items-center gap-1">
              <TextField
                fullWidth
                value={row.instructions}
                onChange={(e) =>
                  handleInputChange(index, "instructions", e.target.value)
                }
                label="Instructions"
                disabled={expertDashboardData?.session_data?.submitted}
              />
               { !expertDashboardData?.session_data?.submitted && <Button
                sx={{ minWidth: "max-content" }}
                onClick={() => handleDeleteRow(index)}
                disabled={expertDashboardData?.session_data?.submitted}
              >
                <DeleteIcon className="text-danger " />
              </Button>}
            </div>
          </div>
        ))}
        {!generated && (
          <Button
            onClick={addNewRow}
            sx={{ marginTop: "20px", width: "120px" }}
            className="PrimaryCTA"
            disabled={expertDashboardData?.session_data?.submitted}
          >
            Add New
          </Button>
        )}
        {!expertDashboardData?.session_data?.submitted && generated && <AutogeneratedReviewBar tick={() => onTickClick()} cross={() => onCrossClick()} />}
      </div>
    </div>
  );
};

export default SessionMedicalPrescriptionTable;
