import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Popup from "./Modal";
import MoodBar from "./MoodBar";
import { getMoodData, removeMoodTrackerRecord } from "../_request";
import SidebarMobile from "./SidebarMobile";
import { useAuth } from "../../../auth";
import { courseInternalContentView } from "../_request";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSnackbar } from "notistack";
import { Skeleton } from "@mui/material";

const modal_data = [
  {
    title: "Mood Monitor",
    desc: "Keep a tab on your emotions with our mood monitor.",
    subHead:
      "You can select an emoticon which is closest to how you feel today and keep a check on how you've been feeling over a period of time.",
  },
];

const MoodMonitor = () => {
  const [moodData, setMoodData] = useState("");
  const [moodMonitorData, setMoodMonitorData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = window.location.href.split("id=");
    let subLevelId;
    if (urlParams.length == 2) {
      subLevelId = urlParams[1];
      let body = {
        userId: currentUser?.UserId,
        subLevelId,
      };
      courseInternalContentView(body).then((resp) => {
        if (resp?.ResponseCode == 200) {
          setMoodMonitorData(resp?.data);
        }
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchMoodSummary();
  }, [moodMonitorData]);

  const fetchMoodSummary = () => {
    const body = {
      user_id: user_id,
      course_id: moodMonitorData?.course_data_id,
    };
    getMoodData(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setMoodData(resp?.data);
      } else {
        console.error(resp?.ResponseMessage);
      }
    });
  };

  const handelDelete = (item) => {
    var body = { user_id: user_id, course_id: 644, mood_tracker_id: item };
    removeMoodTrackerRecord(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        enqueueSnackbar(resp?.data.message, { variant: "success" });
        window.location.reload();
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  };

  const handlePrevButton = () => {
    if (moodMonitorData?.previous_id?.type === "video") {
      navigate(`/program-internal/module/${moodMonitorData?.previous_id?.id}`);
    } else if (moodMonitorData?.previous_id?.type === "audio") {
      navigate(`/program-internal/module/${moodMonitorData?.previous_id?.id}`);
    } else if (moodMonitorData?.previous_id?.type === "checksheet") {
      navigate(
        `/program-internal/module/daily-practice/${moodMonitorData?.previous_id?.id}`
      );
    } else if (moodMonitorData?.previous_id?.type === "journal") {
      navigate(
        `/program-internal/module/my-journal/${moodMonitorData?.previous_id?.id}`
      );
    } else if (moodMonitorData?.previous_id?.type === "quiz") {
      navigate(
        `/program-internal/module/quiz/${moodMonitorData?.previous_id?.id}`
      );
    } else if (moodMonitorData?.previous_id?.type === "stella_assessment") {
      navigate(
        `/program-internal/talk-to-stella?assessmentID=${moodMonitorData?.previous_id?.id}`
      );
    } else if (moodMonitorData?.previous_id?.type === "mood_monitor") {
      navigate(
        `/program-internal/module/mood-monitor?id=${moodMonitorData?.previous_id?.id}`
      );
    } else if (moodMonitorData?.previous_id?.type === "html") {
      navigate(
        `/program-internal/module/article/${moodMonitorData?.previous_id?.id}`
      );
    }
  };

  const handleNextButton = () => {
    if (moodMonitorData?.next_id?.type === "video") {
      navigate(`/program-internal/module/${moodMonitorData?.next_id?.id}`);
    } else if (moodMonitorData?.next_id?.type === "audio") {
      navigate(`/program-internal/module/${moodMonitorData?.next_id?.id}`);
    } else if (moodMonitorData?.next_id?.type === "checksheet") {
      navigate(
        `/program-internal/module/daily-practice/${moodMonitorData?.next_id?.id}`
      );
    } else if (moodMonitorData?.next_id?.type === "journal") {
      navigate(
        `/program-internal/module/my-journal/${moodMonitorData?.next_id?.id}`
      );
    } else if (moodMonitorData?.next_id?.type === "quiz") {
      navigate(`/program-internal/module/quiz/${moodMonitorData?.next_id?.id}`);
    } else if (moodMonitorData?.next_id?.type === "stella_assessment") {
      navigate(
        `/program-internal/talk-to-stella?assessmentID=${moodMonitorData?.next_id?.id}`
      );
    } else if (moodMonitorData?.next_id?.type === "mood_monitor") {
      navigate(
        `/program-internal/module/mood-monitor?id=${moodMonitorData?.next_id?.id}`
      );
    } else if (moodMonitorData?.next_id?.type === "html") {
      navigate(
        `/program-internal/module/article/${moodMonitorData?.next_id?.id}`
      );
    }
  };

  const handlePrevModuleButton = () => {
    navigate(
      `/program-internal/program-view/${moodMonitorData?.previous_module_id?.id}`
    );
  };

  const handleNextModuleButton = () => {
    navigate(
      `/program-internal/program-view/${moodMonitorData?.next_module_id?.id}`
    );
  };

  const moodAddCallback = () => {
    fetchMoodSummary();
  };
  console.log(moodMonitorData);
  return (
    <section style={{ display: "flex", overflowX: "hidden", height: "100%" }}>
      <Helmet>
        <title>Program | Mood monitor</title>
      </Helmet>

      {isLoading ? (
        <>
        <section className="d-none d-md-flex">
            <Skeleton variant="rectangular" width={300} height={1080} />
          </section>
          <section className="mood-monitor-section">
            <div className="mood-monitor-container">
              <div className="mood-monitor  ">
                <div className="sections-name d-flex gap-2 align-items-center justify-content-between">
                  <Skeleton
                    variant="rounded"
                    width="15%"
                    height={40}
                    className="rounded-3"
                  />
                  <Skeleton
                    variant="rounded"
                    width={25}
                    height={25}
                    className=""
                  />
                </div>

                <Skeleton
              variant="rounded"
              width="100%"
              height={30}
              className="rounded-3 my-2"
            />

                <Skeleton
              variant="rounded"
              width="100%"
              height={272}
              className="rounded-3 my-4"
            />
                <div className="mood-analysis-heading">
                <Skeleton
                    variant="rounded"
                    width="15%"
                    height={40}
                    className="rounded-3"
                  />
                </div>
                <div className="mood-analysis-container">
                  {
                    Array.from(new Array(12)).map((_, i) => (
                      <>
                        <div style={{backgroundColor:"rgb(0,0,0,0.07)"}}>
                        <div className="d-flex justify-content-between align-items-center p-4 my-1">
                          <div className="d-flex align-items-center gap-2  ">
                          <Skeleton variant="circular" width={30} height={30} className="rounded-full" />
                          <Skeleton variant="rounded" width={75} height={30} className="rounded-3"  />
                          </div>
                          <Skeleton variant="rounded" width={25} height={25} className="rounded-3" />
                        </div>
                        </div>
                      </>
                    ))
                  }
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <div>
                    <Skeleton variant="circular" width={35} height={35} className="rounded-full" />
                  </div>
                  <div>
                    <Skeleton variant="circular" width={35} height={35} className="rounded-full" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className="mood-monitor-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="mood-monitor-section">
            <div className="mood-monitor-container">
              <div className="mood-monitor-bg-img">
                <img
                  src="/assets/img/course_internal_journey/module_bg_img"
                  alt=""
                  loading="lazy"
                />
              </div>

              <div className="mood-monitor  ">
                {moodMonitorData !== null && (
                  <>
                    <div className="sections-heading">
                      {moodMonitorData?.course_name}
                      <span
                      // style={{ color: "#541CF5" }}
                      >
                        {" >"} {moodMonitorData?.content?.name}
                      </span>
                    </div>

                    <div className="sections-progress">
                      Section {moodMonitorData?.current_level_index + 1}/
                      {moodMonitorData?.level_count}
                    </div>
                  </>
                )}
                <div className="mood-monitor-name">
                  <h2>Mood Monitor</h2>
                  <img
                    src="/assets/img/course_internal_journey/info-icon.png"
                    alt=""
                    loading="lazy"
                    className="info-icon"
                    onClick={() => setShowModal(true)}
                  />
                  <Popup
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    data={modal_data[0]}
                  />
                </div>
                <div className="mood-monitor-desc">
                  The first step towards understanding how you are feeling is to
                  track your moods.
                </div>
                <div>
                  <MoodBar
                    moodCallback={moodAddCallback}
                    courseID={moodMonitorData?.course_data_id}
                  />
                </div>
                <div className="mood-analysis-heading">
                  <div>Mood analysis</div>
                </div>
                <div className="mood-analysis-container">
                  {moodData?.mood_summary
                    ?.sort((a, b) => b.id - a.id)
                    .map((mood, i) => (
                      <div className="mood-analysis-item">
                        <div className="mood-analysis-text">
                          <img
                            alt=""
                            style={{ background: "#79579e", borderRadius: '10px' }}
                            src={
                              (mood?.name === "Angry" &&
                                "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FAngry.png?alt=media") ||
                              ((mood?.name === "Disgusted" ||
                                mood?.name === "Disgust") &&
                                "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FDisgusted.png?alt=media") ||
                              (mood?.name === "Happy" &&
                                "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FHappy.png?alt=media") ||
                              (mood?.name === "Sad" &&
                                "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FSad.png?alt=media") ||
                              (mood?.name === "Afraid" &&
                                "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FAfraid.png?alt=media") ||
                              (mood?.name === "Surprised" &&
                                "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FSurprised.png?alt=media")
                            }
                            className="mood-monitor-emoji"
                          />
                          <p className="mood-icon">{mood?.name}</p>
                          <p>{mood?.date_time}</p>
                        </div>
                        <div onClick={() => handelDelete(mood?.id)}>
                          <img
                            src="/assets/img/course_internal_journey/dlt-icon.png"
                            className="mood-dlt-icon"
                          />
                        </div>
                      </div>
                    ))}
                </div>
                <div className="mood-monitor-btn">
                  <div>
                    {moodMonitorData?.previous_id.type && (
                      <div className="prev-btn" onClick={handlePrevButton}>
                        <div className="modules-prev-text">Previous</div>
                        <div className="prev-arrow">
                          <img
                            src="/assets/img/course_internal_journey/modules/arrow.png"
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                            style={{ transform: "rotate(180deg)" }}
                          />
                        </div>
                      </div>
                    )}
                    {moodMonitorData?.previous_module_id?.id &&
                      !moodMonitorData?.previous_id?.type && (
                        <div
                          className="prev-btn"
                          onClick={handlePrevModuleButton}
                        >
                          <div className="modules-text">Previous Module</div>
                          <div className="prev-arrow">
                            <img
                              src="/assets/img/course_internal_journey/modules/arrow.png"
                              alt=""
                              loading="lazy"
                              className="arrow-img"
                              style={{ transform: "rotate(180deg)" }}
                            />
                          </div>
                        </div>
                      )}
                  </div>

                  <div>
                    {moodMonitorData?.next_id.type && (
                      <div
                        className="next-btn"
                        style={{ marginLeft: "auto" }}
                        onClick={handleNextButton}
                      >
                        <div className="modules-text">Next</div>
                        <div className="next-arrow">
                          <img
                            src="/assets/img/course_internal_journey/modules/arrow.png"
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                    {moodMonitorData?.next_module_id?.id &&
                      !moodMonitorData?.next_id?.type && (
                        <div
                          className="next-btn"
                          onClick={handleNextModuleButton}
                        >
                          <div className="modules-text">Next Module</div>
                          <div className="next-arrow">
                            <img
                              src="/assets/img/course_internal_journey/modules/arrow.png"
                              alt=""
                              loading="lazy"
                              className="arrow-img"
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default MoodMonitor;
