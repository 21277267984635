import React, { useState, useEffect } from "react";
import "./Booking.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "../../components/core/commonFun";

const BookingStatus = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const expert_deatils = location?.state?.expertDetails;
  const appointment_deatils = location?.state?.appointment_Details;
  const success_response_data = location?.state?.resposeData;

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="loader-booking">
        <CircularProgress sx={{ color: "white" }} />
      </div>
    );
  }

  const onClickNavigate = () => {
    if (success_response_data === "Transaction Failed") {
      navigate("/");
    } else {
      navigate("/user-dashboard");
    }
  };

  return (
    <div className="Booking--Status">
      <div className="status-container">
        <div className="card-header">
          {success_response_data?.Message && (
            <img src="/assets/img/Green-tick.png" className="green-tick" />
          )}
          {success_response_data !== "Transaction Failed" ? (
            <h5 className=""> {`${success_response_data?.Message} !`} </h5>
          ) : (
            <h5 style={{ color: "#f80808" }}>Transaction Failed !</h5>
          )}
          <p>
            {success_response_data !== "Transaction Failed"
              ? success_response_data?.ServiceTypeMessage
              : "Apologies, but we couldn't process your payment at this time. Consider using an alternative payment method if needed. For assistance, contact our support or return to homepage"}
          </p>
        </div>
        {success_response_data !== "Transaction Failed" ? (
          <div className="appointment-confirm-card">
            <div className="heading">Appointment Details</div>
            <div className="d-flex gap-3 lh-lg mt-2">
              <img
                src={expert_deatils?.ProfileImage}
                alt=""
                loading="lazy"
                className="expert-image"
              />
              <div className="my-auto d-flex flex-column justify-content-start gap-1">
                <p className="expert-name">{expert_deatils?.Name}</p>
                <p className="expert-exp">{`${expert_deatils?.Experience} Years`}</p>
                {/* <div className="PrimaryCTA---ALT"> */}
                <div className="expert-profession-badge">
                  {expert_deatils?.RegisterYourselfAs}
                </div>
              </div>
            </div>
            <div className="session-info-table">
              <table>
                <tbody>
                  <tr>
                    <td className="session-label">Appointment Date:</td>
                    <td className="session-details">
                      <CalendarTodayIcon />
                      {"  "}
                      {appointment_deatils
                        ? formatDate(appointment_deatils?.selectedDate)
                        : " "}
                    </td>
                  </tr>
                  <tr>
                    <td className="session-label">Appointment Time:</td>
                    <td className="session-details">
                      <AccessTimeIcon />
                      {"  "}
                      {appointment_deatils
                        ? appointment_deatils?.selectSlot?.Time_Duration
                        : " "}
                    </td>
                  </tr>
                  <tr>
                    <td className="session-label">Session Duration:</td>
                    <td className="session-details">
                      <AccessTimeIcon />
                      {"  "}
                      {appointment_deatils
                        ? `${appointment_deatils?.callTime} Minutes`
                        : " "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="my-3 d-flex align-item-center justify-content-center">
            <img src="/assets/img/failed.png" width={300} />
          </div>
        )}

        <button
          role="button"
          onClick={onClickNavigate}
          className="PrimaryCTA my-4 w-100"
        >
          {success_response_data === "Transaction Failed" ? "Home" : "Continue"}
        </button>
      </div>
    </div>
  );
};

export default BookingStatus;
