import React, { useState } from "react";

const SessionStatGraphCard = ({
  title,
  isLanguageCard,
  isEmotionCard,
  graphData,
  isWebsocketDataRecieved,
}) => {
  let userEmotions = {};
  let sortedArray = [];

  const [available, setAvailable] = useState(false);

  if (isEmotionCard && graphData) {
    userEmotions = graphData[0]?.user;
    if (!userEmotions) return;

    sortedArray = Object.entries(userEmotions)
      .map(([key, value]) => [key, parseInt(value, 10)])
      .filter(([_, value]) => value !== 0) // Exclude entries with value 0
      .sort((a, b) => b[1] - a[1]);
  }


  // if (graphData !== undefined && graphData !== null) {
  //   console.log("Object.entries graphData", Object.entries(graphData));
  // }

  return (
    <div className="GraphCard--Item">
      <h6>{title}</h6>
      {isWebsocketDataRecieved ? (
        <>
          {isLanguageCard ? (
            <div className="d-flex align-items-center mt-3 Item--LangContainer">
              {graphData !== undefined &&
                graphData !== null &&
                Object.entries(graphData)
                  .map(([language, percentage]) => {
                    const numericPercentage = +percentage?.split("%")[0];

                    if (numericPercentage > 0) {
                      return { language, percentage, numericPercentage };
                    }

                    return null; // Exclude entries with percentage 0
                  })
                  .filter(Boolean) // Remove null entries
                  .sort((a, b) => b.numericPercentage - a.numericPercentage)
                  .map(({ language, percentage, numericPercentage }) => (
                    <div
                      className="w-100 d-flex justify-content-center p-2 Lang--Item"
                      style={{ flexBasis: `${percentage}` }}
                      key={language}
                    >
                      <p>{language}</p>
                      {numericPercentage > 10 && (
                        <span>{percentage === "" ? "0%" : `${percentage}`}</span>
                      )}
                    </div>
                  ))}



            </div>
          ) : isEmotionCard ? (
            <div className="d-flex mt-3">
              {sortedArray?.map(([emotion, percentage], index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      flexBasis: `${percentage}%`,
                      background: `${emotion === "Client" ? "white" : ""}`,
                      color: `${emotion === "Client" ? "black" : "white"}`,
                      borderTop: `${emotion === "Client" ? "0.5px solid #7944AE" : ""
                        }`,
                      borderBottom: `${emotion === "Client" ? "0.5px solid #7944AE" : ""
                        }`,
                    }}
                    className="d-flex flex-column align-items-center justify-content-center p-2 Item--GraphContainer"
                  >
                    <p>{percentage ? `${percentage}%` : 0}</p>
                    {percentage > 10 && (
                      <span style={{ textTransform: "capitalize" }}>
                        {emotion}
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="d-flex mt-3">
              {graphData !== undefined &&
                Object.entries(graphData)
                  .filter(([name, per]) => parseInt(per, 10) > 0)
                  .sort(([, per1], [, per2]) => parseInt(per2, 10) - parseInt(per1, 10))
                  .map(([name, per]) => {
                    const percentage = parseInt(per, 10);

                    return (
                      <div
                        style={{
                          width: "100%",
                          flexBasis: `${percentage}%`,
                          background: `${name === "Client" ? "white" : ""}`,
                          color: `${name === "Client" ? "black" : "white"}`,
                          borderTop: `${name === "Client" ? "0.5px solid #7944AE" : ""}`,
                          borderBottom: `${name === "Client" ? "0.5px solid #7944AE" : ""}`,
                        }}
                        className="d-flex flex-column align-items-center justify-content-center p-2 Item--GraphContainer"
                        key={name}
                      >
                        <p>{percentage}%</p>
                        {percentage > 10 && (
                          <span style={{ textTransform: "capitalize" }}>
                            {name?.split("_")[0]}
                          </span>
                        )}
                      </div>
                    );
                  })}


            </div>
          )}
        </>
      ) : (
        <div className="d-flex align-items-center justify-content-center my-3">
          <img
            alt=""
            src="/assets/img/icons/sparkling.png"
            width={15}
            className="animate-pulse"
          />
        </div>
      )}
    </div>
  );
};

export default SessionStatGraphCard;
