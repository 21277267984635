import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

//icons
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";

const ClaimHistoryModal = ({ open, setOpen }) => {
  const [expertData, setExpertData] = useState({
    name: "Jane Doe",
    profession: "Psychologist",
    image:
      "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/June_2023%2FScreen%20Shot%202022-11-01%20at%206.48.48%20PM.png?alt=media&token=39365c5d-4fce-4bef-804d-871fed4740dd",
    experience: "7 years",
    languages: "Hindi, English, Punjabi",
    qualifications:
      "Bachelors of Science with honours in Psychology, University of Warwick, UK University of Warwick.",
  });
  const [bookingInformation, setBookingInformation] = useState({
    type: "Video Session",
    date: "20 March 2024",
    time: "09:00 AM (IST)",
    phone: "+91 9876543210",
    duration: "60 Min",
    insuranceBenefits: true,
    insuranceList: [
      {
        isSelected: true,
        insuranceName: "HDFC Ergo Health Insurance",
        verfied: true,
        memberID: "28768947657",
        policy: "4094294300",
        color: "green",
      },
    ],
  });

  const [billDetails, setBillDetails] = useState({
    withInsurance: true,
    name: "John Doe",
    email: "johnDoe@tmail.com",
    mobile: "0987654321",
    insuranceCompany: "HDFC Life Insurance",
    memberID: "28768947657",
    policy: "4094294300",
    totalCharge: "$100.00",
    coveredAmount: "$70.00",
    deductible: "$10.00",
    copay: "$10.00",
    coinsurance: "$10.00",
    billAmount: "$30.00",
    dateoftransaction: "08/16/2024, 04:19 PM",
    transcationId: "UWC7587637",
    paymentStatus: "Denied",
  });

  return (
    <div>
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        className="Claim-History-Modal"
      >
        <DialogContent>
          <div className="Modal-container">
            <div className="session-details-container">
              <h6>Session Details</h6>
              <div className="d-flex flex-column" style={{height: '93%',  justifyContent: 'space-between' }}>
                <div>
                  <div className="d-flex align-items-center gap-3 Expert--Info__Header">
                    <img src={expertData?.image} alt="" />
                    <div>
                      <h3>{expertData?.name}</h3>
                      <p>{expertData?.profession}</p>
                    </div>
                  </div>
                  <div className="Info--Grid my-3 lh-lg">
                    <div className="d-flex align-items-center gap-2">
                      <CalendarMonthOutlinedIcon sx={{ color: "#b3b7bd" }} />
                      <p>
                        {bookingInformation?.date}, {bookingInformation?.time}
                      </p>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <PeopleAltOutlinedIcon sx={{ color: "#b3b7bd" }} />
                      <p>{bookingInformation?.duration}</p>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <VideocamOutlinedIcon sx={{ color: "#b3b7bd" }} />
                      <p>{bookingInformation?.type}</p>
                    </div>
                  </div>
                </div>
                <div
                  role="button"
                  className="PrimaryCTA"
                >
                  Download Invoice
                </div>
              </div>
            </div>

            <div className="bill-container">
              <div className="background">
                <h6>Insurance Details</h6>
                <div className="receipt lh-lg">
                  {bookingInformation.insuranceList.map((item, index) => (
                    <>
                      <div className="d-flex justify-content-between">
                        {item.insuranceName}
                        <img
                          src="/assets/img/hdfc_ergo_insurance.png"
                          alt=""
                          loading="lazy"
                          width={38}
                          height={24}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        Policy Number <span>{item.policy}</span>
                      </div>
                      <div className="d-flex justify-content-between">
                        Member ID <span>{item.memberID}</span>
                      </div>
                    </>
                  ))}
                </div>

                <div className="payment-details mt-4">
                  <h6>Payment Details</h6>
                  <div className="lh-lg">
                    <div className="d-flex justify-content-between">
                      <div className="bill-key"> Date of Transaction</div>
                      <div className="bill-value">
                        {billDetails.dateoftransaction}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="bill-key">Transaction ID</div>
                      <div className="bill-value">
                        {billDetails.transcationId}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="bill-key">Total Charge</div>
                      <div className="bill-value">
                        {billDetails.totalCharge}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="bill-key">Covered Amount</div>
                      <div className="bill-value">
                        {billDetails.coveredAmount}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="bill-key">Deductible</div>
                      <div className="bill-value">{billDetails.deductible}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="bill-key">Co-pay</div>
                      <div className="bill-value">{billDetails.copay}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="bill-key">Co-Insurance</div>
                      <div className="bill-value">
                        {billDetails.coinsurance}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="bill-key">Total amount paid</div>
                      <div className="bill-value">{billDetails.billAmount}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="bill-key">Payment Status</div>
                      <div className="bill-value" style={{color:"red"}}>
                        {billDetails.paymentStatus}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ClaimHistoryModal;
