import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AccessTimeIcon from "../../../assets/icons/clock-icon.svg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NotCompleted from "../modals/NotCompleted";
import SessionComplete from "../modals/SessionCompleted";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context";
const MarkedSessions = ({
  session,
  status,
  markedSessions,
  openModal,
  handleOpenModal,
  handleCloseModal,
  openModal1,
  handleOpenModal1,
  handleCloseModal1,
  loading,
}) => {
  const [currPage, setcurrPage] = useState(1);
  const [appointmentId, setAppointmentId] = useState("");
  const cards = 5;
  const lastIndex = currPage * cards;
  const firstIndex = lastIndex - cards;
  const navigate = useNavigate();
  const { expertDashboardData, setExpertDashboardData } =
    useContext(AppContext);
  const validateSession = (data) => {
    if (data?.session_status === "Not Completed") {
      handleOpenModal1();
    } else {
      handleOpenModal();
    }
    setAppointmentId(data?.appointment_id);
  };
  const searchBarParams = new URLSearchParams(window.location.search);
  const session_status = searchBarParams.get("completed_flag");
  //console.log("markedSessions", markedSessions);
  return (
    <div style={{ backgroundColor: "white" }}>
      <p
        style={{
          color: "black",
          fontSize: "16px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          marginLeft: "40px",
          paddingTop: "30px",
        }}
      >
        {session == "completed"
          ? "Completed Sessions"
          : session === "all"
          ? "Marked Sessions"
          : "Not Completed Sessions"}
      </p>

      <div className="d-flex flex-wrap  px-3 mt-3 mx-3 custom-font">
        {!loading && markedSessions &&
          markedSessions.length > 0 &&
          markedSessions?.map((data, index) => (
            <div key={index} className="col-md-4 col-lg-3 mb-3 px-2">
              <div className="card mb-3" style={{ height: "100%" }}>
                <div className="card-body d-flex flex-column justify-content-evenly">
                  <div className="d-flex justify-content justify-spaceBetween align-items-center mb-3">
                    <img
                      src={data.client_pofile_picture}
                      alt="User"
                      className="img-fluid rounded-circle"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5
                        className="card-title"
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "14px",
                          marginLeft: "10px",
                        }}
                      >
                        {data.client_name}
                      </h5>
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "10px",
                          color: "grey",
                          marginLeft: "10px",
                          marginTop: "-8px",
                        }}
                      >
                        Follow-up
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-2 px-1 ">
                    <div
                      style={{
                        FontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      <CalendarMonthIcon
                        style={{ color: "#73589b", height: "22px" }}
                      />
                      {data.start_date}
                    </div>
                    <div
                      style={{
                        marginRight: "15px",
                        FontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      <img
                        src={AccessTimeIcon}
                        style={{
                          height: "20px",
                          whiteSpace: "nowrap",
                          paddingRight: "5px",
                        }}
                      />
                      {data.start_time}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        marginLeft: "9px",
                      }}
                      className={`text-${
                        data.session_status !== "Not Completed"
                          ? "success"
                          : "danger"
                      }`}
                    >
                      {data.session_status === "Not Completed"
                        ? "Not Completed"
                        : "Completed"}
                    </p>
                    <p
                      style={{
                        textDecoration: "underline",
                        textDecorationColor: "#545454",
                        cursor: "pointer",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 400,
                        marginRight: "20px",
                      }}
                      onClick={() => validateSession(data)}
                    >
                      View Details
                    </p>
                  </div>
                  {/* {data.session_status !== "Not Completed" && (
                    <button
                      className="d-flex align-items-center gap-2 px-2 mt-2 py-1"
                      style={{
                        border: "1px solid #E0E0E0",
                        backgroundColor: "#F5F5F5",
                        fontStyle: "normal",
                        fontWeight: 600,
                        borderRadius: "30px",
                        height: "34px",
                        width: "100%",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        navigate(
                          `/expert/sessionmanagement?view=sessionDashboard&clientID=${
                            data.client_id
                          }&appointmentID=${
                            data.appointment_id
                          }&completed_flag=${
                            data.session_status === "Completed"
                          }`
                        );
                        setExpertDashboardData((prevData) => ({
                          ...prevData,
                          clientID: data.client_id,
                          clientName: data.client_name,
                          appointmentID: data.appointment_id,
                          appointmentDate: data.start_time_ts_ist,
                          bookingDate: data.booking_time_ts_ist,
                          consultationDate: data.start_date,
                        }));
                      }}
                    >
                      <SpaceDashboardOutlinedIcon />
                      <span>Session Dashboard</span>
                    </button>
                  )} */}
                  {data?.incompleted_flag && (
                    <div className="d-flex align-items-center gap-1 mt-2">
                      <span
                        style={{
                          padding: "5px",
                          borderRadius: "50%",
                          background: "#ff000078",
                          border: "1px solid #ff00005c",
                        }}
                      ></span>
                      <p>Missed meeting</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        <NotCompleted
          status={"read"}
          appointment_id={appointmentId}
          open={openModal1}
          handleClose={handleCloseModal1}
        />
        <SessionComplete
          status={"read"}
          appointment_id={appointmentId}
          open={openModal}
          handleClose={handleCloseModal}
        />
        {!loading && markedSessions && markedSessions.length === 0 && (
          <div className="NoDataAvailable">
            <img src="/assets/img/no-appointment.png" />
            <p>
              No Sessions Found
            </p>
          </div>
        )}
      </div>
      <nav>
        <ul className="pagination">
          {/* <li className="page-item">
            <a href="#" className="page-link"
            onClick={prePage}>Prev</a>


          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default MarkedSessions;
