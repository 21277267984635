import React, { useState, useEffect } from "react";
import "../../Styles/style.css"; // Import the custom CSS file
import { Skeleton } from "@mui/material";
import { BsArrowRight } from "react-icons/bs";

import { BiCalendarCheck } from "react-icons/bi";
import { FaBell } from "react-icons/fa";
import { FaHeadset } from "react-icons/fa";
import { FaRegCalendarCheck } from "react-icons/fa";
import { LuCalendarCheck } from "react-icons/lu";
import { LuCalendarClock } from "react-icons/lu";
import headphone from "../../images/calender check.png";
import LogoutIcon from "@mui/icons-material/Logout";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import { useAuth } from "../../../../auth";

import doc from "../../images/doc1.jpg";
import { useNavigate } from "react-router-dom";

function Sidebar({ activeItem }) {
  const navigate = useNavigate();
  const { currentUser, userType, logout } = useAuth();

  const [selectedItem, setSelectedItem] = useState(activeItem);

  const [showIcon, setShowIcon] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer); // Cleanup function to clear the timeout on component unmount
  }, []);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const buttonstyle = {
    backgroundColor: "#73589B",
    color: "#ffff",
    borderRadius: "20px",
    WebkitBorderRadius: "20px",
    MozBorderRadius: "20px",
    msBorderRadius: "20px",
    OBorderRadius: "20px",
  };

  const onLogout = () => {
    navigate("/");
    logout();
  };

  const authToken = localStorage.getItem("Authorization")?.split("Bearer ")[1];

  return (
    <>
      {loading ? (
        <div
          className="p-3 pb-0 h-100  "
          style={{
            backgroundColor: "rgb(0,0,0,0.07)",
          }}
        >
          <div
            className="m-0 p-0 d-none d-md-flex flex-column gap-2"
            style={{
              borderRadius: "8px",
            }}
          >
            {Array.from(new Array(7)).map((_, i) => (
              <>
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={40}
                  className="rounded-pill"
                />
              </>
            ))}
          </div>
        </div>
      ) : (
    <div className="p-2  pb-0 100vh">
      <div
        className="m-0 p-0"
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
        }}
      >
        <ul className="list-unstyled custom-menu-items m-0 p-0">
          <li
            className="mb-3 custom-menu-item options_tabs d-flex justify-content-between align-items-center"
            onClick={() => {
              navigate("/user-dashboard");
              handleItemClick("Dashboard");
            }}
            onMouseEnter={() => setShowIcon(true)}
            onMouseLeave={() => setShowIcon(false)}
            style={selectedItem == "Dashboard" ? buttonstyle : null}
          >
            <div className="d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width="20"
                height="20"
                className="mx-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                />
              </svg>
              <div className="">Dashboard</div>
            </div>

            <div className="mx-3">
              {selectedItem == "Dashboard" ? (
                <span className="">
                  <BsArrowRight />
                </span>
              ) : null}
            </div>
          </li>

          <li
            className="mb-3 custom-menu-item options_tabs d-flex justify-content-between align-items-center"
            onClick={() => {
              navigate("/dashboard/my-appointments");
              handleItemClick("Appointments");
            }}
            onMouseEnter={() => setShowIcon(true)}
            onMouseLeave={() => setShowIcon(false)}
            style={selectedItem === "Appointments" ? buttonstyle : null}
          >
            <div className="d-flex align-items-center">
              <LuCalendarCheck
                style={{ width: "20px", height: "20px" }}
                className="mx-3"
              />
              <div
                className="text-truncate"
                style={{ fontSize: "16px", marginRight: "25px" }}
              >
                Appointments
              </div>
            </div>
            <div className="me-3">
              {selectedItem === "Appointments" ? (
                <span className=" ">
                  <BsArrowRight />
                </span>
              ) : null}
            </div>
          </li>

          <li
            className="mb-3 custom-menu-item options_tabs d-flex justify-content-between align-items-center"
            onClick={() => {
              window.open(
                `https://stella.unitedwecare.com/?token=${authToken}&appURL=https://us.unitedwecare.com`
              );
              handleItemClick("Chat With Stella");
            }}
            onMouseEnter={() => setShowIcon(true)}
            onMouseLeave={() => setShowIcon(false)}
            style={selectedItem == "Chat With Stella" ? buttonstyle : null}
          >
            <div className="d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                width="20"
                height="20"
                className="mx-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                />
              </svg>
              <div className="text-truncate">Chat With Stella</div>
            </div>
            <div className="mx-3">
              {selectedItem == "Chat With Stella" ? (
                <span className="">
                  <BsArrowRight />
                </span>
              ) : null}
            </div>
          </li>

          <li
            className="mb-3 custom-menu-item options_tabs d-flex justify-content-between align-items-center d-none"
            onClick={() => handleItemClick("My Progress")}
            onMouseEnter={() => setShowIcon(true)}
            onMouseLeave={() => setShowIcon(false)}
            style={selectedItem == "My Progress" ? buttonstyle : null}
          >
            <div className="d-flex align-items-center">
              <LuCalendarClock
                className="mx-3"
                style={{ width: "20px", height: "20px" }}
              />
              <div className="text-truncate">My Progress</div>
            </div>
            <div className="mx-3">
              {selectedItem == "My Progress" ? (
                <span className="">
                  <BsArrowRight />
                </span>
              ) : null}
            </div>
          </li>

          <li
            className="mb-3 custom-menu-item options_tabs d-flex justify-content-between align-items-center d-none"
            onClick={() => handleItemClick("Worksheets")}
            onMouseEnter={() => setShowIcon(true)}
            onMouseLeave={() => setShowIcon(false)}
            style={selectedItem === "Worksheets" ? buttonstyle : null}
          >
            <div className="d-flex align-items-center">
              <LuCalendarCheck
                className="mx-3"
                style={{ width: "20px", height: "20px" }}
              />
              <div className="text-truncate">Worksheets</div>
            </div>

            <div className="mx-3">
              {selectedItem === "Worksheets" ? (
                <span className="">
                  <BsArrowRight />
                </span>
              ) : null}
            </div>
          </li>

          <li
            className="mb-3 custom-menu-item options_tabs d-flex justify-content-between align-items-center"
            onClick={() => {
              navigate("/dashboard/program/my-programs");
              handleItemClick("My Programs");
            }}
            onMouseEnter={() => setShowIcon(true)}
            onMouseLeave={() => setShowIcon(false)}
            style={selectedItem === "My Programs" ? buttonstyle : null}
          >
            <div className="d-flex align-items-center">
              <BiCalendarCheck
                className="mx-3"
                style={{ width: "20px", height: "20px" }}
              />
              <div className="text-truncate">My Programs</div>
            </div>
            <div className="mx-3">
              {selectedItem === "My Programs" ? (
                <span className="">
                  <BsArrowRight />
                </span>
              ) : null}
            </div>
          </li>

          {/* <li
            className="mb-3 custom-menu-item options_tabs d-flex justify-content-between align-items-center"
            onClick={() => {
              handleItemClick("My Insurance");
              navigate("/dashboard/my-insurance");
            }}
            onMouseEnter={() => setShowIcon(true)}
            onMouseLeave={() => setShowIcon(false)}
            style={selectedItem === "My Insurance" ? buttonstyle : null}
          >
            <div className="d-flex align-items-center">
              {selectedItem === "My Insurance" ? (
                <img
                  src="/assets/img/insurance-sidebar-active.png"
                  alt=""
                  loading="lazy"
                  className="mx-3"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                <img
                  src="/assets/img/insurance-icon-sidebar-dashboard.png"
                  alt=""
                  loading="lazy"
                  className="mx-3"
                  style={{ width: "20px", height: "20px" }}
                />
              )}

              <div className="text-truncate">My Insurance</div>
            </div>
            <div className="mx-3">
              {selectedItem === "My Insurance" ? (
                <span className="">
                  <BsArrowRight />
                </span>
              ) : null}
            </div>
          </li> */}
          
          <li
            className="mb-3 custom-menu-item options_tabs d-flex justify-content-between align-items-center"
            style={selectedItem === "Blogs" ? buttonstyle : null}
            onClick={() => {
              window.open(
                "https://www.unitedwecare.com/blog/",
                "_blank",
                "noopener noreferrer"
              );
              handleItemClick("Blogs");
            }}
          >
            <div className="d-flex align-items-center">
              <LuCalendarCheck
                className="mx-3"
                style={{ width: "20px", height: "20px" }}
              />
              <div className="text-truncate">Blogs</div>
            </div>
            <div className="mx-3">
              {selectedItem === "Blogs" ? <BsArrowRight /> : null}
            </div>
          </li>

          <li
            className="mb-3 custom-menu-item options_tabs d-flex justify-content-between align-items-center d-none"
            onClick={() => handleItemClick("Notifications")}
            onMouseEnter={() => setShowIcon(true)}
            onMouseLeave={() => setShowIcon(false)}
            style={selectedItem === "Notifications" ? buttonstyle : null}
          >
            <div className="d-flex align-items-center">
              <svg
                className="text-gray-800 dark:text-white mx-3"
                width={20}
                height={20}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 21"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 3.464V1.1m0 2.365a5.338 5.338 0 0 1 5.133 5.368v1.8c0 2.386 1.867 2.982 1.867 4.175C15 15.4 15 16 14.462 16H1.538C1 16 1 15.4 1 14.807c0-1.193 1.867-1.789 1.867-4.175v-1.8A5.338 5.338 0 0 1 8 3.464ZM4.54 16a3.48 3.48 0 0 0 6.92 0H4.54Z"
                />
              </svg>
              <div className="text-truncate">Notifications</div>
            </div>
            <div className="mx-3">
              {selectedItem === "Notifications" ? (
                <span className="">
                  <BsArrowRight />
                </span>
              ) : null}
            </div>
          </li>

          <li
            className="mb-3 custom-menu-item options_tabs d-flex justify-content-between align-items-center"
            onClick={() => {
              window.open(
                "https://www.unitedwecare.com/faq/",
                // "_blank",
                "rel=noopener noreferrer"
              );
              handleItemClick("FAQ");
            }}
            onMouseEnter={() => setShowIcon(true)}
            onMouseLeave={() => setShowIcon(false)}
            style={selectedItem === "FAQ" ? buttonstyle : null}
          >
            <div className="d-flex align-items-center">
              <QuizOutlinedIcon
                className="mx-3"
                style={{ width: "20px", height: "20px" }}
              />
              <div className="text-truncate">FAQ</div>
            </div>
            <div className="mx-3">
              {selectedItem === "FAQ" ? (
                <span className="">
                  <BsArrowRight />
                </span>
              ) : null}
            </div>
          </li>

          <li
            className="mb-3 custom-menu-item options_tabs d-flex justify-content-between align-items-center"
            onClick={() => {
              window.open(
                "https://www.unitedwecare.com/contact/",
                // "_blank",
                "rel=noopener noreferrer"
              );
              handleItemClick("Help Center");
            }}
            onMouseEnter={() => setShowIcon(true)}
            onMouseLeave={() => setShowIcon(false)}
            style={selectedItem === "Help Center" ? buttonstyle : null}
          >
            <div className="d-flex align-items-center">
              <FaHeadset
                className="mx-3"
                style={{ width: "20px", height: "20px" }}
              />
              <div className="text-truncate">Help Center</div>
            </div>
            <div className="mx-3">
              {selectedItem === "Help Center" ? (
                <span className="">
                  <BsArrowRight />
                </span>
              ) : null}
            </div>
          </li>

          <div className="listdrop d-none">
            <div
              className="d-flex align-items-center gap-2"
              style={{ cursor: "pointer" }}
              onClick={onLogout}
            >
              <LogoutIcon className="mx-3" />
              <span style={{ marginLeft: "-10px" }}>Logout</span>
            </div>

            {/* <div
              className="d-flex align-items-center gap-2"
              style={{ cursor: "pointer" }}
            >
              <img
                src={currentUser?.ProfileImage}
                width={35}
                height={35}
                className="mx-2"
                alt="doc"
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
              <span className="dropdown-toggle fw-bold" data-toggle="dropdown">
                Welcome {currentUser?.Name.split(" ")[0]}{" "}
                <span className="caret"></span>
              </span>
            </div> */}

            {/* <ul className="dropdown-menu">
              <li>
                <a href="#">Link 1</a>
              </li>
            </ul> */}
          </div>
        </ul>
      </div>
    </div>
    
  )}
    </>
  );
}

export default Sidebar;
