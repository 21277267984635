import React, { useEffect, useState } from "react";
import "../styles/Sections.css";
import Sidebar from "./Sidebar";
import { useNavigate, useParams } from "react-router-dom/dist";
import { addBookmarkEntry, courseInternalContentView } from "../_request";
import { useAuth } from "../../../auth";
import SidebarMobile from "./SidebarMobile";

import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Helmet } from "react-helmet";
import { Skeleton } from "@mui/material";

const ModuleArticle = () => {
  const [courseContent, setCourseContent] = useState({});
  const { id } = useParams();
  const [subLevelId, setSubLevelId] = useState(id);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    var body = {
      userId: currentUser?.UserId,
      subLevelId: subLevelId,
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setCourseContent(resp?.data);
        setIsBookmarked(resp?.data?.bookmarked);
        setIsLoading(false);
      } else {
        console.error(resp?.ResponseMessage);
      }
    });
  }, [subLevelId, id]);

  const handlePrevButton = () => {
    debugger;
    if (courseContent?.previous_id?.type === "video") {
      navigate(`/program-internal/module/${courseContent?.previous_id?.id}`);
    } else if (courseContent?.previous_id?.type === "audio") {
      navigate(`/program-internal/module/${courseContent?.previous_id?.id}`);
    } else if (courseContent?.previous_id?.type === "checksheet") {
      navigate(
        `/program-internal/module/daily-practice/${courseContent?.previous_id?.id}`
      );
    } else if (courseContent?.previous_id?.type === "journal") {
      navigate(
        `/program-internal/module/my-journal/${courseContent?.previous_id?.id}`
      );
    } else if (courseContent?.previous_id?.type === "quiz") {
      navigate(
        `/program-internal/module/quiz/${courseContent?.previous_id?.id}`
      );
    } else if (courseContent?.previous_id?.type === "html") {
      navigate(
        `/program-internal/module/article/${courseContent?.previous_id?.id}`
      );
      setSubLevelId(courseContent?.previous_id?.id);
    } else if (courseContent?.previous_id?.type === "stella_assessment") {
      navigate(
        `/program-internal/talk-to-stella?assessmentID=${courseContent?.previous_id?.id}`
      );
    } else if (courseContent?.previous_id?.type === "mood_monitor") {
      navigate(
        `/program-internal/module/mood-monitor?id=${courseContent?.previous_id?.id}`
      );
    }
  };

  const handleNextButton = () => {
    if (courseContent?.next_id?.type === "video") {
      navigate(`/program-internal/module/${courseContent?.next_id?.id}`);
      setSubLevelId(courseContent?.next_id?.id);
    } else if (courseContent?.next_id?.type === "audio") {
      navigate(`/program-internal/module/${courseContent?.next_id?.id}`);
    } else if (courseContent?.next_id?.type === "checksheet") {
      navigate(
        `/program-internal/module/daily-practice/${courseContent?.next_id?.id}`
      );
    } else if (courseContent?.next_id?.type === "journal") {
      navigate(
        `/program-internal/module/my-journal/${courseContent?.next_id?.id}`
      );
    } else if (courseContent?.next_id?.type === "quiz") {
      navigate(`/program-internal/module/quiz/${courseContent?.next_id?.id}`);
    } else if (courseContent?.next_id?.type === "html") {
      navigate(
        `/program-internal/module/article/${courseContent?.next_id?.id}`
      );
      setSubLevelId(courseContent?.next_id?.id);
    } else if (courseContent?.next_id?.type === "stella_assessment") {
      navigate(
        `/program-internal/talk-to-stella?assessmentID=${courseContent?.next_id?.id}`
      );
    } else if (courseContent?.next_id?.type === "mood_monitor") {
      navigate(
        `/program-internal/module/mood-monitor?id=${courseContent?.next_id?.id}`
      );
    }
  };

  const handlePrevModuleButton = () => {
    navigate(
      `/program-internal/program-view/${courseContent?.previous_module_id?.id}`
    );
  };

  const handleNextModuleButton = () => {
    navigate(
      `/program-internal/program-view/${courseContent?.next_module_id?.id}`
    );
  };

  const handleBookmarkClick = (id) => {
    const bBody = { sub_level_id: subLevelId };
    addBookmarkEntry(bBody).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setIsBookmarked(resp?.data?.bookmarked);
      }
    });
  };

  return (
    <section style={{ display: "flex", overflowX: "hidden" }}>
      <Helmet>
        <title>Program | Article</title>
      </Helmet>
      {isLoading ? (
        <>
          <section className="d-none d-md-flex">
            <Skeleton variant="rectangular" width={300} height={1080} />
          </section>
          <section className="modules-section">
            <div>
              <div className="modules-container">
              <Skeleton
                  variant="rounded"
                  width="20%"
                  height={40}
                  className="rounded-3"
                />
                <Skeleton
                  variant="rounded"
                  width="40%"
                  height={40}
                  className="rounded-3 my-2"
                />
                <div className="sections-name d-flex gap-2 align-items-center justify-content-between">
                  <Skeleton
                    variant="rounded"
                    width="40%"
                    height={40}
                    className="rounded-3"
                  />
                  <Skeleton
                    variant="rounded"
                    width={25}
                    height={25}
                    className=""
                  />
                </div>

                <div className="modules-sections">

                  <div className="modules-sections-items my-2">
                  <Skeleton variant="rounded" width="100%" height={600} className="rounded-3" />
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <div>
                    <Skeleton variant="circular" width={35} height={35} className="rounded-full" />
                  </div>
                  <div>
                    <Skeleton variant="circular" width={35} height={35} className="rounded-full" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className="sections-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="sections--content">
            <div>
              <div className="sections-container">
                <div className="sections-heading">
                  {courseContent?.course_name}
                  {" >"} {courseContent?.content?.name}
                </div>

                <div className="sections-progress">
                  Section {courseContent?.current_level_index + 1}/
                  {courseContent?.level_count}
                </div>
                <div className="sections-name d-flex gap-2 align-items-center justify-content-between">
                  <h2>{courseContent?.content?.name}</h2>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleBookmarkClick()}
                  >
                    {isBookmarked ? (
                      <BookmarkIcon color="#541cf5" />
                    ) : (
                      <BookmarkBorderOutlinedIcon />
                    )}
                  </div>
                </div>

                <hr style={{ color: "#E0E0E0" }} />

                <div className="sections-desc">
                  {courseContent?.description}
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: courseContent?.content?.html,
                  }}
                />

                <div className="sections-btn">
                  <div>
                    {courseContent?.previous_id?.type && (
                      <div className="prev-btn" onClick={handlePrevButton}>
                        <div className="modules-prev-text">Previous</div>
                        <div className="prev-arrow">
                          <img
                            src="/assets/img/course_internal_journey/modules/arrow.png"
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                            style={{ transform: "rotate(180deg)" }}
                          />
                        </div>
                      </div>
                    )}
                    {courseContent?.previous_module_id?.id &&
                      !courseContent?.previous_id?.type && (
                        <div
                          className="prev-btn"
                          onClick={handlePrevModuleButton}
                        >
                          <div className="modules-text">Previous Module</div>
                          <div className="prev-arrow">
                            <img
                              src="/assets/img/course_internal_journey/modules/arrow.png"
                              alt=""
                              loading="lazy"
                              className="arrow-img"
                              style={{ transform: "rotate(180deg)" }}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                  <div>
                    {courseContent?.next_id?.type && (
                      <div className="next-btn" onClick={handleNextButton}>
                        <div className="modules-text">Next</div>
                        <div className="next-arrow">
                          <img
                            src="/assets/img/course_internal_journey/modules/arrow.png"
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                    {courseContent?.next_module_id?.id &&
                      !courseContent?.next_id?.type && (
                        <div
                          className="next-btn"
                          onClick={handleNextModuleButton}
                        >
                          <div className="modules-text">Next Module</div>
                          <div className="next-arrow">
                            <img
                              src="/assets/img/course_internal_journey/modules/arrow.png"
                              alt=""
                              loading="lazy"
                              className="arrow-img"
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default ModuleArticle;
