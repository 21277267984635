import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "../styles/CompleteQuiz.css";
import { courseInternalContentView } from "../_request";
import Popup from "./Modal";
import SidebarMobile from "./SidebarMobile";
import { useAuth } from "../../../auth";
import { useNavigate, useParams } from "react-router-dom";
import { addBookmarkEntry } from "../_request";

import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Helmet } from "react-helmet";
import { Skeleton } from "@mui/material";

const modal_data = [
  {
    title: "Quiz Time",
    desc: "Answer these questions to understand how much of the information disseminated within the program stuck with you.",
  },
];

const CompleteQuiz = () => {
  const [quizData, setQuizData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { currentUser } = useAuth();
  const { id } = useParams();
  const [subLevelId, setSubLevelId] = useState(id);
  const navigate = useNavigate();

  useEffect(() => {
    let body = {
      userId: currentUser?.UserId,
      subLevelId: subLevelId,
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setQuizData(resp?.data);
        setIsBookmarked(resp?.data?.bookmarked);
        setIsLoading(false);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  }, [subLevelId]);

  const handlePrevButton = () => {
    if (quizData?.previous_id?.type === "video") {
      navigate(`/program-internal/module/${quizData?.previous_id?.id}`);
    } else if (quizData?.previous_id?.type === "audio") {
      navigate(`/program-internal/module/${quizData?.previous_id?.id}`);
    } else if (quizData?.previous_id?.type === "checksheet") {
      navigate(
        `/program-internal/module/daily-practice/${quizData?.previous_id?.id}`
      );
    } else if (quizData?.previous_id?.type === "journal") {
      navigate(
        `/program-internal/module/my-journal/${quizData?.previous_id?.id}`
      );
    } else if (quizData?.previous_id?.type === "quiz") {
      navigate(`/program-internal/module/quiz/${quizData?.previous_id?.id}`);
      setSubLevelId(quizData?.previous_id?.id);
    } else if (quizData?.previous_id?.type === "html") {
      navigate(`/program-internal/module/article/${quizData?.previous_id?.id}`);
    } else if (quizData?.previous_id?.type === "stella_assessment") {
      navigate(
        `/program-internal/talk-to-stella?assessmentID=${quizData?.previous_id?.id}`
      );
    } else if (quizData?.previous_id?.type === "mood_monitor") {
      navigate(
        `/program-internal/module/mood-monitor?id=${quizData?.previous_id?.id}`
      );
    }
  };

  const handleNextButton = () => {
    if (quizData?.next_id?.type === "video") {
      navigate(`/program-internal/module/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "audio") {
      navigate(`/program-internal/module/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "checksheet") {
      navigate(
        `/program-internal/module/daily-practice/${quizData?.next_id?.id}`
      );
    } else if (quizData?.next_id?.type === "journal") {
      navigate(`/program-internal/module/my-journal/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "quiz") {
      navigate(`/program-internal/module/quiz/${quizData?.next_id?.id}`);
      setSubLevelId(quizData?.next_id?.id);
    } else if (quizData?.next_id?.type === "html") {
      navigate(`/program-internal/module/article/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "stella_assessment") {
      navigate(
        `/program-internal/talk-to-stella?assessmentID=${quizData?.next_id?.id}`
      );
    } else if (quizData?.next_id?.type === "mood_monitor") {
      navigate(
        `/program-internal/module/mood-monitor?id=${quizData?.next_id?.id}`
      );
    }
  };

  const handlePrevModuleButton = () => {
    navigate(
      `/program-internal/program-view/${quizData?.previous_module_id?.id}`
    );
  };

  const handleNextModuleButton = () => {
    navigate(`/program-internal/program-view/${quizData?.next_module_id?.id}`);
  };

  const handleBookmarkClick = () => {
    var bBody = { sub_level_id: subLevelId };
    addBookmarkEntry(bBody).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setIsBookmarked(resp?.data?.bookmarked);
      }
    });
  };
  return (
    <section className="complete-quiz">
      <Helmet>
        <title>Program | Quiz</title>
      </Helmet>
      {isLoading ? (
        <>
          <section className="d-none d-md-flex">
            <Skeleton variant="rectangular" width={300} height={1080} />
          </section>
          <section className="modules-section">
            <div>
              <div className="modules-container">
              <Skeleton
                  variant="rounded"
                  width="20%"
                  height={40}
                  className="rounded-3"
                />
                <Skeleton
                  variant="rounded"
                  width="40%"
                  height={40}
                  className="rounded-3 my-2"
                />
                <div className="sections-name d-flex gap-2 align-items-center justify-content-between">
                  <Skeleton
                    variant="rounded"
                    width="40%"
                    height={40}
                    className="rounded-3"
                  />
                  <Skeleton
                    variant="rounded"
                    width={25}
                    height={25}
                    className=""
                  />
                </div>

                <div className="modules-sections">
                
                  <Skeleton
                    variant="circular"
                    width={100}
                    height={100}
                    className="rounded-full mx-auto my-3"
                  />
                  <Skeleton
                    variant="rounded"
                    width="40%"
                    height={40}
                    className="rounded-3 mx-auto"
                  />
                  <div className="modules-sections-items my-2">
                  <Skeleton variant="rounded" width="100%" height={600} className="rounded-3" />
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <div>
                    <Skeleton variant="circular" width={35} height={35} className="rounded-full" />
                  </div>
                  <div>
                    <Skeleton variant="circular" width={35} height={35} className="rounded-full" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className="complete-quiz-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="complete-quiz-section">
            <div className="quiz-bg-img">
              <img
                src="/assets/img/course_internal_journey/module_bg_img"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="complete-quiz-container">
              <div className="sections-heading">
                {quizData?.course_name}
                {/* <span style={{ color: "#541CF5" }}> */}
                <span>
                  {" "}
                  {" >"} {quizData?.content?.name}{" "}
                </span>
              </div>
              <div className="sections-progress">
                Section {quizData?.current_level_index + 1}/
                {quizData?.level_count}
              </div>
              <div className="quiz-sections-name">
                <div style={{ width: "calc(100% - 20px)" }}>
                  {quizData?.content?.name}
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src="/assets/img/course_internal_journey/info-icon.png"
                    alt=""
                    loading="lazy"
                    className="quiz-info-icon"
                    onClick={() => setShowModal(true)}
                  />
                  <Popup
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    data={modal_data[0]}
                  />
                </div>

                <div
                  className="quiz-save"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleBookmarkClick()}
                >
                  {isBookmarked ? (
                    <BookmarkIcon color="#541cf5" />
                  ) : (
                    <BookmarkBorderOutlinedIcon />
                  )}
                </div>
              </div>
              <hr style={{ color: "#E0E0E0" }} />

              <div>
                <img
                  src="/assets/img/course_internal_journey/quiz/quiz-complete.png"
                  alt=""
                  loading="lazy"
                  className="complete-quiz-img"
                />
              </div>
              <div className="complete-quiz-section-desc">
                You have completed this quiz! Here’s a summary of what we
                covered...
              </div>
              <div className="complete-quiz-question-container">
                {quizData?.content?.quiz_summary?.map((item) => (
                  <div className="complete-items">
                    <img
                      src="/assets/img/course_internal_journey/daily-practice/green-tick.png"
                      alt=""
                      loading="lazy"
                      className="green-tick"
                    />
                    <div className="complete-items-text">{item}</div>
                  </div>
                ))}
              </div>

              <div className="complete-quiz-btn justify-content-between">
                <div>
                  {quizData?.previous_id.type && (
                    <div className="prev-btn" onClick={handlePrevButton}>
                      <div className="modules-prev-text">Previous</div>
                      <div className="prev-arrow">
                        <img
                          src="/assets/img/course_internal_journey/modules/arrow.png"
                          style={{ transform: "rotate(180deg)" }}
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                  {quizData?.previous_module_id?.id &&
                    !quizData?.previous_id?.type && (
                      <div
                        className="prev-btn"
                        onClick={handlePrevModuleButton}
                      >
                        <div className="modules-text">Previous Module</div>
                        <div className="prev-arrow">
                          <img
                            src="/assets/img/course_internal_journey/modules/arrow.png"
                            style={{ transform: "rotate(180deg)" }}
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                </div>

                <div>
                  {quizData?.next_id.type && (
                    <div className="next-btn" onClick={handleNextButton}>
                      <div className="modules-text">Next</div>
                      <div className="next-arrow">
                        <img
                          src="/assets/img/course_internal_journey/modules/arrow.png"
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                  {quizData?.next_module_id?.id && !quizData?.next_id?.type && (
                    <div className="next-btn" onClick={handleNextModuleButton}>
                      <div className="modules-text">Next Module</div>
                      <div className="next-arrow">
                        <img
                          src="/assets/img/course_internal_journey/modules/arrow.png"
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default CompleteQuiz;
