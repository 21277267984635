import { useState, useEffect } from "react";
import "./Booking.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import AddNewPolicyModal from "../../components/dashboard/Components/Dashboard/PayorIntegration/modals/AddNewPolicyModal";
import { useAuth } from "../../auth";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
//Icons
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";

//Form
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import InsuranceDetailCard from "./InsuranceDetailCard";
import { getInsuranceBenefitsHandler } from "../../components/subscriptions/core/request";
import { getExpertbyId_WebService } from "../../components/core/_request";
import { getCountryCode, formatDate } from "../../components/core/commonFun";

const BookingWithInsuranceSelection = () => {
  const location = useLocation();
  const appointment_Details = location?.state?.appointmentData;
  let { id } = useParams();
  const [insuranceCardData, setInsuranceCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const [expertDetailsData, setExpertDetailsData] = useState({});
  const countryCode = getCountryCode();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedInsuranceData, setSelectedInsuranceData] = useState(null);
  const [insuranceBenefits, setInsuranceBenefits] = useState(true);
  const handleInsuranceChange = (event) => {
    setInsuranceBenefits(event.target.value === "true");
  };
  const [bookingInformation, setBookingInformation] = useState({
    type: "Video Session",
    date: "20 March 2024",
    time: "09:00 AM (IST)",
    phone: "+91 9876543210",
    duration: "60 Min Individual Session",
    insuranceBenefits: true,
    insuranceList: [
      {
        isSelected: true,
        insuranceName: "HDFC Ergo Health Insurance",
        verfied: true,
        memberID: "28768947657",
        policy: "4094294300",
        color: "green",
      },
      {
        isSelected: false,
        insuranceName: "Tata General Insurance Co. Ltd.",
        verfied: true,
        memberID: "28768947657",
        policy: "4094294301",
        color: "blue",
      },
    ],
  });

  useEffect(() => {
    getExpertbyId_WebService(id).then((resp) => {
      var data = resp.data;
      if (countryCode == "IN")
        data["countryAmount"] = data.Hourly_Consultaion_Fee_amount;
      else data["countryAmount"] = data.USD_Amount;
      setExpertDetailsData(data);
      console.log(resp.data);
    });
  }, []);

  useEffect(() => {
    getInsuranceCardDataHandler();
  }, [expertDetailsData]);

  const getInsuranceCardDataHandler = async () => {
    setLoading(true);
    try {
      const body = {
        user_id: currentUser?.UserId,
        amount: expertDetailsData?.USD_Amount,
        service_type: "Mental Health",
      };
      const insuranceData = await getInsuranceBenefitsHandler(body).then(
        (resp) => {
          return resp?.benefits;
        }
      );
      setInsuranceCardData(insuranceData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const [newPolicyData, setNewPolicyData] = useState({
    policyName: "",
    memberID: "",
    policyNumber: "",
  });

  const [openAddPolicyModal, setOpenAddPolicyModal] = useState(false);

  const navigate = useNavigate();

  const onCheckout = () => {
    if (!selectedInsuranceData && insuranceBenefits) {
      enqueueSnackbar("Please Select Insurance Policy!", {
        variant: "warning",
      });
    } else {
      navigate(`/booking/preview/${expertDetailsData?.Id}?query=${true}`, {
        state: {
          appointmentData: appointment_Details,
          insuranceDetails: selectedInsuranceData,
          isInsuranceBenefits: insuranceBenefits,
        },
      });
    }
  };

  const onSelectedPolicyChange = (data) => {
    setSelectedInsuranceData(data);
    setInsuranceCardData((prevState) => {
      const updatedInsuranceList = insuranceCardData.map((insurance) => ({
        ...insurance,
        // Set isSelected to true only for the insurance with the matching policy, false otherwise
        isSelected: insurance.insurance_id === data?.insurance_id,
      }));

      return updatedInsuranceList;
    });
  };

  const addPolicy = (newPolicy) => {
    setBookingInformation((prevState) => ({
      ...prevState,
      insuranceList: [...prevState.insuranceList, newPolicy],
    }));
    setOpenAddPolicyModal(false); // Close the modal after adding the policy
  };

  return (
    <div className="Booking--Page">
      <div className="Booking--Container">
        <div className="Booking--Container__Left">
          <div className="Expert--Info">
            <div className="d-flex align-items-center gap-3 Expert--Info__Header">
              <img
                src={expertDetailsData?.ProfileImage}
                alt={expertDetailsData?.Name}
              />

              <div>
                <h3 className="expert-name">{expertDetailsData?.Name}</h3>
                <div>{expertDetailsData?.RegisterYourselfAs}</div>
              </div>
            </div>

            <div className="Expert--Info__Content">
              <div className="d-flex align-items-center gap-2">
                <AssignmentOutlinedIcon />
                <p>{`${expertDetailsData?.Experience} Years`}</p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <LanguageOutlinedIcon />
                <p>{expertDetailsData?.Languages}</p>
              </div>
              <div className="d-flex align-items-start gap-2 Content--Qualifications">
                <p>
                  <span>Qualifications:</span>{" "}
                  {expertDetailsData?.Qualifications}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="Booking--Container__Right">
          <div className="Booking--Info">
            <div className="Info--Content">
              <h6>{bookingInformation?.type}</h6>
              <div className="Info--Grid">
                <div className="d-flex align-items-start gap-2">
                  <CalendarMonthOutlinedIcon />
                  <p>{formatDate(appointment_Details?.selectedDate)}</p>
                </div>
                <div className="d-flex align-items-start gap-2">
                  <AccessTimeOutlinedIcon />
                  <p>{appointment_Details?.selectSlot?.Time_Duration}</p>
                </div>
                {/* <div className="d-flex align-items-start gap-2">
                  <CallOutlinedIcon />
                  <p>{bookingInformation?.phone}</p>
                </div> */}
                <div className="d-flex align-items-start gap-2">
                  <PeopleAltOutlinedIcon />
                  <p>{`${appointment_Details?.callTime} Min Individual Session`}</p>
                </div>
              </div>
            </div>

            {/* <button className="Edit--Button">
              <EditOutlinedIcon />
            </button> */}
          </div>

          <div className="Booking--Form">
            <p>
              Consultation Fee:{" "}
              <strong>{`$ ${expertDetailsData?.USD_Amount}`}</strong>
            </p>
          </div>

          <div className="Booking--Form">
            <FormControl>
              <FormLabel
                id="demo-radio-buttons-group-label"
                className="Booking--Form__heading"
              >
                Continue
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="With Insurance Benefits"
                name="radio-buttons-group"
                value={insuranceBenefits}
                onChange={handleInsuranceChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="With Insurance Benefits"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Without Insurance Benefits"
                />
              </RadioGroup>
            </FormControl>
          </div>

          {insuranceBenefits ? (
            insuranceCardData.length > 0 ? (
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="fw-semibold">Select Insurance Policy</h5>
                  {/* <button className="PrimaryCTA" onClick={() => setOpenAddPolicyModal(true)}>
                    Add New Policy
                  </button> */}
                </div>
                {/* <Grid container spacing={2}>
                  {insuranceCardData &&
                    insuranceCardData?.map((insurance, index) => (
                      <Grid item xs={12} sm={6}>
                        <InsuranceDetailCard
                          key={insurance.id}
                          data={insurance}
                          expertDetailsData={expertDetailsData}
                          handleSelect={onSelectedPolicyChange}
                        />
                      </Grid>
                    ))}
                </Grid> */}
                <div className="insurance-card">
                  {insuranceCardData &&
                    insuranceCardData?.map((insurance) => (
                      <InsuranceDetailCard
                        key={insurance.id}
                        data={insurance}
                        expertDetailsData={expertDetailsData}
                        handleSelect={onSelectedPolicyChange}
                      />
                    ))}
                </div>

                <button
                  className="PrimaryCTA mx-auto"
                  onClick={onCheckout}
                  style={{ marginTop: "40px" }}
                >
                  Continue
                </button>
              </div>
            ) : (
              <div className="Policy--Box">
                <h6>You don't have any policy added</h6>
                <button
                  className="PrimaryCTA"
                  onClick={() => setOpenAddPolicyModal(true)}
                >
                  <AddIcon /> Add Policy
                </button>
              </div>
            )
          ) : (
            <>
              <div className="Booking--Form">
                <p style={{ fontSize: "18px", fontWeight: "500" }}>
                  You pay in full
                </p>
              </div>

              <div className="Booking--Form d-flex justify-content-center align-items-center flex-nowrap mt-auto">
                <button className="PrimaryCTA" onClick={onCheckout}>
                  Continue
                </button>
              </div>
            </>
          )}

          <AddNewPolicyModal
            open={openAddPolicyModal}
            setOpen={setOpenAddPolicyModal}
            handleAddPolicy={getInsuranceCardDataHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingWithInsuranceSelection;
