import React from "react";
import "../../styles/courses/WhatToExpect.css";

const ModuleCard = ({ image_url, name, description }) => {
  return (
    <div className="module-card-section">
      <div className="module-card-image">
        {image_url && <img src={image_url} alt="" />}
      </div>
      <div className="module-card-content">
        <div className="module-card-title">{name}</div>
        <div className="module-card-desc">{description}</div>
      </div>
      {/* <div className="module-arrow">
        <img src={module_arrow} />
      </div> */}
    </div>
  );
};

export default ModuleCard;
