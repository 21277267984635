import React from 'react'
import Button from "@mui/material/Button";


//Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

const ScreenerTabItem = ({ type, id, title, submittedOn, onViewClick }) => {

  const handleViewClick = () => {
    //console.log("low", id)
    onViewClick(title, id, type);
  };
  return (
    <div className="Screener--Item">
      <div>
        <h6>{title}</h6>
        <p>Submitted on {submittedOn}</p>
      </div>

      <div className="CTA--Container d-flex gap-2 align-items-center">
        <Button onClick={handleViewClick}>
          <VisibilityIcon />
          <p>View</p>
        </Button>

        {/* <Button>
        <LocalPrintshopOutlinedIcon />
        <p>Print</p>
      </Button> */}
      </div>
    </div>
  )
}

export default ScreenerTabItem