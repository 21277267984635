import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import "bootstrap/dist/css/bootstrap.css";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import "./modals.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import axios from "axios";
import { Config } from "../../../core/_request";
import { data } from "jquery";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const SessionComplete = ({
  open,
  handleClose,
  appointment_id,
  status,
  setMessage,
  handleOpenSnackbar,
  setStatus,
}) => {
  const emojisData = [
    { img: "🙁", status: false, answer: "bad" },
    { img: "😊", status: false, answer: "ok" },
    { img: "😍", status: false, answer: "very_good" },
  ];
  let feed_back_data = [
    { question: "", answer: "" },
    { question: "", answer: "" },
    { question: "", answer: "" },
    { question: "", answer: "" },
    { question: "", answer: "" },
    { question: "", answer: "" },
    { question: "", answer: "" },
  ];
  const yesno = ["Yes", "No"];
  const [feedbackQuestion, setFeedbackQuestion] = useState([]);
  const [emojiStatus, setImojiStatus] = useState({ index: 0, status: false });
  const [emojiStatus2, setImojiStatus2] = useState({ index: 0, status: false });
  const [permission, setPermission] = useState("");
  const [emojis, setEmojis] = useState(emojisData);
  const [emojis2, setEmojis2] = useState(emojisData);
  const [qaList, setQaList] = useState({});
  let [feedbackAnsers, setFeedbackAnsers] = useState(feed_back_data);

  useEffect(() => {
    setPermission(status);
    if (
      status === "read" &&
      appointment_id !== 0 &&
      appointment_id &&
      appointment_id !== ""
    ) {
      sessionFeedbacksByUserId();
    }
    setEmojis(emojisData);
    setEmojis2(emojisData);
    setFeedbackAnsers(feed_back_data);
  }, [status, appointment_id]);

  useEffect(() => {
    let isEmojiSelected = [...emojis];
    let emojistatusdata = isEmojiSelected.map((data, index) => {
      if (index === emojiStatus?.index) {
        return { ...data, status: emojiStatus?.status };
      }
      return { ...data, status: false };
    });
    setEmojis(emojistatusdata);
    setQaList({});
  }, [emojiStatus]);

  useEffect(() => {
    let isEmojiSelected = [...emojis2];
    let emojistatusdata = isEmojiSelected.map((data, index) => {
      if (index === emojiStatus2?.index) {
        return { ...data, status: emojiStatus2?.status };
      }
      return { ...data, status: false };
    });
    setEmojis2(emojistatusdata);
  }, [emojiStatus2]);

  useEffect(() => {
    sessionFeedbacksQuestion();
  }, []);

  const sessionFeedbacksByUserId = () => {
    let userId = JSON.parse(localStorage.getItem("profile"))?.UserId;
    let body = {
      user_id: userId,
      appointment_id: appointment_id,
      user_type: "expert",
    };
    if (!userId) {
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/expert-feedback-details`,
        body,
        Config()
      )
      .then((resp) => resp.data)
      .then((resp) => {
        if (resp.ResponseCode === 200) {
          const selectedEmoji = emojis?.map((data) => {
            if (data.answer === resp?.data[0]?.qa_list[0]?.answer) {
              return { ...data, status: true };
            }
            return { ...data, status: false };
          });
          setEmojis(selectedEmoji);
          const selectedEmoji1 = emojis2?.map((data) => {
            if (data.answer === resp?.data[0]?.qa_list[1]?.answer) {
              return { ...data, status: true };
            }
            return { ...data, status: false };
          });
          setEmojis2(selectedEmoji1);
          setFeedbackAnsers(resp?.data[0]?.qa_list);
        }
      });
  };

  const sessionFeedbacksQuestion = () => {
    let userId = JSON.parse(localStorage.getItem("profile"))?.UserId;
    let body = {
      user_type: "expert",
      user_id: userId,
      session_status: "completed",
    };
    axios
      .post(
        `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/session-feedback-form`,
        body,
        Config()
      )
      .then((resp) => resp.data)
      .then((resp) => {
        if (resp.ResponseCode === 200) {
          setFeedbackQuestion(resp?.data?.template_data);
        }
      });
  };

  const setAnswer = (que, answer, index) => {
    setQaList({ ...qaList, [index]: { question_id: que, answer: answer } });
    if (feedbackAnsers && feedbackAnsers.length > 0) {
      feedbackAnsers[index].answer = answer;
    }
  };

  const submit = () => {
    if (Object.keys(qaList).length < 7) {
      setMessage("All fields are required !");
      setStatus("error");
      handleOpenSnackbar();
      return;
    }
    let userId = JSON.parse(localStorage.getItem("profile"))?.UserId;
    let body = {
      user_type: "expert",
      user_id: userId,
      appointment_id: appointment_id,
      session_status: "completed",
      qa_list: Object.values(qaList),
    };
    axios
      .post(
        `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/feedback-form-data`,
        body,
        Config()
      )
      .then((resp) => resp.data)
      .then((resp) => {
        setMessage(resp.data.message);
        setStatus("success");
        handleOpenSnackbar();
      });
    setImojiStatus({ index: 0, status: false });
    setImojiStatus2({ index: 0, status: false });
    handleClose();
  };

  const checkValidation = () => {};

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      PaperProps={{
        style: { width: "788px", maxWidth: "1000px", height: "903px" },
      }}
      style={{ width: "100%", height: "720px" }}
    >
      <DialogTitle
        className="text-white "
        style={{
          backgroundColor: "#73589b",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        
        Session Completed
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className="d-flex" style={{ overflowX: "hidden" }}>
        <div className="flex-column mx-3 my-4 col-5">
          <div className="mb-3">
            <label className="labels mb-3 customLabel">
              {feedbackQuestion[0]?.question}
            </label>
            <div className="d-flex" style={{ flexWrap: "nowrap" }}>
              {emojis.map((emoji, index) => (
                <label
                  key={index}
                  onClick={() =>
                    setAnswer(feedbackQuestion[0]?.question_id, emoji.answer, 0)
                  }
                  className="mx-1"
                >
                  <input
                    type="radio"
                    name="sessionFeedback"
                    style={{ display: "none" }}
                  />
                  <span
                    style={{
                      fontSize: "27px",
                      color: "black",
                      padding: "5px",
                      borderRadius: "10px",
                      border: emoji?.status
                        ? "2px solid #FD6F1F"
                        : "2px solid rgba(173, 216, 230, 0.9)",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setImojiStatus({ index: index, status: true })
                    }
                  >
                    {emoji?.img}
                  </span>
                </label>
              ))}
            </div>
          </div>

          <Form.Group className="mb-3">
            <Form.Label className="labels customLabel">
              {feedbackQuestion[2]?.question}
            </Form.Label>
            {feedbackQuestion[2]?.options?.map((option, index) => (
              <Form.Check
                key={index}
                type="radio"
                checked={
                  feedbackAnsers?.length > 0
                    ? feedbackAnsers[2]?.answer === option?.name
                    : false
                }
                label={option?.name}
                onChange={() =>
                  setAnswer(feedbackQuestion[2]?.question_id, option?.name, 2)
                }
                name="futureSessionRecommendation"
                id={`futureSessionRecommendation-${index}`}
                className="custom-font1"
              />
            ))}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="labels customLabel">
              {feedbackQuestion[5]?.question}
            </Form.Label>
            {feedbackQuestion[5]?.options?.map((option, index) => (
              <Form.Check
                key={index}
                checked={
                  feedbackAnsers?.length > 0
                    ? feedbackAnsers[5]?.answer === option?.name
                    : false
                }
                type="radio"
                label={option?.name}
                onChange={() =>
                  setAnswer(feedbackQuestion[5]?.question_id, option?.name, 5)
                }
                name="progressTowardsGoal9"
                id={`progressTowardsGoal9-${index}`}
                className="custom-font1"
              />
            ))}
          </Form.Group>
        </div>
        <div className="flex-column  mx-3 my-4  ">
          <div className="mb-3">
            <label className="labels mb-3 customLabel">
              {feedbackQuestion[1]?.question}
            </label>
            <div
              className="d-flex"
              style={{ flexWrap: "nowrap" }} // Add this style
            >
              {emojis2.map((emoji, index) => (
                <label
                  key={index}
                  className="mx-1"
                  onClick={() =>
                    setAnswer(feedbackQuestion[1]?.question_id, emoji.answer, 1)
                  }
                >
                  <input
                    type="radio"
                    name="sessionFeedback"
                    style={{ display: "none" }}
                  />
                  <span
                    onClick={() =>
                      setImojiStatus2({ index: index, status: true })
                    }
                    style={{
                      fontSize: "27px",
                      color: "black",
                      padding: "5px",
                      borderRadius: "10px",
                      border: emoji?.status
                        ? "2px solid #FD6F1F"
                        : "2px solid rgba(173, 216, 230, 0.9)",
                      cursor: "pointer",
                    }}
                  >
                    {emoji?.img}
                  </span>
                </label>
              ))}
            </div>
          </div>
          <Form.Group className="mb-1">
            <Form.Label className="labels customLabel">
              {feedbackQuestion[3]?.question}
            </Form.Label>
            <div className="d-flex">
              {yesno.map((option, index) => (
                <div key={index}>
                  <Form.Check
                    type="radio"
                    onChange={() =>
                      setAnswer(feedbackQuestion[3]?.question_id, option, 3)
                    }
                    label={option}
                    checked={
                      feedbackAnsers?.length > 0
                        ? feedbackAnsers[3]?.answer === option
                        : false
                    }
                    name="progressTowardsGoal1"
                    id={`progressTowardsGoal1-${index}`}
                    className="custom-font1 custom-border-checkbox "
                  />
                </div>
              ))}
            </div>
          </Form.Group>
          <Form.Group className="mb-1 ">
            <Form.Label className="labels customLabel">
              {feedbackQuestion[4]?.question}
            </Form.Label>
            <div className="d-flex ">
              {yesno.map((option, index) => (
                <div key={index}>
                  <Form.Check
                    key={index}
                    type="radio"
                    onChange={() =>
                      setAnswer(feedbackQuestion[4]?.question_id, option, 4)
                    }
                    label={option}
                    checked={
                      feedbackAnsers?.length > 0
                        ? feedbackAnsers[4]?.answer === option
                        : false
                    }
                    name="progressTowardsGoal3"
                    id={`progressTowardsGoal3-${index}`}
                    className="custom-font1"
                  />
                </div>
              ))}
            </div>
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label className="labels customLabel">
              {feedbackQuestion[6]?.question}
            </Form.Label>
            {feedbackQuestion[6]?.options?.map((option, index) => (
              <Form.Check
                key={index}
                type="radio"
                onChange={() =>
                  setAnswer(feedbackQuestion[6]?.question_id, option?.name, 6)
                }
                label={option?.name}
                checked={
                  feedbackAnsers?.length > 0
                    ? feedbackAnsers[6]?.answer === option?.name
                    : false
                }
                name="progressTowardsGoal4"
                id={`progressTowardsGoal4-${index}`}
                className="custom-font1"
              />
            ))}
          </Form.Group>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-start" }}>
        {permission === "read" ? (
          ""
        ) : (
          <Button
            onClick={submit}
            style={{
              backgroundColor: "#73589b",
              textTransform: "none",
              color: "white",
              padding: "14px 24px",
              borderRadius: "8px",
              marginLeft: "30px",
              position: "absolute",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontsize: "18px",
              lineHeight: "130%",
              marginBottom: "60px",
              width: "99.53px",
              height: "40.89px",
            }}
            disabled={checkValidation()}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SessionComplete;
