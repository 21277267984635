import React, { useContext, useState } from 'react'
import './Plans.css'
import "../../pages/PlanDetailsPage.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from '../../auth';
import { AppContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import PlanDetailsReviewSlide from '../planDetails/PlanDetailsReviewSlide';
import TopNavArea from './Components/TopNavArea';
import NavArea from './Components/Nav';
import HeroArea from './Components/HeroArea';
import PersonalDataArea from './Components/PersonalDataArea';
import PickUpPlans from './Components/PickUpPlans';
import MakeUsArea from './Components/MakeUsArea';
import ComparePlans from './Components/ComparePlans';
import ChoiceArea from './Components/ChoiceArea';
import TrustArea from './Components/TrustArea';
import ReasonArea from './Components/ReasonArea';
import PartnerArea from './Components/PartnerArea';
import ClientArea from './Components/ClientArea';
import BestArea from './Components/BestArea';
import ReviewArea from './Components/ReviewArea';
import TeamArea from './Components/TeamArea';
import FaqArea from './Components/FaqArea';
import FloatingWhatsapp from './Components/FloatingWhatsapp';
import { Helmet } from 'react-helmet';



const accItems = [
    {
        title: 'What benefits or features do I get with the subscription?',
        content: 'At United We Care, we offer a versatile subscription model designed to suit your needs. Our subscription plans include: Free Access: Gain entry to essential resources and tools through our free subscription, providing valuable support for mental well-being. Premium Subscription: Upgrade to our premium subscription with an upfront fee to unlock additional features and exclusive content, enhancing your mental wellness journey. Premium + Coaching: For an unparalleled experience, our premium subscription with coaching offers unlimited access to therapy sessions. This personalized support empowers you to thrive and flourish on your path to well-being.'
    },
    {
        title: 'How do I access the program content or resources once I subscribe?',
        content: 'Once you subscribe, you will gain access to our program content and resources through your personalized account on our website or mobile app. The platform is designed to be user-friendly, enabling easy navigation and utilization of our offerings.'
    },
    {
        title: 'Can I cancel or modify my subscription at any time, and what is the cancellation policy?',
        content: 'Yes, you can cancel or modify your subscription at any time. We believe in providing flexibility to our users. The details of our cancellation policy can be found in our terms and conditions or subscription agreement.'
    },
    {
        title: 'Is the program subscription suitable for individuals with specific mental health conditions or needs?',
        content: 'Yes, our program subscription is designed to cater to a wide range of mental health conditions and needs. Our platform offers tailored support and resources to meet individual requirements.'
    },
    {
        title: 'Are there any resources or tools available for self-help or self-assessment?',
        content: 'Yes, we offer a range of self-help resources and tools to support your mental well-being. From informative articles and guided exercises to self-assessment quizzes, our platform empowers you with valuable insights and techniques to foster personal growth.'
    },
    {
        title: 'Are there licensed professionals or experts available to support subscribers during the program?',
        content: 'Absolutely! We have a team of licensed professionals and mental health experts who are committed to supporting and guiding you throughout your journey. They are there to ensure you receive the best possible care and assistance.'
    },
    {
        title: 'Can I share the subscription with family members or friends?',
        content: 'The ability to share the subscription may depend on the type of subscription plan you choose. Please refer to our subscription terms for more details on sharing options.'
    },
];

const NewPlansPage1 = () => {

    return (
        <div className="main bg-white new-plans">
            <Helmet>
                <script src="//code.tidio.co/y84keavfozv8pkn1ar3c4h0cofpeq7le.js" async></script>
                <title>
                    Choose Your Mental Wellness Plan | United We Care Subscription
                </title>
            </Helmet>
            {/* <!------ top-nav-area ------> */}
            {/* <TopNavArea /> */}
            {/* <!------ nav-area (desktop-display)------> */}

            {/* <!------ nav-area ------> */}
            {/* <NavArea /> */}

            {/* <!------ Hero-area ------> */}
            <HeroArea />

            {/* <!------ personal-data-area ------> */}
            <PersonalDataArea />

            {/* <!------ pick-plan-area ------> */}
            <PickUpPlans />

            {/* <!------ Compare-plan-area ------> */}
            <ComparePlans />

            {/* <!------ Make-Us-area ------> */}
            {/* <MakeUsArea /> */}

            {/* <!------ choice-area ------> */}
            {/* <ChoiceArea /> */}

            {/* <!------ trust-area ------> */}
            <TrustArea />

            {/* <!------ reason-area ------> */}
            <ReasonArea />

            {/* <!------ partner-area ------> */}
            {/* <PartnerArea /> */}

            {/* <!------ client-area ------> */}
            {/* <ClientArea /> */}

            {/* <!------ best-area ------> */}
            {/* <BestArea /> */}

            {/* <!------ review-area ------> */}
            <ReviewArea />

            {/* <!------ team-area ------> */}
            {/* <TeamArea /> */}

            {/* <!------ FAQ-area ------> */}
            <FaqArea />

            {/* <!------ Testimonial-area ------> */}
            <div style={{ clear: 'both' }}>
                <PlanDetailsReviewSlide />
            </div>

            {/* floating-WhatsApp */}
            {/* <FloatingWhatsapp /> */}
        </div >
    )
}

export default NewPlansPage1;