import React, { useState, useEffect } from "react";

import { Skeleton, Grid } from "@mui/material";

import image1 from "../../../dashboard/images/heart2.png";
import image2 from "../../../dashboard/images/face2.png";
import image3 from "../../../dashboard/images/flower2.png";
import image4 from "../../../dashboard/images/heartsehild2.png";

//import { BsArrowRightCircle } from "react-icons/bs";

import { getRecommendedAssessments } from "../../_request";
import { useNavigate } from "react-router-dom";

function ProfessionalHelp() {
  const authToken = localStorage.getItem("Authorization")?.split("Bearer ")[1];
  const navigate = useNavigate();

  const professionalHelpList = [
    // { image: image1, text: "Psychologist", uri: "/?Profession=Psychologist" },
    // { image: image2, text: "Psychiatrist", uri: "/?Profession=Psychiatrist" },
    {
      image: image1,
      text: "Mental Health and Wellness Coach",
      uri: "/?Profession=Mental Health and Wellness Coach",
    },
    // { image: image4, text: "Life Coach", uri: "/?Profession=Life Coach" }, 
    { image: image2, text: "Therapist", uri: "/?Profession=Therapist" },
  ];

  const [assessmentsData, setAssessmentsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const { assessments } = await getRecommendedAssessments();
        //console.log('randomExperts', randomExperts);

        setAssessmentsData(assessments);
        setLoading(false);
        
      } catch (error) {
        console.error("Error fetching random experts:", error);
      }
    };

    fetchAssessments();
  }, []);

  //console.log("assessmentsData", assessmentsData)
  return (
    <div className="">
      <div
        className=""
        style={{
          backgroundColor: "",
          borderRadius: "8px",
          boxShadow: " ",
        }}
      >
        <div className="row main-card">
          <div className="col-md-6 mx-0">
            <div
              className="left-card pt-2 pb-2"
              style={{ minHeight: "100%", maxHeight: "300px" }}
            >
            {loading ? (
                <>
                  <Skeleton
                    variant="text"
                    width="25%"
                    height="35px"
                    className="rounded rounded-3 mx-4 my-2"
                  />
                  <div className="row px-3 mt-0 m-0 px-2">
                    <Grid container spacing={2}>
                      {Array.from(new Array(4)).map((_, index) => (
                        <Grid item xs={6} key={index}>
                          <div
                            style={{ backgroundColor: "rgb(0,0,0,0.07)" }}
                            className="p-4 rounded rounded-3"
                          >
                            <div className="d-flex w-100 align-items-center justify-content-center gap-2">
                              <Skeleton
                                variant="rounded"
                                width={48}
                                height={48}
                                className="rounded rounded-3"
                              />
                              <Skeleton
                                variant="rounded"
                                width="50%"
                                height="20px"
                                className="rounded rounded-3"
                              />
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </>
              ) : (
              <>
              <div className="title1 ms-md-4 p-2">
                <h5 className=" m-0 p-0 ms-1" style={{ fontWeight: "bold" }}>
                  Professional Help
                </h5>
              </div>
              <div className="row px-3 mt-0 m-0 py-2">
                {professionalHelpList?.map((a, i) => (
                  <div
                    key={i}
                    className="col-12 col-md-6 professionalHelp--Card"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="rectangle-card  px-2 py-3 my-2">
                      <div
                        className="row m-0 p-0 d-flex align-items-center"
                        onClick={() => {
                          navigate(a.uri);
                        }}
                      >
                        <div className="d-flex col-lg-4 col-sm-6">
                          <img
                            alt=""
                            src={a.image}
                            className=" img-fluid"
                            width="50"
                          />
                        </div>

                        <h6 className="col-lg-8 text-xs-nowrap flex-wrap m-0 p-0 col-sm-6">
                          {a.text}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              </>
              )}
            </div>
          </div>

          <div className="col-md-6 my-3 my-md-0 mt-xs-2">
            <div
              className="cardsl pt-2 pb-2"
              style={{ minHeight: "280px", maxHeight: "300px" }}
            >
            {loading ? (
                <>
                  <Skeleton
                    variant="text"
                    width="25%"
                    height="35px"
                    className="rounded rounded-3 mx-4"
                  />

                  <div className="d-flex pb-3 mx-3 mt-4  " style={{overflow: 'hidden'}}>
                    {Array.from(new Array(3)).map((_, index) => (
                      <div
                        className="assessment-card border border"
                        style={{
                          minWidth: "200px",
                          maxWidth: "200px",
                          marginRight: "10px",
                          backgroundColor: "rgb(0,0,0,0.08)",
                        }}
                        key={index}
                      >
                        <div
                          className="card-body px-4 mt-3"
                          style={{
                            minHeight: "120px",
                            maxHeight: "120px",
                            position: "relative",
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <Skeleton
                              variant="rounded"
                              width="50%"
                              height="25px"
                              className="rounded rounded-pill"
                            />
                          </div>

                          <div className="d-flex align-items-center justify-content-center w-100 mt-3">
                            <Skeleton
                              variant="text"
                              width="75%"
                              height="25px"
                              className="rounded rounded-3"
                            />
                          </div>

                          <div className="d-flex align-items-center justify-content-center w-100 mt-2">
                            <Skeleton
                              variant="rounded"
                              width="50%"
                              height="25px"
                              className="rounded rounded-3"
                            />
                          </div>
                        </div>

                        <Skeleton
                          variant="rounded"
                          width="100%"
                          height="40px"
                        />
                      </div>
                    ))} 
                  </div>
                </>
              ) : (
                <>
              <div className="title1 ms-md-4 py-2 px-2">
                <h5 className=" m-0 p-0 ms-1" style={{ fontWeight: "bold" }}>
                  Recommended Assessments
                </h5>

                {/* <div
                  className="px-3 d-flex align-items-center "
                  style={{ cursor: 'pointer' }} 
                  onClick={() => {
                    navigate("/")
                  }}
                >
                  <h6 className="text-muted m-0 p-0 me-2 d-none d-md-block">View All</h6>
                  <BsArrowRightCircle size={25} className='d-none d-md-block' />
                </div> */}
              </div>

              <div className="scroll-container d-flex px-1 mt-4  mx-3 ">
                {assessmentsData?.map((item) => (
                  <div
                    key={item.id}
                    className="assessment-card mx-2 border border"
                    style={{ minWidthidth: "150px", maxWidth: "200px" }}
                  >
                    <div
                      className="card-body px-4 mt-3"
                      style={{
                        minHeight: "120px",
                        maxHeight: "120px",
                        position: "relative",
                      }}
                    >
                      <div>
                        <span
                          className="badge rounded-pill px-3 py-2"
                          style={{
                            fontWeight: "bold",
                            background: "#EFEAFF",
                            color: "#8D66FF",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            fill="currentColor"
                            className="bi bi-clock mb-1 mx-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                          </svg>

                          {item.time}
                        </span>
                      </div>
                      <div>
                        <p
                          className="fw-bold mt-2 mb-2 text-wrap title-ellipsis"
                          title={item.name}
                        >
                          {item.name}
                        </p>
                      </div>

                      <div
                        className=""
                        style={{
                          width: "75%",
                          position: "absolute",
                          bottom: "0",
                        }}
                      >
                        <p
                          className="text-secondary pb-2  text-center"
                          style={{ fontSize: "13px" }}
                        >
                          {item.count}
                        </p>
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        window.open(
                          `https://stella.unitedwecare.com/?token=${authToken}&message=${
                            item.action.parameter.firstmessage.message
                          }&appURL=${encodeURI(`https://us.unitedwecare.com`)}`
                        );
                      }}
                      className="cardfooter py-2 px-5 text-light"
                      style={{
                        backgroundColor: " var(--button-primary-color)",
                        cursor: "pointer",
                      }}
                    >
                      Let&apos;s Start
                    </div>
                  </div>
                ))}
              </div>
              </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfessionalHelp;
