import React, { useEffect, useState } from "react";
import { UserSubscriptionForExpertService } from "./core/_request";
import { AppointmentService } from "../core/_request";
import { Badge, Button } from "react-bootstrap";
import { useAuth } from "../../auth";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context";
import { handleBrokenImage } from "../../lib/utils";

const MyAppointments = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);

  useEffect(() => {
    UserSubscriptionForExpertService().then((resp) => {
      //console.log("UserSubscriptionForExpertService resp", resp);
      if (resp?.ResponseCode == 401) {
        logout();
        // navigate("/login");
        // setShowLloginPopup(true);
        const currentUrl = encodeURIComponent(window.location.href);
        window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
      } else setData(resp?.data);
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      //assign interval to a variable to clear it.
      UserSubscriptionForExpertService()
        .then((resp) => {
          //console.log("MySubscriptionService resp", resp);
          if (resp.ResponseCode == 401) {
            logout();
            // navigate("/login");
            // setShowLloginPopup(true);
            const currentUrl = encodeURIComponent(window.location.href);
            window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
          } else setData(resp.data);
        })
        .catch(function (error) {
          console.log(error);
          setData(null);
        });
    }, 6 * 1000);

    return () => clearInterval(intervalId); //This is important
  }, [data]);

  return (
    <>
      <div
        className=""
        style={{
          background:
            "linear-gradient(180deg, #3DACF0 55.1%, rgba(99, 132, 227, 0) 100%)",
          padding: 0,
          margin: 0,
        }}
      >
        <div className="container pt-5">
          <div>
            <p className="fs-3 text-white mb-5" style={{ color: "#fff" }}>
              Appointments
            </p>
          </div>
          <div className="d-flex flex-column gap-3">
            {data?.map((item, index) => (
              <AppointmentCard key={index} item={item} />
            ))}
          </div>
          <p className="text-center py-5">
            --------- No more Appointments ---------
          </p>
        </div>
      </div>
    </>
  );
};

const AppointmentCard = (props) => {
  const { item } = props;
  const { currentUser } = useAuth();
  const onClickCallNow = (url) => {
    var request = {
      AppointmentId: item.ID,
      type: currentUser.UserType == 3 ? "Expert" : "User",
    };
    AppointmentService(request);
    window.open(url, "_blank");
  };
  return (
    <div>
      <div
        className="container bg-white d-flex align-items-center justify-content-between py-2 px-4"
        style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12" }}
      >
        <div className="d-flex flex-column flex-md-row align-items-center gap-3 gap-md-5">
          <div
            className="d-flex flex-row flex-md-column align-items-baseline justify-content-between gap-3 gap-md-0 content"
            style={{ width: "max-content" }}
          >
            <Badge
              pill
              bg={
                item?.Status == "Open"
                  ? "success"
                  : item?.Status == "Completed"
                    ? "secondary"
                    : item?.Status == "Canceled"
                      ? "danger"
                      : "info"
              }
            >
              {item?.Status}
            </Badge>
            <p className="fs-6 font-w600 text-black">{item?.AppDate}</p>
            <p className="fs-6 font-w600 text-black">{item?.AppTime} (IST)</p>
          </div>
          <div className="vr d-none d-md-block"></div>
          <div className="d-flex gap-3 align-items-center py-md-2">
            <img
              src={item?.ProfileImage}
              onError={(e) => {
                const newLink = handleBrokenImage(item?.ProfileImage);
                e.target.src = newLink
              }}
              style={{ width: 100, height: 100, borderRadius: "50%" }}
              alt=""
            />
            <div className="d-flex flex-column justify-content-center">
              <h6>{item?.Name || "Professional"}</h6>
            </div>
          </div>
          <div className="d-md-none flex-row justify-content-between align-items-center w-100 d-flex">
            {item?.Status == "Open" && (
              <Button
                onClick={() => onClickCallNow(item?.Meeting_link)}
                className="rounded-5 border-0 px-3 py-2"
                style={{
                  background:
                    "linear-gradient(102.41deg, rgba(96, 180, 231, 0.8) -0.17%, rgba(100, 97, 224, 0.8) 70.6%)",
                }}
              >
                <img src="/assets/img/video-1.png" className="px-2" alt="" />
                Video Call
              </Button>
            )}
          </div>
        </div>
        <div className="d-md-flex flex-row gap-5 align-items-center d-none justify-content-end">
          {item?.Status == "Open" && (
            <Button
              onClick={() => onClickCallNow(item?.Meeting_link)}
              className="rounded-5 border-0"
              style={{
                background:
                  "linear-gradient(102.41deg, rgba(96, 180, 231, 0.8) -0.17%, rgba(100, 97, 224, 0.8) 70.6%)",
              }}
            >
              <img src="/assets/img/video-1.png" className="px-2" alt="" />
              Video Call
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyAppointments;
