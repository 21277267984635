import React, { useContext, useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "react-bootstrap";
import DisclaimerModal from "./DisclaimerModal";
// import OTPModal from './OTPModal';
import {
  CheckReferralCode,
  CheckUserEmailIsRegister,
  LoginByOTPService,
  OODOCreateUserV4Service,
  OODOGetCountryListV4Service,
  OODOGetTimezoneV4Service,
  RegistrationByOTPV4Service,
  SendRequestOTPService,
} from "./core/_request";
import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/CloseButton";
import { useAuth } from "../auth";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Form from "react-bootstrap/Form";
import { AppContext } from "../context";
import jwtDecode from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import "./SignUp.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { getCountryCode, getCurrencyCode } from "./core/commonFun";
import Snackbar from "@mui/material/Snackbar";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";

const SignUp2 = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMsg, setSucessMsg] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [otpModalShow, setOtpModalShow] = useState(false);
  const [data, setData] = useState({ OTP: "" });
  const [validationMessage, setValidationMessage] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timezoneList, setTimezoneList] = useState([]);
  const [validReferralCode, setValidReferralCode] = useState();
  const [referralCodeMessage, setReferralCodeMessage] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  // const [counter, setCounter] = useState(0);
  const count_time = process.env.REACT_APP_OTP_TIME;
  const { login, setCurrentUser, currentUser, userType } = useAuth();
  const navigate = useNavigate();
  const params = new URLSearchParams(window ? window.location.search : {});
  const [disclaimer, setDisclaimer] = useState();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [credentialCode, setCredentialCode] = useState();
  const [decodedResponse, setDecodedResponse] = useState();
  const [detailModalShow, setdetailModalShow] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [resendOtpSent, setResendOtpSent] = useState(false);
  const countryCode = getCountryCode();
  const [counter, setCounter] = useState(30); // Initial countdown value in seconds
  const [resendAllowed, setResendAllowed] = useState(true); // Flag to control resend button
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (otpModalShow) {
      // Focus the first OTP input field
      inputRefs.current[0].focus();
    }
  }, [otpModalShow]);

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000); // Decrease counter every second
    } else if (counter === 0) {
      setResendAllowed(true); // Enable resend button when countdown reaches 0
    }
    return () => clearTimeout(timer);
  }, [counter]);

  useEffect(() => {
    OODOGetCountryListV4Service().then((resp) => {
      setCountryList(resp.data.countries);
      // Update specific fields in the data state
      setData((prevData) => ({
        ...prevData,
        countryId: 233,
        country: "United States",
      }));
    });
  }, []);

  useEffect(() => {
    var body = {
      code: "US",
    };
    OODOGetTimezoneV4Service(body).then((resp) => {
      //console.log("OODOGetTimezoneV4Service resp ", JSON.stringify(resp));
      setTimezoneList(resp?.data?.timezones);
      setLoading(false);
    });
  }, []);

  const handleChange = (index, value) => {
    if (!isNaN(value) && value.length === 1) {
      // Use the callback form of setOtp to ensure you get the latest state
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        setData({ ...data, OTP: newOtp.join("") }); // Join the array to form the final OTP
        return newOtp;
      });

      // Move to the next input
      if (index < 3 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    // Move to the previous input on backspace
    if (e.key === "Backspace") {
      const newOtp = [...otp];

      // If the current input is empty, move to the previous input
      if (index > 0 && !otp[index]) {
        newOtp[index - 1] = "";
        inputRefs.current[index - 1].focus();
      } else {
        newOtp[index] = "";
      }

      setOtp(newOtp);
      setData((prevData) => ({
        ...prevData,
        OTP: newOtp.join(""),
      })); // Join the array to form the final OTP
    } else if (e.key === "Enter") {
      e.preventDefault();
      onSubmitOtp();
    } else if (!/^\d$/.test(e.key)) {
      // Prevent input for non-numeric keys
      e.preventDefault();
    }
  };

  const onEmailChange = (e) => {
    // debugger
    if (e.key === "Enter") {
      e.preventDefault();
      onContinueSignUp();
    } else {
      setData((prevData) => ({
        ...prevData,
        Email: e?.target?.value.replace(/\s/g, ""),
      }));
    }
  };

  const onCountryChange = (value) => {
    var item = countryList?.find((o) => o.id == value);
    if (item) {
      setData({ ...data, countryId: value, country: item.name });
      // item?.name
      setLoading(true);
      var body = {
        code: item?.code,
      };
      OODOGetTimezoneV4Service(body).then((resp) => {
        //console.log("OODOGetTimezoneV4Service resp ", JSON.stringify(resp));
        setTimezoneList(resp?.data?.timezones);
        setLoading(false);
      });
      setLoading(false);
    } else {
      setTimezoneList([]);
      setData({ ...data, timezoneId: null, timezoneName: null });
    }
  };

  useEffect(() => {
    if (timezoneList?.length == 1) {
      setData({
        ...data,
        timezoneId: timezoneList[0].id,
        timezoneName: timezoneList[0].name,
      });
    }
  }, [onCountryChange]);

  const onTimezoneChange = (value) => {
    if (value !== null) {
      const item = timezoneList.find((o) => o.id == value);
      if (item) {
        setData({ ...data, timezoneId: value, timezoneName: item.name });
        // item?.name
      } else {
        setData({ ...data, timezoneId: null, timezoneName: null });
      }
    }

  };

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 5) {
        elmnt.target?.form?.elements[next]?.focus();
      }
    }
  };

  const HandelReferalCodeChange = (e) => {
    const textWithoutSpaces = e.target.value.replace(/\s/g, ""); // Remove spaces
    setValidReferralCode(textWithoutSpaces);
  };

  const onContinueSignUp = () => {
    setCounter(30);
    setLoading(true);
    if (validReferralCode) {
      CheckReferralCode(validReferralCode).then((resp) => {
        if (resp.ResponseCode === 200) {
          setReferralCodeMessage("Referral code Applied");
          setLoading(false);
          setData({ ...data, referralCode: validReferralCode });
          console.log("****dfdfd", validReferralCode, data);
          //onLogin();
          // setData({ ...data, Referral_Code: referralCode })
          //createRegistration();
          setValidationMessage([]);
          setLoading(true);

          // Checking Email Pattern
          var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
          if (!pattern.test(data.Email)) {
            setValidEmail(false);
            let newValidationMessage = Object.assign(
              [],
              [
                ...validationMessage.filter((o) => o.key != "Email"),
                { key: "Email", message: "Invalid Email" },
              ]
            );
            setValidationMessage(newValidationMessage);
            setLoading(false);
          } else {
            var req = { Email: data.Email };
            SendRequestOTPService(req).then((resp) => {
              setLoading(false);
              //console.log("SendRequestOTPService resp", resp);
              if (resp != null && resp.ResponseCode == 200) {
                setOtpSent(true);
                setCounter(count_time);
                setOtpModalShow(true);
              } else if (resp != null && resp.ResponseCode == 401) {
                //console.log("ResponseCode 401");
              }
            });
          }
        } else {
          setReferralCodeMessage("Referral code doesn't exist");
          enqueueSnackbar("Referral code doesn't exist", { variant: "error" });
          //setValidReferralCode('')
          setData({ ...data, Referral_Code: null });
          setLoading(false);
        }
      });
    } else {
      setValidationMessage([]);
      setLoading(true);

      // Checking Email Pattern
      var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      if (!pattern.test(data.Email)) {
        setValidEmail(false);
        let newValidationMessage = Object.assign(
          [],
          [
            ...validationMessage.filter((o) => o.key != "Email"),
            { key: "Email", message: "Invalid email" },
          ]
        );
        setValidationMessage(newValidationMessage);
        setLoading(false);
      } else {
        var req = { Email: data?.Email };
        SendRequestOTPService(req).then((resp) => {
          setLoading(false);
          //console.log("SendRequestOTPService resp", resp);
          if (resp != null && resp.ResponseCode == 200) {
            setOtpSent(true);
            setCounter(count_time);
            setOtpModalShow(true);
            // setModalShow(true);
          } else if (resp != null && resp.ResponseCode == 401) {
            //console.log("ResponseCode 401");
          }
        });
      }
    }
  };

  const onSubmitOtp = () => {
    var req = {
      Email: data.Email,
      OTP: data?.OTP,
    };
    LoginByOTPService(req).then((resp) => {
      // debugger
      //console.log("LoginByOTPService resp", resp);
      if (resp != null && resp.ResponseCode == 200) {
        if (resp.data.IsLogin) {
          // debugger
          let UserType = resp.data.UserType;
          let profile = resp.data.userdetails;
          profile.UserType = UserType;
          login(resp.data);
          setCurrentUser(profile);
          //localStorage.setItem("Authorization", resp.data.token_type + " " + resp.data.access_token);
          //localStorage.setItem("profile", JSON.stringify(profile));
          var retrunurl_local = localStorage.getItem("retrunurl");
          const retrunurl = params.get("retrunurl") || retrunurl_local;
          //console.log("retrunurl", retrunurl)
          if (retrunurl) {
            //window.location.href=retrunurl;
            localStorage.removeItem("retrunurl");
            navigate(retrunurl);
          } else {
            enqueueSnackbar("This email is already registered", {
              variant: "warning",
            });
            navigate("/user-dashboard");
          }
        } else {
          // setLoading(false);
          createRegistration();
        }
      } else {
        setResendOtpSent(false);
        let newValidationMessage = Object.assign(
          [],
          [
            ...validationMessage.filter((o) => o.key != "OTP"),
            {
              key: "OTP",
              message: (
                <span>
                  <ErrorOutlineIcon /> Incorrect OTP, Please try again
                </span>
              ),
            },
          ]
        );
        setValidationMessage(newValidationMessage);
        setLoading(false);
      }
    });
  };

  const createRegistration = () => {
    // debugger
    setLoading(true);
    //const{SocialID, SocialType}=this.props.route.params??{};
    const SocialID = null;
    const SocialType = null;

    var MobileNo = "";
    if (data.country == "India") {
      MobileNo = "+91 0000000000";
    } else if (data.country == "Canada") {
      MobileNo = "+1 0000000000";
    } else {
      MobileNo = "+00 0000000000";
    }
    var name = "";
    if (data.firstName != null && data.firstName != "") {
      // name = data.firstName + " " + data?.lastName;
      name = data.firstName;
    } else {
      name = "user";
      setData({ data: { ...data, firstName: "user", lastName: " " } });
    }

    //SocialID:appleAuthRequestResponse.user, SocialType:"apple"
    var req = {
      Emailid: data?.Email,
      Title: "`",
      Name: name,
      MobileNo: MobileNo,
      ReferralCode: null,
      Referral_Code: data?.referralCode,
      Country: data?.country,
      UserType: 4,
      TimeZone: data?.timezoneName,
      SocialID,
      SocialType,
    };

    //console.log(req);

    //var req={"Email":"demo555@tmail.com","OTP":"7777","firstName":"test","lastName":"testest","countryId":"37","country":"Belize","timezoneId":"America/Belize","timezoneName":"America/Belize","email":"demo555@tmail.com","userId":30493,"device_type":"web"};

    //console.log("RegistrationByOTPV4Service req", req);
    RegistrationByOTPV4Service(req).then((resp) => {
      debugger;
      if (resp != null && resp.ResponseCode == 200) {
        //console.log("RegistrationByOTPV4Service resp", resp);

        let UserType = resp.data.UserType;
        let profile = resp.data.userdetails;
        profile.UserType = UserType;

        if (
          resp.data.userdetails.Country == null &&
          resp.data.userdetails.MobileNo.includes("+91")
        ) {
          profile.Country = "India";
        } else if (
          resp.data.userdetails.Country == null &&
          resp.data.userdetails.MobileNo.includes("+1")
        ) {
          profile.Country = "Canada";
        }
        //this.setState({profile, data:{...data, email:profile.Emailid, userId:profile.UserId}});

        login(resp.data);
        setCurrentUser(profile);

        var oodoCreateUserData = {
          ...data,
          email: profile.Emailid,
          userId: profile.UserId,
          device_type: "web",
          timezone: data?.timezoneName,
          country: data?.country,
          countryId: data?.countryId,
          firstName: data.firstName,
          lastName: data.lastName,
        };
        //console.log("OODOCreateUserV4Service req", oodoCreateUserData);

        OODOCreateUserV4Service(oodoCreateUserData).then((resp2) => {
          //console.log("OODOCreateUserV4Service resp", JSON.stringify(resp2));
          //this.setState(prevState=>({createUserResp:resp2.data, profile:{...prevState.profile, showB2BWelcomeScreen:resp2.data.showB2BWelcomeScreen}}), ()=> {callback()});
          //Auth.setAddAProfList(resp2.data.AddAProfList);
          // localStorage.setItem("disclaimer", resp2?.data?.disclaimer);
          console.log("resp2?.data?.disclaimer", resp2);
          setDisclaimer(resp2?.data);

          setLoading(false);
          const retrunurl = params.get("retrunurl");
          navigate("/user-dashboard");
          // navigate(`/disclaimer${retrunurl ? `?retrunurl=${retrunurl}` : ""}`);
          setOtpModalShow(false);
          setModalShow(true);
        });
      }
    });
  };

  const resendOTP = () => {
    setOtp(["", "", "", ""]);
    inputRefs.current[0].focus();
    setResendAllowed(false); // Disable resend button
    setCounter(30);
    let newValidationMessage = Object.assign(
      [],
      [
        ...validationMessage.filter((o) => o.key != "OTP"),
        { key: "OTP", message: "" },
      ]
    );
    setValidationMessage(newValidationMessage);
    setOtpSent(true);
    setCounter(count_time);
    // debugger
    var req = { Email: data.Email };
    SendRequestOTPService(req).then((resp) => {
      setLoading(false);
      //console.log("SendRequestOTPService resp", resp);
      if (resp != null && resp.ResponseCode == 200) {
        let newValidationMessage = Object.assign(
          [],
          [
            ...validationMessage.filter((o) => o.key != "OTP"),
            {
              key: "OTP",
              message: (
                <span>
                  <CheckCircleOutlineIcon /> An OTP has been resent to you.
                </span>
              ),
            },
          ]
        );
        setValidationMessage(newValidationMessage);
        setCounter(count_time);
        setOtpSent(true);
        setResendOtpSent(true);
      } else if (resp != null && resp.ResponseCode == 401) {
        //console.log("ResponseCode 401");
      }
    });
  };

  const onGoogleLoginSuccess = (credentialResponse) => {
    console.log("Login Success!");
    setCredentialCode(credentialResponse?.credential); // Set the credential code received
  };

  useEffect(() => {
    // Decode the token when the credentialCode changes
    //console.log(credentialCode, "(credentialCode");
    if (credentialCode) {
      const decoded = jwtDecode(credentialCode);
      setDecodedResponse(decoded);
      // console.log("decodedResponse", decodedResponse);
    }
  }, [credentialCode]);

  useEffect(() => {
    // console.log("decodedResponse", decodedResponse);
    if (decodedResponse) {
      setData((prevData) => ({
        ...prevData,
        Email: decodedResponse?.email,
        // OTP: 7777,
        firstName: decodedResponse?.given_name,
        lastName: decodedResponse?.family_name,
        Name: decodedResponse?.name,
      }));
      CheckUserEmailIsRegister(decodedResponse?.email).then((resp) => {
        if (resp?.ResponseCode === 400) {
          setErrorMessage(resp?.ResponseMessage);
          setOpenSnackbar(true);
          // setShowLloginPopup(true);
          const currentUrl = encodeURIComponent(window.location.href);
          window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
        } else if (resp?.ResponseCode === 200) {
          setdetailModalShow(true);
        }
      });
    }
  }, [decodedResponse]);

  const onDetailSubmit = () => {
    setLoading(true);
    if (validReferralCode) {
      CheckReferralCode(validReferralCode).then((resp) => {
        if (resp.ResponseCode === 200) {
          setReferralCodeMessage("Referral code Applied");
          setLoading(false);
          setData({ ...data, referralCode: validReferralCode });
          console.log("****dfdfd", validReferralCode, data);
          //onLogin();
          // setData({ ...data, Referral_Code: referralCode })
          createRegistration();
          setValidationMessage([]);
          setLoading(true);
        } else {
          enqueueSnackbar("Referral code doesn't exist", { variant: "error" });
        }
      });
    } else {
      createRegistration();
      setValidationMessage([]);
      setLoading(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className="kareify--SignUp">
      <Helmet>
        <title>United We Care Signup</title>
      </Helmet>
      <div className="signUp-container d-flex flex-column flex-md-row align-items-center align-items-md-end justify-content-md-between justify-content-center">
        <div className="d-flex flex-column gap-3 w-100">
          <h5 className="welcome-text-md text-center col-md-5 align-self-center d-none d-md-block">
            “You are worthy of happiness and peace of mind.”
          </h5>
          <h5 className="welcome-text text-center col-md-5 align-self-center d-block d-md-none">
            “You are worthy of happiness and peace of mind.”
          </h5>
          <div className="d-none d-md-flex flex-column align-self-start ms-3">
            <h2>Compliance</h2>
            <div className="d-flex gap-3 align-items-center justify-content-center">
              <img
                src="/assets/img/kareify/iso.webp.png"
                alt=""
                style={{ borderRadius: "8px" }}
              />
              <img
                src="/assets/img/kareify/hippa.webp.png"
                alt=""
                style={{ borderRadius: "8px" }}
              />
              {/* <img src='/assets/img/image 17763.png' alt='' style={{ borderRadius: '8px' }} /> */}
              {/* <img
                src="/assets/img/image 17762.png"
                alt=""
                style={{ borderRadius: "8px" }}
              /> */}
            </div>
          </div>
        </div>
        <div className="SignIn--box col-11 col-md-4 col-xxl-3 d-flex flex-column align-items-center gap-2">
          <h3>Create Account</h3>

          <div className="d-flex align-items-center justify-content-around w-75 social--login">
            <GoogleLogin
              onSuccess={onGoogleLoginSuccess}
              onError={() => {
                console.log("Login Failed");
              }}
            />
            {/* <img src='/assets/img/kareify/Google.png' alt='' />
                        <img src='/assets/img/kareify/Apple.png' alt='' />
                        <img src='/assets/img/kareify/Twitter.png' alt='' />
                        <img src='/assets/img/kareify/linkedin.png' alt='' /> */}
          </div>

          <Modal
            show={otpModalShow}
            onHide={() => setOtpModalShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="otp--popup"
          >
            <Modal.Body className="d-flex flex-column gap-4">
              <div className="otp--header d-flex align-items-center justify-content-between">
                <h3>Confirm OTP</h3>
                <CloseButton onClick={() => setOtpModalShow(false)} />
              </div>

              <p>
                An One Time Password has been sent to your email{" "}
                <span>{data?.Email}</span> Please enter the OTP to verify your
                email.
              </p>

              <form className="otp-container d-flex align-items-center gap-3 gap-md-5 justify-content-center px-5">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="tel"
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    maxLength="1"
                    ref={(el) => (inputRefs.current[index] = el)}
                    value={digit}
                  />
                ))}
              </form>

              {resendOtpSent && counter !== 0 && (
                <span
                  className="error-message px-5 d-flex align-items-center justify-content-center gap-2"
                  style={{ color: "#00AD65" }}
                >
                  {validationMessage.find((o) => o.key === "OTP")?.message}
                </span>
              )}

              {!resendOtpSent &&
                validationMessage.some((o) => o.key === "OTP") && (
                  <span
                    className="error-message px-5 d-flex align-items-center justify-content-center gap-2"
                    style={{ color: "#dc3545" }}
                  >
                    {validationMessage.find((o) => o.key === "OTP")?.message}
                  </span>
                )}

              <div className="otp--resend d-flex align-items-center justify-content-between px-4">
                {counter === 0 ? (
                  <div className="w-100">
                    <p className="font-inter fs-6 text-black text-center w-100 d-flex align-items-center justify-content-between">
                      <span>00:0{counter}</span>{" "}
                      <span
                        onClick={resendOTP}
                        style={{ cursor: "pointer", color: "#73589B" }}
                      >
                        Resend OTP
                      </span>
                    </p>
                  </div>
                ) : (
                  <div
                    className="w-100 d-flex align-items-center justify-content-between font-inter fs-6 text-black text-center border-0 bg-white"
                  // style={{ color: "red !important" }}
                  >
                    <span>
                      00:{counter < 10 ? 0 : ""}
                      {counter}
                    </span>{" "}
                    <span>Resend OTP</span>
                  </div>
                )}
              </div>

              <Button
                disabled={loading || data?.OTP.length < 4}
                className="otp--continue-btn border-0 d-block mx-auto my-3"
                onClick={onSubmitOtp}
              >
                Continue
              </Button>
            </Modal.Body>
          </Modal>

          <div className="d-flex align-items-center w-100 gap-2 px-5 horizontal--line">
            <hr />
            <p>Or</p>
            <hr />
          </div>

          <p>Sign up with your email address</p>

          <input
            placeholder="Email Address *"
            variant="outlined"
            className="w-75"
            onChange={(e) =>
              setData((prevData) => ({ ...prevData, Email: e?.target?.value }))
            }
            onKeyDown={onEmailChange}
          />
          {!validEmail && (
            <span
              className="error-message fs-6"
              style={{ color: "#dc3545", fontWeight: 400 }}
            >
              {validationMessage.filter((o) => o.key == "Email")[0]?.message}
            </span>
          )}
          <input
            placeholder="Full Name*"
            variant="outlined"
            className="w-75"
            onChange={(e) => {
              const trimmedValue = e.target.value.trim(); // Remove leading and trailing spaces
              if (trimmedValue.length > 0) {
                setData({ ...data, firstName: trimmedValue });
              }
            }}
          />
          <Form.Select
            placeholder="Country *"
            aria-label="Country *"
            className="w-75"
            onChange={(e) => onCountryChange(e?.target?.value)}
            value={countryCode == "US" ? 233 : data?.countryId || ""}
          >
            <option disabled>Select Country *</option>
            {countryList?.map((option, index) => (
              <option
                value={option?.id}
                key={index}
                defaultValue={data?.countryId}
              >
                {option.name}
              </option>
            ))}
          </Form.Select>
          {/* <TextField
                        select
                        label="Country *"
                        className='w-75'
                        value={data?.countryId}
                        onChange={(e) => onCountryChange(e?.target?.value)}
                    >
                        {countryList?.map((option, index) => (
                            <MenuItem value={option?.id} key={index}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField> */}
          {/* <TextField
                        select
                        label="Time Zone *"
                        className='w-75'
                        value={data?.timezoneId}
                        onChange={(e) => onTimezoneChange(e?.target?.value)}
                    >
                        {timezoneList?.map((option, index) => (
                            <MenuItem value={option?.id} key={index} >
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField> */}
          {timezoneList?.length > 1 && (
            <Form.Select
              placeholder="Select Time Zone *"
              className="w-75"
              value={data?.timezoneId}
              onChange={(e) => onTimezoneChange(e?.target?.value)}
            >
              <option value={null}>Select Time Zone *</option>
              {timezoneList?.map((option, index) => (
                <option value={option?.id} key={index}>
                  {option.name}
                </option>
              ))}
            </Form.Select>
          )}

          <input
            placeholder="Referral code (Optional)"
            variant="outlined"
            className="w-75"
            value={validReferralCode}
            onChange={(e) => HandelReferalCodeChange(e)}
          />
          <p
            className="font-inter font-w500 p-1"
            style={{
              color:
                referralCodeMessage === "Referral code Applied"
                  ? "green"
                  : "red",
            }}
          >
            {referralCodeMessage}
          </p>

          <Button
            className="border-0"
            onClick={onContinueSignUp}
            disabled={
              loading || !data?.Email || !data?.firstName || !data?.timezoneId
            }
          >
            Continue
          </Button>

          <DisclaimerModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            disclaimer={disclaimer}
          />

          <p>
            Already have an account?{" "}
            <span className="diff-clr" onClick={() => {
              // setShowLloginPopup(true)
              const currentUrl = encodeURIComponent(window.location.href);
              window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
            }}>
              Login
            </span>
          </p>
        </div>
      </div>
      {/* <h2 className='col-12 col-md-4 col-xxl-3'>
                “You are worthy of happiness and peace of mind.”
            </h2> */}

      <Modal
        show={detailModalShow}
        onHide={() => setdetailModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="details--popup show"
      >
        <Modal.Body>
          <h5>Please Enter</h5>
          <div className="d-flex flex-column align-items-center gap-3 input-section">
            <TextField
              id="outlined-select-currency"
              select
              label="Country *"
              className="w-100"
              onChange={(e) => onCountryChange(e?.target?.value)}
            >
              {countryList?.map((option) => (
                <MenuItem key={option.value} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="outlined-select-currency"
              select
              label="Time Zone *"
              onChange={(e) => onTimezoneChange(e?.target?.value)}
              className="w-100"
            >
              {timezoneList?.map((option) => (
                <MenuItem key={option.value} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              placeholder="Referral code (Optional)"
              variant="outlined"
              className="w-100"
              value={validReferralCode}
              onChange={(e) => HandelReferalCodeChange(e)}
            />
            <p
              className="font-inter font-w500 p-1"
              style={{
                color:
                  referralCodeMessage === "Referral code Applied"
                    ? "green"
                    : "red",
              }}
            >
              {referralCodeMessage}
            </p>

            <Button onClick={onDetailSubmit}>Continue</Button>
          </div>
        </Modal.Body>
      </Modal>

      {successMsg !== "" && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => handleCloseSnackbar()}
          message={successMsg}
        />
      )}

      {errorMessage !== "" && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => handleCloseSnackbar}
          message={errorMessage}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        />
      )}
    </div>
  );
};

export default SignUp2;
