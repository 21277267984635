import React,{useState} from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import "./Booking.css";

const InsuranceDetailCard = ({ data, handleSelect, expertDetailsData,setSelectedInsuranceData,selectedInsuranceData }) => {
  const insurance_covers = expertDetailsData?.USD_Amount - data?.payment;

  return (
    <div
      className="Insurance-Detail-Card"
      onClick={() => handleSelect(data)}
      // onClick={() => setSelectedInsuranceData(data)}
    >
      <img
        src="/assets/img/mask_group.png"
        alt=""
        className="bg-img"
        style={{
          position: "absolute",
          maxHeight: "145px",
          width: "89%",
          background: ` ${data?.isSelected ? "#73589b" : "gray"}`,
          cursor:"pointer",
        }}
      />

      <div className="card-content">
        <div>
          <div className="insurance-name d-flex justify-content-between">
            {data?.insuring_company}
            {/* <img src="/assets/img/hdfc_ergo_insurance.png" alt="" /> */}
          </div>
          <div className="verified">
            {data.verfied ? (
              <div className="d-flex gap-2 align-items-center">
                <VerifiedIcon sx={{ color: "white" }} fontSize="small" />
                Verified
              </div>
            ) : null}
          </div>
        </div>
        {/* <div className="member-id">
          Member ID <div> {data?.memberID} </div>
        </div> */}
        <p className="member-id">
          Insurance Covers: <strong> {`$ ${insurance_covers}`} </strong>
        </p>
        {/* <div className="policy-number">Policy Number {data?.policy}</div> */}
        {/* <div className="policy-number">
          Insurance Amount {`$ ${data?.payment}`}
        </div> */}
      </div>
    </div>
  );
};

export default InsuranceDetailCard;
