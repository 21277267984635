import React, { useState } from "react";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClaimHistoryModal from "./modals/ClaimHistoryModal";

const claimHistory = [
  {
    image: "/assets/img/vasudha-agarwal.jpg",
    title: "30 minutes session with Psychologist",
    expert: "Dr. Courtney Henry",
    date: "August 7, 2017, 12:30 PM",
  },
  {
    image: "/assets/img/vasudha-agarwal.jpg",
    title: "30 minutes session with Psychologist",
    expert: "Dr. Courtney Henry",
    date: "August 7, 2017, 12:30 PM",
  },
  {
    image: "/assets/img/vasudha-agarwal.jpg",
    title: "30 minutes session with Psychologist",
    expert: "Dr. Courtney Henry",
    date: "August 7, 2017, 12:30 PM",
  },
  {
    image: "/assets/img/vasudha-agarwal.jpg",
    title: "30 minutes session with Psychologist",
    expert: "Dr. Courtney Henry",
    date: "August 7, 2017, 12:30 PM",
  },
  {
    image: "/assets/img/vasudha-agarwal.jpg",
    title: "30 minutes session with Psychologist",
    expert: "Dr. Courtney Henry",
    date: "August 7, 2017, 12:30 PM",
  },
  {
    image: "/assets/img/vasudha-agarwal.jpg",
    title: "30 minutes session with Psychologist",
    expert: "Dr. Courtney Henry",
    date: "August 7, 2017, 12:30 PM",
  },
  {
    image: "/assets/img/vasudha-agarwal.jpg",
    title: "30 minutes session with Psychologist",
    expert: "Dr. Courtney Henry",
    date: "August 7, 2017, 12:30 PM",
  },
  {
    image: "/assets/img/vasudha-agarwal.jpg",
    title: "30 minutes session with Psychologist",
    expert: "Dr. Courtney Henry",
    date: "August 7, 2017, 12:30 PM",
  },
];

const ClaimHistory = () => {
  const [openModal, setOpenModal] = useState(false)
  return (
    <div>
      <div className="claim-history-container">
              <h6 className="fw-semibold">Claim History</h6>
              {claimHistory.length != 0 ? (
                <div>
                  {claimHistory.map((data, index) => (
                    <div role="button" onClick={() => setOpenModal(true)} className="claim-history-row my-2">
                      <div className="d-flex gap-3">
                        <img
                          src={data.image}
                          alt=""
                          loading="lazy"
                          className="expert-img"
                        />
                        <div className="history-details">
                          <p>{data.title}</p>
                          <div className="d-flex gap-3 my-2">
                            <span className="expert-name">{data.expert}</span>
                            <span className="session-date d-flex align-items-center gap-1">
                              <CalendarMonthOutlinedIcon
                                sx={{ color: "#667085" }}
                                variant="small"
                              />
                              {data.date}
                            </span>
                          </div>
                        </div>
                      </div>

                      <span className="hover-icon">
                        <FileDownloadOutlinedIcon />
                      </span>

                      
                    </div>
                  ))}
                  <ClaimHistoryModal open={openModal} setOpen={setOpenModal} />
                </div>
              ) : (
                <div className="d-flex flex-column align-items-center justify-content-center my-auto">
                  <img
                    src="/assets/img/empty-history.png"
                    alt=""
                    loading="lazy"
                    width={120}
                    height={120}
                  />
                  <div className="fw-semibold">No history</div>
                </div>
              )}
            </div>
    </div>
  )
}

export default ClaimHistory