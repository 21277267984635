import React, { useEffect, useState } from "react";
import DetailPageCard from "../DetailPageCard";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Badge,
  Button,
  ButtonGroup,
  Modal,
  Row,
  ToggleButton,
} from "react-bootstrap";
import PrescriptionsCard from "../PrescriptionsCard";
import { useParams, useNavigate } from "react-router-dom";
import {
  MySubscriptionService,
  GetAppointment_Documents_Files_Sent_Expert,
  GetAppointment_Documents_Files_Sent_User,
  saveAppointment_Documents_Files,
  CancelAppointmentService,
} from "./core/_request";
import {
  AppointmentService,
  ExpertDetailsService,
  FeedBackForm,
  FeedbackSubmit,
  RateYourExpertAndFeedback,
  getAppointment_Session,
} from "../core/_request";
import {
  CheckFeedbackStatus,
} from "./core/_request"
import { useAuth } from "../../auth";
import { storage } from "../../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, signInAnonymously } from "firebase/auth";
import { MySubscription_Status } from "../core/models";
import moment from "moment";
import { getCountryCode } from "../core/commonFun";
import DashboardDetailCard from "./DashboardDetailCard";
import { useContext } from "react";
import { AppContext } from "../../context";
import { Rating, TextField, makeStyles } from "@mui/material";
import { enqueueSnackbar } from "notistack";

const Feedback = ({
  feedbackshow,
  handlefeedback,
  appointmentId,
  feedbackAnswers,
  handleClose,
  expertDataP
}) => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [expertData, setExpertData] = useState();
  const countryCode = getCountryCode();
  const [starValue, setStarValue] = useState(0);
  const [callQuality, setCallQuality] = useState("");
  const [expertInteraction, setExpertInteraction] = useState("");
  const [reviewText, setReviewText] = useState({
    question_id: "",
    answer: "",
  });
  const [displayNameBool, setDisplayNameBool] = useState(undefined);
  const [bookAnotherSession, setBookAnotherSession] = useState(undefined);
  const [feedbackStatus, setFeedbackStatus] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [responseApi, setResponseApi] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      feedbackAnswers &&
      feedbackAnswers?.length > 0 &&
      questions &&
      questions.length > 0
    ) {
      let queData = JSON.parse(JSON.stringify(questions));
      queData.forEach((que) => {
        let feedback = feedbackAnswers?.find(
          (feed) => feed.question === que.question
        );
        if (feedback) {
          que.answerData = feedback?.answer;
        }
      });
      console.log(queData);
      setQuestions(queData);
    }
  }, [feedbackAnswers]);

  useEffect(() => {
    const auth = getAuth();
    // Signing in anonymously
    signInAnonymously(auth)
      .then(() => {
        // Handle successful anonymous authentication if needed
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // Ideally, handle errors more robustly here, possibly logging or displaying error messages
        console.error("Firebase signInAnonymously failed:", errorCode, errorMessage);
      });

    // Fetch subscription data
    MySubscriptionService().then((resp) => {
      if (resp?.ResponseCode === 200) {
        debugger
        const foundData = resp?.data?.find(o => o?.ID === (appointmentId ? appointmentId : id));
        setData(foundData);
      } else {
        // Handle scenarios where the response code is not 200
        console.error("Failed to fetch data:", resp);
      }
    }).catch(error => {
      console.error("Error fetching subscription data:", error);
    });

    // Remove debugger from production code
  }, []);

  useEffect(() => {
    if (data?.ExpertId)
      ExpertDetailsService(data?.ExpertId).then((resp) => {
        var data = resp.data;
        if (countryCode == "IN")
          data["countryAmount"] = data.Hourly_Consultaion_Fee_amount;
        else data["countryAmount"] = data.USD_Amount ?? 0;
        setExpertData(resp.data);
      });
  }, [data]);

  var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;

  useEffect(() => {
    var feedbackbody = {
      user_type: "user",
      user_id: user_id,
      session_status: "completed",
      appointment_id: appointmentId ? appointmentId : id,
    };
    FeedBackForm(feedbackbody)
      .then((response) => {
        setQuestions(response?.data?.template_data);
      })
      .catch((error) => {
        console.error("Error fetching questions:", error);
        // Display an error message to the user, possibly using a toast notification
        enqueueSnackbar('Failed to load feedback questions. Please try again later.', { variant: 'error' });
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (id !== undefined) {
        CheckFeedbackStatus(id).then((resp) => {
          if (resp?.data === true) {
            setFeedbackStatus(true);
          } else {
            setFeedbackStatus(false);
          }
        });
      }
    }, 1000);
  }, []);

  const respFun = (question_id, name, filled) => {
    setQuestions(questions);
    let data = { question_id: question_id, answer: name };
    let checkData = responseApi.filter(
      (resFilter) => resFilter.question_id === question_id
    );
    if (checkData.length === 0) {
      setResponseApi([...responseApi, data]);
      return;
    } else {
      let result = responseApi.map((res) => {
        if (res.question_id === question_id) {
          return { ...res, answer: name };
        }
        return res;
      });
      setResponseApi(result);
    }
    if (filled === "emoji") {
      setCallQuality(name);
    } else if (filled === "emoji2") {
      setExpertInteraction(name);
    } else if (filled === "fy") {
      if (name == "Yes") {
        setDisplayNameBool(true);
      } else {
        setDisplayNameBool(false);
      }
    } else if (filled === "py") {
      if (name == "Yes") {
        setBookAnotherSession(true);
      } else {
        setBookAnotherSession(false);
      }
    }
  };

  const onClickFeedbackSubmit = () => {
    debugger
    setIsLoading(true);
    var jsonData =
      reviewText?.answer === ""
        ? [...responseApi]
        : [...responseApi, reviewText];
    console.log(jsonData);
    var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
    const feedbackData = {
      user_id: user_id,
      user_type: "user",
      appointment_id: appointmentId ? appointmentId : id,
      session_status: "completed",
      qa_list: jsonData,
    };
    FeedbackSubmit(feedbackData)
      .then((response) => {
        handlefeedback();
        handleClose();
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
      });
    handleClose();
  };

  const funSetImogies = (index, answer) => {
    questions[index].answerData = answer;
    setQuestions(questions);
  };

  //console.log("expertDataP", expertDataP);

  return (
    <Modal
      className="feedbackModal px-0"
      sx={{ position: "relative" }}
      show={feedbackshow}
      onHide={handlefeedback}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        className="modal-content"
        closeButton
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          width: "max-content",
          zIndex: "1100",
          padding: "10px",
          background: "transparent",
        }}
      ></Modal.Header>
      <Modal.Body className="p-0">
        <div className="d-none d-md-flex">
          <div
            className="col-md-4 px-4 d-flex flex-column align-items-center"
            style={{ background: "#FDFFD6", paddingTop: "5%", borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }}
          >
            <img
              src={expertDataP?.ProfileImage || expertData?.ProfileImage}
              alt=""
              className="rounded-circle d-block mx-auto my-5"
              style={{
                background: "#EEEFEE",
                height: "235px",
                width: "235px",
              }}
            />
            <div className="d-flex flex-column gap-1">
              <div className="d-flex align-items-center gap-2 w-100">
                <h5 className="font-helvetica font-w700 fs-2">
                  {expertDataP?.Title || expertData?.Title} {expertDataP?.Name || expertData?.Name || "Professional"}
                </h5>
                <img
                  src="/assets/img/usa-20.png"
                  alt=""
                  className="rounded-circle"
                  style={{}}
                />
              </div>
              <div
                className="rounded-3 w-auto"
                style={{ background: "#E0F6D8" }}
              >
                <p
                  className="font-inter fs-6 font-w500 px-2"
                  style={{ color: "#134700" }}
                >
                  {expertDataP?.RegisterYourselfAs || expertData?.RegisterYourselfAs}
                </p>
              </div>
              <div className="my-2 w-100">
                <h6
                  className="font-inter fs-6 font-w600 text-black"
                  style={{ lineHeight: "30px" }}
                >
                  {expertDataP?.Experience || expertData?.Experience} years of Experience
                </h6>
                <h6
                  className="font-inter fs-6 font-w600 text-black"
                  style={{ lineHeight: "30px" }}
                >
                  Speaks:
                  <span className="font-inter fs-6 font-w500 text-black">
                    {expertDataP?.Languages || expertData?.Languages}
                  </span>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-8 py-3 px-5">
            <div className="d-flex flex-column align-items-center justify-content-center my-5">
              <h4 className="font-helvetica fs-5 font-w700">
                {questions && questions?.length && questions[0]?.question}
              </h4>
              <Rating
                name="simple-controlled"
                value={questions[0]?.answerData}
                onChange={(event, newValue) => {
                  setStarValue(newValue);
                  respFun(questions[0].question_id, newValue, "star");
                  questions[0].answerData = newValue;
                  funSetImogies(0, newValue);
                }}
                precision={0.5}
                sx={{ fontSize: "50px" }}
              />
            </div>
            <div className="d-flex align-items-center gap-5 my-5">
              <div>
                <h6 className="font-helvetica font-w500 fs-5 text-black">
                  {questions && questions.length && questions[1].question}
                </h6>
                <ButtonGroup className="d-flex align-items-center gap-2">
                  <ToggleButton
                    className="w-auto p-2"
                    style={{
                      borderRadius: "10px",
                      border:
                        questions[1]?.answerData === "Bad"
                          ? "2px solid #73589b"
                          : "2px solid #E1E5EE",
                      background: "rgba(225, 229, 238, 0.31)",
                    }}
                    name="radio"
                    type="radio"
                    onClick={() => {
                      respFun(questions[1].question_id, "Bad", "emoji");
                      setCallQuality("Bad");
                      funSetImogies(1, "Bad");
                    }}
                  >
                    <img src="/assets/img/slightly_frowning_face.png" alt="" />
                  </ToggleButton>
                  <ToggleButton
                    className="w-auto p-2"
                    style={{
                      borderRadius: "10px",
                      border:
                        questions[1]?.answerData === "Good"
                          ? "2px solid #73589b"
                          : "2px solid #E1E5EE",
                      background: "rgba(225, 229, 238, 0.31)",
                    }}
                    name="radio"
                    type="radio"
                    onClick={() => {
                      respFun(questions[1]?.question_id, "Good", "emoji");
                      setCallQuality("Good");
                      funSetImogies(1, "Good");
                    }}
                  >
                    <img src="/assets/img/blush.png" alt="" />
                  </ToggleButton>
                  <ToggleButton
                    className="w-auto p-2"
                    style={{
                      borderRadius: "10px",
                      border:
                        questions[1]?.answerData === "Awesome"
                          ? "2px solid #73589b"
                          : "2px solid #E1E5EE",
                      background: "rgba(225, 229, 238, 0.31)",
                    }}
                    name="radio"
                    type="radio"
                    onClick={() => {
                      respFun(questions[1]?.question_id, "Awesome", "emoji");
                      setCallQuality("Awesome");
                      funSetImogies(1, "Awesome");
                    }}
                  >
                    <img src="/assets/img/heart_eyes.png" alt="" />
                  </ToggleButton>
                </ButtonGroup>
              </div>
              <div>
                <h6 className="font-helvetica font-w500 fs-5 text-black">
                  {questions && questions?.length && questions[2]?.question}
                </h6>
                <ButtonGroup className="d-flex align-items-center gap-2">
                  <ToggleButton
                    className="w-auto p-2"
                    style={{
                      borderRadius: "10px",
                      border:
                        questions[2]?.answerData === "Bad"
                          ? "2px solid #73589b"
                          : "2px solid #E1E5EE",
                      background: "rgba(225, 229, 238, 0.31)",
                    }}
                    name="radio"
                    type="radio"
                    onClick={() => {
                      respFun(questions[2]?.question_id, "Bad", "emoji2");
                      setExpertInteraction("Bad");
                      funSetImogies(2, "Bad");
                    }}
                  >
                    <img src="/assets/img/slightly_frowning_face.png" alt="" />
                  </ToggleButton>
                  <ToggleButton
                    className="w-auto p-2"
                    style={{
                      borderRadius: "10px",
                      border:
                        questions[2]?.answerData === "Good"
                          ? "2px solid #73589b"
                          : "2px solid #E1E5EE",
                      background: "rgba(225, 229, 238, 0.31)",
                    }}
                    name="radio"
                    type="radio"
                    onClick={() => {
                      respFun(questions[2]?.question_id, "Good", "emoji2");
                      setExpertInteraction("Good");
                      funSetImogies(2, "Good");
                    }}
                  >
                    <img src="/assets/img/blush.png" alt="" />
                  </ToggleButton>
                  <ToggleButton
                    className="w-auto p-2"
                    style={{
                      borderRadius: "10px",
                      border:
                        questions[2]?.answerData === "Awesome"
                          ? "2px solid #73589b"
                          : "2px solid #E1E5EE",
                      background: "rgba(225, 229, 238, 0.31)",
                    }}
                    name="radio"
                    type="radio"
                    onClick={() => {
                      respFun(questions[2].question_id, "Awesome", "emoji2");
                      setExpertInteraction("Awesome");
                      funSetImogies(2, "Awesome");
                    }}
                  >
                    <img src="/assets/img/heart_eyes.png" alt="" />
                  </ToggleButton>
                </ButtonGroup>
              </div>
            </div>
            <h6 className="font-helvetica text-black fs-5 font-w500 my-2">
              {questions && questions.length && questions[3].question}
            </h6>
            <TextField
              fullWidth
              multiline
              rows={3}
              value={questions[3]?.answerData}
              style={{ height: "100px" }}
              className="feedbackInput mb-2"
              onChange={(e) => {
                setReviewText({
                  answer: e.target.value,
                  question_id: questions[3].question_id,
                });
                funSetImogies(3, e.target.value);
              }}
            />
            <div className="my-3">
              <h6 className="font-helvetica font-w500 fs-5 text-black">
                {questions && questions.length && questions[4].question}{" "}
              </h6>
              <ButtonGroup className="d-flex align-items-center gap-3">
                <ToggleButton
                  // onClick={}
                  type="radio"
                  name="radio"
                  className="rounded-5"
                  style={{
                    border:
                      questions[4]?.answerData &&
                        questions[4]?.answerData === "Yes"
                        ? "2px solid #73589b"
                        : "none",
                    background: "#EEFFE8",
                    color: "#134700",
                    width: "55px",
                  }}
                  onClick={() => {
                    respFun(questions[4].question_id, "Yes", "fy");
                    funSetImogies(4, "Yes");
                  }}
                >
                  <p className="font-helvetica fs-6">Yes</p>
                </ToggleButton>
                <ToggleButton
                  type="radio"
                  name="radio"
                  className="rounded-5"
                  style={{
                    border:
                      questions[4]?.answerData &&
                        questions[4]?.answerData === "No"
                        ? "2px solid #73589b"
                        : "none",
                    background: "#FFF8E5",
                    color: "#73589b",
                    width: "55px",
                  }}
                  onClick={() => {
                    respFun(questions[4].question_id, "No", "fy");
                    funSetImogies(4, "No");
                  }}
                >
                  <p className="font-helvetica fs-6">No</p>
                </ToggleButton>
              </ButtonGroup>
            </div>
            <div className="my-3">
              <h6 className="font-helvetica font-w500 fs-5 text-black">
                {questions && questions.length && questions[5].question}
              </h6>
              <ButtonGroup className="d-flex align-items-center gap-3">
                <ToggleButton
                  // onClick={}
                  type="radio"
                  name="radio"
                  className="rounded-5"
                  style={{
                    border:
                      questions[5]?.answerData &&
                        questions[5]?.answerData === "Yes"
                        ? "2px solid #73589b"
                        : "none",
                    background: "#EEFFE8",
                    color: "#73589b",
                    width: "55px",
                  }}
                  onClick={() => {
                    respFun(questions[5].question_id, "Yes", "py");
                    setBookAnotherSession(true);
                    funSetImogies(5, "Yes");
                  }}
                >
                  <p className="font-helvetica fs-6">Yes</p>
                </ToggleButton>
                <ToggleButton
                  // onClick={}
                  type="radio"
                  name="radio"
                  className="rounded-5"
                  style={{
                    border:
                      questions[5]?.answerData &&
                        questions[5]?.answerData === "No"
                        ? "2px solid #73589b"
                        : "none",
                    background: "#FFF8E5",
                    color: "#73589b",
                    width: "55px",
                  }}
                  onClick={() => {
                    respFun(questions[5]?.question_id, "No", "py");
                    setBookAnotherSession(false);
                    funSetImogies(5, "No");
                  }}
                >
                  <p className="font-helvetica fs-6">No</p>
                </ToggleButton>
              </ButtonGroup>
            </div>
            <Row className="my-4 gap-3">
              {(isLoading === true && (
                <Button
                  className="border-0 rounded-5 col-3 px-3 py-2"
                  style={{
                    background: "#73589b",
                    boxShadow: "0px 1.06px 2.13px 0px rgba(16, 24, 40, 0.05)",
                  }}
                >
                  <img
                    src="/assets/img/loading.gif"
                    alt="Unitedwecare"
                    style={{ height: 26, width: 26 }}
                  />
                </Button>
              )) ||
                (feedbackAnswers && feedbackAnswers?.length === 0 && (
                  <Button
                    disabled={starValue === 0}
                    onClick={onClickFeedbackSubmit}
                    className="border-0 rounded-5 col-3 px-3 py-2"
                    style={{
                      background: "#73589b",
                      boxShadow: "0px 1.06px 2.13px 0px rgba(16, 24, 40, 0.05)",
                    }}
                  >
                    Submit
                  </Button>
                ))}
            </Row>
          </div>
        </div>
        <div className="d-block d-md-none">
          <div
            className="d-flex flex-column gap-2"
            style={{ background: "#FDFFD6", padding: "50px 0" }}
          >
            <div className="d-flex align-items-center justify-content-center gap-2 px-2">
              <img
                src={expertData?.ProfileImage}
                alt=""
                className="rounded-circle"
                style={{
                  background: "#EEEFEE",
                  height: "100px",
                  width: "100px",
                }}
              />
              <div className="">
                <div className="d-flex align-items-center gap-2 my-2">
                  <h5
                    className="font-helvetica font-w700 fs-2"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {expertData?.Title} {expertData?.Name || "Professional"}
                  </h5>
                  <img
                    src="/assets/img/usa-20.png"
                    alt=""
                    className="rounded-circle"
                    style={{}}
                  />
                </div>
                <div
                  className="rounded-3 w-auto my-2"
                  style={{ background: "#E0F6D8" }}
                >
                  <p
                    className="font-inter text-center fs-6 font-w500 px-2"
                    style={{ color: "#134700" }}
                  >
                    {expertData?.RegisterYourselfAs}
                  </p>
                </div>
                <div className="my-2 w-100 text-center">
                  <h6
                    className="font-inter fs-6 font-w600 text-black"
                    style={{ lineHeight: "30px" }}
                  >
                    {expertData?.Experience} years of Experience
                  </h6>
                  <h6
                    className="font-inter fs-6 font-w600 text-black"
                    style={{ lineHeight: "30px" }}
                  >
                    Speaks:
                    <span className="font-inter fs-6 font-w500 text-black">
                      {expertData?.Languages}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4">
            <div className="d-flex flex-column my-3">
              <h4 className="font-helvetica fs-5 font-w700 mb-0">
                {questions && questions.length && questions[0].question}
              </h4>
              <Rating
                name="simple-controlled"
                value={questions[0]?.answerData}
                onChange={(event, newValue) => {
                  setStarValue(newValue);
                  respFun(questions[0].question_id, newValue, "star");
                  questions[0].answerData = newValue;
                }}
                precision={0.5}
                sx={{ fontSize: "35px" }}
              />
            </div>
            <div className="my-3">
              <h6 className="font-helvetica fs-5 font-w700">
                {questions && questions.length && questions[1].question}
              </h6>
              <ButtonGroup className="d-flex align-items-center gap-2">
                <ToggleButton
                  className="w-auto p-2"
                  style={{
                    borderRadius: "10px",
                    border:
                      callQuality === "Bad"
                        ? "2px solid #73589b"
                        : "2px solid #E1E5EE",
                    background: "rgba(225, 229, 238, 0.31)",
                  }}
                  name="radio"
                  type="radio"
                  onClick={() => {
                    respFun(questions[1].question_id, "Bad", "emoji");
                    setCallQuality("Bad");
                    funSetImogies(1, "Bad");
                  }}
                >
                  <img
                    src="/assets/img/slightly_frowning_face(1).png"
                    alt=""
                    style={{}}
                  />
                </ToggleButton>
                <ToggleButton
                  className="w-auto p-2"
                  style={{
                    borderRadius: "10px",
                    border:
                      callQuality === "Good"
                        ? "2px solid #73589b"
                        : "2px solid #E1E5EE",
                    background: "rgba(225, 229, 238, 0.31)",
                  }}
                  name="radio"
                  type="radio"
                  onClick={() => {
                    respFun(questions[1].question_id, "Good", "emoji");
                    setCallQuality("Good");
                    funSetImogies(1, "Good");
                  }}
                >
                  <img src="/assets/img/blush(1).png" alt="" />
                </ToggleButton>
                <ToggleButton
                  className="w-auto p-2"
                  style={{
                    borderRadius: "10px",
                    border:
                      callQuality === "Awesome"
                        ? "2px solid #73589b"
                        : "2px solid #E1E5EE",
                    background: "rgba(225, 229, 238, 0.31)",
                  }}
                  name="radio"
                  type="radio"
                  onClick={() => {
                    respFun(questions[1].question_id, "Awesome", "emoji");
                    setCallQuality("Awesome");
                    questions[1].answerData = "Awesome";
                    funSetImogies(1, "Awesome");
                  }}
                >
                  <img src="/assets/img/heart_eyes(1).png" alt="" />
                </ToggleButton>
              </ButtonGroup>
            </div>
            <div className="my-3">
              <h6 className="font-helvetica fs-5 font-w700">
                {questions && questions.length && questions[2].question}
              </h6>
              <ButtonGroup className="d-flex align-items-center gap-2">
                <ToggleButton
                  className="w-auto p-2"
                  style={{
                    borderRadius: "10px",
                    border:
                      questions[2]?.answerData === "Bad"
                        ? "2px solid #73589b"
                        : "2px solid #E1E5EE",
                    background: "rgba(225, 229, 238, 0.31)",
                  }}
                  name="radio"
                  type="radio"
                  onClick={() => {
                    respFun(questions[2].question_id, "Bad", "emoji2");
                    setExpertInteraction("Bad");
                    funSetImogies(2, "Bad");
                  }}
                >
                  <img src="/assets/img/slightly_frowning_face(1).png" alt="" />
                </ToggleButton>
                <ToggleButton
                  className="w-auto p-2"
                  style={{
                    borderRadius: "10px",
                    border:
                      questions[2]?.answerData === "Good"
                        ? "2px solid #73589b"
                        : "2px solid #E1E5EE",
                    background: "rgba(225, 229, 238, 0.31)",
                  }}
                  name="radio"
                  type="radio"
                  onClick={() => {
                    respFun(questions[2].question_id, "Good", "emoji2");
                    setExpertInteraction("Good");
                    funSetImogies(2, "Good");
                  }}
                >
                  <img src="/assets/img/blush(1).png" alt="" />
                </ToggleButton>
                <ToggleButton
                  className="w-auto p-2"
                  style={{
                    borderRadius: "10px",
                    border:
                      expertInteraction === "Awesome" ||
                        questions[2]?.answerData === "Awesome"
                        ? "2px solid #73589b"
                        : "2px solid #E1E5EE",
                    background: "rgba(225, 229, 238, 0.31)",
                  }}
                  name="radio"
                  type="radio"
                  onClick={() => {
                    respFun(questions[2].question_id, "Awesome", "emoji2");
                    setExpertInteraction("Awesome");
                    funSetImogies(2, "Awesome");
                  }}
                >
                  <img src="/assets/img/heart_eyes(1).png" alt="" />
                </ToggleButton>
              </ButtonGroup>
            </div>
            <div className="my-3">
              <h6 className="font-helvetica fs-5 font-w700">
                {questions && questions.length && questions[3].question}
              </h6>
              <TextField
                fullWidth
                multiline
                rows={3}
                value={questions[3]?.answerData}
                style={{ height: "100px" }}
                className="feedbackInput mb-2"
                onChange={(e) => {
                  setReviewText({
                    answer: e.target.value,
                    question_id: questions[3].question_id,
                  });
                  funSetImogies(3, e.target.value);
                }}
              />
            </div>
            <div className="my-3">
              <h6 className="font-helvetica fs-5 font-w700">
                {questions && questions.length && questions[4].question}{" "}
              </h6>
              <ButtonGroup className="d-flex align-items-center gap-3">
                <ToggleButton
                  // onClick={}
                  type="radio"
                  name="radio"
                  className="rounded-5"
                  style={{
                    border:
                      questions[4]?.answerData &&
                        questions[4]?.answerData === "Yes"
                        ? "2px solid #73589b"
                        : "none",
                    background: "#EEFFE8",
                    color: "#73589b",
                  }}
                  onClick={() => {
                    respFun(questions[4].question_id, "Yes", "fy");
                    setDisplayNameBool(true);
                    funSetImogies(4, "Yes");
                  }}
                >
                  <p className="font-helvetica fs-6">Yes</p>
                </ToggleButton>
                <ToggleButton
                  type="radio"
                  name="radio"
                  className="rounded-5"
                  style={{
                    border:
                      (displayNameBool !== undefined && !displayNameBool) ||
                        (questions[4]?.answerData &&
                          questions[4]?.answerData === "No")
                        ? "2px solid #73589b"
                        : "none",
                    background: "#FFF8E5",
                    color: "#73589b",
                  }}
                  onClick={() => {
                    respFun(questions[4].question_id, "No", "fy");
                    setDisplayNameBool(false);
                    funSetImogies(4, "No");
                  }}
                >
                  <p className="font-helvetica fs-6">No</p>
                </ToggleButton>
              </ButtonGroup>
            </div>
            <div className="my-3">
              <h6 className="font-helvetica fs-5 font-w700">
                {questions && questions.length && questions[5]?.question}
              </h6>
              <ButtonGroup className="d-flex align-items-center gap-3">
                <ToggleButton
                  type="radio"
                  name="radio"
                  className="rounded-5"
                  style={{
                    border:
                      questions[5]?.answerData &&
                        questions[5]?.answerData === "Yes"
                        ? "2px solid #73589b"
                        : "none",
                    background: "#EEFFE8",
                    color: "#73589b",
                  }}
                  onClick={() => {
                    respFun(questions[5]?.question_id, "Yes", "py");
                    setBookAnotherSession(true);
                    funSetImogies(5, "Yes");
                  }}
                >
                  <p className="font-helvetica fs-6">Yes</p>
                </ToggleButton>
                <ToggleButton
                  type="radio"
                  name="radio"
                  className="rounded-5"
                  style={{
                    border:
                      questions[5]?.answerData &&
                        questions[5]?.answerData === "No"
                        ? "2px solid #73589b"
                        : "none",
                    background: "#FFF8E5",
                    color: "#73589b",
                  }}
                  onClick={() => {
                    respFun(questions[5].question_id, "No", "py");
                    setBookAnotherSession(false);
                    funSetImogies(5, "No");
                  }}
                >
                  <p className="font-helvetica fs-6">No</p>
                </ToggleButton>
              </ButtonGroup>
            </div>
            <Row className="my-4 justify-content-around">
              {(isLoading === true && (
                <Button
                  className="border-0 rounded-5 col-5 px-3 py-2"
                  style={{
                    background: "#73589b",
                    boxShadow: "0px 1.06px 2.13px 0px rgba(16, 24, 40, 0.05)",
                  }}
                >
                  <img
                    src="/assets/img/loading.gif"
                    alt="Unitedwecare"
                    style={{ height: 26, width: 26 }}
                  />
                </Button>
              )) ||
                (feedbackAnswers && feedbackAnswers?.length === 0 && (
                  <Button
                    disabled={starValue === 0}
                    onClick={onClickFeedbackSubmit}
                    className="border-0 rounded-5 col-5 px-3 py-2"
                    style={{
                      background: "#73589b",
                      boxShadow: "0px 1.06px 2.13px 0px rgba(16, 24, 40, 0.05)",
                    }}
                  >
                    Submit
                  </Button>
                ))}
            </Row>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Feedback;
