import React, { useEffect, useState } from "react";
import "../styles/MoodMonitor.css";
import { setMoodData } from "../_request";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const mood_emoji = [
  { name: "Angry", emoji: "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FAngry.png?alt=media" },
  { name: "Disgusted", emoji: "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FDisgusted.png?alt=media" },
  { name: "Sad", emoji: "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FSad.png?alt=media" },
  { name: "Happy", emoji: "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FHappy.png?alt=media" },
  { name: "Afraid", emoji: "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FAfraid.png?alt=media" },
  { name: "Surprised", emoji: "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FSurprised.png?alt=media" },
];



const MoodBar = ({ courseID, moodCallback }) => {
  const [mood, setMood] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [showSavedText, setShowSavedText] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;

  const handleMoodClick = (mood) => {
    setCurrentDateTime(new Date());

    const formattedDateTime = `${currentDateTime.getFullYear()}-${(
      currentDateTime.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDateTime
        .getDate()
        .toString()
        .padStart(2, "0")} ${currentDateTime
          .getHours()
          .toString()
          .padStart(2, "0")}:${currentDateTime
            .getMinutes()
            .toString()
            .padStart(2, "0")}:${currentDateTime
              .getSeconds()
              .toString()
              .padStart(2, "0")}`;

    const body = {
      mood: mood,
      user_id: user_id,
      course_id: courseID,
      date_time: formattedDateTime,
    };

    setMoodData(body).then((resp) => {
      if (resp?.ResponseCode == 200) {
        setShowSavedText(true);
        setTimeout(() => {
          setShowSavedText(false);
        }, 2000);
      }
    });
  };

  useEffect(() => {
    if (openAlert) {
      setTimeout(() => {
        setOpenAlert(false);
        moodCallback();
      }, 3000);
    }
  }, [openAlert]);

  return (
    <section className="mood-section">
      <div className="mood-container">
        <div className="mood-heading">How is your mood today?</div>
      </div>
      <div className="mood-emojis">
        {mood_emoji.map((item, index) => (
          <div
            className="mood-emoji"
            onClick={() => {
              handleMoodClick(item.name);
              setOpenAlert(true);
            }}
          >
            <img src={item.emoji} alt="" loading="lazy" className="emoji" />
            <p className="emoji-text">{item.name}</p>
          </div>
        ))}
      </div>

      {openAlert && (
        <Alert
          color="success"
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Thanks for recording your mood. Keep a check on your mood by clicking
          on the Mood Monitor button.
        </Alert>
      )}
    </section>
  );
};

export default MoodBar;
