import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

//components
import AddNewPolicyModal from "./PayorIntegration/modals/AddNewPolicyModal";
import InsuranceDetailCard from "./PayorIntegration/InsuranceDetailCard";
import CompanyList from "./PayorIntegration/modals/CompanyList";

//icons
import AddIcon from "@mui/icons-material/Add";
import VerifiedIcon from "@mui/icons-material/Verified";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Sidebar from "./Sidebar";
import ClaimHistory from "./PayorIntegration/ClaimHistory";
import { IconButton } from "@mui/material";
import EditPolicyModal from "./PayorIntegration/modals/EditPolicyModal";
import { useAuth } from "../../../../auth";

const Insurance = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const userName = currentUser?.Name?.trim()?.split(" ");
  const [memberID, setMemberID] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [openCompanyListModal, setOpenCompanyListModal] = useState(false);
  const [openAddPolicyModal, setOpenAddPolicyModal] = useState(false);
  const [existingPolicy, setExistingPolicy] = useState([]);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookingInformation, setBookingInformation] = useState({
    type: "Video Session",
    date: "20 March 2024",
    time: "09:00 AM (IST)",
    phone: "+91 9876543210",
    duration: "60 Min Individual Session",
    insuranceBenefits: true,
    insuranceList: [
      {
        isSelected: true,
        insuranceName: "HDFC Ergo Health Insurance",
        verfied: true,
        memberID: "28768947657",
        policy: "4094294300",
        color: "green",
      },
    ],
  });
  const [addNewPolicyModal, setAddNewPolicyModal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");

  const [selectedPolicy, setSelectedPolicy] = useState([]);

  // const isFormFilled = selectedCompany && memberID && policyNumber;
  const isFormFilled = selectedCompany && memberID;

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    getInsurance();
  }, []);

  useEffect(() => {
    const filteredPolicies = existingPolicy.filter(
      (policy) => policy.isSelected === true
    );
    setSelectedPolicy(filteredPolicies);
  }, [existingPolicy]);

  const getInsurance = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PAYOR_BASE_URL}/insurance/payor/insurance/${currentUser?.UserId}`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();

      if (Array.isArray(data?.insurance)) {
        const updatedData = data?.insurance?.map((item, index) => {
          return {
            ...item,
            isSelected: index === 0 ? true : false,
          };
        });

        setExistingPolicy(updatedData);
      } else {
        // Handle non-array response
        console.error("Response is not an array:", data);
      }
    } catch (error) {
      console.error("Error fetching insurance data:", error);
    }
  };

  const deletePolicy = async ({ insuranceId }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PAYOR_BASE_URL}/insurance/payor/delete-insurance/${currentUser?.UserId}?insurance_id=${insuranceId}`,
        {
          method: "POST",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete policy");
      }
      const updatedPolicies = existingPolicy.filter(
        (item) => item.insurance_id !== insuranceId
      );
      setExistingPolicy(updatedPolicies);
      if (existingPolicy.find((item) => item.isSelected)) {
        const newSelectedPolicy = updatedPolicies[0];
        setExistingPolicy((prevPolicies) =>
          prevPolicies.map((policy) =>
            policy === newSelectedPolicy
              ? { ...policy, isSelected: true }
              : { ...policy, isSelected: false }
          )
        );
      }
    } catch (error) {
      console.error("Error deleting policy:", error);
    }
  };

  const addPolicy = async () => {
    try {
      const policyData = {
        member_id: memberID,
        insuring_company: selectedCompany?.payor_name,
        payor_identifier: selectedCompany?.payor_id,
        first_name: userName[0],
        last_name: userName.length >= 2 ? userName[userName.length - 1] : "*",
        // policy_number: policyNumber,
        is_primary_member: checked,
      };

      const response = await fetch(
        `${process.env.REACT_APP_PAYOR_BASE_URL}/insurance/payor/add-insurance/${currentUser?.UserId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(policyData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Policy added successfully:", result);

      const newPolicy = {
        ...policyData,
        insurance_id: result.insurance_id,
        isSelected: false,
      };
      setExistingPolicy((prevPolicies) => [...prevPolicies, newPolicy]);
    } catch (error) {
      console.error("Error adding policy:", error);
    }
  };

  const handleSelectCompany = (company) => {
    setSelectedCompany(company);
  };

  const handleAddPolicy = (newPolicy) => {
    setExistingPolicy([...existingPolicy, newPolicy]);
  };

  const handleInsuranceCardClick = (index) => {
    setExistingPolicy((prevPolicies) => {
      const updatedPolicies = prevPolicies.map((policy, i) => {
        if (i === index) {
          return { ...policy, isSelected: true };
        } else {
          return { ...policy, isSelected: false };
        }
      });
      return updatedPolicies;
    });
  };

  const updatePolicyData = (updatedData) => {
    // Update the existing policy
    const updatedExistingPolicy = existingPolicy.map((policy) =>
      policy.insurance_id === updatedData.insurance_id
        ? { ...updatedData, isSelected: true }
        : policy
    );
    setExistingPolicy(updatedExistingPolicy);
  };

  return (
    <div className="Policy--Page">
      {existingPolicy?.length != 0 ? (
        <div className="Exisiting-Policy--Container">
          <div className="Exisitng-Policy--Container__Left ">
            <div
              role="button"
              className="PrimaryCTA mx-auto w-100"
              onClick={() => setOpenAddPolicyModal(true)}
            >
              <AddIcon /> Add New Policy
            </div>
            <AddNewPolicyModal
              open={openAddPolicyModal}
              setOpen={setOpenAddPolicyModal}
              handleAddPolicy={handleAddPolicy} // Pass the function
            />
            <div className="d-flex flex-column my-4">
              {existingPolicy?.map((insurance, index) => (
                <div className="my-2">
                  <InsuranceDetailCard
                    data={insurance}
                    onClick={() => handleInsuranceCardClick(index)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="Exisitng-Policy--Container__Right">
            {selectedPolicy.map((item, index) => (
              <div className="insurance-details">
                <div className="d-flex gap-3">
                  {/* <img
                    src="/assets/img/hdfc_ergo_insurance.png"
                    alt=""
                    loading="lazy"
                  /> */}

                  <div className="card-content my-auto">
                    <div className="insurance-name">
                      {item.insuring_company}
                    </div>
                    <div className="verified">
                      <div className="d-flex gap-2 align-items-center">
                        <VerifiedIcon
                          sx={{ color: "#17B26A" }}
                          fontSize="small"
                        />
                        Verified
                      </div>
                    </div>

                    <div className="d-flex gap-4">
                      <div className="member-id">
                        Member ID <span>{item.member_id}</span>
                      </div>
                      <div className="policy-number">
                        Policy Number <span>{item.policy_number}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-4 align-items-center">
                  {/* <IconButton>
                    <BorderColorOutlinedIcon
                      onClick={() => setEditModalOpen(true)}
                    />
                    <EditPolicyModal
                      open={editModalOpen}
                      setOpen={setEditModalOpen}
                      data={selectedPolicy[0]}
                      updatePolicyData={updatePolicyData}
                    />
                  </IconButton> */}
                  <IconButton>
                    <DeleteOutlineIcon
                      onClick={() =>
                        deletePolicy({ insuranceId: item.insurance_id })
                      }
                    />
                  </IconButton>
                </div>
              </div>
            ))}

            <ClaimHistory />
          </div>
        </div>
      ) : (
        <div className="Add-policy-container">
          <div className="Add-policy-container__Left">
            <div className="mb-2">
              <h6 style={{ color: "#1e1e1e", fontSize: "32px" }}>Add Policy</h6>
              <p
                style={{
                  color: "#344054",
                  fontSize: "18px",
                  fontWeight: "400",
                }}
              >
                Enter your policy details
              </p>
            </div>
            <div>
              <FormLabel className="fw-normal text-black">
                Insurance Company Name <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <FormControl fullWidth className="my-2">
                <TextField
                  hiddenLabel
                  fullWidth
                  variant="outlined"
                  placeholder="Select Insurance Company"
                  value={selectedCompany?.payor_name} // Make sure this is linked to your state
                  onClick={() => setOpenCompanyListModal(true)} // Ensure this opens your modal
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
              <CompanyList
                open={openCompanyListModal}
                setOpen={setOpenCompanyListModal}
                onSelect={handleSelectCompany} // Pass the function that handles selection
              />

              <FormControl fullWidth className="my-2">
                <FormLabel className="fw-normal text-black my-1">
                  Member Id <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  hiddenLabel
                  type="number"
                  placeholder="Enter Member ID"
                  onChange={(e) => setMemberID(e.target.value)}
                  required
                />
              </FormControl>

              {/* <FormControl fullWidth className="my-2">
                <FormLabel className="fw-normal text-black my-1">
                  Policy Number <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  hiddenLabel
                  type="number"
                  placeholder="Enter Policy Number"
                  onChange={(e) => setPolicyNumber(e.target.value)}
                  required
                />
              </FormControl> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "20px 0px",
                }}
              >
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <p>
                  Are you the primary member{" "}
                  <span style={{ color: "red" }}>*</span>
                </p>
              </div>

              <Button
                fullWidth
                disabled={!isFormFilled}
                className="PrimaryCTA mt-4"
                onClick={addPolicy}
              >
                {loading ? (
                  <CircularProgress
                    disableShrink
                    style={{ width: "30px", height: "30px", color: "#73589b" }}
                  />
                ) : (
                  "Add"
                )}
              </Button>
            </div>
          </div>
          <div className="Add-policy-container__Right">
            <img
              src="/assets/img/add-policy-img.png"
              alt=""
              className="add-policy-img"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Insurance;
