import React, { Component, useState } from "react";
import AppRoutes from "./routing/AppRoutes";
import { for_location, getCurrency } from "./components/core/_request";
import Cookies from "universal-cookie";
import { AppContext } from "./context";
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

// function App() {
//   const [currency_data, setCurrency_Data] = useState([]);
//   const [location_data, setLocation_Data] = useState({});
//   useEffect(() => {
//     if(!localStorage.getItem("Curny"))
//     {
//       getCurrency().then((resp) =>
//         setCurrency_Data(resp.data)
//       );
//       // const request_Currency = getCurrency().then((resp) =>
//       //   setCurrency_Data(resp.data)
//       // );
//       // const request_location = for_location().then((resp) =>
//       //   setLocation_Data(resp)
//       // );
//       // Promise.all([request_Currency, request_location]);
//     }
//   }, []);

//   useEffect(() => {
//       // if (location_data && currency_data && currency_data.length > 0) {
//       if (currency_data && currency_data.length > 0) {
//         if(!localStorage.getItem("Curny"))
//         {
//           //if (location_data?.countryCode === "IN") {
//             var INR_currency = currency_data.find((o) => o.Currency === "INR");
//             var ciphertext = JSON.stringify(INR_currency);
//             // var ciphertext = encode(JSON.stringify(INR_currency));
//             localStorage.setItem("Curny", ciphertext);
//           // } else {
//           //   var USD_currency = currency_data.find((o) => o.Currency === "USD");
//           //   var ciphertext1 = encode(JSON.stringify(USD_currency));
//           //   localStorage.setItem("Curny", ciphertext1);
//           // }
//         }
//       }
//   }, [currency_data]);

//   return <AppRoutes />;
// }

export default class App extends Component {
  componentDidMount = () => {
    const cookies = new Cookies();
    var countryCode = cookies.get("countryCode");

    if (countryCode == null) {
      for_location()
        .then((resp) => {
          if (resp && resp.countryCode) {
            cookies.set("countryCode", resp.countryCode, { path: "/" });
            this.setState({ countryCode: resp.countryCode });
          } else {
            cookies.set("countryCode", "IN", { path: "/" }); //If error
            this.setState({ countryCode: "IN" });
          }
          window.location.reload();
        })
        .catch(() => {
          cookies.set("countryCode", "IN", { path: "/" });
          this.setState({ countryCode: "IN" });
          window.location.reload();
        });
    }
    // var Curny = localStorage.getItem("Curny");
    // if(Curny==null){
    //   getCurrency().then((resp) =>{

    //   if(resp?.data){
    //     for_location().then((resp2) =>{
    //       if (resp2?.countryCode == "IN") {
    //         var INR_currency = resp?.data.find((o) => o.Currency == "INR");
    //         var ciphertext = JSON.stringify(INR_currency);
    //         localStorage.setItem("Curny", ciphertext);
    //       }
    //       else{
    //         var USD_currency = resp?.data.find((o) => o.Currency == "USD");
    //         var ciphertext = JSON.stringify(USD_currency);
    //         localStorage.setItem("Curny", ciphertext);
    //       }
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 2000);
    //     })
    //   }
    //   });
    // }
  };

  render() {
    return <AppView />;
  }
}

const AppView = (props) => {
  const [ShowLloginPopup, setShowLloginPopup] = useState(0);
  const [expertDashboardData, setExpertDashboardData] = useState({});
  return (
    <SnackbarProvider maxSnack={3}
      action={(key) => (
        <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </IconButton>
      )}
    >
      <AppContext.Provider
        value={{
          ShowLloginPopup,
          setShowLloginPopup,
          expertDashboardData,
          setExpertDashboardData,
        }}
      >
        <AppRoutes />
      </AppContext.Provider>
    </SnackbarProvider>
  );
};
