import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";

//icons
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { findPayorByName, insurance_ist } from "../../../../../core/commonFun";

// const insuranceCompaniesStaticList = [
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "Aditya Birla Health Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "Bajaj Allianz General Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "Care Health Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "Cholamandalam MS General Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "Go Digit General Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "HDFC ERGO General Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "ICICI Lombard General Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "IFFCO Tokio General Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "Kotak Mahindra General Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "Liberty General Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "ManipalCigna Health Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "Max Bupa Health Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "Niva Bupa Health Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "Reliance General Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "Royal Sundaram General Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "SBI General Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "Star Health and Allied Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "The New India Assurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "Tata AIG General Insurance",
//   },
//   {
//     image: "/assets/img/hdfc_ergo_insurance.png",
//     name: "United India Insurance",
//   },
// ];

const CompanyList = ({ open, setOpen, onSelect }) => {
  const [addNewPolicyModal, setAddNewPolicyModal] = useState(false);
  const [knowMoreModal, setKnowMoreModal] = useState(false);
  const [company, setCompany] = useState("");
  const [memberID, setMemberID] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [search_key, setSearchKey] = useState("");
  const [companyIdentificationList, setCompanyIdentificationList] = useState([
    "NAIC Number",
    "National Provider Identifier (NPI)",
    "D-U-N-S Number",
    "Federal Tax Identification Number (EIN)",
  ]);

  const [companyIdentification, setCompanyIdentification] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);

  const isFormFilled =
    company && companyIdentification && memberID && policyNumber;

  useEffect(() => {
    getInsuranceCompanies();
  }, []);

  const getInsuranceCompanies = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PAYOR_BASE_URL}/insurance/payor/get-payor-list?start=1&step=903`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      console.log("getInsuranceCompanies data", data);
      setInsuranceCompanies(data.payors);
    } catch (error) {
      console.error("Error fetching insurance companies:", error);
    }
  };

  const handleSelect = (company) => {
    console.log("Handling select in CompanyList", company);
    onSelect(company); // Assuming 'name' is the correct attribute
    setOpen(false);
  };

  const onChangeHandler = (e) => {
    setSearchKey(e.target.value);
  };

  useEffect(() => {
    if (search_key && search_key !== "") {
      const searched_filtered_Data = findPayorByName(search_key, insurance_ist);
      setInsuranceCompanies(searched_filtered_Data);
    }
  }, [search_key]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="Company-list-modal"
      >
        <DialogTitle>Choose Company</DialogTitle>
        <div style={{padding:"0px 24px"}}>
          <TextField
            hiddenLabel
            fullWidth
            value={search_key}
            onChange={onChangeHandler}
            variant="filled"
            placeholder="Search Insurance Company"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            sx={{ borderRadius: "10px" }}
          />
          <div
            role="button"
            className="d-flex align-items-center gap-2 my-2 p-2"
            onClick={() => {
              setAddNewPolicyModal(true);
              // setOpen(false);
            }}
          >
            <AddBoxOutlinedIcon /> Add Insurance Company
          </div>
        </div>

        <DialogContent>
          <div>
            {insuranceCompanies?.map((company, index) => (
              <div
                role="button"
                className="d-flex align-items-center gap-2 my-4"
                onClick={() => handleSelect(company)}
              >
                {/* <img
                  src={company.image}
                  alt=""
                  loading="lazy"
                  className="rounded-circle"
                /> */}
                {company?.payor_name}
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={addNewPolicyModal}
        onClose={() => setAddNewPolicyModal(false)}
        className="Add-Policy-Modal"
      >
        <DialogContent>
          <h6 style={{ color: "#1e1e1e", fontSize: "22px" }}>Add Policy</h6>
          <p
            style={{
              color: "#344054",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Enter your policy details
          </p>
        </DialogContent>

        <DialogContent>
          <FormControl fullWidth className="my-2">
            <FormLabel className="fw-normal text-black">
              Insurance Company Name <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              hiddenLabel
              type="number"
              placeholder="Enter Company Name"
              onChange={(e) => setCompany(e.target.value)}
              required
            />
          </FormControl>

          <FormLabel className="fw-normal text-black">
            Company Identification Type{" "}
            <span
              onClick={() => {
                setKnowMoreModal(true);
              }}
            >
              <HelpOutlinedIcon />
            </span>
          </FormLabel>
          <FormControl fullWidth className="my-2">
            <InputLabel>Select Unique Identification</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={companyIdentification}
              onChange={(e) => setCompanyIdentification(e.target.value)}
            >
              {companyIdentificationList.map((item, index) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {companyIdentification && (
            <FormControl fullWidth className="my-2">
              <FormLabel className="fw-normal text-black">
                {companyIdentification}
              </FormLabel>
              <TextField
                hiddenLabel
                type="number"
                fullWidth
                placeholder={`Enter ${companyIdentification}`}
                required
                onChange={(e) => setIdentifier(e.target.value)}
              />
            </FormControl>
          )}

          <FormControl fullWidth className="my-2">
            <FormLabel className="fw-normal text-black">
              Member Id <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              hiddenLabel
              type="number"
              placeholder="Enter Member ID"
              onChange={(e) => setMemberID(e.target.value)}
              required
            />
          </FormControl>

          <FormControl fullWidth className="my-2">
            <FormLabel className="fw-normal text-black">
              Policy Number <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              hiddenLabel
              type="number"
              placeholder="Enter Policy Number"
              onChange={(e) => setPolicyNumber(e.target.value)}
              required
            />
          </FormControl>

          <Button
            fullWidth
            disabled={!isFormFilled}
            className="PrimaryCTA mt-4"
          >
            Add
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        open={knowMoreModal}
        onClose={() => setKnowMoreModal(false)}
        className="Read-More-Modal"
      >
        <DialogTitle id="read-more-dialog-title">
          <div className="close-button">
            <IconButton
              aria-label="close"
              onClick={() => setKnowMoreModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="p-3">
            <p className="fw-semibold lh-lg">What to Know:</p>
            <p>
              A unique identifier (like an NAIC number) precisely identifies an
              insurance company, reducing errors in service processing.
            </p>
            <br />
            <p className="fw-semibold lh-lg">Why It's Key:</p>
            <p>
              Correct identification ensures accurate verification and billing,
              crucial for your coverage and claims.
            </p>
            <br />
            <p className="fw-semibold lh-lg">Entry Tips:</p>
            <p>
              Verify the insurer’s name and ID against your insurance card or
              official documents. Use the insurer’s exact name as listed, and
              refer to the NAIC database for the unique ID if needed.
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CompanyList;
