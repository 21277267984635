import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import DetailAboutTab from "./DetailAboutTab";
import DetailTestimonialTab from "./DetailTestimonialTab";
import DetailPostTab from "./DetailPostTab";
import DetailArticlesTab from "./DetailArticlesTab";
import DetailPodcastsTab from "./DetailPodcastsTab";
import { useAuth } from "../auth";
import { Link, useNavigate } from "react-router-dom";
import { OODOGetUserFeedV4Service, getExpertRating } from "./core/_request";
import "./ExpertFilterDrawer.css";
import { Skeleton } from "@mui/material";

const DetailPageTabs = (props) => {
  const { data, loading } = props;
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [postData, setPostData] = useState();
  const [articleData, setArticleData] = useState();
  const [videoData, setVideoData] = useState();
  const [audioData, setAudioData] = useState();
  const [ratingData, setRatingData] = useState();
  const [defaultActiveKey, setDefaultActiveKey] = useState("about");
  var ExpertisesArrayAll = data?.Expertise?.split(",");
  var licensedStateArray = data?.licensed_practice_in_state?.split(",");
  console.log("data", data);
  useEffect(() => {
    // console.log("data?.Id", data)
    getExpertRating(data?.Id).then((resp) => {
      var data = resp?.data;
      setRatingData(data);
      // console.log("setRatingData", data)
    });
  }, []);

  const onTabClick = (activeKey) => {
    setDefaultActiveKey(activeKey);
    // if (!currentUser) {
    //     navigate("/login?retrunurl=/detail/" + data?.Id)
    // }
  };

  // useState(() => {
  //   if (currentUser && data) {
  //     OODOGetUserFeedV4Service(
  //       currentUser?.UserId,
  //       data?.Id,
  //       "",
  //       "",
  //       "post",
  //       "",
  //       0,
  //       10,
  //       "id desc"
  //     ).then((resp) => {
  //       setPostData(resp?.data);
  //     });
  //     OODOGetUserFeedV4Service(
  //       currentUser?.UserId,
  //       data?.Id,
  //       "",
  //       "",
  //       "article",
  //       "",
  //       0,
  //       10,
  //       "id desc"
  //     ).then((resp) => {
  //       setArticleData(resp?.data);
  //     });
  //     OODOGetUserFeedV4Service(
  //       currentUser?.UserId,
  //       data?.Id,
  //       "",
  //       "",
  //       "video",
  //       "",
  //       0,
  //       10,
  //       "id desc"
  //     ).then((resp) => {
  //       setVideoData(resp?.data);
  //     });
  //     OODOGetUserFeedV4Service(
  //       currentUser?.UserId,
  //       data?.Id,
  //       "",
  //       "",
  //       "audio",
  //       "",
  //       0,
  //       10,
  //       "id desc"
  //     ).then((resp) => {
  //       setAudioData(resp?.data);
  //     });
  //   }
  // }, []);

  return (
    <div
      className="bg-white detailPage--Container rounded-3"
    >
      <Tabs
        defaultActiveKey={defaultActiveKey}
        activeKey={defaultActiveKey}
        id="controlled-tab-example"
        className="text-black"
        style={{ background: "#F7F8FA", maxWidth: 'max-content' }}
        onSelect={onTabClick}
      >
        <Tab eventKey="about" title="About" className="px-3 tab-heading py-2">
          {(data && (
            <DetailAboutTab
              data={{
                ...data,
                ratingData: ratingData,
                licensedStateArray: licensedStateArray,
              }}
              changeTab={onTabClick}
              loading={loading}
            />
          )) || <div>loading...</div>}
        </Tab>
        {/* <Tab eventKey="testimonial" title="Testimonial" className="px-3 tab-heading py-3">
                    <DetailTestimonialTab />
                </Tab> */}
        {/* {postData && postData?.feed && postData?.feed?.length > 0 && <Tab eventKey="post" title="Post" className="px-3 tab-heading py-3">
                    <DetailPostTab data={postData} type="post" />
                </Tab>}
                {articleData && articleData?.feed && articleData?.feed?.length > 0 && <Tab eventKey="articles" title="Articles" className="px-3 tab-heading py-3" >
                    <DetailArticlesTab data={articleData} type="article" />
                </Tab>}
                {videoData && videoData?.feed && videoData?.feed?.length > 0 && <Tab eventKey="videos" title="Videos" className="px-3 tab-heading py-3" >
                    <DetailPostTab data={videoData} type="video" />
                </Tab>}
                {audioData && audioData?.feed && audioData?.feed?.length > 0 && <Tab eventKey="podcasts" title="Podcasts" className="px-3 tab-heading py-3" >
                    <DetailPodcastsTab data={audioData} type="audio" />
                </Tab>} */}

        {/* {ExpertisesArrayAll.length >= 1 ?
                    <Tab eventKey="expertise" title="Area of Interest" className="px-3 tab-heading py-3" >
                        <p className='font-inter fs-5 font-w500 my-3 text-black d-flex align-items-center flex-wrap'>
                            {
                                ExpertisesArrayAll && ExpertisesArrayAll.map((Expertise) => (
                                    <span className='font-inter font-w500 fs-6 rounded-2 me-2 my-1 px-1 py-md-1 my-md-1' style={{ border: "1px solid #60B4E7CC", width: "auto" }}> {Expertise}</span>
                                ))
                            }
                        </p>
                    </Tab>
                    :
                    null
                } */}

        {ratingData?.length >= 1 && (
          <Tab
            eventKey="reviews"
            title="Reviews"
            className="px-3 tab-heading py-3"
          >
            {/* <DetailPodcastsTab data={audioData} type="audio" /> */}
            {!loading ? (
              ratingData &&
              ratingData?.map((rating) => (
                <div className="my-3">
                  <div className="d-flex align-items-center gap-3 my-3">
                    <img src="/assets/img/Ellipse 1.png" alt="" />
                    <p className="font-inter fs-6 font-w500 text-black text-capitalize lh-sm">
                      {rating?.UserName} <br />
                      <span className="font-inter fs-6 font-w400">
                        {rating?.UserCountry}
                      </span>
                    </p>
                  </div>
                  <p className="font-inter fs-6">
                    {rating?.Message}
                    {/* As a self-harm patient, I cannot express enough gratitude for the exceptional care and attention I received from you as my psychologist. Your unwavering support and guidance have been instrumental in helping me overcome the struggles I have faced in my life. */}
                  </p>
                  <hr />
                </div>
              ))
            ) : (
              <div>
                <div className="d-flex flex-column gap-1">
                  {Array.from(new Array(ratingData?.length)).map((_, index) => (
                    <div>
                      <div className="d-flex flex-row align-items-center gap-3 my-1">
                        <Skeleton variant="circular" width={50} height={50} />
                        <p className="font-inter fs-6 font-w500 text-black text-capitalize lh-sm">
                          <Skeleton variant="text" width="5vw" />
                          <span className="font-inter fs-6 font-w400">
                            <Skeleton variant="text" width="5vw" />
                          </span>
                        </p>
                      </div>
                      <p className="font-inter fs-6">
                        <Skeleton variant="text" width="40vw" />
                        <Skeleton variant="text" width="40vw" />
                        {/* As a self-harm patient, I cannot express enough gratitude for the exceptional care and attention I received from you as my psychologist. Your unwavering support and guidance have been instrumental in helping me overcome the struggles I have faced in my life. */}
                      </p>
                      <hr />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Tab>
        )}
        {data?.licensed_practice_in_state?.length >= 1 && (
          <Tab
            eventKey="practiceState"
            title="Licenced to practice in States"
            className="px-3 tab-heading py-3"
          >
            {/* <DetailPodcastsTab data={audioData} type="audio" /> */}
            <p className="font-inter fs-5 font-w500 my-3 text-black d-flex align-items-center flex-wrap">
              {licensedStateArray &&
                licensedStateArray.map((Expertise) => (
                  <span
                    className="font-inter font-w500 fs-6 rounded-2 me-2 my-1 px-1 py-md-1 my-md-1"
                    style={{
                      border: "1px solid var(--button-primary-color)",
                      width: "auto",
                    }}
                  >
                    {" "}
                    {Expertise}
                  </span>
                ))}
            </p>
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default DetailPageTabs;
