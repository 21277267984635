import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, ModalFooter } from "react-bootstrap";
import { useAuth } from "../auth";
import { Link, useNavigate } from "react-router-dom";
import {
  CheckGoogleLogin,
  CheckReferralCode,
  LoginByOTPService,
  OODOCreateUserV4Service,
  OODOGetCountryListV4Service,
  OODOGetTimezoneV4Service,
  RegistrationByOTPV4Service,
  SendRequestOTPService,
} from "./core/_request";
import SignupComponent from "./SignupComponent";
import SignUpDrawer from "./SignUpDrawer";
import { AppContext } from "../context";
import { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import CloseButton from "react-bootstrap/CloseButton";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import MenuItem from "@mui/material/MenuItem";
import DisclaimerModal from "./DisclaimerModal";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "./SignUp.css";
import "./SignIn.css";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";

export const SignInDrawer = () => {
  const [show, setShow] = useState(false);
  const [otpModalShow, setOtpModalShow] = useState(false);
  const { login, setCurrentUser, userType } = useAuth();
  const [step, setStep] = useState(1);
  const [validationMessage, setValidationMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMailRegistered, setIsMailRegistered] = useState(false);
  const [signeInSigneUp, setSignInSignUp] = useState("signIn");
  const [data, setData] = useState({ OTP: "" });
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);
  const [otpSent, setOtpSent] = useState(false);
  // const [counter, setCounter] = useState(0);
  const count_time = process.env.REACT_APP_OTP_TIME;
  const [credentialCode, setCredentialCode] = useState();
  const [decodedResponse, setDecodedResponse] = useState();
  const [countryList, setCountryList] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [validReferralCode, setValidReferralCode] = useState();
  const [referralCodeMessage, setReferralCodeMessage] = useState("");
  const [detailModalShow, setdetailModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [disclaimer, setDisclaimer] = useState();
  const [resendOtpSent, setResendOtpSent] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [typeOfUser, setTypeOfUser] = useState();
  const [counter, setCounter] = useState(30); // Initial countdown value in seconds
  const [resendAllowed, setResendAllowed] = useState(true); // Flag to control resend button
  const { enqueueSnackbar } = useSnackbar();
  const authToken = localStorage.getItem("Authorization")?.split("Bearer ")[1];

  const onTalkToStella = () => {
    window.open(
      `https://stella.unitedwecare.com/?token=${authToken}&appURL=${encodeURI(
        `https://us.unitedwecare.com`
      )}`
    );
  };

  useEffect(() => {
    if (otpModalShow) {
      // Focus the first OTP input field
      inputRefs.current[0].focus();
    }
  }, [otpModalShow]);

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000); // Decrease counter every second
    } else if (counter === 0) {
      setResendAllowed(true); // Enable resend button when countdown reaches 0
    }
    return () => clearTimeout(timer);
  }, [counter]);

  useEffect(() => {
    OODOGetCountryListV4Service().then((resp) =>
      setCountryList(resp?.data?.countries)
    );
  }, []);

  const handleChange = (index, value) => {
    if (!isNaN(value) && value.length === 1) {
      // Use the callback form of setOtp to ensure you get the latest state
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        setData({ ...data, OTP: newOtp.join("") }); // Join the array to form the final OTP
        return newOtp;
      });

      // Move to the next input
      if (index < 3 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    // Move to the previous input on backspace
    if (e.key === "Backspace") {
      const newOtp = [...otp];

      // If the current input is empty, move to the previous input
      if (index > 0 && !otp[index]) {
        newOtp[index - 1] = "";
        inputRefs.current[index - 1].focus();
      } else {
        newOtp[index] = "";
      }

      setOtp(newOtp);
      setData((prevData) => ({
        ...prevData,
        OTP: newOtp.join(""),
      })); // Join the array to form the final OTP
    } else if (e.key === "Enter") {
      e.preventDefault();
      onSubmitOtp();
    } else if (!/^\d$/.test(e.key)) {
      // Prevent input for non-numeric keys
      e.preventDefault();
    }
  };

  const onCountryChange = (value) => {
    // debugger
    var item = countryList?.find((o) => o.id == value);
    if (item) {
      setData({ ...data, countryId: value, country: item?.name });
      setLoading(true);
      var body = {
        code: item?.code,
      };
      OODOGetTimezoneV4Service(body).then((resp) => {
        //console.log("OODOGetTimezoneV4Service resp ", JSON.stringify(resp));
        setTimezoneList(resp?.data?.timezones);
        setLoading(false);
      });
      setLoading(false);
    } else {
      setTimezoneList([]);
      setData({ ...data, timezoneId: null, timezoneName: null });
    }
  };

  const onTimezoneChange = (value) => {
    const item = timezoneList.find((o) => o.id == value);
    if (item) {
      setData({ ...data, timezoneId: value, timezoneName: item?.name });
    } else {
      setData({ ...data, timezoneId: null, timezoneName: null });
    }
  };

  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const navigate = useNavigate();
  //const{retrunurl}=useParams();
  const retrunurl = null;
  const params = new URLSearchParams(window ? window.location.search : {});
  // useEffect(()=>{
  //console.log("params", params.get("retrunurl"))
  // },[]);

  const HandelReferalCodeChange = (e) => {
    const textWithoutSpaces = e.target.value.replace(/\s/g, ""); // Remove spaces
    setValidReferralCode(textWithoutSpaces);
  };

  const resendOTP = () => {
    setOtp(["", "", "", ""]);
    inputRefs.current[0].focus();
    let newValidationMessage = Object.assign(
      [],
      [
        ...validationMessage.filter((o) => o.key != "OTP"),
        { key: "OTP", message: "" },
      ]
    );
    setValidationMessage(newValidationMessage);
    setOtpSent(true);
    setCounter(count_time);
    // debugger
    var req = { Email: data?.Email };
    SendRequestOTPService(req).then((resp) => {
      setLoading(false);
      //console.log("SendRequestOTPService resp", resp);
      if (resp != null && resp.ResponseCode == 200) {
        setResendAllowed(false); // Disable resend button
        setCounter(30);
        let newValidationMessage = Object.assign(
          [],
          [
            ...validationMessage.filter((o) => o.key != "OTP"),
            {
              key: "OTP",
              message: (
                <span>
                  <CheckCircleOutlineIcon />
                  An OTP has been resent to you.
                </span>
              ),
            },
          ]
        );
        setValidationMessage(newValidationMessage);
        setResendOtpSent(true);
        setOtpSent(true);
        setCounter(count_time);
        setStep(2);
      } else if (resp != null && resp.ResponseCode == 401) {
        //console.log("ResponseCode 401");
      }
    });
  };

  const onLogin = () => {
    setCounter(30);
    setValidationMessage([]);
    setLoading(true);
    var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (!pattern.test(data?.Email)) {
      setValidEmail(false);
      let newValidationMessage = Object.assign(
        [],
        [
          ...validationMessage.filter((o) => o.key != "Email"),
          { key: "Email", message: "Invalid email" },
        ]
      );
      setValidationMessage(newValidationMessage);
      setLoading(false);
    } else {
      var req = { Email: data?.Email };
      SendRequestOTPService(req).then((resp) => {
        setLoading(false);
        //console.log("SendRequestOTPService resp", resp);
        if (resp != null && resp.ResponseCode == 200) {
          setIsMailRegistered(resp?.data?.IsMailRegistered);
          if (resp?.data?.IsMailRegistered) {
            // setStep(2);
            setOtpSent(true);
            setCounter(count_time);
            setOtpModalShow(true);
          } else {
            toast("Email address does not exist");
            setTimeout(() => {
              setShowLloginPopup(false);
              navigate("/create-account", { step: 3 });
            }, 2000);
          }
        } else if (resp != null && resp.ResponseCode == 401) {
          //console.log("ResponseCode 401");
        }
      });
    }
  };

  const onSubmitOtp = () => {
    var req = {
      Email: data.Email,
      OTP: data?.OTP,
    };
    LoginByOTPService(req).then((resp) => {
      // debugger
      //console.log("LoginByOTPService resp", resp);
      if (resp != null && resp?.ResponseCode == 200) {
        if (resp?.data?.IsLogin) {
          let UserType = resp?.data?.UserType;
          let profile = resp?.data?.userdetails;
          profile.UserType = UserType;

          let now = new Date();
          let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
          let firstName = resp?.data?.userdetails?.Name.split(" ")
            .slice(0, 1)
            .join("");
          document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toISOString()};domain=.unitedwecare.com;path=/;`;
          if (resp?.data?.userdetails.UserType == 4) {
            let typeUser = "user";
            document.cookie = `userType=${typeUser};domain=.unitedwecare.com;path=/;`;
          } else {
            let typeUser = "expert";
            document.cookie = `userType=${typeUser};domain=.unitedwecare.com;path=/;`;
          }

          login(resp?.data);
          setCurrentUser(profile);
          //localStorage.setItem("Authorization", resp.data.token_type + " " + resp.data.access_token);
          //localStorage.setItem("profile", JSON.stringify(profile));

          var retrunurl_local = localStorage.getItem("retrunurl");
          const retrunurl = params.get("retrunurl") || retrunurl_local;
          setShowLloginPopup(false);
          if (retrunurl) {
            //window.location.href=retrunurl;
            localStorage.removeItem("retrunurl");
            navigate(retrunurl);
          } else {
            if (
              sessionStorage.getItem("Login-Invoked") !== undefined &&
              sessionStorage.getItem("Login-Invoked") !== null &&
              sessionStorage.getItem("Login-Invoked").includes("/stella")
            ) {
              onTalkToStella();
              navigate("/");
              sessionStorage.removeItem("Login-Invoked");
            } else {
              if (profile.UserType == userType.ENDUSER) {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                let stellaURL = urlParams.get("stella");

                //console.log("params", params.get("stella"))
                let isStellaPage = false;
                const locationParts = window.location.href
                  .split("?")[0]
                  .split("/");
                locationParts.map((item) => {
                  if (item === "stella") {
                    isStellaPage = true;
                  }
                });

                if (isStellaPage) {
                  window.location.href = window.location.href;
                } else {
                  navigate("/user-dashboard");
                  // window.location.reload()
                }
              } else if (profile.UserType == userType.EXPERT) {
                navigate("/expert/dashboard");
              } else {
                navigate("/user-dashboard");
              }
            }
          }
        } else {
          setLoading(false);
          //setStep(3);
          setShowLloginPopup(false);
          navigate("/create-account", { step: 3 });
        }
      } else {
        setResendOtpSent(false);
        let newValidationMessage = Object.assign(
          [],
          [
            ...validationMessage.filter((o) => o.key != "OTP"),
            {
              key: "OTP",
              message: (
                <span>
                  <ErrorOutlineIcon /> Incorrect OTP, Please try again
                </span>
              ),
            },
          ]
        );
        setValidationMessage(newValidationMessage);
        setLoading(false);
      }
    });
  };

  const onClickSignUp = () => {
    navigate(
      `/create-account${
        params.get("retrunurl") ? `?retrunurl=${params.get("retrunurl")}` : ""
      }`
    );
    setShowLloginPopup(false);
  };

  const onEmailChange = (e) => {
    // debugger
    if (e.key === "Enter") {
      e.preventDefault();
      onLogin();
    } else {
      setData((prevData) => ({
        ...prevData,
        Email: e?.target?.value.replace(/\s/g, ""),
      }));
    }
  };

  const onSwithView = (viewName) => setSignInSignUp(viewName);

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 5) {
        elmnt.target?.form?.elements[next]?.focus();
      }
    }
  };

  const onGoogleLoginSuccess = (credentialResponse) => {
    console.log("Login Success:", credentialResponse);
    setCredentialCode(credentialResponse?.credential); // Set the credential code received
  };

  useEffect(() => {
    if (credentialCode) {
      try {
        const decoded = jwt_decode(credentialCode);
        setDecodedResponse(decoded);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [credentialCode]);

  useEffect(() => {
    if (decodedResponse) {
      setData((prevData) => ({
        ...prevData,
        Email: decodedResponse?.email,
        OTP: 7777,
        firstName: decodedResponse?.given_name,
        lastName: decodedResponse?.family_name,
        Name: decodedResponse?.name,
      }));

      setValidationMessage([]);
      setLoading(true);

      var body123 = {
        Emailid: decodedResponse?.email,
        SocialType: "google",
        Name: decodedResponse?.name,
      };
      CheckGoogleLogin(body123).then((resp) => {
        setLoading(false);
        //console.log("SendRequestOTPService resp", resp);
        if (resp != null && resp?.ResponseCode == 200) {
          if (resp?.data != null) {
            let UserType = resp?.data?.UserType;
            let profile = resp?.data?.userdetails;
            profile.UserType = UserType;
            login(resp?.data);
            setCurrentUser(profile);
            //localStorage.setItem("Authorization", resp.data.token_type + " " + resp.data.access_token);
            //localStorage.setItem("profile", JSON.stringify(profile));

            var retrunurl_local = localStorage.getItem("retrunurl");
            const retrunurl = params.get("retrunurl") || retrunurl_local;
            setShowLloginPopup(false);
            if (retrunurl) {
              //window.location.href=retrunurl;
              localStorage.removeItem("retrunurl");
              navigate(retrunurl);
            } else {
              if (
                sessionStorage.getItem("Login-Invoked") !== undefined &&
                sessionStorage.getItem("Login-Invoked") !== null &&
                sessionStorage.getItem("Login-Invoked").includes("/stella")
              ) {
                onTalkToStella();
                navigate("/");
                sessionStorage.removeItem("Login-Invoked");
              } else {
                if (profile.UserType == userType.ENDUSER) {
                  const queryString = window.location.search;
                  const urlParams = new URLSearchParams(queryString);
                  let stellaURL = urlParams.get("stella");

                  //console.log("params", params.get("stella"))
                  let isStellaPage = false;
                  const locationParts = window.location.href
                    .split("?")[0]
                    .split("/");
                  locationParts.map((item) => {
                    if (item === "stella") {
                      isStellaPage = true;
                    }
                  });

                  if (isStellaPage) {
                    window.location.href = window.location.href;
                  } else {
                    navigate("/user-dashboard");
                    // window.location.reload()
                  }
                } else if (profile.UserType == userType.EXPERT) {
                  navigate("/expert/dashboard");
                } else {
                  navigate("/user-dashboard");
                }
              }
            }
          } else {
            setLoading(false);
            //setStep(3);
            setShowLloginPopup(false);
            navigate("/create-account", { step: 3 });
          }
        } else {
          let newValidationMessage = Object.assign(
            [],
            [
              ...validationMessage.filter((o) => o.key != "OTP"),
              { key: "Google login", message: "Something went wrong" },
            ]
          );
          setValidationMessage(newValidationMessage);
          setLoading(false);
        }
      });
    }
  }, [decodedResponse]);

  const onDetailSubmit = () => {
    setLoading(true);
    if (validReferralCode) {
      CheckReferralCode(validReferralCode).then((resp) => {
        if (resp.ResponseCode === 200) {
          // debugger
          setReferralCodeMessage("Referral code Applied");
          setLoading(false);
          setData({ ...data, referralCode: validReferralCode });
          console.log("****dfdfd", validReferralCode, data);
          //onLogin();
          // setData({ ...data, Referral_Code: referralCode })
          createRegistration();
          setValidationMessage([]);
          setLoading(true);
        }
      });
    } else {
      // debugger
      setReferralCodeMessage("Referral code doesn't exist");
      enqueueSnackbar("Referral code doesn't exist", { variant: "error" });
      //setValidReferralCode('')
      setData({ ...data, Referral_Code: null });
      setLoading(false);
    }
  };

  const createRegistration = () => {
    // debugger
    setLoading(true);
    //const{SocialID, SocialType}=this.props.route.params??{};
    const SocialID = null;
    const SocialType = null;

    var MobileNo = "";
    if (data?.country == "India") {
      MobileNo = "+91 0000000000";
    } else if (data?.country == "Canada") {
      MobileNo = "+1 0000000000";
    } else {
      MobileNo = "+00 0000000000";
    }
    var name = "";
    if (data?.firstName != null && data?.firstName != "") {
      // name = data.firstName + " " + data?.lastName;
      name = data?.firstName;
    } else {
      name = "user";
      setData({ data: { ...data, firstName: "user", lastName: " " } });
    }

    //SocialID:appleAuthRequestResponse.user, SocialType:"apple"
    var req = {
      Emailid: data?.Email,
      Title: "`",
      Name: name,
      MobileNo: MobileNo,
      ReferralCode: null,
      Referral_Code: data?.referralCode,
      Country: data?.country,
      UserType: 4,
      TimeZone: data?.timezoneName,
      SocialID,
      SocialType,
    };

    //console.log(req);

    //var req={"Email":"demo555@tmail.com","OTP":"7777","firstName":"test","lastName":"testest","countryId":"37","country":"Belize","timezoneId":"America/Belize","timezoneName":"America/Belize","email":"demo555@tmail.com","userId":30493,"device_type":"web"};

    //console.log("RegistrationByOTPV4Service req", req);
    RegistrationByOTPV4Service(req).then((resp) => {
      if (resp != null && resp.ResponseCode == 200) {
        //console.log("RegistrationByOTPV4Service resp", resp);

        let UserType = resp?.data?.UserType;
        let profile = resp?.data?.userdetails;
        profile.UserType = UserType;

        if (
          resp?.data?.userdetails?.Country == null &&
          resp?.data?.userdetails?.MobileNo.includes("+91")
        ) {
          profile.Country = "India";
        } else if (
          resp?.data?.userdetails?.Country == null &&
          resp?.data?.userdetails?.MobileNo.includes("+1")
        ) {
          profile.Country = "Canada";
        }
        //this.setState({profile, data:{...data, email:profile.Emailid, userId:profile.UserId}});

        login(resp?.data);
        setCurrentUser(profile);

        var oodoCreateUserData = {
          ...data,
          email: profile?.Emailid,
          userId: profile?.UserId,
          device_type: "web",
          timezone: data?.timezoneName,
        };
        //console.log("OODOCreateUserV4Service req", oodoCreateUserData);

        OODOCreateUserV4Service(oodoCreateUserData).then((resp2) => {
          //console.log("OODOCreateUserV4Service resp", JSON.stringify(resp2));
          //this.setState(prevState=>({createUserResp:resp2.data, profile:{...prevState.profile, showB2BWelcomeScreen:resp2.data.showB2BWelcomeScreen}}), ()=> {callback()});
          //Auth.setAddAProfList(resp2.data.AddAProfList);
          // localStorage.setItem("disclaimer", resp2?.data?.disclaimer);
          console.log("resp2?.data?.disclaimer", resp2);
          setDisclaimer(resp2?.data?.disclaimer);

          setLoading(false);
          const retrunurl = params.get("retrunurl");

          // navigate(`/disclaimer${retrunurl ? `?retrunurl=${retrunurl}` : ""}`);
          setModalShow(true);
        });
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>United We Care Login</title>
      </Helmet>
      <div className="m-0 signInDrawerContainer" style={{ height: "100vh" }}>
        {/* <Modal.Header className="justify-content-end my-5 my-md-0" closeButton>
          <Modal.Title
            className="font-inter fs-5 text-end ms-auto"
            id="example-custom-modal-styling-title"
          >
            Close
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body
          className="d-flex align-content-center justify-content-center w-100 h-100"
          // style={{ height: "70vh" }}
        >
          {/* {(signeInSigneUp == "signUp" && (
            <SignUpDrawer step={step} onSwithView={onSwithView} data={data} />
          )) || */}
          {/* (signeInSigneUp == "signIn" && ( */}
          <div className="h-100 w-100">
            {/* {step == 1 && ( */}
            <div className="kareify--SignIn d-flex flex-column align-items-center justify-content-around h-100">
              <div className="close-btn">
                <svg
                  onClick={() => setShowLloginPopup(false)}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.3602 3.62755C23.5603 3.43454 23.7198 3.20363 23.8297 2.9483C23.9395 2.69296 23.9974 2.41831 23.9999 2.14037C24.0025 1.86242 23.9496 1.58676 23.8445 1.32945C23.7394 1.07214 23.584 0.838353 23.3876 0.641717C23.1911 0.445081 22.9575 0.289538 22.7003 0.184165C22.4431 0.0787913 22.1675 0.025698 21.8895 0.0279813C21.6116 0.0302647 21.3369 0.0878794 21.0814 0.197464C20.826 0.307049 20.5949 0.466409 20.4017 0.666246L12.0119 9.05334L3.62476 0.666246C3.43312 0.460583 3.20202 0.295626 2.94524 0.181216C2.68847 0.0668051 2.41128 0.00528486 2.13021 0.00032577C1.84915 -0.00463332 1.56996 0.0470705 1.30931 0.152352C1.04866 0.257633 0.811885 0.414335 0.61311 0.61311C0.414335 0.811885 0.257633 1.04866 0.152352 1.30931C0.0470705 1.56996 -0.00463332 1.84915 0.00032577 2.13021C0.00528486 2.41128 0.0668051 2.68847 0.181216 2.94524C0.295626 3.20202 0.460583 3.43312 0.666246 3.62476L9.04776 12.0146L0.660665 20.4017C0.290907 20.7986 0.0896069 21.3234 0.0991752 21.8657C0.108743 22.408 0.328433 22.9254 0.711959 23.309C1.09549 23.6925 1.6129 23.9122 2.15521 23.9217C2.69751 23.9313 3.22236 23.73 3.61917 23.3602L12.0119 14.9732L20.3989 23.363C20.7958 23.7328 21.3206 23.9341 21.8629 23.9245C22.4052 23.915 22.9226 23.6953 23.3062 23.3117C23.6897 22.9282 23.9094 22.4108 23.9189 21.8685C23.9285 21.3262 23.7272 20.8013 23.3575 20.4045L14.9759 12.0146L23.3602 3.62755Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="SignIn--box d-flex flex-column align-items-center gap-2 gap-md-3 col-11">
                <h6>
                  Welcome to <span className="diff-clr">United We Care</span>
                </h6>
                <h3>Log in</h3>

                <div className="d-flex align-items-center justify-content-around w-75 my-2 login--with-social">
                  <GoogleLogin
                    onSuccess={onGoogleLoginSuccess}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                    // type="icon"
                    theme="outline"
                    shape="square"
                  />
                  {/* <img src='/assets/img/kareify/Google.png' alt='' />
                  <img src='/assets/img/kareify/Apple.png' alt='' />
                  <img src='/assets/img/kareify/Twitter.png' alt='' />
                  <img src='/assets/img/kareify/linkedin.png' alt='' /> */}
                </div>

                <div className="d-flex align-items-center w-100 gap-2 px-5 horizontal--line">
                  <hr />
                  <p>Or</p>
                  <hr />
                </div>

                <p className="pb-2">Log in with your Email Address</p>

                <input
                  id="outlined-basic"
                  placeholder="Email Address *"
                  variant="outlined"
                  className="w-50"
                  onChange={(e) =>
                    setData((prevData) => ({
                      ...prevData,
                      Email: e?.target?.value.replace(/\s/g, ""),
                    }))
                  }
                  onKeyDown={onEmailChange}
                />
                {!validEmail && (
                  <span className="error-message" style={{ color: "#dc3545" }}>
                    {
                      validationMessage.filter((o) => o.key == "Email")[0]
                        ?.message
                    }
                  </span>
                )}

                <Button
                  className="border-0 w-50"
                  disabled={loading || !data?.Email}
                  onClick={onLogin}
                >
                  Continue
                </Button>

                <Modal
                  show={otpModalShow}
                  onHide={() => setOtpModalShow(false)}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="otp--popup"
                >
                  <Modal.Body className="d-flex flex-column gap-4">
                    <div className="otp--header d-flex align-items-center justify-content-between">
                      <h3>Confirm OTP</h3>
                      <CloseButton onClick={() => setOtpModalShow(false)} />
                    </div>

                    <p>
                      An One Time Password has been sent to your email{" "}
                      <span>{data?.Email}</span> Please enter the OTP to verify
                      your email.
                    </p>

                    <form className="otp-container d-flex align-items-center gap-3 gap-md-5 justify-content-center px-5">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="tel"
                          onChange={(e) => handleChange(index, e.target.value)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          maxLength="1"
                          ref={(el) => (inputRefs.current[index] = el)}
                          value={digit}
                        />
                      ))}
                      {/* <TextField
                        id="standard-basic"
                        variant="standard"
                        inputProps={{ maxLength: 1 }}
                        onChange={(e) => {
                          setData({ ...data, OTP4: e?.target?.value });
                        }}
                        onKeyDown={(event) => {
                          // if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                          if (
                            event.key != "Backspace" &&
                            !/[0-9]/.test(event.key)
                          ) {
                            event.preventDefault();
                          }
                        }}
                        autoComplete="off"
                        InputProps={{ inputProps: { tabIndex: 4 } }}
                        onKeyUp={(e) => inputfocus(e)}
                        onPaste={(e) => {
                          e.preventDefault(); // Prevent paste
                        }}
                      /> */}
                    </form>

                    {resendOtpSent && counter !== 0 && (
                      <span
                        className="error-message px-5 d-flex align-items-center justify-content-center gap-2"
                        style={{ color: "#00AD65" }}
                      >
                        {
                          validationMessage.find((o) => o.key === "OTP")
                            ?.message
                        }
                      </span>
                    )}

                    {!resendOtpSent &&
                      validationMessage.some((o) => o.key === "OTP") && (
                        <span
                          className="error-message px-5 d-flex align-items-center justify-content-center gap-2"
                          style={{ color: "#dc3545" }}
                        >
                          {
                            validationMessage.find((o) => o.key === "OTP")
                              ?.message
                          }
                        </span>
                      )}

                    <div className="otp--resend d-flex align-items-center justify-content-between px-4">
                      {counter === 0 ? (
                        <div className="w-100">
                          <p className="font-inter fs-6 text-black text-center w-100 d-flex align-items-center justify-content-between">
                            <span>00:0{counter}</span>{" "}
                            <span
                              onClick={resendOTP}
                              style={{ cursor: "pointer", color: "#73589B" }}
                            >
                              Resend OTP
                            </span>
                          </p>
                        </div>
                      ) : (
                        <div
                          className="w-100 d-flex align-items-center justify-content-between font-inter fs-6 text-black text-center border-0 bg-white"
                          // style={{ color: "red !important" }}
                        >
                          <span>
                            00:{counter < 10 ? 0 : ""}
                            {counter}
                          </span>{" "}
                          <span>Resend OTP</span>
                        </div>
                      )}
                    </div>

                    <Button
                      disabled={loading || !data?.OTP}
                      className="otp--continue-btn border-0 d-block mx-auto my-3"
                      onClick={onSubmitOtp}
                    >
                      Continue
                    </Button>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={detailModalShow}
                  onHide={() => setdetailModalShow(false)}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="details--popup show"
                >
                  <Modal.Body>
                    <h5>Please Enter</h5>
                    <div className="d-flex flex-column align-items-center gap-3 input-section">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Country *"
                        className="w-100"
                        onChange={(e) => onCountryChange(e?.target?.value)}
                      >
                        {countryList?.map((option) => (
                          <MenuItem key={option.value} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Time Zone *"
                        onChange={(e) => onTimezoneChange(e?.target?.value)}
                        className="w-100"
                      >
                        {timezoneList?.map((option) => (
                          <MenuItem key={option.value} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        placeholder="Referral code (Optional)"
                        variant="outlined"
                        className="w-100"
                        value={validReferralCode}
                        onChange={(e) => HandelReferalCodeChange(e)}
                      />
                      <p
                        className="font-inter font-w500 p-1"
                        style={{
                          color:
                            referralCodeMessage === "Referral code Applied"
                              ? "green"
                              : "red",
                        }}
                      >
                        {referralCodeMessage}
                      </p>

                      <Button onClick={onDetailSubmit}>Continue</Button>
                    </div>
                  </Modal.Body>
                </Modal>

                <p>
                  Don’t have an account?{" "}
                  <span className="diff-clr" onClick={() => onClickSignUp()}>
                    Create Account
                  </span>
                </p>
              </div>
              <div>
                <p className="Cerified--desc">
                  Your privacy and security are our top priority. All
                  interactions on this platform are protected with advanced
                  encryption and managed with strict confidentiality. <br />
                  We implement robust security measures to safeguard your
                  personal information.
                </p>
                <h2 className="my-3">Compliance</h2>

                <div className="d-flex gap-3 align-items-center justify-content-center">
                  <img
                    src="/assets/img/kareify/iso.webp.png"
                    alt=""
                    style={{ borderRadius: "8px" }}
                  />
                  <img
                    src="/assets/img/kareify/hippa.webp.png"
                    alt=""
                    style={{ borderRadius: "8px" }}
                  />
                  {/* <img
                    src="/assets/img/image 17763.png"
                    alt=""
                    style={{ borderRadius: "8px" }}
                  /> */}
                  {/* <img
                    src="/assets/img/image 17762.png"
                    alt=""
                    style={{ borderRadius: "8px" }}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <ToastContainer />

        <DisclaimerModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          disclaimer={disclaimer}
        />
      </div>
    </>
  );
};
