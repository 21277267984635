import React, { useEffect, useState } from "react";
import DetailPageCard from "./DetailPageCard";
import DetailPageTabs from "./DetailPageTabs";
import { getExpertbyId_WebService } from "./core/_request";
import { getCountryCode } from "./core/commonFun";
import { Helmet } from "react-helmet";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import './ExpertFilterDrawer.css'

const ExpertDetails = (props) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true)
  const countryCode = getCountryCode();

  useEffect(() => {
    const { id } = props;
    // console.log("id", id)
    getExpertbyId_WebService(id).then((resp) => {
      var data = resp.data;
      if (countryCode == "IN")
        data["countryAmount"] = data.Hourly_Consultaion_Fee_amount;
      else data["countryAmount"] = data.USD_Amount;
      setData(data);
      setLoading(false)
      //console.log(resp.data)
    });
  }, []);

  return (
    (data && (
      <div>
        <Helmet>
          <title>
            {data?.Name +
              ":" +
              " Professional " +
              data?.RegisterYourselfAs +
              " - United We Care"}
          </title>
        </Helmet>
        <div>
          <div className="expertDetail--container px-0 py-md-3">
            
          {loading ? (<Skeleton variant="text" width="50%" height="40px" className="mx-auto" />) : (
                <p className="fs-2 text-black text-center pb-5 py-4 px-3 font-w600 lh-base font-inter d-none d-md-block">
                {data?.RegisterYourselfAs}
              </p>
              )
            }
            <div className="row col-12 justify-content-between align-content-center align-items-md-start w-100 mx-0 position-relative">
              <div className="col-md-4 my-1 mx-auto px-0 px-md-2">
                <DetailPageCard data={data} loading={loading} />
              </div>
              <div className="col-md-8 p-0 m-0 my-1">
                <DetailPageTabs data={data} loading={loading}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
  );
};

export default ExpertDetails;
