import React, { useState, useEffect, useContext } from "react";

import { useAuth } from "../auth";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../context";

import { Helmet } from "react-helmet";

function Stella() {
  const { currentUser, userType, logout } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);

  useEffect(() => {

    const urlParams = window.location.href.split("?")[1];

    const authToken = localStorage.getItem("Authorization")?.split("Bearer ")[1] || false;

    let formattedURLParams;
    if (authToken) {
      if (urlParams) {
        formattedURLParams = `https://stella.unitedwecare.com/?token=${authToken}&${urlParams}&appURL=${encodeURI('https://us.unitedwecare.com')}`
      } else {
        formattedURLParams = `https://stella.unitedwecare.com/?token=${authToken}&appURL=${encodeURI('https://us.unitedwecare.com')}`
      }
    } else {
      if (urlParams) {
        formattedURLParams = `https://stella.unitedwecare.com/?${urlParams}&appURL=${encodeURI('https://us.unitedwecare.com')}`
      } else {
        formattedURLParams = `https://stella.unitedwecare.com/?appURL=${encodeURI('https://us.unitedwecare.com')}`
      }
    }


    window.location.href = formattedURLParams;


  }, [currentUser]);


  return (
    <>
      <Helmet>
        <title>Talk to Stella - Mental Health First Aid | United We Care</title>
      </Helmet>
      <div className="min-h-screen"></div>

    </>
  );
}

export default Stella;
