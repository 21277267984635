import React from "react";
import Sidebar from "./components/Sidebar";
import Home from "./components/Home";

const CourseInternal = () => {
  return (
    <div style={{ display: "flex", overflow: "hidden", width: "100%" }}>
      <Home />
    </div>
  );
};

export default CourseInternal;
