import React from 'react'
import '.././Plans.css'

const ReviewArea = () => {
    return (
        <div>
            {/* <!------ review-area (desktop-display)------> */}
            <div className="review-area mobile-display-none desktop-display">
                <div className="container">
                    <div className="review-inner">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <img src="assets/img/google.png" className="google-img" alt='' />
                                <h2 className="heading text-lf cl-wh d-flex align-items-center gap-2">Loved by 5M+ users <img src="assets/img/love.png" alt='' /></h2>
                                <img src="assets/img/review2.png" className="rat-img" alt='' />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <img src="assets/img/review.png" className="more-img" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!------ review-area (mobile-display)------> */}
            <div className="review-area mobile-display desktop-display-none">
                <div className="container">
                    <div className="review-inner">
                        <img src="assets/img/google.png" className="google-img" alt='' />
                        <img src="assets/img/review.png" className="more-img" alt='' />
                        <h2 className="heading text-lf cl-wh">Loved by 5M+ users <img src="assets/img/love.png" style={{float:"none", verticalAlign:"sub"}} alt='' /></h2>
                        <img src="assets/img/review2.png" className="rat-img" alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewArea