import React from 'react'
import { Button, Tab, Table, Tabs } from 'react-bootstrap'
import { SubscriptionComparison, SubscriptionPacks } from './core/request'
import { useState } from 'react'
import { useContext } from 'react';
import { AppContext } from '../../context';
import { useAuth } from '../../auth'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
const SubscriptionPlans = () => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [comparisonData, setComparisonData] = useState({});
    const [current_month, setCurrent_month] = useState();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);

    var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
    var body = { "country_id": 233 }

    useEffect(() => {
        SubscriptionPacks(body).then(resp => {
            if (resp.ResponseCode === 200) {
                setSubscriptionData(resp?.data?.packs);
            }
            else {
                enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
            }
        })

        SubscriptionComparison(body).then(resp => {
            if (resp.ResponseCode === 200) {
                setComparisonData(resp?.data)
            }
            else {
                enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
            }
        })
    }, []);

    const onClickBuy = (data) => {
        debugger
        if (currentUser) {
            var plan_id = data?.id;
            //var fff = current_month;
            navigate(`/subscription-order-summary/${plan_id}`)
        }
        else {
            // navigate(`/login?retrunurl=/book-an-appointment/${data?.Id}`) 
            // setShowLloginPopup(true)
            const currentUrl = encodeURIComponent(window.location.href);
            window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
        }
    }

    const handel_Current_month = (item) => {
        setCurrent_month(item);
    }

    return (
        <div className='py-5'>
            <h6 className='text-center font-inter font-w800 fs-2'>Plans & Pricing</h6>
            <div className='row justify-content-center gap-5 my-5 subscriptionPlansDiv'>
                {subscriptionData && subscriptionData?.map((data, index) =>
                    <div className='col-11 col-md-3 rounded-3 d-flex flex-column justify-content-between px-0' style={{ border: "1px solid #6A6C6A", boxShadow: "#0000002B" }}>
                        <div className='d-flex flex-column justify-content-between'>
                            <div className='rounded-top' style={{ background: index === 0 ? "#C4E5F9" : "#9FE770", height: "25px" }}></div>
                            <div className='mx-3'>
                                <h6 className='font-inter lh-lg font-w600 fs-4'>{data?.name}</h6>
                                <div className='subscriptions-plans'>
                                    <Tabs
                                        defaultActiveKey="tab_0"
                                        id="fill-tab-example"
                                        className="mb-3 rounded-3"
                                        fill
                                        style={{ border: '1px solid #C4E5F9' }}
                                    >
                                        {data?.available?.map((item, index) =>
                                            <Tab eventKey={"tab_" + index} title={item?.name}>
                                                <div>
                                                    <p className='font-inter fs-6 lh-lg' dangerouslySetInnerHTML={{ __html: data?.plan_description }}></p>
                                                </div>
                                            </Tab>
                                        )}
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        <Button onClick={() => onClickBuy(data)} className='rounded-5 border-0 text-black col-6 d-block mx-auto my-4 font-inter py-3' style={{ background: index === 0 ? "#C4E5F9" : "#9FE770" }}>Buy Now</Button>
                    </div>
                )}
            </div>

            <h6 className='text-center font-inter font-w800 fs-2'>Compare Plans</h6>
            <div className='col-11 col-md-6 d-block mx-auto d-md-block d-none'>
                <Table bordered className='px-4'>
                    <thead>
                        <tr>
                            <th></th>
                            {comparisonData && comparisonData?.plans?.map((data, index) =>
                                <th>
                                    <div className='w-auto font-inter text-center py-2 px-2' style={{ border: "1px solid #C4E5F9", whiteSpace: "nowrap" }}>
                                        {data}
                                    </div>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {comparisonData?.features && comparisonData?.features?.map((item, index) =>
                            <tr className='my-1' style={{ background: "#F8F8F8" }}>
                                <td>
                                    {item?.feature}
                                </td>
                                {
                                    item?.plans?.map((boolean) =>
                                        boolean &&
                                        <td className='text-center'><img src='/assets/img/done_black_24dp 3.png' alt='' /></td>
                                        ||
                                        <td className='text-center'></td>
                                    )}
                            </tr>
                        )}
                    </tbody>
                    {/* <tfoot>
                        <tr>
                            <td></td>
                            <td>
                                <Button onClick={onClickBuy} className='rounded-5 border-0 text-black d-block mx-auto my-4 font-inter py-2' style={{ background: "#C4E5F9", whiteSpace: "nowrap" }}>Buy Genesis</Button>
                            </td>
                            <td>
                                <Button onClick={onClickBuy} className='rounded-5 border-0 text-black d-block mx-auto my-4 font-inter py-2' style={{ background: "#9FE770", whiteSpace: "nowrap" }}>Buy Zenith</Button>
                            </td>
                        </tr>
                    </tfoot> */}
                </Table>
            </div>

            <div className='col-11 col-md-6 mx-auto d-block d-md-none'>
                <Table className=''>
                    <thead>
                        {comparisonData && comparisonData?.plans?.map((data, index) =>
                            <th>
                                <div className='w-auto font-inter text-center py-2 px-2' style={{ border: "1px solid #C4E5F9", whiteSpace: "nowrap" }}>
                                    {data}
                                </div>
                            </th>
                        )}
                    </thead>
                    <tbody>
                        {comparisonData?.features && comparisonData?.features?.map((item, index) =>
                            <tr className='my-1' style={{ background: "#F8F8F8" }}>
                                <td>
                                    {item?.feature}
                                </td>
                                {
                                    item?.plans?.map((boolean) =>
                                        boolean &&
                                        <td className='text-center'><img src='/assets/img/done_black_24dp 3.png' alt='' /></td>
                                        ||
                                        <td className='text-center'></td>
                                    )}
                            </tr>
                        )}
                    </tbody>
                    {/* <tfoot>
                        <tr>
                            <td>
                                <Button onClick={onClickBuy} className='rounded-5 border-0 text-black d-block mx-auto my-4 font-inter py-2' style={{ background: "#C4E5F9", whiteSpace: "nowrap" }}>Buy Genesis</Button>
                            </td>
                            <td>
                                <Button onClick={onClickBuy} className='rounded-5 border-0 text-black d-block mx-auto my-4 font-inter py-2' style={{ background: "#9FE770", whiteSpace: "nowrap" }}>Buy Zenith</Button>
                            </td>
                        </tr>
                    </tfoot> */}
                </Table>
            </div>
        </div >
    )
}

export default SubscriptionPlans