import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import {
  course_web_lists,
  getCourseDetail_WebService,
  getDashboardCourseList_WebService,
} from "../core/_request";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { courseData } from "./core/models";
import { Helmet } from "react-helmet";
import CourseCard from "./components/CourseCard";
import { Button } from "@mui/material";
import Popup from "../courseInternalJourney/components/Modal";

import { Skeleton } from "@mui/material";

const modal_data = [
  {
    title: "Alert !",
    desc: "We regret to inform you that this course is temporarily unavailable. We apologize for the inconvenience and appreciate your understanding. Please explore more programs on related topics to continue on your healing path.",
  },
];

const DashboardMyCoursesTab = () => {
  const navigate = useNavigate();
  const [myCourseData, setMyCourseData] = useState(null);
  const [ttcourseData, setTtcourseData] = useState({});
  const [data, setData] = useState();
  const { currentUser } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const body = { userId: currentUser?.UserId };

    // getDashboardCourseList_WebService(body).then((resp) => {
    //   //console.log("resp Data", resp.data);
    //   setMyCourseData(resp.data);
    // });

    const getDataLists = async () => {
      const CourseList = await getDashboardCourseList_WebService(body);
      setMyCourseData(CourseList);
      setLoading(false);
      // console.log("CourseList", CourseList);
    };

    course_web_lists(body).then((resp) => {
      setTtcourseData(resp?.data?.courses);
    });

    getDataLists();
  }, []);

  const onClick = (item) => {
    debugger;
    const body = { courseId: item.course_id, userId: currentUser?.UserId };

    var tcourseData = ttcourseData?.find((o) => o.id == item.course_id);
    if (tcourseData === null || tcourseData === undefined) {
      // To open the info Dialog Box
      setShowModal(true);
    }
    getCourseDetail_WebService(body).then((resp) => {
      debugger;
      console.log("resp09", resp);
      if (
        resp?.Data &&
        resp?.Data &&
        resp?.Data?.type == "detail" &&
        tcourseData
      ) {
        navigate(`/dashboard/program/details/${tcourseData?.urlTitle}`);
      }
      // else if (resp?.Data && resp?.Data && resp?.Data?.type == "options") {
      //     navigate(`/dashboard/course/course-questions/${tcourseData?.id}`);
      // }
      else if (
        resp?.Data &&
        resp?.Data &&
        resp?.Data?.type == "consultation" &&
        tcourseData
      ) {
        navigate(`/book-an-appointment/${tcourseData?.urlTitle}`);
      } else if (
        resp?.Data &&
        resp?.Data &&
        resp?.Data?.type == "payment" &&
        tcourseData
      ) {
        navigate(`/program/details/${tcourseData?.urlTitle}`);
      } else if (resp?.Data && resp?.Data && resp?.Data?.type == "dashboard") {
        navigate("/dashboard/program");
      } else if (
        resp?.Data &&
        resp?.Data &&
        resp?.Data?.type == "course" &&
        tcourseData
      ) {
        // console.log("resp?.Data?.data?.type", resp?.Data?.data?.type)
        navigate(`/dashboard/program/program-preview/${tcourseData?.urlTitle}`);
      }
      // else if (tcourseData === undefined || tcourseData === null) {
      //     alert("We regret to inform you that this course is temporarily unavailable. We apologize for the inconvenience and appreciate your understanding. Please explore more programs on related topics to continue on your healing path.")
      // }
      //setCourseDetailData(resp.Data);
      // console.log("respData", resp.Data)
    });
  };

  const onClickCourse = (url) => {
    navigate(url);
    let linksData = [
      {
        name: "Home",
        uri: url,
      },
      { name: "My Journal", uri: `/program-internal/module/my-journal/:id` },
    ];
    sessionStorage.setItem("courseInternalLinks", JSON.stringify(linksData));
  };

  // console.log("myCourseData", myCourseData);

  return (
    <div style={{ minHeight: "90vh", marginTop: "20px" }}>
      <Helmet>
        <title>My Programs - UNITED WE CARE</title>
      </Helmet>
      
      {loading ? (
        <>
        <div className=" d-md-flex align-items-center gap-3 py-4 px-3 d-none  " style={{maxWidth: "1440px",
            margin: "auto",}}>
          <Skeleton
            variant="rounded"
            width="250px"
            height="60px"
            className="rounded rounded-3"
          />
        </div>
        <div className=" d-flex align-items-center gap-3 py-4 px-3 d-block d-md-none ">
          <Skeleton
            variant="rounded"
            width="250px"
            height="60px"
            className="rounded rounded-3"
          />
        </div>
        </>
      ) : (
        <div
          className="flex align-items-center gap-3 py-4 px-3"
          style={{
            boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25",
            borderBottom: "2px solid #73589b",
            maxWidth: "1440px",
            margin: "auto",
          }}
        >
          <h1 className="font-w500">My Programs</h1>
        </div>
      )}
      {/* {myCourseData?.courses?.map((item) => (
                
                <div className='row align-items-center px-md-5 py-3 gap-3 gap-md-0 mx-1' style={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)" }}>
                    <p className='col-md-5 d-flex justify-content-between align-items-center'>
                        <button onClick={() => onClick(item)} style={{ border: "none", background: "none" }}>
                           
                            {item?.name}
                        </button>
                        <button onClick={() => onClick(item)} className='d-block d-md-none' style={{ border: "none", background: "none" }}>
                            <Badge bg='success' text='light' className='py-2 rounded-5'>
                               
                                {item?.status}
                            </Badge>
                        </button>
                    </p>
                    <p className='col-md-5'>Start Date : <span>
                        {item?.start_date}
                    </span></p>
                    <div className='col-md-2 d-none d-md-block'>
                        <button onClick={() => onClick(item)} style={{ border: "none", background: "none" }}>
                            <Badge bg='success' text='light' className='py-2 rounded-5'>
                               
                                {item?.status}
                            </Badge>
                        </button>
                    </div>
                </div>
            ))
            } */}
      <div>
        {myCourseData?.length > 0 ? (
          <div
            className="dashboardCourses--Container px-3 py-4 gap-3"
            style={{ maxWidth: "1440px", margin: "auto" }}
          >
            {myCourseData?.map((courseData) => {
              return (
                <div
                  className="dashboardCourse--Item rounded-4 px-3 py-3 d-flex flex-column justify-content-between gap-3"
                  style={{ border: "1px solid #dadada", height: "100%" }}
                >
                  <Link
                    onClick={() =>
                      onClickCourse(
                        `/program-internal/${courseData?.id}/${courseData?.name}`
                      )
                    }
                    className="text-decoration-none d-flex flex-column gap-1"
                  >
                    <img
                      src={courseData?.website_thumbnail_url}
                      alt=""
                      className="rounded-3"
                    />
                    <h2
                      className="font-w600 font-inter fs-4 my-2 my-md-0"
                      style={{ color: "#101828", lineHeight: "32px" }}
                    >
                      {courseData?.name}
                    </h2>
                  </Link>
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex align-items-center justify-content-between d-none">
                      <div
                        className="rounded-5 d-flex align-items-center gap-1 justify-content-center px-2 py-1"
                        style={{ background: "rgba(159, 214, 139, 0.10)" }}
                      >
                        <img src="assets/img/tabler_clock-filled.png" alt="" />
                        <p
                          className="font-inter fs-6 font-w500"
                          style={{ color: "#4C9234" }}
                        >
                          {courseData?.duration}
                        </p>
                      </div>
                      <div className="d-flex gap-2 align-items-center">
                        <Link
                          //onClick={onCLickCopy}
                          className="rounded-5 d-flex align-items-center gap-1 justify-content-center px-2 py-1 text-decoration-none"
                          style={{
                            border: "1px solid #D0D5DD",
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                          }}
                        >
                          <img src="assets/img/copy.png" alt="" />
                          <p
                            className="font-inter font-w600 fs-6"
                            style={{ color: "#344054" }}
                          >
                            Copy link
                          </p>
                        </Link>

                        <div
                          className="rounded-circle d-flex align-items-center justify-content-center"
                          style={{
                            border: "1px solid #D0D5DD",
                            height: "35px",
                            width: "35px",
                            cursor: "pointer",
                          }}
                        >
                          <a
                            className="d-none d-md-block"
                            style={{ cursor: "pointer" }}
                            //  onClick={onClickWhatsAppOnWeb}
                          >
                            <img
                              src="/assets/img/whatsapp.png"
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />
                          </a>
                          <a
                            className="d-block d-md-none"
                            style={{ cursor: "pointer" }}
                            // onClick={onClickWhatsAppOnMobileWeb}
                          >
                            <img
                              src="/assets/img/whatsapp.png"
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <Button
                        onClick={() =>
                          onClickCourse(
                            `/program-internal/${courseData?.id}/${courseData?.name}`
                          )
                        }
                        className="d-flex align-items-center gap-3 mt-2 justify-content-center border-0 rounded-3 col-12 primary-color"
                        style={{
                          background: "var(--button-primary-color)",
                          padding: "0.75rem",
                          color: "#FFF",
                        }}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : myCourseData !== null ? (
          <div
            className="px-3 py-4 gap-3 my-5"
            style={{ maxWidth: "1440px", width: "100%", margin: "auto" }}
          >
            <div
              className="dashboardCourse--Item rounded-4 px-3 py-3 d-flex flex-column justify-content-between"
              style={{
                border: "1px solid #dadada",
                height: "100%",
                margin: "auto",
                maxWidth: "400px",
                gap: "30px",
              }}
            >
              <div>
                <h2 className="font-w600 fs-5 mt-2">
                  You have no subscribed programs!
                </h2>
                <h6 className="font-w400 fs-7 my-3">
                  Embark on your wellness journey with our programs.
                </h6>
              </div>
              <Button
                className="PrimaryCTA"
                onClick={() => {
                  navigate("/program");
                }}
              >
                Explore
              </Button>
            </div>
          </div>
        ) : (
          <div
            className="dashboardCourses--Container px-3 py-4 gap-3"
            style={{ maxWidth: "1440px", margin: "auto" }}
          >
            {Array.from(new Array(12)).map((_, index) => (
              <div
                className="dashboardCourse--Item rounded-4 px-3 py-3 d-flex flex-column justify-content-between gap-3"
                style={{ border: "1px solid #dadada", height: "100%" }}
              >
                <div>
                  <div
                    className="rounded-3"
                    style={{
                      aspectRatio: "16/9",
                      objectFit: "fill",
                      marginTop: "-5px",
                    }}
                  >
                    <Skeleton variant="rounded" width="100%" height={200} />
                  </div>
                  <h2
                    className="font-w600 font-inter fs-4 my-2  CourseListing--Card__Title"
                    style={{
                      color: "#101828",
                      lineHeight: "32px",
                      marginTop: "-10px",
                    }}
                  >
                    <Skeleton variant="rounded" width="90%" height={40} />
                  </h2>

                  <div className="d-flex flex-row justify-content-between my-3 d-none">
                    <Skeleton variant="rounded" width="5vw" height={30} />
                    <div className="d-flex flex-row gap-2">
                      <Skeleton variant="rounded" width="5vw" height={30} />
                      <Skeleton variant="circular" width={30} height={30} />
                    </div>
                  </div>
                  <Skeleton variant="rounded" width="100%" height={50} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Popup
        show={showModal}
        onHide={() => setShowModal(false)}
        data={modal_data[0]}
      />
    </div>
  );
};

export default DashboardMyCoursesTab;
