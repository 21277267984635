import React from 'react';
import VideoCard from './VideoCard';
import PodcastCard from './PodcastCard';
import BlogCard from './BlogCard';
import ExpertCard from './ExpertCard';
import ProgramCard from './ProgramCard';

const SearchResult = ({ SearchedData, searchQuery, selectOption }) => {
    const noResultsComponent = (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center gap-3 noResultOnSearch">
            <img src='/assets/img/kareify/Out_Of_Stock.png' alt='No Result' />
            <p style={{ textDecoration: 'none' }}>No results related to ‘{searchQuery}’</p>
        </div>
    );

    // Function to check if there are items and render content or the "No Results" message
    const renderContentOrEmpty = (items, CardComponent) => {
        return items?.length > 0 ? (
            items?.map((item, index) => (
                <div key={index} className='col-md-4 p-2'>
                    <CardComponent item={item} />
                </div>
            ))
        ) : (
            noResultsComponent
        );
    };

    // Decide if we need to show the "No Results" div
    const shouldShowNoResults = selectOption === 'all' && (
        (!SearchedData?.results[0]?.expert_?.length &&
            !SearchedData?.results[2]?.blogs?.filter(blog => blog?.post_type === 'Video')?.length &&
            !SearchedData?.results[2]?.blogs?.filter(blog => blog?.post_type === 'Audio')?.length &&
            !SearchedData?.results[1]?.courses_?.length)
    );

    console.log("SearchedData?.results[0]?.expert_?", SearchedData?.results[0]?.expert_?.length)

    return (
        <div className='container search--result pt-3'>
            {selectOption === 'expert' || selectOption === 'all' ? (
                <div className={`video--container d-block`}>
                    {SearchedData?.results[0]?.expert_?.length < 1 || (selectOption !== 'all') && (selectOption !== 'expert') ?
                        null : <h6>Professionals</h6>
                    }
                    <div className='row my-2'>
                        {SearchedData?.results[0]?.expert_?.length > 0 ?
                            renderContentOrEmpty(SearchedData.results[0].expert_.slice(0, 6), ExpertCard) :
                            (selectOption !== 'all' && noResultsComponent)}
                    </div>
                </div>
            ) : null}

            {selectOption === 'video' || selectOption === 'all' ? (
                <div className={`video--container d-block`}>
                    {SearchedData?.results[2]?.blogs?.filter(blog => blog?.post_type === 'Video').length < 1 || (selectOption !== 'all') && (selectOption !== 'video') ?
                        null : <h6>Videos</h6>
                    }
                    <div className='row my-2'>
                        {SearchedData?.results[2]?.blogs?.filter(blog => blog?.post_type === 'Video').length > 0 ?
                            renderContentOrEmpty(SearchedData.results[2].blogs.filter(blog => blog.post_type === 'Video').slice(0, 6), VideoCard) :
                            (selectOption !== 'all' && noResultsComponent)}
                    </div>
                </div>
            ) : null}

            {selectOption === 'podcast' || selectOption === 'all' ? (
                <div className={`video--container d-block`}>
                    {SearchedData?.results[2]?.blogs?.filter(blog => blog?.post_type === 'Audio').length < 1 || (selectOption !== 'all') && (selectOption !== 'podcast') ?
                        null : <h6>Podcasts</h6>
                    }
                    <div className='row my-2'>
                        {SearchedData?.results[2]?.blogs?.filter(blog => blog?.post_type === 'Audio').length > 0 ?
                            renderContentOrEmpty(SearchedData.results[2].blogs.filter(blog => blog.post_type === 'Audio'), PodcastCard) :
                            (selectOption !== 'all' && noResultsComponent)}
                    </div>
                </div>
            ) : null}

            {selectOption === 'program' || selectOption === 'all' ? (
                <div className={`video--container d-block`}>
                    {SearchedData?.results[1]?.courses_?.length < 1 || (selectOption !== 'all') && (selectOption !== 'program') ?
                        null : <h6>Programs</h6>
                    }
                    <div className='row my-2'>
                        {SearchedData?.results[1]?.courses_?.length > 0 ?
                            renderContentOrEmpty(SearchedData.results[1].courses_.slice(0, 6), ProgramCard) :
                            (selectOption !== 'all' && noResultsComponent)}
                    </div>
                </div>
            ) : null}

            {shouldShowNoResults && noResultsComponent}
            {/* {noResultsComponent} */}
        </div>
    );
}

export default SearchResult;
