import Cookies from "universal-cookie";

export const getCountryCode = () => {
  const cookies = new Cookies();
  var countryCode = cookies.get("countryCode");
  return countryCode;
};

export const getCurrencyCode = () => {
  const cookies = new Cookies();
  var countryCode = cookies.get("countryCode");
  if (countryCode == "IN") return "₹";
  else return "$";
};

export const formatDate = (isoDateString) => {
  let inputDate = new Date(isoDateString);
  let options = { month: "long", day: "numeric", year: "numeric" };
  return inputDate.toLocaleDateString("en-US", options);
};


export const insurance_ist = [{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f2a"
  },
  "payor_name": "1199 National Benefit Fund",
  "payor_id": "13162",
  "seq_id": 1
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f2b"
  },
  "payor_name": "729 Demo Payer",
  "payor_id": "88888",
  "seq_id": 2
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f2c"
  },
  "payor_name": "AARP Medicare Supplement by UnitedHealthcare",
  "payor_id": "36273",
  "seq_id": 3
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f2d"
  },
  "payor_name": "AblePay Health",
  "payor_id": "ABLPY",
  "seq_id": 4
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f2e"
  },
  "payor_name": "Ace Property & Casualty Ins. Company Medicare Supplement (Eligibility and Remits Only)",
  "payor_id": "Z96821",
  "seq_id": 5
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f2f"
  },
  "payor_name": "ACS Benefit Services Inc",
  "payor_id": "72467",
  "seq_id": 6
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f30"
  },
  "payor_name": "Administrative Concepts, Inc",
  "payor_id": "22384",
  "seq_id": 7
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f31"
  },
  "payor_name": "Administrative Services Inc",
  "payor_id": "59141",
  "seq_id": 8
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f32"
  },
  "payor_name": "Advantage by Bridgeway Health Solutions",
  "payor_id": "68056",
  "seq_id": 9
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f33"
  },
  "payor_name": "Adventist Health System West (Roseville, CA)",
  "payor_id": "95340",
  "seq_id": 10
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f34"
  },
  "payor_name": "Aetna",
  "payor_id": "60054",
  "seq_id": 11
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f35"
  },
  "payor_name": "Aetna Administrator Medicare Supplement (Eligibility and Remits Only)",
  "payor_id": "62118",
  "seq_id": 12
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f36"
  },
  "payor_name": "Aetna Better Health - IL Medicaid",
  "payor_id": "26337",
  "seq_id": 13
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f37"
  },
  "payor_name": "Aetna Better Health - PA",
  "payor_id": "23228",
  "seq_id": 14
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f38"
  },
  "payor_name": "Aetna Better Health (Eligibility Only)",
  "payor_id": "ABH01",
  "seq_id": 15
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f39"
  },
  "payor_name": "Aetna Better Health of California",
  "payor_id": "128CA",
  "seq_id": 16
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f3a"
  },
  "payor_name": "Aetna Better Health of Florida",
  "payor_id": "128FL",
  "seq_id": 17
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f3b"
  },
  "payor_name": "Aetna Better Health of Kansas",
  "payor_id": "128KS",
  "seq_id": 18
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f3c"
  },
  "payor_name": "Aetna Better Health of Kentucky",
  "payor_id": "128KY",
  "seq_id": 19
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f3d"
  },
  "payor_name": "Aetna Better Health of Louisiana",
  "payor_id": "128LA",
  "seq_id": 20
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f3e"
  },
  "payor_name": "Aetna Better Health of Maryland",
  "payor_id": "128MD",
  "seq_id": 21
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f3f"
  },
  "payor_name": "Aetna Better Health of Michigan",
  "payor_id": "128MI",
  "seq_id": 22
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f40"
  },
  "payor_name": "Aetna Better Health of New Jersey",
  "payor_id": "46320",
  "seq_id": 23
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f41"
  },
  "payor_name": "Aetna Better Health of New York",
  "payor_id": "34734",
  "seq_id": 24
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f42"
  },
  "payor_name": "Aetna Better Health of Ohio",
  "payor_id": "50023",
  "seq_id": 25
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f43"
  },
  "payor_name": "Aetna Better Health of Texas",
  "payor_id": "38692",
  "seq_id": 26
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f44"
  },
  "payor_name": "Aetna Better Health of Virginia",
  "payor_id": "128VA",
  "seq_id": 27
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f45"
  },
  "payor_name": "Aetna Better Health of West Virginia",
  "payor_id": "128WV",
  "seq_id": 28
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f46"
  },
  "payor_name": "Aetna Retiree Medical Plan - Administrator",
  "payor_id": "10891",
  "seq_id": 29
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f47"
  },
  "payor_name": "Affinity Health Plan (DOS 10/31/21 and prior)",
  "payor_id": "13334",
  "seq_id": 30
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f48"
  },
  "payor_name": "AFLAC (Eligibility Only)",
  "payor_id": "Z10955",
  "seq_id": 31
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f49"
  },
  "payor_name": "AFLAC Dental (Eligibility Only)",
  "payor_id": "10398",
  "seq_id": 32
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f4a"
  },
  "payor_name": "AgeWell New York",
  "payor_id": "AWNY6",
  "seq_id": 33
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f4b"
  },
  "payor_name": "AGIA Inc",
  "payor_id": "95241",
  "seq_id": 34
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f4c"
  },
  "payor_name": "Alan Sturm and Associates - Dental",
  "payor_id": "10798",
  "seq_id": 35
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f4d"
  },
  "payor_name": "Align Senior Care (MI)",
  "payor_id": "ASMI1",
  "seq_id": 36
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f4e"
  },
  "payor_name": "Allegiance Benefit Plan Management Inc",
  "payor_id": "81040",
  "seq_id": 37
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f4f"
  },
  "payor_name": "Alliance Coal Health Plan",
  "payor_id": "93658",
  "seq_id": 38
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f50"
  },
  "payor_name": "Alliant Health Plans of Georgia",
  "payor_id": "58234",
  "seq_id": 39
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f51"
  },
  "payor_name": "Allied Benefit Systems",
  "payor_id": "37308",
  "seq_id": 40
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f52"
  },
  "payor_name": "AlohaCare Medicare and Medicaid",
  "payor_id": "Z1375",
  "seq_id": 41
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f53"
  },
  "payor_name": "Alternative Insurance Resources (Eligibility Only)",
  "payor_id": "11002",
  "seq_id": 42
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f54"
  },
  "payor_name": "AmeriBen Solutions Inc",
  "payor_id": "75137",
  "seq_id": 43
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f55"
  },
  "payor_name": "American Family Life Assurance Co AFLAC-Medicare Supplemental",
  "payor_id": "10663",
  "seq_id": 44
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f56"
  },
  "payor_name": "American General Life & Accident (Eligibility Only)",
  "payor_id": "Z1089",
  "seq_id": 45
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f57"
  },
  "payor_name": "American Income Life Insurance Company",
  "payor_id": "60577",
  "seq_id": 46
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f58"
  },
  "payor_name": "American Insurance Administrators (AIA)",
  "payor_id": "26119",
  "seq_id": 47
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f59"
  },
  "payor_name": "American National Insurance Co (ANICO)",
  "payor_id": "74048",
  "seq_id": 48
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f5a"
  },
  "payor_name": "American National Life Insurance Company of Texas",
  "payor_id": "10596",
  "seq_id": 49
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f5b"
  },
  "payor_name": "American Postal Workers Union (APWU) (DOS 1/1/18 and After)",
  "payor_id": "44445",
  "seq_id": 50
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f5c"
  },
  "payor_name": "American Postal Workers Union (APWU) (DOS 12/31/17 and prior)",
  "payor_id": "44444",
  "seq_id": 51
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f5d"
  },
  "payor_name": "American Republic Insurance Company (ARIC)",
  "payor_id": "42011",
  "seq_id": 52
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f5e"
  },
  "payor_name": "American Retirement Life Insurance Co Medicare Supp",
  "payor_id": "E10538",
  "seq_id": 53
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f5f"
  },
  "payor_name": "America's PPO/America's TPA",
  "payor_id": "41178",
  "seq_id": 54
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f60"
  },
  "payor_name": "Americo Financial Life & Annuity Insurance Company",
  "payor_id": "R26509",
  "seq_id": 55
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f61"
  },
  "payor_name": "Amerigroup",
  "payor_id": "26375",
  "seq_id": 56
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f62"
  },
  "payor_name": "AMERIGROUP",
  "payor_id": "AMGRP",
  "seq_id": 57
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f63"
  },
  "payor_name": "Amerigroup - Wellpoint",
  "payor_id": "WLPNT",
  "seq_id": 58
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f64"
  },
  "payor_name": "Amerigroup Corporation",
  "payor_id": "27514",
  "seq_id": 59
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f65"
  },
  "payor_name": "AmeriHealth Administrators & Inter-County - TPA Suffix",
  "payor_id": "54763",
  "seq_id": 60
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f66"
  },
  "payor_name": "AmeriHealth Caritas Delaware",
  "payor_id": "77799",
  "seq_id": 61
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f67"
  },
  "payor_name": "Amerihealth Caritas District of Columbia",
  "payor_id": "77002",
  "seq_id": 62
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f68"
  },
  "payor_name": "AmeriHealth Caritas Florida",
  "payor_id": "77003",
  "seq_id": 63
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f69"
  },
  "payor_name": "AmeriHealth Caritas Louisiana",
  "payor_id": "27357",
  "seq_id": 64
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f6a"
  },
  "payor_name": "Amerihealth Caritas New Hampshire",
  "payor_id": "87716",
  "seq_id": 65
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f6b"
  },
  "payor_name": "AmeriHealth Caritas Next - Product of AmeriHealth Caritas Florida",
  "payor_id": "45408",
  "seq_id": 66
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f6c"
  },
  "payor_name": "AmeriHealth Caritas Next North Carolina",
  "payor_id": "83148",
  "seq_id": 67
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f6d"
  },
  "payor_name": "Amerihealth Caritas North Carolina",
  "payor_id": "81671",
  "seq_id": 68
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f6e"
  },
  "payor_name": "Amerihealth Caritas PA Community Healthchoices",
  "payor_id": "77062",
  "seq_id": 69
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f6f"
  },
  "payor_name": "AmeriHealth Caritas Pennsylvania",
  "payor_id": "22248",
  "seq_id": 70
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f70"
  },
  "payor_name": "AmeriHealth Caritas VIP Care - Delaware DSNP",
  "payor_id": "87406",
  "seq_id": 71
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f71"
  },
  "payor_name": "AmeriHealth Caritas VIP Care (HMO-SNP) (FL)",
  "payor_id": "88232",
  "seq_id": 72
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f72"
  },
  "payor_name": "AmeriHealth Caritas VIP Care Plus (Michigan)",
  "payor_id": "77013",
  "seq_id": 73
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f73"
  },
  "payor_name": "AmeriHealth Caritas VIP Next",
  "payor_id": "47073",
  "seq_id": 74
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f74"
  },
  "payor_name": "AmeriHealth HMO",
  "payor_id": "95044",
  "seq_id": 75
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f75"
  },
  "payor_name": "Amerihealth New Jersey (Eligibility Only)",
  "payor_id": "Z95397",
  "seq_id": 76
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f76"
  },
  "payor_name": "Ameritas Life Insurance Corp. (Eligibility and Remits Only)",
  "payor_id": "47009",
  "seq_id": 77
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f77"
  },
  "payor_name": "Anthem Dental (Remits and Eligibility Only)",
  "payor_id": "84105",
  "seq_id": 78
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f78"
  },
  "payor_name": "Apex Benefit Services",
  "payor_id": "34196",
  "seq_id": 79
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f79"
  },
  "payor_name": "Arise Health Plan",
  "payor_id": "39185",
  "seq_id": 80
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f7a"
  },
  "payor_name": "Arizona Physicians IPA (UHC) Community Plan",
  "payor_id": "3432",
  "seq_id": 81
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f7b"
  },
  "payor_name": "Arkansas Empower Healthcare Solutions",
  "payor_id": "12956",
  "seq_id": 82
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f7c"
  },
  "payor_name": "ArmadaCare Global",
  "payor_id": "90886",
  "seq_id": 83
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f7d"
  },
  "payor_name": "Aspirus Health Plan (Medicare Advantage) (DOS 1/1/2021 and after)",
  "payor_id": "36483",
  "seq_id": 84
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f7e"
  },
  "payor_name": "ASR Health Benefits",
  "payor_id": "38265",
  "seq_id": 85
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f7f"
  },
  "payor_name": "Asuris Northwest Health",
  "payor_id": "93221",
  "seq_id": 86
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f80"
  },
  "payor_name": "Atrio Health Plans",
  "payor_id": "Z1351",
  "seq_id": 87
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f81"
  },
  "payor_name": "AultCare",
  "payor_id": "Z1224",
  "seq_id": 88
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f82"
  },
  "payor_name": "Author By Humana (DOS 5/31/2022 and prior)",
  "payor_id": "61108",
  "seq_id": 89
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f83"
  },
  "payor_name": "Automated Benefit Services",
  "payor_id": "38259",
  "seq_id": 90
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f84"
  },
  "payor_name": "Avera Health Plans",
  "payor_id": "46045",
  "seq_id": 91
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f85"
  },
  "payor_name": "AvMed",
  "payor_id": "59274",
  "seq_id": 92
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f86"
  },
  "payor_name": "Bankers Fidelity Life Assurance Co",
  "payor_id": "Z95871",
  "seq_id": 93
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f87"
  },
  "payor_name": "Bankers Life and Casualty",
  "payor_id": "61263",
  "seq_id": 94
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f88"
  },
  "payor_name": "Banner Medicare Advantage Plus",
  "payor_id": "84324",
  "seq_id": 95
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f89"
  },
  "payor_name": "Banner Medicare Advantage Prime",
  "payor_id": "84323",
  "seq_id": 96
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f8a"
  },
  "payor_name": "Banner- University Family Care (University of AZ Health Plans)",
  "payor_id": "66901",
  "seq_id": 97
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f8b"
  },
  "payor_name": "BayCare Select Health Plans Inc",
  "payor_id": "81079",
  "seq_id": 98
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f8c"
  },
  "payor_name": "BCBS - National Accounts Dental (Eligibility Only)",
  "payor_id": "DL008",
  "seq_id": 99
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f8d"
  },
  "payor_name": "BCBS Alabama",
  "payor_id": "SB510",
  "seq_id": 100
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f8e"
  },
  "payor_name": "BCBS Alabama Institutional Eligibility Only",
  "payor_id": "Z96002",
  "seq_id": 101
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f8f"
  },
  "payor_name": "BCBS Arizona",
  "payor_id": "SB530",
  "seq_id": 102
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f90"
  },
  "payor_name": "BCBS Arkansas",
  "payor_id": "SB520",
  "seq_id": 103
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f91"
  },
  "payor_name": "BCBS Colorado",
  "payor_id": "SB550",
  "seq_id": 104
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f92"
  },
  "payor_name": "BCBS Connecticut",
  "payor_id": "SB560",
  "seq_id": 105
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f93"
  },
  "payor_name": "BCBS DC and National Capital Area",
  "payor_id": "SB580",
  "seq_id": 106
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f94"
  },
  "payor_name": "BCBS Delaware",
  "payor_id": "SB570",
  "seq_id": 107
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f95"
  },
  "payor_name": "BCBS Florida",
  "payor_id": "SB590",
  "seq_id": 108
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f96"
  },
  "payor_name": "BCBS Georgia",
  "payor_id": "SB601",
  "seq_id": 109
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f97"
  },
  "payor_name": "BCBS Hawaii - HMSA",
  "payor_id": "SB971",
  "seq_id": 110
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f98"
  },
  "payor_name": "BCBS Illinois",
  "payor_id": "SB621",
  "seq_id": 111
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f99"
  },
  "payor_name": "BCBS Indiana",
  "payor_id": "ZBIN0",
  "seq_id": 112
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f9a"
  },
  "payor_name": "BCBS Iowa (Wellmark)",
  "payor_id": "SB640",
  "seq_id": 113
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f9b"
  },
  "payor_name": "BCBS Kansas",
  "payor_id": "SB650",
  "seq_id": 114
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f9c"
  },
  "payor_name": "BCBS Kansas City",
  "payor_id": "SB740",
  "seq_id": 115
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f9d"
  },
  "payor_name": "BCBS Kentucky",
  "payor_id": "ZBKY0",
  "seq_id": 116
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f9e"
  },
  "payor_name": "BCBS Louisiana",
  "payor_id": "SB670",
  "seq_id": 117
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4f9f"
  },
  "payor_name": "BCBS Maine",
  "payor_id": "SB680",
  "seq_id": 118
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fa0"
  },
  "payor_name": "BCBS Maryland (CareFirst)",
  "payor_id": "SB690",
  "seq_id": 119
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fa1"
  },
  "payor_name": "BCBS Massachusetts",
  "payor_id": "SB700",
  "seq_id": 120
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fa2"
  },
  "payor_name": "BCBS Michigan",
  "payor_id": "SB710",
  "seq_id": 121
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fa3"
  },
  "payor_name": "BCBS Michigan Institutional Eligibility Only",
  "payor_id": "Z1380",
  "seq_id": 122
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fa4"
  },
  "payor_name": "BCBS Minnesota",
  "payor_id": "SB720",
  "seq_id": 123
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fa5"
  },
  "payor_name": "BCBS Minnesota Blue Plus Medicaid",
  "payor_id": "Z96439",
  "seq_id": 124
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fa6"
  },
  "payor_name": "BCBS Mississippi",
  "payor_id": "SB730",
  "seq_id": 125
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fa7"
  },
  "payor_name": "BCBS Mississippi - State Employee",
  "payor_id": "SB732",
  "seq_id": 126
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fa8"
  },
  "payor_name": "BCBS Missouri",
  "payor_id": "SB741",
  "seq_id": 127
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fa9"
  },
  "payor_name": "BCBS Montana",
  "payor_id": "7450",
  "seq_id": 128
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4faa"
  },
  "payor_name": "BCBS Nebraska",
  "payor_id": "SB760",
  "seq_id": 129
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fab"
  },
  "payor_name": "BCBS Nevada",
  "payor_id": "SB765",
  "seq_id": 130
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fac"
  },
  "payor_name": "BCBS New Hampshire",
  "payor_id": "SB770",
  "seq_id": 131
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fad"
  },
  "payor_name": "BCBS New Jersey",
  "payor_id": "22099",
  "seq_id": 132
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fae"
  },
  "payor_name": "BCBS New Mexico",
  "payor_id": "SB790",
  "seq_id": 133
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4faf"
  },
  "payor_name": "BCBS New York Central Syracuse",
  "payor_id": "SB805",
  "seq_id": 134
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fb0"
  },
  "payor_name": "BCBS New York Empire",
  "payor_id": "SB803",
  "seq_id": 135
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fb1"
  },
  "payor_name": "BCBS New York Northeastern",
  "payor_id": "SB800",
  "seq_id": 136
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fb2"
  },
  "payor_name": "BCBS New York Rochester",
  "payor_id": "SB804",
  "seq_id": 137
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fb3"
  },
  "payor_name": "BCBS New York Utica-Watertown",
  "payor_id": "SB806",
  "seq_id": 138
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fb4"
  },
  "payor_name": "BCBS New York Western",
  "payor_id": "SB801",
  "seq_id": 139
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fb5"
  },
  "payor_name": "BCBS North Carolina",
  "payor_id": "SB810",
  "seq_id": 140
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fb6"
  },
  "payor_name": "BCBS North Dakota",
  "payor_id": "SB820",
  "seq_id": 141
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fb7"
  },
  "payor_name": "BCBS of New York (Excellus)",
  "payor_id": "10323",
  "seq_id": 142
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fb8"
  },
  "payor_name": "BCBS Ohio",
  "payor_id": "ZBOH0",
  "seq_id": 143
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fb9"
  },
  "payor_name": "BCBS Oklahoma",
  "payor_id": "SB840",
  "seq_id": 144
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fba"
  },
  "payor_name": "BCBS Oregon (Regence)",
  "payor_id": "SB850",
  "seq_id": 145
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fbb"
  },
  "payor_name": "BCBS Pennsylvania - Highmark",
  "payor_id": "SB865",
  "seq_id": 146
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fbc"
  },
  "payor_name": "BCBS Pennsylvania (Highmark) Institutional Eligibility Only",
  "payor_id": "Z1440",
  "seq_id": 147
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fbd"
  },
  "payor_name": "BCBS Puerto Rico",
  "payor_id": "Z0065",
  "seq_id": 148
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fbe"
  },
  "payor_name": "BCBS Puerto Rico - Medicare Advantage",
  "payor_id": "973MA",
  "seq_id": 149
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fbf"
  },
  "payor_name": "BCBS Real-time",
  "payor_id": "Z1066",
  "seq_id": 150
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fc0"
  },
  "payor_name": "BCBS Rhode Island",
  "payor_id": "7420",
  "seq_id": 151
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fc1"
  },
  "payor_name": "BCBS South Carolina",
  "payor_id": "SB880",
  "seq_id": 152
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fc2"
  },
  "payor_name": "BCBS South Dakota",
  "payor_id": "2490",
  "seq_id": 153
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fc3"
  },
  "payor_name": "BCBS Tennessee",
  "payor_id": "SB890",
  "seq_id": 154
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fc4"
  },
  "payor_name": "BCBS Tennessee - Bluecare",
  "payor_id": "SB390",
  "seq_id": 155
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fc5"
  },
  "payor_name": "BCBS Tennessee - TennCare Select",
  "payor_id": "SB893",
  "seq_id": 156
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fc6"
  },
  "payor_name": "BCBS Tennessee HMO",
  "payor_id": "SB891",
  "seq_id": 157
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fc7"
  },
  "payor_name": "BCBS Tennessee Memphis",
  "payor_id": "SB892",
  "seq_id": 158
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fc8"
  },
  "payor_name": "BCBS Texas",
  "payor_id": "SB900",
  "seq_id": 159
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fc9"
  },
  "payor_name": "BCBS Texas Medicaid",
  "payor_id": "66001",
  "seq_id": 160
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fca"
  },
  "payor_name": "BCBS Utah (Regence)",
  "payor_id": "SB910",
  "seq_id": 161
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fcb"
  },
  "payor_name": "BCBS Vermont",
  "payor_id": "SB915",
  "seq_id": 162
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fcc"
  },
  "payor_name": "BCBS Virginia",
  "payor_id": "SB923",
  "seq_id": 163
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fcd"
  },
  "payor_name": "BCBS West Virginia",
  "payor_id": "SB941",
  "seq_id": 164
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fce"
  },
  "payor_name": "BCBS Western New York Medicaid/CHP",
  "payor_id": "246",
  "seq_id": 165
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fcf"
  },
  "payor_name": "BCBS Wisconsin",
  "payor_id": "SB950",
  "seq_id": 166
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fd0"
  },
  "payor_name": "BCBS Wyoming",
  "payor_id": "SB960",
  "seq_id": 167
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fd1"
  },
  "payor_name": "Behavioral Health Systems",
  "payor_id": "63100",
  "seq_id": 168
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fd2"
  },
  "payor_name": "Benefit Administrative Systems",
  "payor_id": "36149",
  "seq_id": 169
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fd3"
  },
  "payor_name": "Benefit Management Inc of KS",
  "payor_id": "48611",
  "seq_id": 170
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fd4"
  },
  "payor_name": "Benefit Management Services",
  "payor_id": "56139",
  "seq_id": 171
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fd5"
  },
  "payor_name": "Better Health Plans - Three Rivers Administrative Services (TRAS)",
  "payor_id": "62183",
  "seq_id": 172
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fd6"
  },
  "payor_name": "Bind",
  "payor_id": "25463",
  "seq_id": 173
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fd7"
  },
  "payor_name": "Blue Advantage of Louisiana",
  "payor_id": "72107",
  "seq_id": 174
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fd8"
  },
  "payor_name": "Blue Choice Health Plan of South Carolina",
  "payor_id": "SB884",
  "seq_id": 175
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fd9"
  },
  "payor_name": "Blue Cross Blue Shield of Arizona Advantage (DOS prior to 01/01/2020)",
  "payor_id": "77078",
  "seq_id": 176
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fda"
  },
  "payor_name": "Blue Cross California",
  "payor_id": "SB040",
  "seq_id": 177
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fdb"
  },
  "payor_name": "Blue Cross Community Option/66005",
  "payor_id": "MCDIL",
  "seq_id": 178
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fdc"
  },
  "payor_name": "Blue Cross of Alaska and Washington - Premera",
  "payor_id": "SB930",
  "seq_id": 179
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fdd"
  },
  "payor_name": "Blue Cross of Idaho",
  "payor_id": "ZBID0",
  "seq_id": 180
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fde"
  },
  "payor_name": "Blue Cross of Pennsylvania -Capital Blue Cross",
  "payor_id": "23045",
  "seq_id": 181
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fdf"
  },
  "payor_name": "Blue Shield California",
  "payor_id": "SB542",
  "seq_id": 182
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fe0"
  },
  "payor_name": "Blue Shield of Idaho (Regence)",
  "payor_id": "ZBID1",
  "seq_id": 183
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fe1"
  },
  "payor_name": "Blue Shield Washington - Regence",
  "payor_id": "7451",
  "seq_id": 184
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fe2"
  },
  "payor_name": "Boon Administrative Service Inc",
  "payor_id": "BOONG",
  "seq_id": 185
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fe3"
  },
  "payor_name": "Boon-Chapman Benefit Administrators Inc",
  "payor_id": "74238",
  "seq_id": 186
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fe4"
  },
  "payor_name": "Boston Medical Center Healthnet",
  "payor_id": "13337",
  "seq_id": 187
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fe5"
  },
  "payor_name": "Braven Health",
  "payor_id": "84367",
  "seq_id": 188
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fe6"
  },
  "payor_name": "Bravo Health",
  "payor_id": "52192",
  "seq_id": 189
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fe7"
  },
  "payor_name": "BridgeSpan",
  "payor_id": "BRIDG",
  "seq_id": 190
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fe8"
  },
  "payor_name": "Bridgeway Arizona",
  "payor_id": "68054",
  "seq_id": 191
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fe9"
  },
  "payor_name": "Bright Health Medicare Advantage (DOS 12/31/2021 and Prior)",
  "payor_id": "BRT01",
  "seq_id": 192
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fea"
  },
  "payor_name": "Bright Health Plan Commercial (DOS 12/31/2021 and Prior)",
  "payor_id": "CB186",
  "seq_id": 193
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4feb"
  },
  "payor_name": "Bright Healthcare",
  "payor_id": "BRGHT",
  "seq_id": 194
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fec"
  },
  "payor_name": "Brokers National - Dental (Eligibility Only)",
  "payor_id": "10783",
  "seq_id": 195
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fed"
  },
  "payor_name": "Brown & Toland Sutter",
  "payor_id": "BTSS1",
  "seq_id": 196
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fee"
  },
  "payor_name": "C & O Employees' Hospital Association",
  "payor_id": "23708",
  "seq_id": 197
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fef"
  },
  "payor_name": "California Health & Wellness",
  "payor_id": "68047",
  "seq_id": 198
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ff0"
  },
  "payor_name": "Cal-Optima Direct",
  "payor_id": "CALOP",
  "seq_id": 199
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ff1"
  },
  "payor_name": "Capital District Physician's Health Plan (CDPHP)",
  "payor_id": "SX065",
  "seq_id": 200
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ff2"
  },
  "payor_name": "Capital Health Plan",
  "payor_id": "95112",
  "seq_id": 201
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ff3"
  },
  "payor_name": "Capitol Administrators",
  "payor_id": "68011",
  "seq_id": 202
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ff4"
  },
  "payor_name": "CapRock HealthPlans",
  "payor_id": "CAPHP",
  "seq_id": 203
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ff5"
  },
  "payor_name": "Care Management Company (CMO) (Eligibility Only)",
  "payor_id": "Z98245",
  "seq_id": 204
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ff6"
  },
  "payor_name": "Care N Care",
  "payor_id": "66010",
  "seq_id": 205
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ff7"
  },
  "payor_name": "CareFirst Administrators/NCAS - Charlotte",
  "payor_id": "75191",
  "seq_id": 206
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ff8"
  },
  "payor_name": "CareFirst Administrators/NCAS - Fairfax, VA",
  "payor_id": "75190",
  "seq_id": 207
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ff9"
  },
  "payor_name": "CareFirst BlueCross BlueShield Community Health Plan Maryland",
  "payor_id": "45281",
  "seq_id": 208
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ffa"
  },
  "payor_name": "Carelon Behavioral Health Strategies",
  "payor_id": "Z1226",
  "seq_id": 209
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ffb"
  },
  "payor_name": "Carelon Health-Advanced Primary (fka Caremore)",
  "payor_id": "CARMO",
  "seq_id": 210
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ffc"
  },
  "payor_name": "CareOregon",
  "payor_id": "Z1060",
  "seq_id": 211
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ffd"
  },
  "payor_name": "CarePartners of Connecticut",
  "payor_id": "16307",
  "seq_id": 212
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4ffe"
  },
  "payor_name": "CarePlus Health Plan",
  "payor_id": "65031",
  "seq_id": 213
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f4fff"
  },
  "payor_name": "CareSource",
  "payor_id": "325",
  "seq_id": 214
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5000"
  },
  "payor_name": "CareSource Arkansas",
  "payor_id": "ARCS1",
  "seq_id": 215
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5001"
  },
  "payor_name": "Caresource Georgia",
  "payor_id": "GACS1",
  "seq_id": 216
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5002"
  },
  "payor_name": "CareSource Indiana",
  "payor_id": "INCS1",
  "seq_id": 217
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5003"
  },
  "payor_name": "Caresource KY (Humana)",
  "payor_id": "KYCS1",
  "seq_id": 218
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5004"
  },
  "payor_name": "CareSource North Carolina",
  "payor_id": "NCCS1",
  "seq_id": 219
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5005"
  },
  "payor_name": "Caresource of Michigan Medicaid (MIMCDCS1)",
  "payor_id": "MIMD1",
  "seq_id": 220
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5006"
  },
  "payor_name": "CareSource Ohio",
  "payor_id": "31114",
  "seq_id": 221
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5007"
  },
  "payor_name": "Caresource West Virginia",
  "payor_id": "WVCS1",
  "seq_id": 222
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5008"
  },
  "payor_name": "CBA Blue/Blue Benefit Administrators",
  "payor_id": "3036",
  "seq_id": 223
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5009"
  },
  "payor_name": "Celtic Insurance",
  "payor_id": "68063",
  "seq_id": 224
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f500a"
  },
  "payor_name": "Cenpatico - Texas",
  "payor_id": "CBHTX",
  "seq_id": 225
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f500b"
  },
  "payor_name": "Cenpatico Behavioral Health",
  "payor_id": "68068",
  "seq_id": 226
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f500c"
  },
  "payor_name": "Centene Health Plans",
  "payor_id": "68069",
  "seq_id": 227
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f500d"
  },
  "payor_name": "Centene TPA",
  "payor_id": "42139",
  "seq_id": 228
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f500e"
  },
  "payor_name": "Centivo",
  "payor_id": "45564",
  "seq_id": 229
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f500f"
  },
  "payor_name": "Central California Alliance for Health",
  "payor_id": "SX169",
  "seq_id": 230
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5010"
  },
  "payor_name": "Central Massachusetts Health LLC",
  "payor_id": "86220",
  "seq_id": 231
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5011"
  },
  "payor_name": "Central Pennsylvania Teamsters Fund",
  "payor_id": "23626",
  "seq_id": 232
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5012"
  },
  "payor_name": "Central Reserve Life Ins Co- Medicare Supplement (Eligibility Only)",
  "payor_id": "Z90023",
  "seq_id": 233
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5013"
  },
  "payor_name": "Central States Health & Welfare Funds",
  "payor_id": "36215",
  "seq_id": 234
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5014"
  },
  "payor_name": "Central States Indemnity (Eligibility & Remits Only)",
  "payor_id": "Z95701",
  "seq_id": 235
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5015"
  },
  "payor_name": "CHAMPVA/VA-HAC",
  "payor_id": "84146",
  "seq_id": 236
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5016"
  },
  "payor_name": "CHCS Services Inc",
  "payor_id": "Z10899",
  "seq_id": 237
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5017"
  },
  "payor_name": "Children's Mercy PCN",
  "payor_id": "10906",
  "seq_id": 238
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5018"
  },
  "payor_name": "CHP Direct",
  "payor_id": "10907",
  "seq_id": 239
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5019"
  },
  "payor_name": "Christian Brothers Services",
  "payor_id": "38308",
  "seq_id": 240
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f501a"
  },
  "payor_name": "Christus Health Medicare Advantage",
  "payor_id": "10629",
  "seq_id": 241
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f501b"
  },
  "payor_name": "Christus Health Plan Medicaid",
  "payor_id": "45210",
  "seq_id": 242
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f501c"
  },
  "payor_name": "Christus Health Plan of Texas and Louisiana",
  "payor_id": "52106",
  "seq_id": 243
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f501d"
  },
  "payor_name": "CIGNA (Connecticut General, Equicor, Equitable)",
  "payor_id": "62308",
  "seq_id": 244
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f501e"
  },
  "payor_name": "CIGNA Behavioral Health",
  "payor_id": "SX071",
  "seq_id": 245
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f501f"
  },
  "payor_name": "CIGNA HealthCare for Seniors - AZ Medicare",
  "payor_id": "86033",
  "seq_id": 246
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5020"
  },
  "payor_name": "Clear Health Alliance",
  "payor_id": "CLEAR",
  "seq_id": 247
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5021"
  },
  "payor_name": "Clear Spring Health",
  "payor_id": "85468",
  "seq_id": 248
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5022"
  },
  "payor_name": "Clover Health (DOS prior to 1/1/23)",
  "payor_id": "77023",
  "seq_id": 249
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5023"
  },
  "payor_name": "Clover Health LLC",
  "payor_id": "13285",
  "seq_id": 250
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5024"
  },
  "payor_name": "Collective Health Administrators",
  "payor_id": "36479",
  "seq_id": 251
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5025"
  },
  "payor_name": "Colonial Penn Life Insurance",
  "payor_id": "62065",
  "seq_id": 252
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5026"
  },
  "payor_name": "Colorado Access (Denver, CO)",
  "payor_id": "84129",
  "seq_id": 253
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5027"
  },
  "payor_name": "Common Ground Healthcare Cooperative",
  "payor_id": "77170",
  "seq_id": 254
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5028"
  },
  "payor_name": "Community Care Managed Health Care Plans of Oklahoma",
  "payor_id": "73143",
  "seq_id": 255
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5029"
  },
  "payor_name": "Community Health Choice",
  "payor_id": "48145",
  "seq_id": 256
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f502a"
  },
  "payor_name": "Community Health Choice Texas HIM",
  "payor_id": "60495",
  "seq_id": 257
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f502b"
  },
  "payor_name": "Community Health Group of SD",
  "payor_id": "Z1700",
  "seq_id": 258
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f502c"
  },
  "payor_name": "Community Health Plan of Washington",
  "payor_id": "CHPWA",
  "seq_id": 259
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f502d"
  },
  "payor_name": "Community HealthFirst Medicare (CHF Medicare Advantage)",
  "payor_id": "10421",
  "seq_id": 260
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f502e"
  },
  "payor_name": "ComPsych",
  "payor_id": "37363",
  "seq_id": 261
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f502f"
  },
  "payor_name": "Concentrix Insurance Solutions",
  "payor_id": "Z95754",
  "seq_id": 262
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5030"
  },
  "payor_name": "Connecticare - Medicare",
  "payor_id": "78375",
  "seq_id": 263
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5031"
  },
  "payor_name": "ConnectiCare Inc",
  "payor_id": "6105",
  "seq_id": 264
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5032"
  },
  "payor_name": "Consociate Group",
  "payor_id": "37135",
  "seq_id": 265
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5033"
  },
  "payor_name": "Consolidated Associates Railroad",
  "payor_id": "75284",
  "seq_id": 266
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5034"
  },
  "payor_name": "Constellation Health",
  "payor_id": "L0260",
  "seq_id": 267
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5035"
  },
  "payor_name": "Consumer Mutual Insurance of Michigan",
  "payor_id": "11051",
  "seq_id": 268
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5036"
  },
  "payor_name": "Contigo Health",
  "payor_id": "34158",
  "seq_id": 269
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5037"
  },
  "payor_name": "Continental General Ins Co Medicare Supplement",
  "payor_id": "10540",
  "seq_id": 270
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5038"
  },
  "payor_name": "Continental General Insurance Company - Medicare Supplement",
  "payor_id": "13193",
  "seq_id": 271
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5039"
  },
  "payor_name": "Contra Costa Health Plan (CCHS)",
  "payor_id": "Z1800",
  "seq_id": 272
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f503a"
  },
  "payor_name": "Cook Children's Health Plan",
  "payor_id": "CCHP1",
  "seq_id": 273
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f503b"
  },
  "payor_name": "Cook Children's Star Plan",
  "payor_id": "CCHP9",
  "seq_id": 274
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f503c"
  },
  "payor_name": "Cook Medical Group",
  "payor_id": "60065",
  "seq_id": 275
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f503d"
  },
  "payor_name": "Cooperative Benefit Administrators (CBA)",
  "payor_id": "10500",
  "seq_id": 276
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f503e"
  },
  "payor_name": "CoreSource (NC & IN)",
  "payor_id": "35180",
  "seq_id": 277
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f503f"
  },
  "payor_name": "Country Life Insurance Company (Eligibility Only)",
  "payor_id": "62553",
  "seq_id": 278
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5040"
  },
  "payor_name": "County Care",
  "payor_id": "6541",
  "seq_id": 279
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5041"
  },
  "payor_name": "Covenant Administrators Inc (Atlanta GA)",
  "payor_id": "58102",
  "seq_id": 280
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5042"
  },
  "payor_name": "Cox Health Plan",
  "payor_id": "19",
  "seq_id": 281
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5043"
  },
  "payor_name": "CSI Life Insurance Company (Eligibility & Remits Only)",
  "payor_id": "Z95707",
  "seq_id": 282
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5044"
  },
  "payor_name": "Cypress Benefit Administrators (Eligibility Only)",
  "payor_id": "13113",
  "seq_id": 283
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5045"
  },
  "payor_name": "Davis Vision",
  "payor_id": "V0QJA",
  "seq_id": 284
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5046"
  },
  "payor_name": "Dean Health Plan",
  "payor_id": "39113",
  "seq_id": 285
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5047"
  },
  "payor_name": "Delta Dental  - Nevada",
  "payor_id": "10715",
  "seq_id": 286
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5048"
  },
  "payor_name": "Delta Dental - Alabama (Eligibility Only)",
  "payor_id": "10709",
  "seq_id": 287
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5049"
  },
  "payor_name": "Delta Dental - West Virginia (Eligibility Only)",
  "payor_id": "31096",
  "seq_id": 288
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f504a"
  },
  "payor_name": "Delta Dental Ins. Co. - Florida",
  "payor_id": "10710",
  "seq_id": 289
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f504b"
  },
  "payor_name": "Delta Dental Ins. Co. - Georgia",
  "payor_id": "10711",
  "seq_id": 290
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f504c"
  },
  "payor_name": "Delta Dental Ins. Co. - Louisiana",
  "payor_id": "10712",
  "seq_id": 291
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f504d"
  },
  "payor_name": "Delta Dental Ins. Co. - Montana",
  "payor_id": "10714",
  "seq_id": 292
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f504e"
  },
  "payor_name": "Delta Dental Ins. Co. - Texas",
  "payor_id": "10716",
  "seq_id": 293
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f504f"
  },
  "payor_name": "Delta Dental Ins. Co. - Utah",
  "payor_id": "94276",
  "seq_id": 294
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5050"
  },
  "payor_name": "Delta Dental of Arizona (AZ)",
  "payor_id": "86027",
  "seq_id": 295
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5051"
  },
  "payor_name": "Delta Dental of Arkansas (AR)",
  "payor_id": "CDAR1",
  "seq_id": 296
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5052"
  },
  "payor_name": "Delta Dental of California",
  "payor_id": "10705",
  "seq_id": 297
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5053"
  },
  "payor_name": "Delta Dental of Colorado (Eligibility Only)",
  "payor_id": "84056",
  "seq_id": 298
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5054"
  },
  "payor_name": "Delta Dental of Connecticut",
  "payor_id": "10719",
  "seq_id": 299
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5055"
  },
  "payor_name": "Delta Dental of Delaware",
  "payor_id": "10720",
  "seq_id": 300
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5056"
  },
  "payor_name": "Delta Dental of Illinois (IL)",
  "payor_id": "47589",
  "seq_id": 301
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5057"
  },
  "payor_name": "Delta Dental of Indiana (IN)",
  "payor_id": "CDIN1",
  "seq_id": 302
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5058"
  },
  "payor_name": "Delta Dental of Iowa (IA)",
  "payor_id": "CDIA1",
  "seq_id": 303
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5059"
  },
  "payor_name": "Delta Dental of Kansas (KS)",
  "payor_id": "CDKS1",
  "seq_id": 304
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f505a"
  },
  "payor_name": "Delta Dental of Kentucky (KY)",
  "payor_id": "CDKY1",
  "seq_id": 305
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f505b"
  },
  "payor_name": "Delta Dental of Maryland",
  "payor_id": "10723",
  "seq_id": 306
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f505c"
  },
  "payor_name": "Delta Dental of Massachusetts (MA)",
  "payor_id": "DDLMA",
  "seq_id": 307
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f505d"
  },
  "payor_name": "Delta Dental of Michigan (Eligibility Only)",
  "payor_id": "CDMI0",
  "seq_id": 308
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f505e"
  },
  "payor_name": "Delta Dental of Minnesota",
  "payor_id": "10725",
  "seq_id": 309
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f505f"
  },
  "payor_name": "Delta Dental of Missouri (MO)",
  "payor_id": "43090",
  "seq_id": 310
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5060"
  },
  "payor_name": "Delta Dental of New Jersey",
  "payor_id": "10727",
  "seq_id": 311
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5061"
  },
  "payor_name": "Delta Dental of New York",
  "payor_id": "10729",
  "seq_id": 312
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5062"
  },
  "payor_name": "Delta Dental of North Carolina",
  "payor_id": "10730",
  "seq_id": 313
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5063"
  },
  "payor_name": "Delta Dental of North Carolina (NC)",
  "payor_id": "56101",
  "seq_id": 314
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5064"
  },
  "payor_name": "Delta Dental of North Dakota",
  "payor_id": "10731",
  "seq_id": 315
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5065"
  },
  "payor_name": "Delta Dental of Northeast - Maine, NH, VT (Eligibility Only)",
  "payor_id": "2027",
  "seq_id": 316
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5066"
  },
  "payor_name": "Delta Dental of Ohio",
  "payor_id": "10732",
  "seq_id": 317
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5067"
  },
  "payor_name": "Delta Dental of Oklahoma",
  "payor_id": "11175",
  "seq_id": 318
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5068"
  },
  "payor_name": "Delta Dental of Pennsylvania",
  "payor_id": "10733",
  "seq_id": 319
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5069"
  },
  "payor_name": "Delta Dental of Rhode Island",
  "payor_id": "10734",
  "seq_id": 320
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f506a"
  },
  "payor_name": "Delta Dental of South Carolina (SC)",
  "payor_id": "43091",
  "seq_id": 321
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f506b"
  },
  "payor_name": "Delta Dental of Tennessee",
  "payor_id": "10735",
  "seq_id": 322
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f506c"
  },
  "payor_name": "Delta Dental of Virginia",
  "payor_id": "10736",
  "seq_id": 323
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f506d"
  },
  "payor_name": "Delta Dental of Washington DC",
  "payor_id": "10737",
  "seq_id": 324
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f506e"
  },
  "payor_name": "Delta Dental of Washington State",
  "payor_id": "10738",
  "seq_id": 325
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f506f"
  },
  "payor_name": "Delta Dental of Wisconsin (WI)",
  "payor_id": "39069",
  "seq_id": 326
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5070"
  },
  "payor_name": "DeltaCare USA - Claims",
  "payor_id": "10740",
  "seq_id": 327
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5071"
  },
  "payor_name": "DeltaCare USA - Encounters",
  "payor_id": "10741",
  "seq_id": 328
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5072"
  },
  "payor_name": "Dental Benefit Providers",
  "payor_id": "Z96290",
  "seq_id": 329
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5073"
  },
  "payor_name": "Dental Health and Wellness - Envolve Dental (Eligibility Only)",
  "payor_id": "E46278",
  "seq_id": 330
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5074"
  },
  "payor_name": "DentaQuest Dental",
  "payor_id": "CX014",
  "seq_id": 331
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5075"
  },
  "payor_name": "Denver Health Medical Plan",
  "payor_id": "84135",
  "seq_id": 332
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5076"
  },
  "payor_name": "Devoted Health Plans",
  "payor_id": "DEVOT",
  "seq_id": 333
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5077"
  },
  "payor_name": "District No. 9, I.A. of M. & A. W. Welfare Trust",
  "payor_id": "11098",
  "seq_id": 334
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5078"
  },
  "payor_name": "Diversified Administration",
  "payor_id": "6102",
  "seq_id": 335
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5079"
  },
  "payor_name": "DMBA (Deseret Mutual Benefit Administrators [SX105]",
  "payor_id": "Z1013",
  "seq_id": 336
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f507a"
  },
  "payor_name": "Doctors Healthcare Plans",
  "payor_id": "DRHCP",
  "seq_id": 337
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f507b"
  },
  "payor_name": "Doral Dental Plan WI",
  "payor_id": "10780",
  "seq_id": 338
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f507c"
  },
  "payor_name": "Driscoll Childrens Health Plan",
  "payor_id": "DCHCH",
  "seq_id": 339
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f507d"
  },
  "payor_name": "El Paso First - CHIP",
  "payor_id": "12T27",
  "seq_id": 340
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f507e"
  },
  "payor_name": "Elderplan",
  "payor_id": "31625",
  "seq_id": 341
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f507f"
  },
  "payor_name": "Elips Life Insurance Company (Remits & Eligibility Only)",
  "payor_id": "Z96801",
  "seq_id": 342
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5080"
  },
  "payor_name": "EMI Health",
  "payor_id": "Z1011",
  "seq_id": 343
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5081"
  },
  "payor_name": "Employee Benefit Management Corp (EBMC)",
  "payor_id": "31074",
  "seq_id": 344
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5082"
  },
  "payor_name": "Employee Benefit Management Services Inc (EBMS)",
  "payor_id": "81039",
  "seq_id": 345
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5083"
  },
  "payor_name": "Employee Benefit Services - Dental",
  "payor_id": "10784",
  "seq_id": 346
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5084"
  },
  "payor_name": "EPSI, Inc",
  "payor_id": "11011",
  "seq_id": 347
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5085"
  },
  "payor_name": "Erie Family Life Insurance Company (Eligibility Only)",
  "payor_id": "13284",
  "seq_id": 348
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5086"
  },
  "payor_name": "Essence Healthcare",
  "payor_id": "20818",
  "seq_id": 349
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5087"
  },
  "payor_name": "Everence",
  "payor_id": "35605",
  "seq_id": 350
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5088"
  },
  "payor_name": "Everest Reinsurance Company",
  "payor_id": "11164",
  "seq_id": 351
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5089"
  },
  "payor_name": "Evry Health",
  "payor_id": "EH001",
  "seq_id": 352
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f508a"
  },
  "payor_name": "Fallon Community Health Plan",
  "payor_id": "SX072",
  "seq_id": 353
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f508b"
  },
  "payor_name": "Farm Bureau Health Plans",
  "payor_id": "62045",
  "seq_id": 354
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f508c"
  },
  "payor_name": "Fidelis Care New York",
  "payor_id": "11315",
  "seq_id": 355
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f508d"
  },
  "payor_name": "First Ameritas of New York",
  "payor_id": "10232",
  "seq_id": 356
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f508e"
  },
  "payor_name": "First Carolina Care (Commercial)",
  "payor_id": "FCC01",
  "seq_id": 357
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f508f"
  },
  "payor_name": "First Choice Health Network (aka Sound Health)",
  "payor_id": "91131",
  "seq_id": 358
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5090"
  },
  "payor_name": "First Choice Next - Product of Select Health of South Carolina",
  "payor_id": "57103",
  "seq_id": 359
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5091"
  },
  "payor_name": "First Choice VIP Care (SC - DSNP)",
  "payor_id": "32456",
  "seq_id": 360
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5092"
  },
  "payor_name": "First Choice VIP Care Plus",
  "payor_id": "77009",
  "seq_id": 361
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5093"
  },
  "payor_name": "First Community Health Plan",
  "payor_id": "11222",
  "seq_id": 362
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5094"
  },
  "payor_name": "First Medical Network",
  "payor_id": "Z96298",
  "seq_id": 363
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5095"
  },
  "payor_name": "First Reliance Standard",
  "payor_id": "68381",
  "seq_id": 364
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5096"
  },
  "payor_name": "First United American Life Insurance Company",
  "payor_id": "74101",
  "seq_id": 365
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5097"
  },
  "payor_name": "Firstcare",
  "payor_id": "TH003",
  "seq_id": 366
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5098"
  },
  "payor_name": "FirstCare Star Medicaid",
  "payor_id": "94998",
  "seq_id": 367
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5099"
  },
  "payor_name": "Fiserv Wausau",
  "payor_id": "11068",
  "seq_id": 368
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f509a"
  },
  "payor_name": "Flex Compensation - Dental",
  "payor_id": "10799",
  "seq_id": 369
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f509b"
  },
  "payor_name": "Florida Blue Medicare",
  "payor_id": "FBM01",
  "seq_id": 370
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f509c"
  },
  "payor_name": "Florida Combined Life - Dental",
  "payor_id": "10811",
  "seq_id": 371
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f509d"
  },
  "payor_name": "Florida Healthcare Plan",
  "payor_id": "59322",
  "seq_id": 372
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f509e"
  },
  "payor_name": "Food Employers & Bakery Workers Benefit (Eligibility Only)",
  "payor_id": "10776",
  "seq_id": 373
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f509f"
  },
  "payor_name": "Freedom Health Plan",
  "payor_id": "41212",
  "seq_id": 374
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50a0"
  },
  "payor_name": "Friday Health Plans",
  "payor_id": "H0657",
  "seq_id": 375
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50a1"
  },
  "payor_name": "FSL Admin by KBA",
  "payor_id": "11052",
  "seq_id": 376
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50a2"
  },
  "payor_name": "G.M.P. Employers Retiree Trust",
  "payor_id": "11144",
  "seq_id": 377
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50a3"
  },
  "payor_name": "Gateway Health Plan - Medicare Assured",
  "payor_id": "60550",
  "seq_id": 378
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50a4"
  },
  "payor_name": "Gateway Health Plan Ohio-Medicare Assured (Pittsburg PA)",
  "payor_id": "91741",
  "seq_id": 379
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50a5"
  },
  "payor_name": "GEHA (Government Employee Health Association)",
  "payor_id": "57254",
  "seq_id": 380
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50a6"
  },
  "payor_name": "Geisinger Health Plan",
  "payor_id": "75273",
  "seq_id": 381
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50a7"
  },
  "payor_name": "Gemcare Health Plan",
  "payor_id": "MCS03",
  "seq_id": 382
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50a8"
  },
  "payor_name": "GHI HMO",
  "payor_id": "25531",
  "seq_id": 383
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50a9"
  },
  "payor_name": "Gilsbar Inc",
  "payor_id": "7205",
  "seq_id": 384
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50aa"
  },
  "payor_name": "Globe Life & Accident Insurance Company",
  "payor_id": "Z96300",
  "seq_id": 385
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50ab"
  },
  "payor_name": "Golden Rule Insurance Company",
  "payor_id": "37602",
  "seq_id": 386
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50ac"
  },
  "payor_name": "Government Employees Health Association (GEHA)",
  "payor_id": "44054",
  "seq_id": 387
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50ad"
  },
  "payor_name": "Gravie Administrative Services (DOS on or after 10/1/2022)",
  "payor_id": "GRV01",
  "seq_id": 388
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50ae"
  },
  "payor_name": "Great American Life Ins Co- Medicare Supplement (Eligibility Only)",
  "payor_id": "Z90024",
  "seq_id": 389
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50af"
  },
  "payor_name": "Great Southern Life Insurance Company",
  "payor_id": "Z96477",
  "seq_id": 390
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50b0"
  },
  "payor_name": "Great West HealthCare",
  "payor_id": "80705",
  "seq_id": 391
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50b1"
  },
  "payor_name": "Group Health Insurance (GHI) of NY",
  "payor_id": "13551",
  "seq_id": 392
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50b2"
  },
  "payor_name": "Group Hospitalization and Medical Services",
  "payor_id": "10964",
  "seq_id": 393
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50b3"
  },
  "payor_name": "Guardian Life Insurance Co. of America Dental",
  "payor_id": "10788",
  "seq_id": 394
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50b4"
  },
  "payor_name": "Harmony Health Plan",
  "payor_id": "10514",
  "seq_id": 395
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50b5"
  },
  "payor_name": "Harrington Health Non-EPO",
  "payor_id": "59143",
  "seq_id": 396
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50b6"
  },
  "payor_name": "Harvard Pilgrim Health Care",
  "payor_id": "4271",
  "seq_id": 397
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50b7"
  },
  "payor_name": "Hawaii Management Alliance Association (Honolulu)",
  "payor_id": "48330",
  "seq_id": 398
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50b8"
  },
  "payor_name": "Health Alliance Medical Plans of Illinois",
  "payor_id": "77950",
  "seq_id": 399
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50b9"
  },
  "payor_name": "Health Alliance Plan of Michigan",
  "payor_id": "38224",
  "seq_id": 400
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50ba"
  },
  "payor_name": "Health Choice Pathway",
  "payor_id": "62180",
  "seq_id": 401
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50bb"
  },
  "payor_name": "Health First Health Plans - National",
  "payor_id": "95019",
  "seq_id": 402
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50bc"
  },
  "payor_name": "Health First Insurance",
  "payor_id": "10674",
  "seq_id": 403
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50bd"
  },
  "payor_name": "Health Insurance Plan of Greater NY (HIP)",
  "payor_id": "55247",
  "seq_id": 404
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50be"
  },
  "payor_name": "Health Net National",
  "payor_id": "Z1378",
  "seq_id": 405
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50bf"
  },
  "payor_name": "Health Net of California and Oregon",
  "payor_id": "95567",
  "seq_id": 406
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50c0"
  },
  "payor_name": "Health Net of the Northeast (Eligibility Only)",
  "payor_id": "6108",
  "seq_id": 407
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50c1"
  },
  "payor_name": "Health New England",
  "payor_id": "4286",
  "seq_id": 408
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50c2"
  },
  "payor_name": "Health Partners - Minnesota",
  "payor_id": "SX009",
  "seq_id": 409
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50c3"
  },
  "payor_name": "Health Partners PA",
  "payor_id": "80142",
  "seq_id": 410
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50c4"
  },
  "payor_name": "Health Plan of San Joaquin",
  "payor_id": "68035",
  "seq_id": 411
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50c5"
  },
  "payor_name": "Health Plan of San Mateo (CA Medicaid MCO)",
  "payor_id": "HPSM1",
  "seq_id": 412
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50c6"
  },
  "payor_name": "Health Plan of Upper Ohio Valley",
  "payor_id": "HPOUV",
  "seq_id": 413
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50c7"
  },
  "payor_name": "Health Plans Inc",
  "payor_id": "44273",
  "seq_id": 414
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50c8"
  },
  "payor_name": "Health Services for Children with Special Needs",
  "payor_id": "37290",
  "seq_id": 415
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50c9"
  },
  "payor_name": "Health Share of Oregon",
  "payor_id": "10823",
  "seq_id": 416
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50ca"
  },
  "payor_name": "Health Spring, Inc",
  "payor_id": "63092",
  "seq_id": 417
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50cb"
  },
  "payor_name": "Healthcare Highways",
  "payor_id": "HCH01",
  "seq_id": 418
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50cc"
  },
  "payor_name": "Healthcare Management Admin (HMA)",
  "payor_id": "HMA01",
  "seq_id": 419
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50cd"
  },
  "payor_name": "Healthchoice of AZ",
  "payor_id": "62179",
  "seq_id": 420
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50ce"
  },
  "payor_name": "HealthChoice Oklahoma (DOS on or after 01/01/18)",
  "payor_id": "71064",
  "seq_id": 421
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50cf"
  },
  "payor_name": "Healthcomp Inc",
  "payor_id": "85729",
  "seq_id": 422
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50d0"
  },
  "payor_name": "HEALTHe Exchange",
  "payor_id": "10597",
  "seq_id": 423
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50d1"
  },
  "payor_name": "Healthfirst - Tyler, TX",
  "payor_id": "75234",
  "seq_id": 424
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50d2"
  },
  "payor_name": "Healthfirst New Jersey",
  "payor_id": "10438",
  "seq_id": 425
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50d3"
  },
  "payor_name": "HealthFirst of New York",
  "payor_id": "80141",
  "seq_id": 426
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50d4"
  },
  "payor_name": "Healthgram Inc (fka Primary PhysicianCare Inc)",
  "payor_id": "56144",
  "seq_id": 427
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50d5"
  },
  "payor_name": "HealthNow NY [55204]",
  "payor_id": "Z1093",
  "seq_id": 428
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50d6"
  },
  "payor_name": "HealthPlan of Nevada",
  "payor_id": "10804",
  "seq_id": 429
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50d7"
  },
  "payor_name": "HealthSCOPE Benefits Inc",
  "payor_id": "71063",
  "seq_id": 430
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50d8"
  },
  "payor_name": "HealthSmart Benefit Solutions (formerly American Admin/Gallagher Benefit)",
  "payor_id": "37283",
  "seq_id": 431
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50d9"
  },
  "payor_name": "HealthSmart Benefit Solutions (formerly MAA-Oklahoma)",
  "payor_id": "37256",
  "seq_id": 432
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50da"
  },
  "payor_name": "HealthSmart Benefit Solutions (Wells Fargo)",
  "payor_id": "87815",
  "seq_id": 433
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50db"
  },
  "payor_name": "Healthspan Integrated Care fka Kaiser of Ohio",
  "payor_id": "HLTSPN",
  "seq_id": 434
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50dc"
  },
  "payor_name": "Healthteam Advantage PPO",
  "payor_id": "88250",
  "seq_id": 435
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50dd"
  },
  "payor_name": "Healthy Blue Dual Advantage Louisiana",
  "payor_id": "551",
  "seq_id": 436
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50de"
  },
  "payor_name": "Healthy Blue Louisiana",
  "payor_id": "58532",
  "seq_id": 437
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50df"
  },
  "payor_name": "Healthy Blue Missouri",
  "payor_id": "541",
  "seq_id": 438
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50e0"
  },
  "payor_name": "Healthy Blue Nebraska",
  "payor_id": "544",
  "seq_id": 439
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50e1"
  },
  "payor_name": "Healthy Blue North Carolina",
  "payor_id": "602",
  "seq_id": 440
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50e2"
  },
  "payor_name": "Healthy Blue South Carolina (DOS 1/1/2024 and after)",
  "payor_id": "SB885",
  "seq_id": 441
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50e3"
  },
  "payor_name": "Healthy Blue South Carolina (DOS 12/31/2023 and prior)",
  "payor_id": "Z1248",
  "seq_id": 442
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50e4"
  },
  "payor_name": "Hennepin Health",
  "payor_id": "60058",
  "seq_id": 443
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50e5"
  },
  "payor_name": "Highmark BCBSD Health Options",
  "payor_id": "47181",
  "seq_id": 444
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50e6"
  },
  "payor_name": "Highmark Senior Health Company",
  "payor_id": "15460",
  "seq_id": 445
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50e7"
  },
  "payor_name": "Highmark Wholecare Medicaid",
  "payor_id": "25169",
  "seq_id": 446
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50e8"
  },
  "payor_name": "HM Care Advantage Admin by KBA",
  "payor_id": "11053",
  "seq_id": 447
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50e9"
  },
  "payor_name": "HMA Hawaii",
  "payor_id": "86066",
  "seq_id": 448
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50ea"
  },
  "payor_name": "Hometown Health Plan Nevada",
  "payor_id": "88023",
  "seq_id": 449
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50eb"
  },
  "payor_name": "Hopkins Health Advantage",
  "payor_id": "66003",
  "seq_id": 450
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50ec"
  },
  "payor_name": "Horizon NJ Health",
  "payor_id": "22326",
  "seq_id": 451
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50ed"
  },
  "payor_name": "Humana (and subsidiaries) claims",
  "payor_id": "61101",
  "seq_id": 452
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50ee"
  },
  "payor_name": "Humana Dental",
  "payor_id": "73288",
  "seq_id": 453
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50ef"
  },
  "payor_name": "Humana of Florida",
  "payor_id": "FLHUMA",
  "seq_id": 454
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50f0"
  },
  "payor_name": "Illinois Medicaid",
  "payor_id": "SKIL0",
  "seq_id": 455
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50f1"
  },
  "payor_name": "Imagine 360 Administrators",
  "payor_id": "48143",
  "seq_id": 456
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50f2"
  },
  "payor_name": "Independence Administrators",
  "payor_id": "TA720",
  "seq_id": 457
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50f3"
  },
  "payor_name": "Independence Blue Cross Personal Choice/Blue Card",
  "payor_id": "54704",
  "seq_id": 458
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50f4"
  },
  "payor_name": "Independent Care Health Plan",
  "payor_id": "Z1044",
  "seq_id": 459
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50f5"
  },
  "payor_name": "Independent Health",
  "payor_id": "SX073",
  "seq_id": 460
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50f6"
  },
  "payor_name": "Indiana ProHealth Network (Effective for DOS 12/31/2023 and Prior)",
  "payor_id": "35161",
  "seq_id": 461
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50f7"
  },
  "payor_name": "Indiana University Health Plan (Commercial IUHPLNS)",
  "payor_id": "26212",
  "seq_id": 462
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50f8"
  },
  "payor_name": "Indiana University Health Plan (Medicare)",
  "payor_id": "95444",
  "seq_id": 463
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50f9"
  },
  "payor_name": "Inland Empire Health Plan [IEHP1]",
  "payor_id": "IEHP2",
  "seq_id": 464
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50fa"
  },
  "payor_name": "InnovAge",
  "payor_id": "31182",
  "seq_id": 465
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50fb"
  },
  "payor_name": "Innovation Health",
  "payor_id": "40025",
  "seq_id": 466
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50fc"
  },
  "payor_name": "Insurers Administrative Corporation",
  "payor_id": "86304",
  "seq_id": 467
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50fd"
  },
  "payor_name": "Integra Administrative Group (DE)",
  "payor_id": "51020",
  "seq_id": 468
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50fe"
  },
  "payor_name": "Integra Group",
  "payor_id": "31127",
  "seq_id": 469
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f50ff"
  },
  "payor_name": "International Benefit Administration [11329]",
  "payor_id": "12277",
  "seq_id": 470
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5100"
  },
  "payor_name": "International Medical Card",
  "payor_id": "66053",
  "seq_id": 471
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5101"
  },
  "payor_name": "Iron Road Healthcare fka UPREHS",
  "payor_id": "87042",
  "seq_id": 472
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5102"
  },
  "payor_name": "Island Home Insurance Company",
  "payor_id": "85502",
  "seq_id": 473
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5103"
  },
  "payor_name": "JAI Medical Systems",
  "payor_id": "JAI01",
  "seq_id": 474
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5104"
  },
  "payor_name": "Johns Hopkins Healthcare",
  "payor_id": "52189",
  "seq_id": 475
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5105"
  },
  "payor_name": "Johns Hopkins Healthcare (USFHP)",
  "payor_id": "52123",
  "seq_id": 476
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5106"
  },
  "payor_name": "Kaiser - Self Funded",
  "payor_id": "94320",
  "seq_id": 477
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5107"
  },
  "payor_name": "Kaiser Foundation Health Plan of Colorado",
  "payor_id": "277",
  "seq_id": 478
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5108"
  },
  "payor_name": "Kaiser Foundation Health Plan of Georgia",
  "payor_id": "21313",
  "seq_id": 479
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5109"
  },
  "payor_name": "Kaiser Foundation Health Plan of Hawaii",
  "payor_id": "94123",
  "seq_id": 480
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f510a"
  },
  "payor_name": "Kaiser Foundation Health Plan of Hawaii (DHMO Choice)",
  "payor_id": "10676",
  "seq_id": 481
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f510b"
  },
  "payor_name": "Kaiser Foundation Health Plan of Northern CA Region",
  "payor_id": "94135",
  "seq_id": 482
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f510c"
  },
  "payor_name": "Kaiser Foundation Health Plan of Southern CA Region",
  "payor_id": "94134",
  "seq_id": 483
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f510d"
  },
  "payor_name": "Kaiser Foundation Health Plan of the Mid-Atlantic States",
  "payor_id": "52095",
  "seq_id": 484
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f510e"
  },
  "payor_name": "Kaiser Foundation Health Plan of Washington",
  "payor_id": "91051",
  "seq_id": 485
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f510f"
  },
  "payor_name": "Kaiser Foundation of the Northwest (OR & WA Only)",
  "payor_id": "93079",
  "seq_id": 486
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5110"
  },
  "payor_name": "Kelsey-Seybold",
  "payor_id": "KELSE",
  "seq_id": 487
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5111"
  },
  "payor_name": "Key Benefit Administrators (Indianapolis, IN)",
  "payor_id": "37217",
  "seq_id": 488
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5112"
  },
  "payor_name": "Key Healthy Partners",
  "payor_id": "11044",
  "seq_id": 489
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5113"
  },
  "payor_name": "Key Solution",
  "payor_id": "37323",
  "seq_id": 490
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5114"
  },
  "payor_name": "Keystone First (fka Keystone Mercy)",
  "payor_id": "23284",
  "seq_id": 491
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5115"
  },
  "payor_name": "Keystone First Community HealthChoices",
  "payor_id": "42344",
  "seq_id": 492
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5116"
  },
  "payor_name": "Keystone First VIP Choice",
  "payor_id": "77741",
  "seq_id": 493
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5117"
  },
  "payor_name": "Keystone Health Plan East",
  "payor_id": "7417",
  "seq_id": 494
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5118"
  },
  "payor_name": "Kitsap Physician Services (Eligibility Only)",
  "payor_id": "10341",
  "seq_id": 495
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5119"
  },
  "payor_name": "KSKJ Life (Remits and Eligibility Only)",
  "payor_id": "Z95791",
  "seq_id": 496
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f511a"
  },
  "payor_name": "LA Care Health Plan (Los Angeles Care Health Plan)",
  "payor_id": "LACAR",
  "seq_id": 497
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f511b"
  },
  "payor_name": "Leon Medical Center Health Plan",
  "payor_id": "37316",
  "seq_id": 498
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f511c"
  },
  "payor_name": "Liberty Dental Plan",
  "payor_id": "CX083",
  "seq_id": 499
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f511d"
  },
  "payor_name": "Liberty National Life Insurance Company",
  "payor_id": "65331",
  "seq_id": 500
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f511e"
  },
  "payor_name": "Lifecare Assurance Company",
  "payor_id": "11170",
  "seq_id": 501
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f511f"
  },
  "payor_name": "LifeWise - A Premera Health Plan (OR only)",
  "payor_id": "93093",
  "seq_id": 502
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5120"
  },
  "payor_name": "Lifewise Arizona",
  "payor_id": "10843",
  "seq_id": 503
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5121"
  },
  "payor_name": "Lincoln Financial (Eligibility & Remits Only)",
  "payor_id": "Z96304",
  "seq_id": 504
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5122"
  },
  "payor_name": "Lincoln Heritage",
  "payor_id": "Z95717",
  "seq_id": 505
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5123"
  },
  "payor_name": "Longevity Health Plan of Florida",
  "payor_id": "LFL01",
  "seq_id": 506
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5124"
  },
  "payor_name": "Longevity Health Plan of Illinois",
  "payor_id": "LIL01",
  "seq_id": 507
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5125"
  },
  "payor_name": "Longevity Health Plan of New Jersey",
  "payor_id": "LNJ01",
  "seq_id": 508
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5126"
  },
  "payor_name": "Longevity Health Plan of New York",
  "payor_id": "LNY01",
  "seq_id": 509
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5127"
  },
  "payor_name": "Longevity Health Plan of North Carolina",
  "payor_id": "LNC01",
  "seq_id": 510
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5128"
  },
  "payor_name": "Longevity Health Plan of Oklahoma",
  "payor_id": "LOK01",
  "seq_id": 511
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5129"
  },
  "payor_name": "Louisiana Health Exchange (Eligibility Only)",
  "payor_id": "10830",
  "seq_id": 512
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f512a"
  },
  "payor_name": "Loyal American Life Ins Co- Medicare Supplement (Eligibility Only)",
  "payor_id": "Z90025",
  "seq_id": 513
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f512b"
  },
  "payor_name": "Loyal Christian Benefit Association",
  "payor_id": "11159",
  "seq_id": 514
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f512c"
  },
  "payor_name": "Lumico Life Insurance Company (Eligibility and Remits Only)",
  "payor_id": "Z96364",
  "seq_id": 515
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f512d"
  },
  "payor_name": "Luminare Health AZ,IL,IN,MD,MN,NC,PA(fka Trustmark Health Benefits)",
  "payor_id": "35182",
  "seq_id": 516
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f512e"
  },
  "payor_name": "Luminare Health Internal(fka Trustmark Health Benefits)",
  "payor_id": "35187",
  "seq_id": 517
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f512f"
  },
  "payor_name": "Luminare Health Internal(fka Trustmark Health Benefits)",
  "payor_id": "38225",
  "seq_id": 518
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5130"
  },
  "payor_name": "Luminare Health KC (fka Trustmark Health Benefits)",
  "payor_id": "48117",
  "seq_id": 519
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5131"
  },
  "payor_name": "Luminare Health Little Rock(fka Trustmark Health Benefits)",
  "payor_id": "75136",
  "seq_id": 520
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5132"
  },
  "payor_name": "Luminare Health OH(fka Trustmark Health Benefits)",
  "payor_id": "35183",
  "seq_id": 521
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5133"
  },
  "payor_name": "Magellan Behavioral Health",
  "payor_id": "MBH11",
  "seq_id": 522
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5134"
  },
  "payor_name": "Magellan Complete Care of Virginia DSNP",
  "payor_id": "MCC02",
  "seq_id": 523
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5135"
  },
  "payor_name": "Magnacare",
  "payor_id": "11303",
  "seq_id": 524
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5136"
  },
  "payor_name": "Magnolia Health Plan",
  "payor_id": "68062",
  "seq_id": 525
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5137"
  },
  "payor_name": "Maine Community Health Options",
  "payor_id": "45341",
  "seq_id": 526
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5138"
  },
  "payor_name": "Managed Care of North America MCNA",
  "payor_id": "14317",
  "seq_id": 527
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5139"
  },
  "payor_name": "Managed Care Systems (Gemcare)",
  "payor_id": "MCS01",
  "seq_id": 528
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f513a"
  },
  "payor_name": "Managed Health Services Indiana (Medicaid HMO)",
  "payor_id": "39186",
  "seq_id": 529
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f513b"
  },
  "payor_name": "Managed Health Services Wisconsin",
  "payor_id": "39187",
  "seq_id": 530
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f513c"
  },
  "payor_name": "Manhattan Life (Eligibility Only)",
  "payor_id": "MNHTN",
  "seq_id": 531
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f513d"
  },
  "payor_name": "MAPFRE",
  "payor_id": "5251",
  "seq_id": 532
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f513e"
  },
  "payor_name": "Maricopa Health Plan, managed by UPH",
  "payor_id": "9908",
  "seq_id": 533
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f513f"
  },
  "payor_name": "Marpai Health",
  "payor_id": "35245",
  "seq_id": 534
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5140"
  },
  "payor_name": "Martins Point Health Care",
  "payor_id": "53275",
  "seq_id": 535
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5141"
  },
  "payor_name": "Mary Washington Health Plan",
  "payor_id": "83269",
  "seq_id": 536
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5142"
  },
  "payor_name": "Maryland Physicians Care MPC",
  "payor_id": "76498",
  "seq_id": 537
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5143"
  },
  "payor_name": "Mass General Brigham Health Plan",
  "payor_id": "4293",
  "seq_id": 538
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5144"
  },
  "payor_name": "MCA Administrators Inc",
  "payor_id": "25160",
  "seq_id": 539
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5145"
  },
  "payor_name": "McLaren Health Plan",
  "payor_id": "38338",
  "seq_id": 540
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5146"
  },
  "payor_name": "McLaren Health Plan Medicaid",
  "payor_id": "3833C",
  "seq_id": 541
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5147"
  },
  "payor_name": "MdWise Healthy Indiana Plan (DOS 1/1/19 and After)",
  "payor_id": "3135M",
  "seq_id": 542
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5148"
  },
  "payor_name": "MdWise Hoosier Healthwise (DOS 1/1/19 and After)",
  "payor_id": "3519M",
  "seq_id": 543
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5149"
  },
  "payor_name": "MedCost Benefits Services",
  "payor_id": "56205",
  "seq_id": 544
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f514a"
  },
  "payor_name": "Medica Health Plan - Fee for Service",
  "payor_id": "94265",
  "seq_id": 545
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f514b"
  },
  "payor_name": "Medica Health Plan Solutions",
  "payor_id": "71890",
  "seq_id": 546
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f514c"
  },
  "payor_name": "Medica Medicare Advantage (Effective 1/1/2021)",
  "payor_id": "MEDM1",
  "seq_id": 547
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f514d"
  },
  "payor_name": "Medica2 IFB",
  "payor_id": "Z95382",
  "seq_id": 548
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f514e"
  },
  "payor_name": "Medicaid Alabama",
  "payor_id": "SKAL0",
  "seq_id": 549
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f514f"
  },
  "payor_name": "Medicaid Alaska",
  "payor_id": "SKAK0",
  "seq_id": 550
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5150"
  },
  "payor_name": "Medicaid Arizona (AHCCCS)",
  "payor_id": "SKAZ0",
  "seq_id": 551
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5151"
  },
  "payor_name": "Medicaid Arkansas",
  "payor_id": "SKAR0",
  "seq_id": 552
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5152"
  },
  "payor_name": "Medicaid California",
  "payor_id": "SKCA0",
  "seq_id": 553
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5153"
  },
  "payor_name": "Medicaid Colorado",
  "payor_id": "SKCO0",
  "seq_id": 554
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5154"
  },
  "payor_name": "Medicaid Connecticut",
  "payor_id": "SKCT0",
  "seq_id": 555
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5155"
  },
  "payor_name": "Medicaid Delaware",
  "payor_id": "SKDE0",
  "seq_id": 556
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5156"
  },
  "payor_name": "Medicaid District of Columbia (DC)",
  "payor_id": "SKDC0",
  "seq_id": 557
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5157"
  },
  "payor_name": "Medicaid Florida",
  "payor_id": "SKFL0",
  "seq_id": 558
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5158"
  },
  "payor_name": "Medicaid Georgia",
  "payor_id": "SKGA0",
  "seq_id": 559
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5159"
  },
  "payor_name": "Medicaid Hawaii",
  "payor_id": "SKHI0",
  "seq_id": 560
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f515a"
  },
  "payor_name": "Medicaid Idaho",
  "payor_id": "ZKID0",
  "seq_id": 561
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f515b"
  },
  "payor_name": "Medicaid Indiana",
  "payor_id": "SKIN0",
  "seq_id": 562
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f515c"
  },
  "payor_name": "Medicaid Iowa",
  "payor_id": "SKIA0",
  "seq_id": 563
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f515d"
  },
  "payor_name": "Medicaid Kansas",
  "payor_id": "SKKS0",
  "seq_id": 564
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f515e"
  },
  "payor_name": "Medicaid Kentucky",
  "payor_id": "SKKY0",
  "seq_id": 565
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f515f"
  },
  "payor_name": "Medicaid Louisiana - Ambulance Transportation [.TRA]",
  "payor_id": "Z1057",
  "seq_id": 566
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5160"
  },
  "payor_name": "Medicaid Louisiana - Durable Medical Equipment [.DME]",
  "payor_id": "Z1050",
  "seq_id": 567
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5161"
  },
  "payor_name": "Medicaid Louisiana - Non-Emergency Transportation [.NAM]",
  "payor_id": "SKLA05",
  "seq_id": 568
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5162"
  },
  "payor_name": "Medicaid Louisiana - Physicians, Pediatric Day Healthcare [.PHY]",
  "payor_id": "SKLA0",
  "seq_id": 569
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5163"
  },
  "payor_name": "Medicaid Louisiana - Rehabilitation [.REH]",
  "payor_id": "SKLA4",
  "seq_id": 570
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5164"
  },
  "payor_name": "Medicaid Maine",
  "payor_id": "SKME1",
  "seq_id": 571
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5165"
  },
  "payor_name": "Medicaid Maryland",
  "payor_id": "SKMD0",
  "seq_id": 572
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5166"
  },
  "payor_name": "Medicaid Massachusetts",
  "payor_id": "SKMA0",
  "seq_id": 573
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5167"
  },
  "payor_name": "Medicaid Michigan",
  "payor_id": "SKMI0",
  "seq_id": 574
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5168"
  },
  "payor_name": "Medicaid Minnesota",
  "payor_id": "SKMN0",
  "seq_id": 575
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5169"
  },
  "payor_name": "Medicaid Mississippi",
  "payor_id": "SKMS0",
  "seq_id": 576
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f516a"
  },
  "payor_name": "Medicaid Missouri",
  "payor_id": "SKMO0",
  "seq_id": 577
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f516b"
  },
  "payor_name": "Medicaid Montana",
  "payor_id": "SKMT0",
  "seq_id": 578
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f516c"
  },
  "payor_name": "Medicaid Nebraska",
  "payor_id": "SKNE0",
  "seq_id": 579
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f516d"
  },
  "payor_name": "Medicaid Nevada",
  "payor_id": "SKNV0",
  "seq_id": 580
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f516e"
  },
  "payor_name": "Medicaid New Hampshire",
  "payor_id": "SKNH0",
  "seq_id": 581
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f516f"
  },
  "payor_name": "Medicaid New Jersey",
  "payor_id": "SKNJ0",
  "seq_id": 582
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5170"
  },
  "payor_name": "Medicaid New Mexico",
  "payor_id": "SKNM0",
  "seq_id": 583
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5171"
  },
  "payor_name": "Medicaid New York",
  "payor_id": "SKNY1",
  "seq_id": 584
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5172"
  },
  "payor_name": "Medicaid North Carolina",
  "payor_id": "SKNC0",
  "seq_id": 585
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5173"
  },
  "payor_name": "Medicaid North Dakota",
  "payor_id": "SKND0",
  "seq_id": 586
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5174"
  },
  "payor_name": "Medicaid Ohio",
  "payor_id": "SKOH0",
  "seq_id": 587
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5175"
  },
  "payor_name": "Medicaid Ohio - Amerihealth Caritas Ohio",
  "payor_id": "35374",
  "seq_id": 588
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5176"
  },
  "payor_name": "Medicaid Ohio - AmeriHealth Caritas Ohio Radiology Only",
  "payor_id": "84243",
  "seq_id": 589
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5177"
  },
  "payor_name": "Medicaid Ohio - Anthem Ohio Medicaid",
  "payor_id": "SKOH5",
  "seq_id": 590
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5178"
  },
  "payor_name": "Medicaid Ohio - Buckeye Ohio Medicaid",
  "payor_id": "SKOH3",
  "seq_id": 591
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5179"
  },
  "payor_name": "Medicaid Ohio - Caresource Ohio - Dental",
  "payor_id": "SKOH1",
  "seq_id": 592
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f517a"
  },
  "payor_name": "Medicaid Ohio - Caresource Ohio - Vision",
  "payor_id": "SKOH6",
  "seq_id": 593
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f517b"
  },
  "payor_name": "Medicaid Ohio - Caresource Ohio Medicaid",
  "payor_id": "SKOH2",
  "seq_id": 594
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f517c"
  },
  "payor_name": "Medicaid Ohio - Humana DentaQuest",
  "payor_id": "SKOH13",
  "seq_id": 595
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f517d"
  },
  "payor_name": "Medicaid Ohio - Humana EyeMed",
  "payor_id": "SKOH9",
  "seq_id": 596
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f517e"
  },
  "payor_name": "Medicaid Ohio - Humana Ohio Medicaid",
  "payor_id": "61103",
  "seq_id": 597
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f517f"
  },
  "payor_name": "Medicaid Ohio - Molina Ohio Medicaid",
  "payor_id": "SKOH4",
  "seq_id": 598
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5180"
  },
  "payor_name": "Medicaid Ohio - OhioRISE - Aetna Better Health of Ohio",
  "payor_id": "45221",
  "seq_id": 599
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5181"
  },
  "payor_name": "Medicaid Ohio - United Healthcare OH Medicaid",
  "payor_id": "88337",
  "seq_id": 600
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5182"
  },
  "payor_name": "Medicaid Ohio - United Healthcare OH Medicaid - Dental",
  "payor_id": "83244",
  "seq_id": 601
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5183"
  },
  "payor_name": "Medicaid Ohio - United Healthcare OH Medicaid - Vision",
  "payor_id": "83572",
  "seq_id": 602
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5184"
  },
  "payor_name": "Medicaid Oklahoma",
  "payor_id": "SKOK0",
  "seq_id": 603
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5185"
  },
  "payor_name": "Medicaid Oregon",
  "payor_id": "SKOR0",
  "seq_id": 604
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5186"
  },
  "payor_name": "Medicaid Pennsylvania",
  "payor_id": "SKPA0",
  "seq_id": 605
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5187"
  },
  "payor_name": "Medicaid South Carolina",
  "payor_id": "SKSC0",
  "seq_id": 606
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5188"
  },
  "payor_name": "Medicaid South Dakota",
  "payor_id": "SKSD0",
  "seq_id": 607
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5189"
  },
  "payor_name": "Medicaid Tennessee",
  "payor_id": "SKTN1",
  "seq_id": 608
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f518a"
  },
  "payor_name": "Medicaid Texas",
  "payor_id": "SKTX0",
  "seq_id": 609
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f518b"
  },
  "payor_name": "Medicaid Texas (Long Term Care)",
  "payor_id": "SKTX2",
  "seq_id": 610
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f518c"
  },
  "payor_name": "Medicaid Utah",
  "payor_id": "SKUT0",
  "seq_id": 611
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f518d"
  },
  "payor_name": "Medicaid Vermont",
  "payor_id": "SKVM0",
  "seq_id": 612
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f518e"
  },
  "payor_name": "Medicaid Virginia",
  "payor_id": "SKVA0",
  "seq_id": 613
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f518f"
  },
  "payor_name": "Medicaid Washington - Washington Apple Health",
  "payor_id": "SKWA0",
  "seq_id": 614
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5190"
  },
  "payor_name": "Medicaid West Virginia",
  "payor_id": "SKWV0",
  "seq_id": 615
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5191"
  },
  "payor_name": "Medicaid Wisconsin",
  "payor_id": "SKWI0",
  "seq_id": 616
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5192"
  },
  "payor_name": "Medicaid Wyoming",
  "payor_id": "SKWY0",
  "seq_id": 617
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5193"
  },
  "payor_name": "Medical Associates Health Plans/Health Choices [MAHC1]",
  "payor_id": "Z1269",
  "seq_id": 618
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5194"
  },
  "payor_name": "Medical Benefits Companies (Newark, OH)",
  "payor_id": "74323",
  "seq_id": 619
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5195"
  },
  "payor_name": "Medical Card System",
  "payor_id": "Z1419",
  "seq_id": 620
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5196"
  },
  "payor_name": "Medical Mutual of Ohio (MMO)",
  "payor_id": "SX057",
  "seq_id": 621
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5197"
  },
  "payor_name": "Medicare A & B Eligibility (All States)",
  "payor_id": "Z1073",
  "seq_id": 622
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5198"
  },
  "payor_name": "Medicare Advantage - Pennsylvania",
  "payor_id": "Z96299",
  "seq_id": 623
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5199"
  },
  "payor_name": "Medicare Advantage- MT, OK, TX, IL, NM (BC)",
  "payor_id": "66006",
  "seq_id": 624
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f519a"
  },
  "payor_name": "Medicare y Mucho Mas (MMM)",
  "payor_id": "L0210",
  "seq_id": 625
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f519b"
  },
  "payor_name": "Medico Life and Health Insurance Company",
  "payor_id": "HDM04",
  "seq_id": 626
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f519c"
  },
  "payor_name": "Medigold Health Plans",
  "payor_id": "95655",
  "seq_id": 627
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f519d"
  },
  "payor_name": "Mediview Curative",
  "payor_id": "CURTV",
  "seq_id": 628
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f519e"
  },
  "payor_name": "Med-Pay",
  "payor_id": "7480",
  "seq_id": 629
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f519f"
  },
  "payor_name": "Medstar DC",
  "payor_id": "RP062",
  "seq_id": 630
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51a0"
  },
  "payor_name": "MedStar Family Choice",
  "payor_id": "RP063",
  "seq_id": 631
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51a1"
  },
  "payor_name": "Mercy Care Arizona (AHCCCS)",
  "payor_id": "86052",
  "seq_id": 632
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51a2"
  },
  "payor_name": "Mercy Care Health Plan",
  "payor_id": "340",
  "seq_id": 633
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51a3"
  },
  "payor_name": "Mercy Maricopa Integrated Care",
  "payor_id": "33628",
  "seq_id": 634
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51a4"
  },
  "payor_name": "Meridian Complete of Michigan (Effective 1/1/2021)",
  "payor_id": "MHPMI",
  "seq_id": 635
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51a5"
  },
  "payor_name": "Meridian Health Plan of Illinois",
  "payor_id": "MHPIL",
  "seq_id": 636
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51a6"
  },
  "payor_name": "Meridian Health Plan of Illinois (DOS 6/30/2021 and Prior)",
  "payor_id": "13189",
  "seq_id": 637
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51a7"
  },
  "payor_name": "Meridian Health Plan of Michigan (DOS 3/31/2022 and prior)",
  "payor_id": "Z1054",
  "seq_id": 638
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51a8"
  },
  "payor_name": "Meritain Health Minneapolis",
  "payor_id": "41124",
  "seq_id": 639
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51a9"
  },
  "payor_name": "MetLife Dental Family",
  "payor_id": "10134",
  "seq_id": 640
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51aa"
  },
  "payor_name": "MetroPlus Health Plan",
  "payor_id": "13265",
  "seq_id": 641
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51ab"
  },
  "payor_name": "MHN-Managed Health Network (Richmond CA)",
  "payor_id": "22771",
  "seq_id": 642
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51ac"
  },
  "payor_name": "Michigan Medicaid Pending",
  "payor_id": "SKMI2",
  "seq_id": 643
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51ad"
  },
  "payor_name": "Michigan MIChild",
  "payor_id": "10138",
  "seq_id": 644
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51ae"
  },
  "payor_name": "Mississippi Children's Health Insurance Program",
  "payor_id": "10140",
  "seq_id": 645
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51af"
  },
  "payor_name": "Missouri Care",
  "payor_id": "10702",
  "seq_id": 646
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51b0"
  },
  "payor_name": "Missouri Care  (SCHALLER ANDERSON)",
  "payor_id": "10695",
  "seq_id": 647
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51b1"
  },
  "payor_name": "MMM Florida",
  "payor_id": "MMMFL",
  "seq_id": 648
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51b2"
  },
  "payor_name": "Moda Health",
  "payor_id": "13350",
  "seq_id": 649
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51b3"
  },
  "payor_name": "Molina Complete Care of Arizona",
  "payor_id": "MCC01",
  "seq_id": 650
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51b4"
  },
  "payor_name": "Molina Complete Care of Virginia (DOS prior to 7/1/2022)",
  "payor_id": "MCCVA",
  "seq_id": 651
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51b5"
  },
  "payor_name": "Molina Healthcare of California",
  "payor_id": "38333",
  "seq_id": 652
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51b6"
  },
  "payor_name": "Molina Healthcare of Florida",
  "payor_id": "51062",
  "seq_id": 653
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51b7"
  },
  "payor_name": "Molina Healthcare of Idaho",
  "payor_id": "61799",
  "seq_id": 654
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51b8"
  },
  "payor_name": "Molina Healthcare of Illinois",
  "payor_id": "20934",
  "seq_id": 655
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51b9"
  },
  "payor_name": "Molina Healthcare of Iowa (DOS 7/1/23 and after)",
  "payor_id": "MLNIA",
  "seq_id": 656
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51ba"
  },
  "payor_name": "Molina Healthcare of Michigan",
  "payor_id": "Z1007",
  "seq_id": 657
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51bb"
  },
  "payor_name": "Molina Healthcare of Mississippi",
  "payor_id": "77010",
  "seq_id": 658
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51bc"
  },
  "payor_name": "Molina Healthcare of Nebraska",
  "payor_id": "MLNNE",
  "seq_id": 659
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51bd"
  },
  "payor_name": "Molina Healthcare of Nevada",
  "payor_id": "MLNNV",
  "seq_id": 660
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51be"
  },
  "payor_name": "Molina Healthcare of New Mexico",
  "payor_id": "TH059",
  "seq_id": 661
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51bf"
  },
  "payor_name": "Molina Healthcare of New York",
  "payor_id": "16146",
  "seq_id": 662
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51c0"
  },
  "payor_name": "Molina Healthcare of Ohio",
  "payor_id": "20149",
  "seq_id": 663
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51c1"
  },
  "payor_name": "Molina Healthcare of South Carolina",
  "payor_id": "46299",
  "seq_id": 664
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51c2"
  },
  "payor_name": "Molina Healthcare of Texas",
  "payor_id": "Z1161",
  "seq_id": 665
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51c3"
  },
  "payor_name": "Molina Healthcare of Utah",
  "payor_id": "Z1014",
  "seq_id": 666
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51c4"
  },
  "payor_name": "Molina Healthcare of Washington",
  "payor_id": "38336",
  "seq_id": 667
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51c5"
  },
  "payor_name": "Molina Healthcare of Wisconsin",
  "payor_id": "ABRI1",
  "seq_id": 668
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51c6"
  },
  "payor_name": "Montefiore Contract Management Organization",
  "payor_id": "13174",
  "seq_id": 669
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51c7"
  },
  "payor_name": "MoreCare",
  "payor_id": "65465",
  "seq_id": 670
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51c8"
  },
  "payor_name": "Mountain State FreedomBlue Medicare Advantage",
  "payor_id": "15459",
  "seq_id": 671
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51c9"
  },
  "payor_name": "Municipal Health Benefit Fund",
  "payor_id": "81883",
  "seq_id": 672
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51ca"
  },
  "payor_name": "Mutual Health Services",
  "payor_id": "34192",
  "seq_id": 673
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51cb"
  },
  "payor_name": "Mutual of Omaha Insurance Company",
  "payor_id": "71412",
  "seq_id": 674
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51cc"
  },
  "payor_name": "MVP Health Care",
  "payor_id": "14165",
  "seq_id": 675
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51cd"
  },
  "payor_name": "National Association of Letter Carriers (NALC)",
  "payor_id": "53011",
  "seq_id": 676
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51ce"
  },
  "payor_name": "National Health Benefits Corporation-NHBC-Auxiant-MPC",
  "payor_id": "88050",
  "seq_id": 677
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51cf"
  },
  "payor_name": "Neighborhood Health Partnership",
  "payor_id": "95123",
  "seq_id": 678
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51d0"
  },
  "payor_name": "Neighborhood Health Plan of RI",
  "payor_id": "5047",
  "seq_id": 679
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51d1"
  },
  "payor_name": "Neighborhood Health Plan RI - Integrity",
  "payor_id": "96240",
  "seq_id": 680
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51d2"
  },
  "payor_name": "Network Health Insurance Corp-Medicare",
  "payor_id": "77076",
  "seq_id": 681
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51d3"
  },
  "payor_name": "Network Health Plan of Wisconsin Inc",
  "payor_id": "39144",
  "seq_id": 682
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51d4"
  },
  "payor_name": "New Era Life Insurance Company",
  "payor_id": "98798",
  "seq_id": 683
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51d5"
  },
  "payor_name": "NextBlue of North Dakota",
  "payor_id": "55892",
  "seq_id": 684
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51d6"
  },
  "payor_name": "Nippon Life Insurance Company of America",
  "payor_id": "81264",
  "seq_id": 685
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51d7"
  },
  "payor_name": "Northwest Administrators Inc",
  "payor_id": "NWADM",
  "seq_id": 686
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51d8"
  },
  "payor_name": "Nova Healthcare Administrators Inc. (Grand Island, NY)",
  "payor_id": "16644",
  "seq_id": 687
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51d9"
  },
  "payor_name": "NovaSys Health Network (Little Rock, AR)",
  "payor_id": "71080",
  "seq_id": 688
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51da"
  },
  "payor_name": "NTCA National Telecommunications Cooperative Assn",
  "payor_id": "52103",
  "seq_id": 689
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51db"
  },
  "payor_name": "Ochsner Health Plan",
  "payor_id": "A5236",
  "seq_id": 690
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51dc"
  },
  "payor_name": "Oklahoma DRS Doc (DOS on or after 01/01/18)",
  "payor_id": "71065",
  "seq_id": 691
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51dd"
  },
  "payor_name": "Old Surety Life Insurance Company (Remits & Eligibility Only)",
  "payor_id": "29237",
  "seq_id": 692
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51de"
  },
  "payor_name": "OptumCare",
  "payor_id": "LIFE1",
  "seq_id": 693
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51df"
  },
  "payor_name": "Oregon's Health Co-Op",
  "payor_id": "11124",
  "seq_id": 694
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51e0"
  },
  "payor_name": "Oscar Health",
  "payor_id": "OSCAR",
  "seq_id": 695
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51e1"
  },
  "payor_name": "Oxford Health Plans",
  "payor_id": "6111",
  "seq_id": 696
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51e2"
  },
  "payor_name": "Pacificare of California",
  "payor_id": "Z1015",
  "seq_id": 697
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51e3"
  },
  "payor_name": "PacificSource Community Solutions",
  "payor_id": "20416",
  "seq_id": 698
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51e4"
  },
  "payor_name": "PacificSource Health Plans",
  "payor_id": "93029",
  "seq_id": 699
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51e5"
  },
  "payor_name": "PacificSource Medicare",
  "payor_id": "20377",
  "seq_id": 700
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51e6"
  },
  "payor_name": "Pan American Life Insurance Group",
  "payor_id": "4218",
  "seq_id": 701
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51e7"
  },
  "payor_name": "Panamerican Palic Puerto Rico",
  "payor_id": "L0180",
  "seq_id": 702
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51e8"
  },
  "payor_name": "Paramount Health",
  "payor_id": "SX158",
  "seq_id": 703
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51e9"
  },
  "payor_name": "Parkland Community Health Plan",
  "payor_id": "66917",
  "seq_id": 704
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51ea"
  },
  "payor_name": "Partners National Health Plans of North Carolina Inc",
  "payor_id": "7472",
  "seq_id": 705
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51eb"
  },
  "payor_name": "Partnership Health Plan of California",
  "payor_id": "PHP02",
  "seq_id": 706
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51ec"
  },
  "payor_name": "Passport Advantage",
  "payor_id": "66008",
  "seq_id": 707
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51ed"
  },
  "payor_name": "Passport Health Plan by Molina Healthcare",
  "payor_id": "61325",
  "seq_id": 708
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51ee"
  },
  "payor_name": "Patriot Dental",
  "payor_id": "10782",
  "seq_id": 709
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51ef"
  },
  "payor_name": "PayerFusion Holdings, LLC",
  "payor_id": "27048",
  "seq_id": 710
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51f0"
  },
  "payor_name": "Peach State Health Plan",
  "payor_id": "68049",
  "seq_id": 711
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51f1"
  },
  "payor_name": "Peak Health",
  "payor_id": "PEAK0",
  "seq_id": 712
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51f2"
  },
  "payor_name": "PEHP (Public Employees Health Program)",
  "payor_id": "Z1012",
  "seq_id": 713
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51f3"
  },
  "payor_name": "Pekin Life Insurance",
  "payor_id": "37086",
  "seq_id": 714
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51f4"
  },
  "payor_name": "Penn Treaty Network America Ins Medicare Supp",
  "payor_id": "Z96295",
  "seq_id": 715
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51f5"
  },
  "payor_name": "Pennsylvania Health and Wellness",
  "payor_id": "Z96336",
  "seq_id": 716
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51f6"
  },
  "payor_name": "People First (Eligibility Only)",
  "payor_id": "Z96294",
  "seq_id": 717
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51f7"
  },
  "payor_name": "People's Health Network (DOS 12/31/2023 and prior)",
  "payor_id": "72126",
  "seq_id": 718
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51f8"
  },
  "payor_name": "PerformCare - HealthChoices",
  "payor_id": "65391",
  "seq_id": 719
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51f9"
  },
  "payor_name": "Personal Insurance Administrators Inc (Agoura Hills, CA)",
  "payor_id": "95397",
  "seq_id": 720
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51fa"
  },
  "payor_name": "Physicians Health Plan (PHP)",
  "payor_id": "37330",
  "seq_id": 721
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51fb"
  },
  "payor_name": "Physicians Health Plan of Michigan Medicare",
  "payor_id": "83276",
  "seq_id": 722
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51fc"
  },
  "payor_name": "Physicians Health Plan of Northern Indiana (PHP)",
  "payor_id": "12399",
  "seq_id": 723
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51fd"
  },
  "payor_name": "Physicians Mutual Insurance Company",
  "payor_id": "47027",
  "seq_id": 724
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51fe"
  },
  "payor_name": "Piedmont (GA)",
  "payor_id": "10882",
  "seq_id": 725
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f51ff"
  },
  "payor_name": "Piedmont Community Health Plan",
  "payor_id": "55768",
  "seq_id": 726
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5200"
  },
  "payor_name": "Plan De Salud Menonita",
  "payor_id": "66063",
  "seq_id": 727
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5201"
  },
  "payor_name": "Planned Administrators",
  "payor_id": "37287",
  "seq_id": 728
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5202"
  },
  "payor_name": "Populytics",
  "payor_id": "23253",
  "seq_id": 729
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5203"
  },
  "payor_name": "Prairie States Enterprises Inc",
  "payor_id": "36373",
  "seq_id": 730
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5204"
  },
  "payor_name": "Preferred Care Network",
  "payor_id": "78857",
  "seq_id": 731
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5205"
  },
  "payor_name": "Preferred Care Partners (FL)",
  "payor_id": "65088",
  "seq_id": 732
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5206"
  },
  "payor_name": "Preferred Health Systems",
  "payor_id": "263",
  "seq_id": 733
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5207"
  },
  "payor_name": "Preferred Medicare Choice (PMC)",
  "payor_id": "Z60056",
  "seq_id": 734
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5208"
  },
  "payor_name": "PreferredOne (MN)",
  "payor_id": "41147",
  "seq_id": 735
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5209"
  },
  "payor_name": "Premier HealthCare Exchange",
  "payor_id": "88056",
  "seq_id": 736
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f520a"
  },
  "payor_name": "Presbyterian Health Plan",
  "payor_id": "Z0003",
  "seq_id": 737
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f520b"
  },
  "payor_name": "Prime West Health Plan",
  "payor_id": "61604",
  "seq_id": 738
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f520c"
  },
  "payor_name": "Priority Health",
  "payor_id": "38217",
  "seq_id": 739
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f520d"
  },
  "payor_name": "PRODUCT TEST",
  "payor_id": "TESTP",
  "seq_id": 740
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f520e"
  },
  "payor_name": "Professional Benefit Administrators Inc (Oak Brook, IL)",
  "payor_id": "36331",
  "seq_id": 741
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f520f"
  },
  "payor_name": "Prominence Administrative Services",
  "payor_id": "88022",
  "seq_id": 742
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5210"
  },
  "payor_name": "Prominence Health Plan",
  "payor_id": "88029",
  "seq_id": 743
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5211"
  },
  "payor_name": "Prominence Health Plan of Nevada",
  "payor_id": "93082",
  "seq_id": 744
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5212"
  },
  "payor_name": "Prominence Health Plan of Texas",
  "payor_id": "80095",
  "seq_id": 745
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5213"
  },
  "payor_name": "Prominence Healthfirst of Florida",
  "payor_id": "83352",
  "seq_id": 746
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5214"
  },
  "payor_name": "Providence Health Plan",
  "payor_id": "4498",
  "seq_id": 747
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5215"
  },
  "payor_name": "Providence Pace CA",
  "payor_id": "77240",
  "seq_id": 748
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5216"
  },
  "payor_name": "Provident American Life & Health Ins Co- Medicare Supplement (Eligibility Only)",
  "payor_id": "Z90026",
  "seq_id": 749
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5217"
  },
  "payor_name": "Provident Preferred Network - Dental",
  "payor_id": "10790",
  "seq_id": 750
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5218"
  },
  "payor_name": "Provider Partners Health Plan Illinois",
  "payor_id": "31401",
  "seq_id": 751
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5219"
  },
  "payor_name": "Provider Partners Health Plan of Maryland",
  "payor_id": "31118",
  "seq_id": 752
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f521a"
  },
  "payor_name": "Provider Partners Health Plan of Missouri",
  "payor_id": "31404",
  "seq_id": 753
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f521b"
  },
  "payor_name": "Provider Partners Health Plan of Ohio",
  "payor_id": "31402",
  "seq_id": 754
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f521c"
  },
  "payor_name": "Provider Partners Health Plan of Pennsylvania",
  "payor_id": "31400",
  "seq_id": 755
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f521d"
  },
  "payor_name": "Public Employee Benefit Authority (Eligibility)",
  "payor_id": "Z96292",
  "seq_id": 756
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f521e"
  },
  "payor_name": "Public Employees Health Plan - Medicare (Eligibility Only)",
  "payor_id": "11049",
  "seq_id": 757
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f521f"
  },
  "payor_name": "Public Employees Health Program- CHIP (Eligibility Only)",
  "payor_id": "11050",
  "seq_id": 758
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5220"
  },
  "payor_name": "Puritan Life Insurance",
  "payor_id": "Z95719",
  "seq_id": 759
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5221"
  },
  "payor_name": "Qual Choice of Arkansas",
  "payor_id": "35174",
  "seq_id": 760
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5222"
  },
  "payor_name": "QuikTrip Corporation",
  "payor_id": "73067",
  "seq_id": 761
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5223"
  },
  "payor_name": "Railroad Medicare",
  "payor_id": "SRRGA",
  "seq_id": 762
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5224"
  },
  "payor_name": "Regence Group Administrators",
  "payor_id": "RGA01",
  "seq_id": 763
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5225"
  },
  "payor_name": "Reliance Standard Life (Remits & Eligibility Only)",
  "payor_id": "36088",
  "seq_id": 764
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5226"
  },
  "payor_name": "Reserve National Insurance",
  "payor_id": "73066",
  "seq_id": 765
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5227"
  },
  "payor_name": "Rocky Mountain Health (DOS Prior to 1/1/2023)",
  "payor_id": "Z0062",
  "seq_id": 766
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5228"
  },
  "payor_name": "Royal Neighbors Of America (Eligibility & Remits Only)",
  "payor_id": "Z95720",
  "seq_id": 767
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5229"
  },
  "payor_name": "S and S Healthcare Strategies",
  "payor_id": "Z96289",
  "seq_id": 768
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f522a"
  },
  "payor_name": "S USA Life Insurance Company (Eligibility Only)",
  "payor_id": "Z96288",
  "seq_id": 769
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f522b"
  },
  "payor_name": "Samaritan Health Plans",
  "payor_id": "SAMCP",
  "seq_id": 770
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f522c"
  },
  "payor_name": "SAMBA/Special Agents Mutual Benefit Association",
  "payor_id": "37259",
  "seq_id": 771
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f522d"
  },
  "payor_name": "San Francisco Health Plan",
  "payor_id": "SFHP1",
  "seq_id": 772
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f522e"
  },
  "payor_name": "Sana Benefits",
  "payor_id": "50114",
  "seq_id": 773
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f522f"
  },
  "payor_name": "Sanford Health Plan",
  "payor_id": "91184",
  "seq_id": 774
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5230"
  },
  "payor_name": "Sanford Health Plan Medicare Advantage",
  "payor_id": "RP035",
  "seq_id": 775
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5231"
  },
  "payor_name": "Santa Barbara Regional Health Authority",
  "payor_id": "Z1243",
  "seq_id": 776
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5232"
  },
  "payor_name": "Santa Clara Family Health",
  "payor_id": "24077",
  "seq_id": 777
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5233"
  },
  "payor_name": "Scan Health Plan - California",
  "payor_id": "72261",
  "seq_id": 778
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5234"
  },
  "payor_name": "Scion Dental (Eligibility Only)",
  "payor_id": "SCION",
  "seq_id": 779
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5235"
  },
  "payor_name": "Scott and White",
  "payor_id": "88030",
  "seq_id": 780
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5236"
  },
  "payor_name": "Securian/Patriot Dental",
  "payor_id": "10793",
  "seq_id": 781
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5237"
  },
  "payor_name": "Security Health Plan",
  "payor_id": "39045",
  "seq_id": 782
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5238"
  },
  "payor_name": "Select Benefits Administrators (aka PacificSource Administrators)",
  "payor_id": "93031",
  "seq_id": 783
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5239"
  },
  "payor_name": "Select Health of South Carolina",
  "payor_id": "23285",
  "seq_id": 784
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f523a"
  },
  "payor_name": "SelectHealth of Utah",
  "payor_id": "Z0001",
  "seq_id": 785
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f523b"
  },
  "payor_name": "Self Insured Services Company (SISCO)",
  "payor_id": "44827",
  "seq_id": 786
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f523c"
  },
  "payor_name": "Selman & Co (DOS after 1/1/2019)",
  "payor_id": "52214",
  "seq_id": 787
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f523d"
  },
  "payor_name": "Selman Tricare Supp [DOS prior to 1/1/2019)",
  "payor_id": "TRSEL",
  "seq_id": 788
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f523e"
  },
  "payor_name": "Senior Dimensions",
  "payor_id": "Z96281",
  "seq_id": 789
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f523f"
  },
  "payor_name": "Senior Whole Health New York",
  "payor_id": "SWHNY",
  "seq_id": 790
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5240"
  },
  "payor_name": "Senior Whole Health of Massachusetts",
  "payor_id": "SWHMA",
  "seq_id": 791
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5241"
  },
  "payor_name": "Sentara Health Management",
  "payor_id": "54154",
  "seq_id": 792
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5242"
  },
  "payor_name": "Sentinel Security Life Insurance Company",
  "payor_id": "87020",
  "seq_id": 793
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5243"
  },
  "payor_name": "Shared Health Mississippi",
  "payor_id": "SHMS1",
  "seq_id": 794
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5244"
  },
  "payor_name": "Sharp Health Plan (DOS 1/1/18 and After)",
  "payor_id": "SHP102",
  "seq_id": 795
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5245"
  },
  "payor_name": "Shenandoah Life Insurance (Eligibility Only)",
  "payor_id": "Z96279",
  "seq_id": 796
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5246"
  },
  "payor_name": "Shenandoah Life/S USA Life/SBLI Life (Eligibility and Remits Only)",
  "payor_id": "Z95721",
  "seq_id": 797
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5247"
  },
  "payor_name": "Sidecar Health",
  "payor_id": "SDCAR",
  "seq_id": 798
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5248"
  },
  "payor_name": "Sierra Health Services",
  "payor_id": "76342",
  "seq_id": 799
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5249"
  },
  "payor_name": "Significa Benefit Services",
  "payor_id": "23250",
  "seq_id": 800
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f524a"
  },
  "payor_name": "SilverSummit Health Plan (Eligibility Only)",
  "payor_id": "11229",
  "seq_id": 801
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f524b"
  },
  "payor_name": "Simply Healthcare (DOS 1/1/19 And After)",
  "payor_id": "SMPLY",
  "seq_id": 802
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f524c"
  },
  "payor_name": "Simply Healthcare (DOS 12/31/18 And Prior)",
  "payor_id": "27094",
  "seq_id": 803
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f524d"
  },
  "payor_name": "South Country Health Alliance",
  "payor_id": "81600",
  "seq_id": 804
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f524e"
  },
  "payor_name": "Southeastern Indiana Health Organization (SIHO)",
  "payor_id": "IN432",
  "seq_id": 805
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f524f"
  },
  "payor_name": "SPJST Medicare Supplement (Eligibility Only)",
  "payor_id": "10546",
  "seq_id": 806
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5250"
  },
  "payor_name": "Standard Life and Accident Insurance Company",
  "payor_id": "1758",
  "seq_id": 807
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5251"
  },
  "payor_name": "Stanford Health Care Advantage",
  "payor_id": "46407",
  "seq_id": 808
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5252"
  },
  "payor_name": "State Farm Group Medical & Individual Health Ins Companies",
  "payor_id": "31053",
  "seq_id": 809
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5253"
  },
  "payor_name": "State Mutual Med Supplement",
  "payor_id": "Z96277",
  "seq_id": 810
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5254"
  },
  "payor_name": "State Mutual-Lincoln Novation",
  "payor_id": "10982",
  "seq_id": 811
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5255"
  },
  "payor_name": "Stewart C Miller and Co",
  "payor_id": "10937",
  "seq_id": 812
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5256"
  },
  "payor_name": "SummaCare Health Plan",
  "payor_id": "95202",
  "seq_id": 813
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5257"
  },
  "payor_name": "Summit America Insurance Services Inc",
  "payor_id": "37301",
  "seq_id": 814
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5258"
  },
  "payor_name": "Summit Community Care",
  "payor_id": "PASSE",
  "seq_id": 815
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5259"
  },
  "payor_name": "Sunshine State Health Plan",
  "payor_id": "68057",
  "seq_id": 816
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f525a"
  },
  "payor_name": "Superior Health Plan Texas",
  "payor_id": "39188",
  "seq_id": 817
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f525b"
  },
  "payor_name": "Sutter Health Aetna",
  "payor_id": "60624",
  "seq_id": 818
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f525c"
  },
  "payor_name": "Sutter Medical Foundation",
  "payor_id": "87163",
  "seq_id": 819
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f525d"
  },
  "payor_name": "TennDent - Dental",
  "payor_id": "10794",
  "seq_id": 820
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f525e"
  },
  "payor_name": "Texas Childrens Health Plan",
  "payor_id": "76048",
  "seq_id": 821
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f525f"
  },
  "payor_name": "Texas Childrens Health Plan- STAR (Medicaid)",
  "payor_id": "75228",
  "seq_id": 822
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5260"
  },
  "payor_name": "The Health Exchange (Cerner Corporation)",
  "payor_id": "20356",
  "seq_id": 823
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5261"
  },
  "payor_name": "The Loomis Company",
  "payor_id": "23223",
  "seq_id": 824
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5262"
  },
  "payor_name": "Thrivent Financial",
  "payor_id": "THRIV",
  "seq_id": 825
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5263"
  },
  "payor_name": "TransactRX Infusion and Specialty",
  "payor_id": "11168",
  "seq_id": 826
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5264"
  },
  "payor_name": "TransactRX Part D",
  "payor_id": "11037",
  "seq_id": 827
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5265"
  },
  "payor_name": "Transamerica Financial Life Insurance (IA, MD, PA)",
  "payor_id": "TRP1E",
  "seq_id": 828
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5266"
  },
  "payor_name": "Transamerica Life Insurance (KBA)",
  "payor_id": "10946",
  "seq_id": 829
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5267"
  },
  "payor_name": "TRASH - ALL TRANSACTIONS WILL BE DISCARDED",
  "payor_id": "0",
  "seq_id": 830
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5268"
  },
  "payor_name": "Tricare (CHAMPUS)",
  "payor_id": "Z1006",
  "seq_id": 831
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5269"
  },
  "payor_name": "Tricare (Foreign Regions)  FOREN",
  "payor_id": "TRICF",
  "seq_id": 832
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f526a"
  },
  "payor_name": "Tricare East (Tricare North & South DOS after 1/1/18)",
  "payor_id": "TREST",
  "seq_id": 833
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f526b"
  },
  "payor_name": "Tricare for Life",
  "payor_id": "TDDIR",
  "seq_id": 834
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f526c"
  },
  "payor_name": "Tricare West Region",
  "payor_id": "TRICW",
  "seq_id": 835
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f526d"
  },
  "payor_name": "True Health New Mexico",
  "payor_id": "82288",
  "seq_id": 836
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f526e"
  },
  "payor_name": "Trustmark Small Business Benefits fka Trustmark/Starmark",
  "payor_id": "61425",
  "seq_id": 837
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f526f"
  },
  "payor_name": "Tufts Health Plans",
  "payor_id": "4298",
  "seq_id": 838
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5270"
  },
  "payor_name": "Tufts Health Public Plans",
  "payor_id": "4332",
  "seq_id": 839
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5271"
  },
  "payor_name": "UCare Individual & Family Plans (DOS of 01/01/2019 and After)",
  "payor_id": "55413",
  "seq_id": 840
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5272"
  },
  "payor_name": "UCARE of Minnesota (DOS 12/31/21 and prior)",
  "payor_id": "4496",
  "seq_id": 841
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5273"
  },
  "payor_name": "UMR",
  "payor_id": "39026",
  "seq_id": 842
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5274"
  },
  "payor_name": "UMR (UMR Onalaska)",
  "payor_id": "79480",
  "seq_id": 843
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5275"
  },
  "payor_name": "UMWA Health & Retirement Funds",
  "payor_id": "52180",
  "seq_id": 844
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5276"
  },
  "payor_name": "Unicare",
  "payor_id": "80314",
  "seq_id": 845
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5277"
  },
  "payor_name": "Unicare - Dental",
  "payor_id": "10791",
  "seq_id": 846
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5278"
  },
  "payor_name": "Unite Here Health Las Vegas",
  "payor_id": "59144",
  "seq_id": 847
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5279"
  },
  "payor_name": "United American Insurance Company",
  "payor_id": "92916",
  "seq_id": 848
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f527a"
  },
  "payor_name": "United Concordia Companies, Inc - Dental",
  "payor_id": "Z96273",
  "seq_id": 849
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f527b"
  },
  "payor_name": "United Concordia Federal Employees Program - Dental",
  "payor_id": "10809",
  "seq_id": 850
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f527c"
  },
  "payor_name": "United Healthcare",
  "payor_id": "87726",
  "seq_id": 851
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f527d"
  },
  "payor_name": "United Healthcare Facets Detroit Community & State",
  "payor_id": "10836",
  "seq_id": 852
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f527e"
  },
  "payor_name": "United Healthcare Facets Pittsburgh Community & State",
  "payor_id": "10834",
  "seq_id": 853
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f527f"
  },
  "payor_name": "United Healthcare Vision",
  "payor_id": "773",
  "seq_id": 854
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5280"
  },
  "payor_name": "United States Automobile Association (USAA)",
  "payor_id": "74095",
  "seq_id": 855
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5281"
  },
  "payor_name": "United Teacher Assoc Ins Co- Medicare Supplement (Eligibility Only)",
  "payor_id": "Z90027",
  "seq_id": 856
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5282"
  },
  "payor_name": "UnitedHealthcare Community Plan / NJ",
  "payor_id": "86047",
  "seq_id": 857
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5283"
  },
  "payor_name": "UnitedHealthcare Community Plan Kansas (KanCare)",
  "payor_id": "96385",
  "seq_id": 858
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5284"
  },
  "payor_name": "UnitedHealthcare Community Plan Missouri",
  "payor_id": "86050",
  "seq_id": 859
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5285"
  },
  "payor_name": "UnitedHealthcare Community Plan TN",
  "payor_id": "95378",
  "seq_id": 860
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5286"
  },
  "payor_name": "UnitedHealthcare Dental",
  "payor_id": "52133",
  "seq_id": 861
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5287"
  },
  "payor_name": "UnitedHealthcare StudentResources",
  "payor_id": "74227",
  "seq_id": 862
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5288"
  },
  "payor_name": "UnitedHealthOne",
  "payor_id": "81400",
  "seq_id": 863
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5289"
  },
  "payor_name": "Unity Health Insurance",
  "payor_id": "39180",
  "seq_id": 864
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f528a"
  },
  "payor_name": "Univera PPO",
  "payor_id": "UNINW",
  "seq_id": 865
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f528b"
  },
  "payor_name": "Universal American",
  "payor_id": "11216",
  "seq_id": 866
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f528c"
  },
  "payor_name": "University Care Advantage Arizona",
  "payor_id": "10699",
  "seq_id": 867
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f528d"
  },
  "payor_name": "University Family Care",
  "payor_id": "9830",
  "seq_id": 868
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f528e"
  },
  "payor_name": "University of Arizona Health Plan - UHM (Eligibility Only)",
  "payor_id": "10889",
  "seq_id": 869
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f528f"
  },
  "payor_name": "University of Utah Health Plans (Healthy U)",
  "payor_id": "Z1030",
  "seq_id": 870
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5290"
  },
  "payor_name": "UPMC Health Plan",
  "payor_id": "23281",
  "seq_id": 871
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5291"
  },
  "payor_name": "Upper Peninsula Health Plan",
  "payor_id": "38337",
  "seq_id": 872
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5292"
  },
  "payor_name": "US Family Health Plan",
  "payor_id": "90551",
  "seq_id": 873
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5293"
  },
  "payor_name": "USAA Life Insurance Company (Eligibility Only)",
  "payor_id": "Z1316",
  "seq_id": 874
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5294"
  },
  "payor_name": "VA Fee Basis Program",
  "payor_id": "12115",
  "seq_id": 875
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5295"
  },
  "payor_name": "VA Health Administration Center (Eligibility Only)",
  "payor_id": "Z96272",
  "seq_id": 876
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5296"
  },
  "payor_name": "VA Healthcare",
  "payor_id": "Z96271",
  "seq_id": 877
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5297"
  },
  "payor_name": "Valley Care Program",
  "payor_id": "10948",
  "seq_id": 878
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5298"
  },
  "payor_name": "Valley Health Plan (Commercial)",
  "payor_id": "VHP01",
  "seq_id": 879
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f5299"
  },
  "payor_name": "ValueOptions/MBHP",
  "payor_id": "43307",
  "seq_id": 880
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f529a"
  },
  "payor_name": "Vantage Health Plan Inc",
  "payor_id": "72128",
  "seq_id": 881
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f529b"
  },
  "payor_name": "Varipro",
  "payor_id": "72187",
  "seq_id": 882
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f529c"
  },
  "payor_name": "Viva Health Plan",
  "payor_id": "63114",
  "seq_id": 883
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f529d"
  },
  "payor_name": "VNSNY CHOICE Medicare",
  "payor_id": "77073",
  "seq_id": 884
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f529e"
  },
  "payor_name": "Vytra Healthcare",
  "payor_id": "22264",
  "seq_id": 885
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f529f"
  },
  "payor_name": "Washington National Insurance Company (Eligibility and Remits Only)",
  "payor_id": "70319",
  "seq_id": 886
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52a0"
  },
  "payor_name": "WebTPA Employer Services LLC",
  "payor_id": "75261",
  "seq_id": 887
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52a1"
  },
  "payor_name": "Wellcare Health Plans",
  "payor_id": "14163",
  "seq_id": 888
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52a2"
  },
  "payor_name": "WellCare PFFS (Eligibility Only)",
  "payor_id": "77072",
  "seq_id": 889
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52a3"
  },
  "payor_name": "Wellmed",
  "payor_id": "TH023",
  "seq_id": 890
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52a4"
  },
  "payor_name": "Western Grower's Insurance Company",
  "payor_id": "24735",
  "seq_id": 891
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52a5"
  },
  "payor_name": "Western Health Advantage",
  "payor_id": "68039",
  "seq_id": 892
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52a6"
  },
  "payor_name": "Western Health Advantage by Ayin",
  "payor_id": "77225",
  "seq_id": 893
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52a7"
  },
  "payor_name": "Western Southern Financial Group (Cincinnati, OH)",
  "payor_id": "31048",
  "seq_id": 894
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52a8"
  },
  "payor_name": "Wilson McShane - Dental",
  "payor_id": "10797",
  "seq_id": 895
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52a9"
  },
  "payor_name": "Windsor Medicare Extra (Eligibility Only)",
  "payor_id": "62153",
  "seq_id": 896
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52aa"
  },
  "payor_name": "Wisconsin Chronic Disease Program",
  "payor_id": "WCDP",
  "seq_id": 897
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52ab"
  },
  "payor_name": "Wisconsin Physicians Service Group Health / WPS",
  "payor_id": "SX022",
  "seq_id": 898
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52ac"
  },
  "payor_name": "Wisconsin Well Woman Program",
  "payor_id": "WWWP",
  "seq_id": 899
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52ad"
  },
  "payor_name": "World Corp",
  "payor_id": "Z96261",
  "seq_id": 900
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52ae"
  },
  "payor_name": "Yamhill CCO Physical Health",
  "payor_id": "77946",
  "seq_id": 901
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52af"
  },
  "payor_name": "Zing Choice IL (HMO)",
  "payor_id": "83248",
  "seq_id": 902
},
{
  "_id": {
    "$oid": "6618f53f62d3e62d840f52b0"
  },
  "payor_name": "ZirMed Test Payer_",
  "payor_id": "22222",
  "seq_id": 903
}];

export const findPayorByName = (name, insurance_ist) => {
  let regex = new RegExp(name, "i"); // 'i' flag for case-insensitive search
  return insurance_ist.filter((obj) => regex.test(obj.payor_name));
};

// export const getPriceOnly = (amount) =>{
//     var Curny = JSON.parse(localStorage.getItem("Curny"));
//     if(Curny?.Currency === 'USD')
//     {
//         var cal_amt = Math.round((amount / Curny.CurrencyValue) + Curny.Margin_IN_Percentage);
//         return cal_amt;
//     }
//     return amount;
// }

// export const getPrice = (amount) =>{
//     // var Curny = JSON.parse(decode(localStorage.getItem("Curny")));
//     var Curny = JSON.parse(localStorage.getItem("Curny"));
//     if(Curny?.Currency === 'USD')
//     {
//         var cal_amt = Math.round((amount / Curny.CurrencyValue) + Curny.Margin_IN_Percentage);
//         return cal_amt + " USD";
//     }
//     return amount + " INR";
// }

// export const getPriceType = () =>{
//     // var Curny = JSON.parse(decode(localStorage.getItem("Curny")));
//     var Curny = JSON.parse(localStorage.getItem("Curny"));
//     return Curny?.Currency;
// }
