import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

const Disclaimer = () => {
    const navigate=useNavigate();
    const[iAgree, setIAgree]=useState();
    const params = new URLSearchParams(
        window ? window.location.search : {}
    );

    const onSubmit = () => {
        const retrunurl = params.get("retrunurl");
        //console.log("retrunurl", retrunurl)
        if (retrunurl) {
            //window.location.href=retrunurl;
            navigate(retrunurl)
        }
        else {
            // navigate("/onboarding-stella");
            navigate('/')
        }
    }

    const disclaimerText = localStorage.getItem("disclaimer");
    return (
        <div>
            <div className='' style={{ background: "linear-gradient(180deg, #3DACF0 55.1%, rgba(99, 132, 227, 0) 100%)", padding: 0, margin: 0 }}>
                <div className='container'>
                    <div className="py-5">
                        <h6 className="text-white font-w600 fs-4">Welcome to United We Care!</h6>
                    </div>
                    <div className='bg-white px-5' style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)", borderRadius: "12px" }}>
                        <h6 className='fs-5 pt-5'>Disclaimer</h6>
                        <p className='py-3' dangerouslySetInnerHTML={{__html:disclaimerText}}>
                            {/* These terms and conditions outline the rules and regulations for the use of Company Name's Website, located at Website.com. By accessing this website we assume you accept these terms and conditions. Do not continue to use United We Care if you do not agree to take all of the terms and conditions stated on this page. The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you, the person log on this website and compliant to the Company's terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same. Cookies We employ the use of cookies. By accessing United We Care, you agreed to use cookies in agreement with the Company Name's Privacy Policy. */}
                        </p>
                        <Form.Group className="mb-3 pb-5 d-flex justify-content-between" controlId="formBasicCheckbox">
                            <Form.Check onClick={()=> setIAgree(!iAgree)} type="checkbox" label="Agree & Continue" /> 
                            <Button disabled={!iAgree} onClick={()=> onSubmit()} color='white' className='border-0 rounded-5' style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)" }}>Accept</Button>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Disclaimer