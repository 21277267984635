import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './RegisterAsProfessional.css'
import RegisterAsProfessionalProfileDetailTab from './RegisterAsProfessionalProfileDetailTab'
import RegisterAsProfessionalProfessionalDetailTab from './RegisterAsProfessionalProfessionalDetailTab'
import RegisterAsProfessionalSchedulingTab from './RegisterAsProfessionalSchedulingTab'
import RegisterAsProfessionalPaymentTab from './RegisterAsProfessionalPaymentTab'
import RegisterAsProfessionalSummaryTab from './RegisterAsProfessionalSummaryTab'
import ThankYouTab from './ThankYouTab'
import { usOnBoardingExpertProfileDetail, usOnBoarding_registrationStatus, usOnBoarding_updateProfilePicture } from '../core/_request'
import { toast } from 'react-toastify'
import { getAuth, signInAnonymously } from 'firebase/auth'
import moment from 'moment'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../firebaseConfig'
import { Helmet } from 'react-helmet'
import { useSnackbar } from 'notistack'

const RegisterAsProfessional = () => {
    const inputRef = useRef(null);
    const [currentTab, setCurrentTab] = useState("profile");
    const [profileImage, setProfileImage] = useState();
    const [data, setData] = useState({});
    const [registeredData, setRegisteredData] = useState({})
    const [loading, setLoading] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const handleSelect = (eventKey) => {
        setCurrentTab(eventKey);
    };

    const Save_Profile = (url) => {
        var user_id = (JSON.parse(localStorage.getItem("Email_Verified_id")) ?? (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id))
        if (user_id === undefined || user_id === null || user_id === '') {
            // toast('Please verify your email id');
            enqueueSnackbar("Please verify your email id", { variant: "warning" });
            return false;
        }
        var body = {
            "user_id": user_id,
            "image_link": url,
        }
        usOnBoarding_updateProfilePicture(body).then(resp => {
            if (resp?.code === 200) {
                // toast('Professional Details Successfully');
                enqueueSnackbar("Professional Details Successfully", { variant: "success" });
                setData({ ...data, profile_image: url })
                localStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));
            }
            else {
                // toast(resp?.data?.error ?? resp?.message);
                enqueueSnackbar(resp?.data?.error ?? resp?.message, { variant: "error" });
            }
        })
    }

    const handleClick = () => {
        // 👇️ open file input box on click of another element
        inputRef.current.click();
    };

    var user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);

    useEffect(() => {
        const auth = getAuth();
        signInAnonymously(auth)
            .then(() => {
                // Signed in..
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ...
            });

        var body = { "user_id": user_id }
        usOnBoarding_registrationStatus(body).then((resp) => {
            setRegisteredData(resp.data)
        })

    }, [user_id, currentTab]);

    useEffect(() => {
        localStorage.removeItem("UsOnBoarding")
        localStorage.removeItem("User_Registered_Info")

    }, []);

    const handleFileUpload = async (event) => {
        setLoading(true);

        var file = event.target.files[0];
        // console.log("file", file.type)
        if (file?.type === "image/png" || file?.type === "image/jpg" || file?.type === "image/jpeg") {
            var dirName = moment().format("MMMM_YYYY");
            const storageRef = ref(storage, dirName + "/" + file.name)
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    // update progress
                    // setPercent(percent);
                },
                (err) => enqueueSnackbar(err, { variant: "error" }),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        // console.log("file path url ", url);
                        setLoading(false);
                        Save_Profile(url);
                    });
                });
                setProfileImage(true);
        }
        else {
            toast("Incorrect  file format.")
            enqueueSnackbar("Incorrect  file format.", { variant: "warning" });
        }
    }

    return (
        <>
            <Helmet>
                <title>Register as professional</title>
            </Helmet>

            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey="profile" activeKey={currentTab} onSelect={(key) => setCurrentTab(key)}>
                    <div className='d-block d-lg-none'>
                        <p className='font-inter text-center fs-6 font-w500 text-black-50 my-5'>
                            We apologize for the inconvenience. The Expert onboarding feature is currently only available on desktop devices. For the best experience, we kindly request that you access the onboarding process from a desktop computer or laptop. Thank you for your understanding. Should you have any questions or need further assistance, please feel free to reach out to our support team at professionalsus@unitedwecare.com.
                        </p>
                    </div>
                    <div className='d-none d-lg-block'>
                        <Row className=''>
                            <Col sm={3} className='my-3 px-3 h-100 rounded-3' style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)" }}>
                                <div className='py-3'>
                                    <div className='d-flex flex-column align-items-center'>
                                        <img src={data?.profile_image ?? '/assets/img/noDP.png'} alt='' className='rounded-3' style={{ height: 140, width: 140 }} />
                                        <input type='file' accept="image/png, image/jpg, image/jpeg" ref={inputRef} onChange={handleFileUpload} className='d-none' />
                                        <Link style={{
                                            color: "var(--Light-Base-Color-Primary, #153300)",
                                            textAlign: "center",
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            lineHeight: "130%", /* 20.8px */
                                            textDecoration: "underline",
                                            cursor: ''
                                        }} onClick={handleClick}>{profileImage ? "Change Photo" : "Upload Photo"}</Link>
                                    </div>
                                    <Nav className="flex-column my-4">
                                        <Nav.Item>
                                            <Nav.Link eventKey="profile" className='text-black fs-6 font-inter d-flex align-items-center gap-3' disabled={currentTab === "thankYou"}>
                                                <img src='/assets/img/Vector(21).png' alt='' />
                                                Profile Details
                                                {registeredData?.profile && <img src='/assets/img/done_black_24dp 1.png' alt='' /> || <img src='/assets/img/Vector (6).png' alt='' />}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="professionalDetail" className='text-black fs-6 font-inter d-flex align-items-center gap-3' disabled={currentTab === "thankYou"}>
                                                <img src='/assets/img/Vector(22).png' alt='' />
                                                Professional Details
                                                {registeredData?.professional && <img src='/assets/img/done_black_24dp 1.png' alt='' /> || <img src='/assets/img/Vector (6).png' alt='' />}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="scheduling" className='text-black fs-6 font-inter d-flex align-items-center gap-3' disabled={currentTab === "thankYou"}>
                                                <img src='/assets/img/Vector(23).png' alt='' />
                                                Scheduling
                                                {registeredData?.scheduling && <img src='/assets/img/done_black_24dp 1.png' alt='' /> || <img src='/assets/img/Vector (6).png' alt='' />}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="payment" className='text-black fs-6 font-inter d-flex align-items-center gap-3' disabled={currentTab === "thankYou"}>
                                                <img src='/assets/img/Vector(24).png' alt='' />
                                                Payment
                                                {registeredData?.payment && <img src='/assets/img/done_black_24dp 1.png' alt='' /> || <img src='/assets/img/Vector (6).png' alt='' />}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="summary" className='text-black fs-6 font-inter d-flex align-items-center gap-3' disabled={currentTab === "thankYou"}><img src='/assets/img/Vector(25).png' alt='' /> Summary</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="thankYou" className='d-none' disabled></Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Col>
                            <Col sm={9} className='rounded-4 px-3 my-3'>
                                <div className='rounded-3' style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)" }}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="profile"><RegisterAsProfessionalProfileDetailTab setProfileImage={setProfileImage} onClick={(e) => handleSelect(e)} onNext={() => handleSelect('professionalDetail')} /></Tab.Pane>
                                        <Tab.Pane eventKey="professionalDetail"><RegisterAsProfessionalProfessionalDetailTab onNext={() => handleSelect('scheduling')} /></Tab.Pane>
                                        <Tab.Pane eventKey="scheduling"><RegisterAsProfessionalSchedulingTab onNext={() => handleSelect('payment')} /></Tab.Pane>
                                        <Tab.Pane eventKey="payment"><RegisterAsProfessionalPaymentTab onNext={() => handleSelect('summary')} /></Tab.Pane>
                                        <Tab.Pane eventKey="summary"><RegisterAsProfessionalSummaryTab registeredData={registeredData} onClick={(e) => handleSelect(e)} onNext={() => handleSelect('thankYou', 'profile', 'professionalDetail')} /></Tab.Pane>
                                        <Tab.Pane eventKey="thankYou"><ThankYouTab /></Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Tab.Container>
            </Container>
        </>

    )
}

export default RegisterAsProfessional