import { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import moment from "moment";

const WorksheetCard = ({
  id,
  assigned,
  name,
  updatedOn,
  avatars,
  viewActivity,
}) => {
  return (
    <Card
      className="WorksheetCard"
      variant="outlined"
      sx={{
        borderRadius: "10px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      id={id}
    >
      <CardContent>
        <Typography gutterBottom variant="b" component="div">
          {name}
        </Typography>

        <Typography gutterBottom variant="span" component="div">
          {moment(updatedOn).format("DD MMMM YYYY")}
        </Typography>
        {avatars ? (
          <AvatarGroup max={4}>
            {avatars?.map((image) => (
              <Avatar alt="Remy Sharp" src={image} />
            ))}
          </AvatarGroup>
        ) : (
          <span
            style={{ marginTop: "15px", fontSize: "15px", color: "#656565" }}
          >
            No Client assigned
          </span>
        )}
      </CardContent>
      <CardActions>
        <Button
          className="ViewAll--Button"
          variant="contained"
          onClick={() => viewActivity(name, id)}
          disableElevation
        >
          View Activity
        </Button>
      </CardActions>
    </Card>
  );
};

export default WorksheetCard;
