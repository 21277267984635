import React, {useState} from "react";
import VerifiedIcon from "@mui/icons-material/Verified";

const InsuranceDetailCard = ({ data, onClick }) => {
  return (
    <div className="Insurance-Detail-Card" onClick={onClick}>
      <img
        src="/assets/img/mask_group.png"
        alt=""
        className="bg-img"
        style={{
          position: "absolute",
          background: ` ${data?.isSelected ? "#73589b" : "gray"}`,
          // boxShadow: ` ${data?.isSelected && `0px 20px 20px 0px ${data?.color}` }`,
        }}
      />

      <div className="card-content">
        <div>
          <div className="insurance-name d-flex justify-content-between">
            {data?.insuring_company}
            {/* <img src="/assets/img/hdfc_ergo_insurance.png" alt="" /> */}
          </div>
          <div className="verified">
            {data.verfied ? (
              <div className="d-flex gap-2 align-items-center">
                <VerifiedIcon sx={{ color: "white" }} fontSize="small" />
                Verified
              </div>
            ) : null}
          </div>
        </div>
        <div className="member-id">
          Member ID <div> {data?.member_id} </div>
        </div>
        {/* <div className="policy-number">Policy Number {data?.policy_number}</div> */}
      </div>
    </div>
  );
};

export default InsuranceDetailCard;
