import React, { useState, useEffect } from "react";
import "../../styles/courses/Testimonials.css";
import TestimonialCard from "./TestimonialCard";
import { testimonial_commas, user_image } from "./assets";
import {} from "./assets";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Skeleton, Grid } from "@mui/material";

// import required modules
import { Pagination, Navigation } from "swiper";

const data = [
  {
    title: "Practical tools to manage my stress and anxiety",
    desc: "I used to struggle with overwhelming anxiety that made it hard to enjoy life. This course provided me with practical tools to manage my stress and anxiety. I now feel more in control and am able to face challenges with a clearer mindset.",
    imageUrl: user_image,
    username: "John Thompson",
    courseName: "Anxiety and Stress Management Program",
  },
  {
    title: "Practical tools to manage my stress and anxiety",
    desc: "I used to struggle with overwhelming anxiety that made it hard to enjoy life. This course provided me with practical tools to manage my stress and anxiety. I now feel more in control and am able to face challenges with a clearer mindset.",
    imageUrl: user_image,
    username: "John Thompson",
    courseName: "Anxiety and Stress Management Program",
  },
  {
    title: "Practical tools to manage my stress and anxiety",
    desc: "I used to struggle with overwhelming anxiety that made it hard to enjoy life. This course provided me with practical tools to manage my stress and anxiety. I now feel more in control and am able to face challenges with a clearer mindset.",
    imageUrl: user_image,
    username: "John Thompson",
    courseName: "Anxiety and Stress Management Program",
  },
  {
    title: "Practical tools to manage my stress and anxiety",
    desc: "I used to struggle with overwhelming anxiety that made it hard to enjoy life. This course provided me with practical tools to manage my stress and anxiety. I now feel more in control and am able to face challenges with a clearer mindset.",
    imageUrl: user_image,
    username: "John Thompson",
    courseName: "Anxiety and Stress Management Program",
  },
  {
    title: "Practical tools to manage my stress and anxiety",
    desc: "I used to struggle with overwhelming anxiety that made it hard to enjoy life. This course provided me with practical tools to manage my stress and anxiety. I now feel more in control and am able to face challenges with a clearer mindset.",
    imageUrl: user_image,
    username: "John Thompson",
    courseName: "Anxiety and Stress Management Program",
  },
];

const Testimonials = (props) => {
  const { courseData, loading } = props;
  const len = courseData?.reviews?.length;
  const [columns, setColumn] = useState(2);
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 768) {
        setColumn(1);
      }
    };
    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the initial state based on the window size
    handleResize();

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="testimonial-section">
      {loading ? (
        <div className="testimonial-container">
          <div className="testimonial-heading d-block d-md-none">
            <Skeleton
              variant="rounded"
              sx={{ borderRadius: "10px", margin: "5px auto" }}
              width="50%"
              height={50}
            />
          </div>
          <div className="testimonial-heading d-none d-md-block">
            <Skeleton
              variant="rounded"
              sx={{ borderRadius: "10px", margin: "5px 0" }}
              width="25vw"
              height={60}
            />
          </div>

          <div>
            <Grid container spacing={2}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(${columns}, 1fr)`,
                  gap: "15px",
                  width: "100%",
                  padding: "20px",
                }}
              >
                {Array.from(new Array(columns)).map((_, index) => (
                  <Grid
                    item
                    xs={6}
                    md={4}
                    lg={3}
                    key={index}
                    sx={{ minWidth: "100%", margin: "20px 0" }}
                  >
                    <div
                      style={{
                        width: "500px",
                        backgroundColor: "rgb(0,0,0,0.09)",
                        margin: "10px 0 0 40px",
                        padding: "20px",
                      }}
                      className="d-none d-md-block"
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "10px", margin: "8px 0" }}
                        width={80}
                        height={80}
                      />
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "10px", margin: "8px 0" }}
                        width="90%"
                        height={50}
                      />
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "10px", margin: "8px 0" }}
                        width="90%"
                        height={100}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "20px",
                        }}
                      >
                        <Skeleton
                          variant="rounded"
                          sx={{ borderRadius: "10px", margin: "8px 0" }}
                          width={100}
                          height={100}
                        />
                        <div style={{ marginTop: "20px" }}>
                          <Skeleton
                            variant="rounded"
                            sx={{ borderRadius: "10px", margin: "8px 0" }}
                            width="10vw"
                            height={30}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ borderRadius: "10px", margin: "8px 0" }}
                            width="10vw"
                            height={30}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "400px",
                        backgroundColor: "rgb(0,0,0,0.09)",
                        padding: "20px",
                        borderRadius: "10px",
                        margin: "0 15px",
                      }}
                      className="d-block d-md-none"
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "10px", margin: "8px 0" }}
                        width={80}
                        height={80}
                      />
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "10px", margin: "8px 0" }}
                        width="100%"
                        height={50}
                      />
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "10px", margin: "8px 0" }}
                        width="100%"
                        height={100}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "20px",
                        }}
                      >
                        <Skeleton
                          variant="rounded"
                          sx={{ borderRadius: "10px", margin: "8px 0" }}
                          width={100}
                          height={100}
                        />
                        <div style={{ marginTop: "20px" }}>
                          <Skeleton
                            variant="rounded"
                            sx={{ borderRadius: "10px", margin: "8px 0" }}
                            width="30vw"
                            height={30}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ borderRadius: "10px", margin: "8px 0" }}
                            width="30vw"
                            height={30}
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </div>
            </Grid>
          </div>
        </div>
      ) : (
        <div className="testimonial-container">
          <div className="testimonial-heading">
            Hear from our <span style={{ color: "#73589B" }}>Users</span>
          </div>
          <div className="testimonial-cards" style={{}}>
            <Swiper
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                // Todo: len is less than 3 otherwise 3
                1024: {
                  slidesPerView: len < 3 ? len : 3,
                },
              }}
              spaceBetween={30}
              navigation={len > 3 ? true : false}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              // style={{ width: `${len * 25}vw` }}
            >
              <div className="testimonial-card active">
                {courseData?.reviews?.map((item, i) => (
                  <SwiperSlide key={i}>
                    <TestimonialCard item={item} />
                  </SwiperSlide>
                ))}
              </div>
              {(len > 3 && (
                <>
                  <div className="swiper-button-next custom-arrow"></div>
                  <div className="swiper-button-prev custom-arrow"></div>
                </>
              )) ||
                ""}
            </Swiper>
          </div>
        </div>
      )}
    </section>
  );
};

export default Testimonials;
