import React, { useEffect, useState } from "react";
import DetailPageCard from "../DetailPageCard";
import {
  Badge,
  Button,
  ButtonGroup,
  Modal,
  Row,
  ToggleButton,
} from "react-bootstrap";
import PrescriptionsCard from "../PrescriptionsCard";
import { useParams, useNavigate } from "react-router-dom";
import {
  MySubscriptionService,
  GetAppointment_Documents_Files_Sent_Expert,
  GetAppointment_Documents_Files_Sent_User,
  saveAppointment_Documents_Files,
  CancelAppointmentService,
  CheckFeedbackStatus,
} from "./core/_request";
import {
  AppointmentService,
  ExpertDetailsService,
  FeedbackFormCall,
  RateYourExpertAndFeedback,
  UpdateCallJoinStatus,
  getAppointment_Session,
  getUserDetail,
} from "../core/_request";
import { useAuth } from "../../auth";
import { storage } from "../../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, signInAnonymously } from "firebase/auth";
import { MySubscription_Status } from "../core/models";
import moment from "moment";
import { getCountryCode } from "../core/commonFun";
import DashboardDetailCard from "./DashboardDetailCard";
import { useContext } from "react";
import { AppContext } from "../../context";
import { Rating, Skeleton, TextField } from "@mui/material";
import { Helmet } from "react-helmet";
import { useSnackbar } from "notistack";
import OnFeedback from "./OnFeedback";
import Feedback from "./Feedback";

const AppointmentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [expertData, setExpertData] = useState();
  const [expertDocumentList, setExpertDocumentList] = useState();
  const [userDocumentList, setUserDocumentList] = useState();
  const [sessionData, setSessionData] = useState();
  const [percent, setPercent] = useState();
  const [title, setTitle] = useState();
  const [docsFile, setDocsFile] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const { currentUser, logout } = useAuth();
  const countryCode = getCountryCode();
  const [show, setShow] = useState(false);
  const [starValue, setStarValue] = useState(0);
  const [callQuality, setCallQuality] = useState("");
  const [expertInteraction, setExpertInteraction] = useState("");
  const [reviewText, setReviewText] = useState("");
  const [displayNameBool, setDisplayNameBool] = useState();
  const [bookAnotherSession, setBookAnotherSession] = useState();
  const [feedbackStatus, setFeedbackStatus] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [expertDataLoad, setExpertDataLoad] = useState(true);
  const [showSimpleModal, setShowSimpleModal] = useState(false);
  const [validate, setValidate] = useState("");
  const [feedbackShow, setfeedbackShow] = useState(false);
  const [status, setFeedStatus] = useState(false);
  const [feedbackAnswers, setFeedbackAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;

  const handleClose = () => setShow(false);
  const handleSimpleModal = () => setShowSimpleModal(true);

  const handleCloseSimpleModal = () => {
    setShowSimpleModal(false);
  };

  useEffect(() => {
    if (validate !== "") {
      if (status) {
        setfeedbackShow(true);
      } else {
        handleSimpleModal();
      }
    }
  }, [status, validate]);

  const getUserByIdIncompleted = () => {
    debugger
    setIsLoading(true);
    var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
    var feedbackbody = {
      user_type: "user",
      user_id: user_id,
      appointment_id: id,
    };
    getUserDetail(feedbackbody).then((response) => {
      setFeedbackAnswers(
        response?.data[0]?.qa_list ? response?.data[0]?.qa_list : []
      );
      if (response?.data?.message === "No Feedback Available !") {
        setFeedStatus(false);
        handleSimpleModal();
      } else if (response?.data[0]?.session_status === "completed") {
        if (
          response?.data[0]?.qa_list &&
          response?.data[0]?.qa_list?.length > 0
        ) {
          setFeedStatus(true);
          setValidate(Math.random());
        }
      } else {
        setFeedStatus(false);
        handleSimpleModal();
      }
    })
      .catch((error) => {
        console.error("Error fetching questions:", error);
      });
    setIsLoading(false);
  };

  const getCurrentTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
    MySubscriptionService().then((resp) =>
      setData(resp?.data?.find((o) => o.ID == id))
    );
  }, []);

  useEffect(() => {
    bindFiles();
  }, [expertData]);

  useEffect(() => {
    //console.log("DATA", data);
    if (data?.ExpertId)
      ExpertDetailsService(data?.ExpertId).then((resp) => {
        //console.log("ExpertDetailsService", resp)
        var data = resp.data;
        // console.log("resp.data", resp.data)
        if (countryCode == "IN")
          data["countryAmount"] = data.Hourly_Consultaion_Fee_amount;
        else data["countryAmount"] = data.USD_Amount ?? 0;
        setExpertData(resp.data);
        setExpertDataLoad(false);
      });
  }, [data]);

  const bindFiles = () => {
    if (expertData && expertData.Id) {
      GetAppointment_Documents_Files_Sent_Expert(id, expertData.Id).then(
        (resp) => setExpertDocumentList(resp.data)
      );
      GetAppointment_Documents_Files_Sent_User(id, expertData.Id).then((resp) =>
        setUserDocumentList(resp.data)
      );
      getAppointment_Session(expertData.Id).then((resp) =>
        setSessionData(resp.data)
      );
    }
  };

  const uploadNewFile = async (event) => {
    // setLoading(true);
    var file = event.target.files[0];
    var dirName = moment().format("MMMM_YYYY");
    const storageRef = ref(storage, dirName + "/" + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // update progress
        setPercent(percent);
      },
      (err) => enqueueSnackbar(err, { variant: "error" }),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setDocsFile(url);
          // setLoading(false);
        });
      }
    );
  };

  const onSubmitNewDoc = () => {
    // //console.log(this.props.route.params);
    // //console.log(this.state);
    if (!title) {
      enqueueSnackbar("Please give title.", { variant: "warning" });
    } else if (!docsFile) {
      enqueueSnackbar("Please upload document.", { variant: "warning" });
    } else {
      setLoading(true);
      var req = {
        AppointmentId: id,
        Title: title,
        Note: "Note",
        DocsFile: docsFile,
      };

      //console.log("Add Documents req", req)
      saveAppointment_Documents_Files(req).then((resp) => {
        bindFiles();
        setLoading(false);
        setTitle("");
        setDocsFile("");
        if (resp.ResponseCode == 200 || resp.ResponseCode == 201) {
          //console.log("Add Documents resp", resp);
        } else {
          enqueueSnackbar(resp.ResponseMessage, { variant: "error" });
        }
      });
    }
  };

  const onClickRatingSubmit = () => {
    var ratingBody = {
      ExpertId: data?.ExpertId,
      AppointmentId: id,
      RateYourExpert: starValue,
      CallQuality: callQuality,
      InteractionWithExpert: expertInteraction,
      ReviewForExpert: reviewText,
      DisplayMyName: displayNameBool,
      BookAnotherSessionWithExpert: bookAnotherSession,
    };
    RateYourExpertAndFeedback(ratingBody).then((resp) => {
      if (resp.ResponseCode === 200) {
        enqueueSnackbar(resp.ResponseMessage, { variant: "success" });
        window.location.reload();
        handleClose();
      }
    });
  };

  const cancellation = () => {
    setLoadingCancel(true);  // Set loading to true at the start of the operation
    var req = { AppointmentId: data?.ID };
    CancelAppointmentService(req).then((resp) => {
      if (resp != null && resp.ResponseCode === 200) {
        enqueueSnackbar("Appointment Cancelled Successfully", { variant: "success" });
        window.location.reload();
      } else if (resp != null && resp.ResponseCode === 401) {
        logout();
        // setShowLloginPopup(true);
        const currentUrl = encodeURIComponent(window.location.href);
        window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
      }
    }).catch(error => {
      // Handle any errors here
      enqueueSnackbar("Cancellation failed", { variant: "error" });
    }).finally(() => {
      setLoadingCancel(false);  // Set loading to false at the end of the operation
    });
  };

  const onClickCallNow = (url) => {
    var body = {
      user_id: user_id,
      appointment_id: id,
      join_status: "Joined",
      user_type: "Expert",
    };

    UpdateCallJoinStatus(body).then((resp) => {
      if (resp?.ResponseCode) {
      }
    });

    var request = {
      AppointmentId: id,
      type: currentUser.UserType == 3 ? "Expert" : "User",
    };
    AppointmentService(request);
    window.open(url, "_blank");
  };

  const onClickSkip = () => {
    handleClose();
  };

  useEffect(() => {
    CheckFeedbackStatus(id).then((resp) => {
      if (resp?.data === true) {
        setFeedbackStatus(true);
      } else {
        setFeedbackStatus(false);
      }
    });
  }, [feedbackStatus]);

  return (
    <>
      <Helmet>
        <title>My Appointments Dashboard - United We Care</title>
      </Helmet>
      <div
        className="pt-5"
        style={{
          padding: 0,
          margin: 0,
          background: "#fff",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              {expertDataLoad ? (
                <>
                  <div className="my-4">
                    <Skeleton
                      variant="rounded"
                      width="20vw"
                      height={35}
                      sx={{ borderRadius: "10px" }}
                    />
                  </div>
                  <div
                    className="bg-white d-flex flex-column align-items-center justify-content-around px-2 py-3"
                    style={{
                      borderRadius: "12px",
                      boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)",
                    }}
                  >
                    <div className="row my-3 align-items-center justify-content-around">
                      <div className="my-3 d-flex align-items-center justify-content-around col-xs-6 col-md-12">
                        <Skeleton variant="circular" width={200} height={200} />
                      </div>
                      <div className="d-md-flex d-none flex-column align-items-center justify-content-center col-xs-6 col-md-12 ">
                        <h6 className="text-center">
                          <Skeleton
                            variant="rounded"
                            width="10vw"
                            height={20}
                            sx={{ borderRadius: "10px" }}
                          />
                        </h6>
                        <p className="text-center fs-6 my-2">
                          <Skeleton
                            variant="rounded"
                            width="15vw"
                            height={20}
                            sx={{ borderRadius: "10px" }}
                          />
                        </p>
                        <p className="text-center fs-6 mt-2">
                          <Skeleton
                            variant="rounded"
                            width="15vw"
                            height={20}
                            sx={{ borderRadius: "10px" }}
                          />
                        </p>
                      </div>
                      <div className="d-flex d-md-none flex-column align-items-center justify-content-center col-xs-6 col-md-12 ">
                        <h6 className="text-center">
                          <Skeleton
                            variant="rounded"
                            width="35vw"
                            height={20}
                            sx={{ borderRadius: "10px" }}
                          />
                        </h6>
                        <p className="text-center fs-6 my-2">
                          <Skeleton
                            variant="rounded"
                            width="50vw"
                            height={20}
                            sx={{ borderRadius: "10px" }}
                          />
                        </p>
                        <p className="text-center fs-6 mt-2">
                          <Skeleton
                            variant="rounded"
                            width="50vw"
                            height={20}
                            sx={{ borderRadius: "10px" }}
                          />
                        </p>
                      </div>
                    </div>
                    <hr className="w-100" />
                    {/* {sdata && (
            <div className="d-flex flex-row justify-content-between w-100">
              <p className="fs-6"><Skeleton variant="rounded" width={100} height={20}/></p>
              <p className="fs-6"><Skeleton variant="rounded" width={100} height={20}/></p>
            </div>
          )} */}
                    <div>
                      <Skeleton
                        variant="rounded"
                        width={200}
                        height={40}
                        sx={{ borderRadius: "40px" }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className="fs-3 mb-5">
                    My Appointments / {data?.Name || "Professional"}
                  </p>
                  <DashboardDetailCard data={expertData ?? data} />
                </>
              )}
            </div>

            <div className="col-md-8 d-flex flex-column gap-5">
              <div
                className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center px-2 py-2 bg-white rounded-2 mt-3 mt-md-0"
                style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)" }}
              >
                {expertDataLoad ? (
                  <div className="d-flex py-2 gap-1 align-items-center justify-content-between justify-content-md-start w-100">
                    <Skeleton
                      variant="rounded"
                      width={58}
                      height={36}
                      style={{ borderRadius: "5px" }}
                    />
                    <div className="d-flex flex-column d-none d-md-block">
                      <Skeleton
                        variant="rounded"
                        width="7vw"
                        height={18}
                        className="mt-1"
                        style={{ borderRadius: "5px" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width="7vw"
                        height={18}
                        className="mt-1"
                        style={{ borderRadius: "5px" }}
                      />
                    </div>
                    <div className="d-flex flex-column gap-2 d-block d-md-none">
                      <Skeleton
                        variant="rounded"
                        width="15vw"
                        height={18}
                        style={{ borderRadius: "5px" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width="15vw"
                        height={18}
                        style={{ borderRadius: "5px" }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex py-2 gap-2 align-items-center justify-content-between justify-content-md-start w-100">
                    <Badge
                      bg={
                        (data?.Status == "Canceled" && "danger") ||
                        data?.Status == "Open" && 'success' ||
                        (data?.Status == "Completed" && "success") ||
                        "warning"
                      }
                      className="p-2"
                    >
                      {data?.Status}
                    </Badge>
                    <p className="fs-6 font-w600 text-black">
                      {data?.AppDate}
                      <br /> {data?.AppTime} ({data?.TimeZone})
                    </p>
                  </div>
                )}

                <div className="w-100 d-flex justify-content-end gap-2">
                  {expertDataLoad && data?.Status == "Open" ? (
                    <Skeleton
                      variant="rounded"
                      width={104}
                      height={40}
                      style={{ borderRadius: "5px" }}
                    />
                  ) : (
                    data?.Status == "Open" && (
                      <Button
                        onClick={() => onClickCallNow(data?.Meeting_link)}
                        className="rounded-5 border-0"
                        style={{
                          background: "var(--primary-color)",
                        }}
                      >
                        {/* <img
                          src="/assets/img/phone.png"
                          className="px-2"
                          alt=""
                        />{" "} */}
                        Join Now
                      </Button>
                    )
                  )}
                  {expertDataLoad &&
                    data?.Status == MySubscription_Status.BOOKED &&
                    data?.IsValid24HourReschdule ? (
                    <>
                      <Skeleton
                        variant="rounded"
                        width={104}
                        height={40}
                        style={{ borderRadius: "5px" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width={104}
                        height={40}
                        style={{ borderRadius: "5px" }}
                      />
                    </>
                  ) : (
                    data?.Status == MySubscription_Status.BOOKED &&
                    data?.IsValid24HourReschdule && (
                      <div className="d-flex justify-content-between justify-content-md-end align-items-center px-md-4 py-4 bg-white rounded-2 mt-3 mt-md-0 gap-3">
                        <Button
                          onClick={() =>
                            navigate(
                              `/book-an-appointment/${data?.ExpertId}?AppointmentId=${data?.ID}`
                            )
                          }
                          className="rounded-3 border-0 py-2"
                          style={{
                            background: "var(--primary-color)",
                            color: "#fff",
                          }}
                        >
                          {/* <img src='/assets/img/clock.png' className='px-2' alt='' /> */}
                          Reschedule
                        </Button>

                        <Button
                          disabled={loadingCancel}
                          onClick={cancellation}
                          variant="outline-danger"
                          className="rounded-3 py-2 btn-danger text-white"
                        >
                          Cancel
                        </Button>
                      </div>
                    )
                  )}

                  {expertDataLoad && data?.Status === "Completed" ? (
                    <Skeleton
                      variant="rounded"
                      width={104}
                      height={40}
                      style={{ borderRadius: "5px" }}
                    />
                  ) : (
                    data?.Status === "Completed" && (
                      <Button
                        className="border-0 ms-auto"
                        onClick={() => getUserByIdIncompleted()}
                        style={{ background: "var(--primary-color)" }}
                      >
                        {isLoading ? (
                          <img
                            src="/assets/img/loading.gif"
                            alt="Unitedwecare"
                            style={{ height: 26, width: 26 }}
                          />
                        ) : (
                          "Feedback"
                        )}
                      </Button>
                    )
                  )}

                  <OnFeedback
                    handleClose={handleCloseSimpleModal}
                    show={showSimpleModal}
                    expertDataP={expertData ?? data}
                  />

                  <Feedback
                    feedbackAnswers={feedbackAnswers}
                    feedbackshow={feedbackShow}
                    handlefeedback={() => setfeedbackShow(false)}
                    expertDataP={expertData ?? data}
                  />

                  <Modal
                    className="feedbackModal px-0"
                    show={show}
                    onHide={handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header className="d-block d-md-none">
                      <h5 className="font-helvetica font-w700 fs-2 text-center">
                        Feedback Form
                      </h5>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                      <div className="d-none d-md-flex">
                        <div
                          className="col-md-4 px-4 d-flex flex-column align-items-center"
                          style={{ background: "#FDFFD6", paddingTop: "5%" }}
                        >
                          <img
                            src={expertData?.ProfileImage}
                            alt=""
                            className="rounded-circle d-block mx-auto my-5"
                            style={{
                              background: "#EEEFEE",
                              height: "235px",
                              width: "235px",
                            }}
                          />
                          <div className="d-flex flex-column gap-1">
                            <div className="d-flex align-items-center gap-2 w-100">
                              <h5 className="font-helvetica font-w700 fs-2">
                                {expertData?.Title}{" "}
                                {expertData?.Name || "Professional"}
                              </h5>
                              <img
                                src="/assets/img/usa-20.png"
                                alt=""
                                className="rounded-circle"
                                style={{}}
                              />
                            </div>
                            {/* <div className='d-flex align-items-center gap-2 my-2'>
                                                        <img src='/assets/img/Vector(32).png' alt='' />
                                                        <p className='font-helvetica text-black fs-5'>
                                                            Your live call with our expert for <b>2:03 mins</b>
                                                        </p>
                                                    </div> */}
                            <div
                              className="rounded-3 w-auto"
                              style={{ background: "#E0F6D8" }}
                            >
                              <p
                                className="font-inter fs-6 font-w500 px-2"
                                style={{ color: "#134700" }}
                              >
                                {expertData?.RegisterYourselfAs}
                              </p>
                            </div>
                            <div className="my-2 w-100">
                              <h6
                                className="font-inter fs-6 font-w600 text-black"
                                style={{ lineHeight: "30px" }}
                              >
                                {expertData?.Experience} years of Experience
                              </h6>
                              <h6
                                className="font-inter fs-6 font-w600 text-black"
                                style={{ lineHeight: "30px" }}
                              >
                                Speaks:
                                <span className="font-inter fs-6 font-w500 text-black">
                                  {expertData?.Languages}
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8 py-3 px-5">
                          <div className="d-flex flex-column align-items-center justify-content-center my-5">
                            <h4 className="font-helvetica fs-5 font-w700">
                              How was your call with our expert?
                            </h4>
                            <Rating
                              name="simple-controlled"
                              value={starValue}
                              onChange={(event, newValue) => {
                                setStarValue(newValue);
                              }}
                              precision={0.5}
                              sx={{ fontSize: "50px" }}
                            />
                          </div>
                          <div className="d-flex align-items-center gap-5 my-5">
                            <div>
                              <h6 className="font-helvetica font-w500 fs-5 text-black">
                                How was your cloud call quality?
                              </h6>
                              <ButtonGroup className="d-flex align-items-center gap-2">
                                <ToggleButton
                                  className="w-auto p-2"
                                  style={{
                                    borderRadius: "10px",
                                    border:
                                      callQuality === "0"
                                        ? "2px solid #FD6F1F"
                                        : "2px solid #E1E5EE",
                                    background: "rgba(225, 229, 238, 0.31)",
                                  }}
                                  name="radio"
                                  type="radio"
                                  onClick={() => setCallQuality("0")}
                                >
                                  <img
                                    src="/assets/img/slightly_frowning_face.png"
                                    alt=""
                                  />
                                </ToggleButton>
                                <ToggleButton
                                  className="w-auto p-2"
                                  style={{
                                    borderRadius: "10px",
                                    border:
                                      callQuality === "1"
                                        ? "2px solid #FD6F1F"
                                        : "2px solid #E1E5EE",
                                    background: "rgba(225, 229, 238, 0.31)",
                                  }}
                                  name="radio"
                                  type="radio"
                                  onClick={() => setCallQuality("1")}
                                >
                                  <img src="/assets/img/blush.png" alt="" />
                                </ToggleButton>
                                <ToggleButton
                                  className="w-auto p-2"
                                  style={{
                                    borderRadius: "10px",
                                    border:
                                      callQuality === "2"
                                        ? "2px solid #FD6F1F"
                                        : "2px solid #E1E5EE",
                                    background: "rgba(225, 229, 238, 0.31)",
                                  }}
                                  name="radio"
                                  type="radio"
                                  onClick={() => setCallQuality("2")}
                                >
                                  <img
                                    src="/assets/img/heart_eyes.png"
                                    alt=""
                                  />
                                </ToggleButton>
                              </ButtonGroup>
                            </div>
                            <div>
                              <h6 className="font-helvetica font-w500 fs-5 text-black">
                                How was your interaction with expert?
                              </h6>
                              <ButtonGroup className="d-flex align-items-center gap-2">
                                <ToggleButton
                                  className="w-auto p-2"
                                  style={{
                                    borderRadius: "10px",
                                    border:
                                      expertInteraction === "0"
                                        ? "2px solid #FD6F1F"
                                        : "2px solid #E1E5EE",
                                    background: "rgba(225, 229, 238, 0.31)",
                                  }}
                                  name="radio"
                                  type="radio"
                                  onClick={() => setExpertInteraction("0")}
                                >
                                  <img
                                    src="/assets/img/slightly_frowning_face.png"
                                    alt=""
                                  />
                                </ToggleButton>
                                <ToggleButton
                                  className="w-auto p-2"
                                  style={{
                                    borderRadius: "10px",
                                    border:
                                      expertInteraction === "1"
                                        ? "2px solid #FD6F1F"
                                        : "2px solid #E1E5EE",
                                    background: "rgba(225, 229, 238, 0.31)",
                                  }}
                                  name="radio"
                                  type="radio"
                                  onClick={() => setExpertInteraction("1")}
                                >
                                  <img src="/assets/img/blush.png" alt="" />
                                </ToggleButton>
                                <ToggleButton
                                  className="w-auto p-2"
                                  style={{
                                    borderRadius: "10px",
                                    border:
                                      expertInteraction === "2"
                                        ? "2px solid #FD6F1F"
                                        : "2px solid #E1E5EE",
                                    background: "rgba(225, 229, 238, 0.31)",
                                  }}
                                  name="radio"
                                  type="radio"
                                  onClick={() => setExpertInteraction("2")}
                                >
                                  <img
                                    src="/assets/img/heart_eyes.png"
                                    alt=""
                                  />
                                </ToggleButton>
                              </ButtonGroup>
                            </div>
                          </div>
                          <h6 className="font-helvetica text-black fs-5 font-w500 my-2">
                            Please write a review for expert
                          </h6>
                          <TextField
                            fullWidth
                            multiline
                            rows={3}
                            style={{ height: "100px" }}
                            className="feedbackInput mb-2"
                            onChange={(e) => setReviewText(e.target.value)}
                          />
                          <div className="my-3">
                            <h6 className="font-helvetica font-w500 fs-5 text-black">
                              Would you like to have your name displayed with
                              your review?
                            </h6>
                            <ButtonGroup className="d-flex align-items-center gap-3">
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: displayNameBool
                                    ? "2px solid #134700"
                                    : "none",
                                  background: "#EEFFE8",
                                  color: "#134700",
                                  width: "55px",
                                }}
                                onClick={() => setDisplayNameBool(true)}
                              >
                                <p className="font-helvetica fs-6">Yes</p>
                              </ToggleButton>
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: !displayNameBool
                                    ? "2px solid #B54100"
                                    : "none",
                                  background: "#FFF8E5",
                                  color: "#B54100",
                                  width: "55px",
                                }}
                                onClick={() => setDisplayNameBool(false)}
                              >
                                <p className="font-helvetica fs-6">No</p>
                              </ToggleButton>
                            </ButtonGroup>
                          </div>
                          <div className="my-3">
                            <h6 className="font-helvetica font-w500 fs-5 text-black">
                              Will you book another session with this expert?
                            </h6>
                            <ButtonGroup className="d-flex align-items-center gap-3">
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: bookAnotherSession
                                    ? "2px solid #134700"
                                    : "none",
                                  background: "#EEFFE8",
                                  color: "#134700",
                                  width: "55px",
                                }}
                                onClick={() => setBookAnotherSession(true)}
                              >
                                <p className="font-helvetica fs-6">Yes</p>
                              </ToggleButton>
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: !bookAnotherSession
                                    ? "2px solid #B54100"
                                    : "none",
                                  background: "#FFF8E5",
                                  color: "#B54100",
                                  width: "55px",
                                }}
                                onClick={() => setBookAnotherSession(false)}
                              >
                                <p className="font-helvetica fs-6">No</p>
                              </ToggleButton>
                            </ButtonGroup>
                          </div>
                          <Row className="my-4 gap-3">
                            <Button
                              disabled={feedbackStatus === true}
                              onClick={onClickRatingSubmit}
                              className="border-0 rounded-1 col-3 px-3 py-2"
                              style={{
                                background: "var(--button-primary-color)",
                                boxShadow:
                                  "0px 1.06px 2.13px 0px rgba(16, 24, 40, 0.05)",
                              }}
                            >
                              Submit
                            </Button>
                            <Button
                              onClick={onClickSkip}
                              className="rounded-5 bg-white col-3 px-3 py-2"
                              style={{
                                border: "1px solid #FD6F1F",
                                boxShadow:
                                  "0px 1.06px 2.13px 0px rgba(16, 24, 40, 0.05)",
                                color: "#FD6F1F",
                              }}
                            >
                              Skip
                            </Button>
                          </Row>
                        </div>
                      </div>
                      <div className="d-block d-md-none">
                        <div
                          className="d-flex flex-column gap-2"
                          style={{ background: "#FDFFD6", padding: "30px 0" }}
                        >
                          <div className="d-flex align-items-center justify-content-center gap-2 px-2">
                            <img
                              src={expertData?.ProfileImage}
                              alt=""
                              className="rounded-circle"
                              style={{
                                background: "#EEEFEE",
                                height: "100px",
                                width: "100px",
                              }}
                            />
                            <div className="">
                              <div className="d-flex align-items-center gap-2 my-2">
                                <h5 className="font-helvetica font-w700 fs-2">
                                  {expertData?.Title}{" "}
                                  {expertData?.Name || "Professional"}
                                </h5>
                                <img
                                  src="/assets/img/usa-20.png"
                                  alt=""
                                  className="rounded-circle"
                                  style={{}}
                                />
                              </div>
                              <div
                                className="rounded-3 w-auto my-2"
                                style={{ background: "#E0F6D8" }}
                              >
                                <p
                                  className="font-inter text-center fs-6 font-w500 px-2"
                                  style={{ color: "#134700" }}
                                >
                                  {expertData?.RegisterYourselfAs}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <div className='d-flex align-items-center justify-content-center'>
                                                        <div className='d-flex align-items-center gap-2 my-2 mx-1 rounded-3 p-2' style={{ border: "1px solid #00860D", background: "rgba(255, 255, 255, 0.47)", width: "fit-content" }}>
                                                            <img src='/assets/img/Vector(32).png' alt='' />
                                                            <p className='font-helvetica text-black fs-6'>
                                                                Your live call with our expert for <b>2:03 mins</b>
                                                            </p>
                                                        </div>
                                                    </div> */}
                        </div>
                        <div className="px-2">
                          <div className="d-flex flex-column my-3">
                            <h4 className="font-helvetica fs-5 font-w700 mb-0">
                              How was your call with our expert?
                            </h4>
                            <Rating
                              name="simple-controlled"
                              value={starValue}
                              onChange={(event, newValue) => {
                                setStarValue(newValue);
                              }}
                              precision={0.5}
                              sx={{ fontSize: "35px" }}
                            />
                          </div>
                          <div className="my-3">
                            <h6 className="font-helvetica fs-5 font-w700">
                              How was your cloud call quality?
                            </h6>
                            <ButtonGroup className="d-flex align-items-center gap-2">
                              <ToggleButton
                                className="w-auto p-2"
                                style={{
                                  borderRadius: "10px",
                                  border: "2px solid #E1E5EE",
                                  background:
                                    callQuality === "0"
                                      ? "#3DACF0"
                                      : "rgba(225, 229, 238, 0.31)",
                                }}
                                name="radio"
                                type="radio"
                                onClick={() => setCallQuality("0")}
                              >
                                <img
                                  src="/assets/img/slightly_frowning_face(1).png"
                                  alt=""
                                  style={{}}
                                />
                              </ToggleButton>
                              <ToggleButton
                                className="w-auto p-2"
                                style={{
                                  borderRadius: "10px",
                                  border: "2px solid #E1E5EE",
                                  background:
                                    callQuality === "1"
                                      ? "#3DACF0"
                                      : "rgba(225, 229, 238, 0.31)",
                                }}
                                name="radio"
                                type="radio"
                                onClick={() => setCallQuality("1")}
                              >
                                <img src="/assets/img/blush(1).png" alt="" />
                              </ToggleButton>
                              <ToggleButton
                                className="w-auto p-2"
                                style={{
                                  borderRadius: "10px",
                                  border: "2px solid #E1E5EE",
                                  background:
                                    callQuality === "2"
                                      ? "#3DACF0"
                                      : "rgba(225, 229, 238, 0.31)",
                                }}
                                name="radio"
                                type="radio"
                                onClick={() => setCallQuality("2")}
                              >
                                <img
                                  src="/assets/img/heart_eyes(1).png"
                                  alt=""
                                />
                              </ToggleButton>
                            </ButtonGroup>
                          </div>
                          <div className="my-3">
                            <h6 className="font-helvetica fs-5 font-w700">
                              How was your interaction with expert?
                            </h6>
                            <ButtonGroup className="d-flex align-items-center gap-2">
                              <ToggleButton
                                className="w-auto p-2"
                                style={{
                                  borderRadius: "10px",
                                  border: "2px solid #E1E5EE",
                                  background:
                                    expertInteraction === "0"
                                      ? "#3DACF0"
                                      : "rgba(225, 229, 238, 0.31)",
                                }}
                                name="radio"
                                type="radio"
                                onClick={() => setExpertInteraction("0")}
                              >
                                <img
                                  src="/assets/img/slightly_frowning_face(1).png"
                                  alt=""
                                />
                              </ToggleButton>
                              <ToggleButton
                                className="w-auto p-2"
                                style={{
                                  borderRadius: "10px",
                                  border: "2px solid #E1E5EE",
                                  background:
                                    expertInteraction === "1"
                                      ? "#3DACF0"
                                      : "rgba(225, 229, 238, 0.31)",
                                }}
                                name="radio"
                                type="radio"
                                onClick={() => setExpertInteraction("1")}
                              >
                                <img src="/assets/img/blush(1).png" alt="" />
                              </ToggleButton>
                              <ToggleButton
                                className="w-auto p-2"
                                style={{
                                  borderRadius: "10px",
                                  border: "2px solid #E1E5EE",
                                  background:
                                    expertInteraction === "2"
                                      ? "#3DACF0"
                                      : "rgba(225, 229, 238, 0.31)",
                                }}
                                name="radio"
                                type="radio"
                                onClick={() => setExpertInteraction("2")}
                              >
                                <img
                                  src="/assets/img/heart_eyes(1).png"
                                  alt=""
                                />
                              </ToggleButton>
                            </ButtonGroup>
                          </div>
                          <div className="my-3">
                            <h6 className="font-helvetica fs-5 font-w700">
                              Please write a review for expert
                            </h6>
                            <TextField
                              fullWidth
                              multiline
                              rows={3}
                              style={{ height: "100px" }}
                              className="feedbackInput mb-2"
                              onChange={(e) => setReviewText(e.target.value)}
                            />
                          </div>
                          <div className="my-3">
                            <h6 className="font-helvetica fs-5 font-w700">
                              Would you like to have your name displayed with
                              your review?
                            </h6>
                            <ButtonGroup className="d-flex align-items-center gap-3">
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: displayNameBool
                                    ? "2px solid #134700"
                                    : "none",
                                  background: "#EEFFE8",
                                  color: "#134700",
                                }}
                                onClick={() => setDisplayNameBool(true)}
                              >
                                <p className="font-helvetica fs-6">Yes</p>
                              </ToggleButton>
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: !displayNameBool
                                    ? "2px solid #B54100"
                                    : "none",
                                  background: "#FFF8E5",
                                  color: "#B54100",
                                }}
                                onClick={() => setDisplayNameBool(false)}
                              >
                                <p className="font-helvetica fs-6">No</p>
                              </ToggleButton>
                            </ButtonGroup>
                          </div>
                          <div className="my-3">
                            <h6 className="font-helvetica fs-5 font-w700">
                              Will you book another session with this expert?
                            </h6>
                            <ButtonGroup className="d-flex align-items-center gap-3">
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: bookAnotherSession
                                    ? "2px solid #134700"
                                    : "none",
                                  background: "#EEFFE8",
                                  color: "#134700",
                                }}
                                onClick={() => setBookAnotherSession(true)}
                              >
                                <p className="font-helvetica fs-6">Yes</p>
                              </ToggleButton>
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: !bookAnotherSession
                                    ? "2px solid #B54100"
                                    : "none",
                                  background: "#FFF8E5",
                                  color: "#B54100",
                                }}
                                onClick={() => setBookAnotherSession(false)}
                              >
                                <p className="font-helvetica fs-6">No</p>
                              </ToggleButton>
                            </ButtonGroup>
                          </div>
                          <Row className="my-4 justify-content-around">
                            <Button
                              disabled={feedbackStatus === true}
                              onClick={onClickRatingSubmit}
                              className="border-0 rounded-5 col-5 px-3 py-2"
                              style={{
                                background: "#FD6F1F",
                                boxShadow:
                                  "0px 1.06px 2.13px 0px rgba(16, 24, 40, 0.05)",
                              }}
                            >
                              Submit
                            </Button>
                            <Button
                              onClick={onClickSkip}
                              className="rounded-5 bg-white col-5 px-3 py-2"
                              style={{
                                border: "1px solid #FD6F1F",
                                boxShadow:
                                  "0px 1.06px 2.13px 0px rgba(16, 24, 40, 0.05)",
                                color: "#FD6F1F",
                              }}
                            >
                              Skip
                            </Button>
                          </Row>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>

              <div
                className="px-2 bg-white rounded-1"
                style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)" }}
              >
                {expertDataLoad ? (
                  <>
                    <p className="font-600 fs-4 text-black pt-3 pb-4">
                      <Skeleton
                        variant="text"
                        width="10vw"
                        height={50}
                        className="my-2 d-none d-md-block"
                        style={{ borderRadius: "5px" }}
                      />
                      <Skeleton
                        variant="text"
                        width="20vw"
                        height={50}
                        className="my-2 d-block d-md-none"
                        style={{ borderRadius: "5px" }}
                      />
                    </p>
                    <div className="d-flex flex-column">
                      {Array.from(new Array(7)).map((_, index) => (
                        <>
                          <div className="d-flex justify-content-between ">
                            <div className="d-flex gap-3">
                              <Skeleton
                                variant="rectangle"
                                width={60}
                                height={60}
                                style={{ borderRadius: "5px" }}
                              />
                              <div className="d-none d-md-block">
                                <p className="my-1">
                                  <Skeleton
                                    variant="text"
                                    width="10vw"
                                    height={20}
                                    style={{ borderRadius: "5px" }}
                                  />
                                </p>
                                <p className="my-1">
                                  <Skeleton
                                    variant="text"
                                    width="10vw"
                                    height={20}
                                    style={{ borderRadius: "5px" }}
                                  />
                                </p>
                              </div>
                              <div className="d-block d-md-none">
                                <p className="my-1">
                                  <Skeleton
                                    variant="text"
                                    width="20vw"
                                    height={20}
                                    style={{ borderRadius: "5px" }}
                                  />
                                </p>
                                <p className="my-1">
                                  <Skeleton
                                    variant="text"
                                    width="20vw"
                                    height={20}
                                    style={{ borderRadius: "5px" }}
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="d-flex gap-2 mt-1">
                              <Skeleton
                                variant="rounded"
                                width={96}
                                height={36}
                                style={{ borderRadius: "5px" }}
                              />
                            </div>
                          </div>
                          <hr />
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <p className="font-600 fs-4 text-black pt-3 pb-4">
                      Prescriptions
                    </p>
                    <div>
                      {expertDocumentList?.map((item, index) => (
                        <>
                          <PrescriptionsCard data={item} />
                          <hr />
                        </>
                      ))}
                    </div>
                  </>
                )}

                {expertDataLoad ? (
                  <>
                    <p className="font-600 fs-4 text-black pt-3 pb-4">
                      <Skeleton
                        variant="text"
                        width="10vw"
                        height={50}
                        className="my-1 d-none d-md-block"
                        style={{ borderRadius: "5px" }}
                      />
                      <Skeleton
                        variant="text"
                        width="20vw"
                        height={50}
                        className="my-1 d-block d-md-none"
                        style={{ borderRadius: "5px" }}
                      />
                    </p>
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height={38}
                      className="mt-1"
                      style={{ borderRadius: "5px" }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={96}
                      height={29}
                      className="mt-1"
                      style={{ borderRadius: "5px" }}
                    />
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height={42}
                      className="mt-1"
                      style={{ borderRadius: "5px" }}
                    />

                    <div className="d-flex flex-column my-4">
                      {Array.from(new Array(10))?.map((_, index) => (
                        <>
                          <div className="d-flex justify-content-between ">
                            <div className="d-flex gap-3">
                              <Skeleton
                                variant="rectangle"
                                width={60}
                                height={60}
                                style={{ borderRadius: "5px" }}
                              />
                              <div className="d-none d-md-block">
                                <p className="my-1">
                                  <Skeleton
                                    variant="text"
                                    width="10vw"
                                    height={20}
                                    style={{ borderRadius: "5px" }}
                                  />
                                </p>
                                <p className="my-1">
                                  <Skeleton
                                    variant="text"
                                    width="10vw"
                                    height={20}
                                    style={{ borderRadius: "5px" }}
                                  />
                                </p>
                              </div>
                              <div className="d-block d-md-none">
                                <p className="my-1">
                                  <Skeleton
                                    variant="text"
                                    width="20vw"
                                    height={20}
                                    style={{ borderRadius: "5px" }}
                                  />
                                </p>
                                <p className="my-1">
                                  <Skeleton
                                    variant="text"
                                    width="20vw"
                                    height={20}
                                    style={{ borderRadius: "5px" }}
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="d-flex gap-2">
                              <Skeleton
                                variant="rounded"
                                width={96}
                                height={36}
                                style={{ borderRadius: "5px" }}
                              />
                            </div>
                          </div>
                          <hr />
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <p className="font-600 fs-4 text-black pt-3 pb-4">
                      Document
                    </p>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Title"
                      defaultValue={title}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={uploadNewFile}
                    />
                    <Button
                      disabled={loading}
                      className="w-100 my-2 mb-4 py-2 bg-white"
                      style={{
                        border: "1px solid var(--primary-color)",
                        color: "var(--primary-color)",
                      }}
                      onClick={onSubmitNewDoc}
                    >
                      {loading ? "Uploading..." : "Upload New Document"}
                    </Button>
                    <div>
                      {userDocumentList?.map((item, index) => (
                        <>
                          <PrescriptionsCard data={item} />
                          <hr />
                        </>
                      ))}
                    </div>
                  </>
                )}

                {expertDataLoad ? (
                  <>
                    <p className="font-600 fs-4 text-black pt-3 pb-4">
                      <Skeleton
                        variant="text"
                        width="10vw"
                        height={50}
                        className="my-2 d-none d-md-block"
                      />
                      <Skeleton
                        variant="text"
                        width="20vw"
                        height={50}
                        className="my-2 d-block d-md-none"
                        style={{ borderRadius: "5px" }}
                      />
                    </p>
                    {Array.from(new Array(10))?.map((_, index) => (
                      <>
                        <Skeleton
                          variant="rounded"
                          width={59}
                          height={20}
                          style={{ borderRadius: "5px" }}
                        />
                        <div className="d-none d-md-block">
                          <p className="my-1">
                            <Skeleton
                              variant="text"
                              width="10vw"
                              height={20}
                              style={{ borderRadius: "5px" }}
                            />
                          </p>
                          <p className="my-1">
                            <Skeleton
                              variant="text"
                              width="10vw"
                              height={20}
                              style={{ borderRadius: "5px" }}
                            />
                          </p>
                        </div>
                        <div className="d-block d-md-none">
                          <p className="my-1">
                            <Skeleton
                              variant="text"
                              width="25vw"
                              height={20}
                              style={{ borderRadius: "5px" }}
                            />
                          </p>
                          <p className="my-1">
                            <Skeleton
                              variant="text"
                              width="25vw"
                              height={20}
                              style={{ borderRadius: "5px" }}
                            />
                          </p>
                        </div>
                        <hr />
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <p className="font-600 fs-4 text-black pt-3 pb-4">
                      Session History
                    </p>
                    {sessionData?.map((item, index) => (
                      <>
                        <Badge
                          bg={
                            (item.Status == "Canceled" && "danger") ||
                            item.Status == "Open" ||
                            (item.Status == "Completed" && "success") ||
                            "warning"
                          }
                        >
                          {item.Status}
                        </Badge>
                        <p>
                          Session Date:{" "}
                          <span>
                            {item.AppDate} {item.AppTime}
                          </span>
                        </p>
                        <p>
                          Mode: <span>{item.CallingMode}</span>
                        </p>
                        <hr />
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentDetail;
