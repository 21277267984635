import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../../context";
import KeyPointsRichText from "../../richText/sessionDashboard/ObjectRichTextEditor";
import StopIcon from "@mui/icons-material/Stop";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import MicIcon from "@mui/icons-material/Mic";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

//SpeechRecognition
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { getTranscript, getTranslation } from "../../APIS/clientAPIS";
import ObjectRichTextEditor from "../../richText/sessionDashboard/ObjectRichTextEditor";


function CaseSummary({ isWebsocketDataRecieved, case_summary, sessionStatus }) {
  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const recorderControls = useAudioRecorder();
  const [autoGenPopup, setAutoGenPopup] = useState(false);
  useEffect(() => {
    setAutoGenPopup(isWebsocketDataRecieved);
  }, [isWebsocketDataRecieved]);

  // audio recording
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const [audioRequestData, setAudioRequestData] = useState(null);
  const { expertDashboardData } = useContext(AppContext);


  useEffect(() => {
    //console.log("expertDashboardData", expertDashboardData);
    const sendAudioData = async () => {
      const base64String = await convertBlobToBase64(
        recorderControls.recordingBlob
      );
      // console.log("BLOB", recorderControls.recordingBlob);

      getTranscript(
        expertDashboardData.session_data.appointment_details.appointment_id,
        expertDashboardData.session_data.appointment_details.user_id,
        expertDashboardData.session_data.appointment_details.expert_id,
        expertDashboardData.session_data.appointment_details.appointment_date,
        expertDashboardData.session_data.appointment_details.booking_date,
        base64String
      ).then((res) => {
        //console.log("RES", res)
        getTranslation(
          expertDashboardData.session_data.appointment_details.appointment_id,
          expertDashboardData.session_data.appointment_details.user_id,
          expertDashboardData.session_data.appointment_details.expert_id,
          expertDashboardData.session_data.appointment_details.appointment_date,
          expertDashboardData.session_data.appointment_details.booking_date,
          res.transcript
        );
      });
    };
    if (
      recorderControls.recordingBlob !== undefined &&
      recorderControls.recordingBlob !== null
    ) {
      sendAudioData();
    }

    // console.log("finalTranscript", finalTranscript);
    // console.log("interimTranscript", interimTranscript);
    // console.log("transcript", transcript);
  }, [recorderControls.recordingBlob]);
  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // console.log("reader.result", reader.result)
        resolve(reader.result.split("data:audio/webm;codecs=opus;base64,")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleRecordingStart = () => {
    recorderControls.startRecording();
    //SpeechRecognition.startListening();
    setIsRecording(true);
  };

  const handleRecordingStop = async () => {
    recorderControls.stopRecording();
    setIsRecording(false);
    setIsRecordingStopped(true);
    //SpeechRecognition.stopListening();
  };

  const handleRecordingComplete = async (blob) => {
    setAudioRequestData(blob);
  };
  return (
    <div>
      <div className="d-flex justify-content-between">
        {!expertDashboardData?.session_data?.submitted && (
          <div className="d-flex gap-2">
            {/* <button
              className="PrimaryCTA"
              onClick={handleAutoGenerate}
              disabled={!isWebsocketDataRecieved}
            >
              <AutoFixHighIcon color="#FFF" />
              Auto Generate Prescription from transcript
            </button> */}
            {isRecording ? (
              <button
                style={{ maxWidth: "fit-content", right: "30px", bottom: "30px", position: "fixed", zIndex: "1000" }}
                className="PrimaryCTA"
                onClick={handleRecordingStop}
              >
                <StopIcon color="#FFF" />
                Stop voice recording
              </button>
            ) : (
              <button
                style={{ maxWidth: "fit-content", right: "30px", bottom: "30px", position: "fixed", zIndex: "1000" }}
                className="PrimaryCTA"
                onClick={handleRecordingStart}
              >
                <MicIcon color="#FFF" />
                Generate prescription from voice
              </button>
            )}
          </div>
        )}
        {/* hidden for developmen purpose*/}
        <div className="d-none gap-3 align-items-center">
          <button
            style={{
              border: "none",
              borderRadius: "5px",
              background: "#FFF",
              color: "#73589b",
            }}
          >
            <DownloadForOfflineIcon style={{ fontSize: "30px" }} />
          </button>
          <button
            style={{
              border: "none",
              borderRadius: "5px",
              background: "#FFF",
              color: "#73589b",
            }}
          >
            <LocalPrintshopIcon style={{ fontSize: "30px" }} />
          </button>
        </div>
      </div>
      <div style={{ visibility: "hidden", position: "absolute" }}>
        <AudioRecorder
          onRecordingComplete={(blob) => handleRecordingComplete(blob)}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          downloadOnSavePress={false}
        />
      </div>
      <div className="my-3 ">
        {autoGenPopup ? (
          <ObjectRichTextEditor
            mainFieldName={"case_summary"}
            fieldName={"session_highlights"}
            name={"Case History"}
            sessionStatus={sessionStatus}
            initialText={case_summary?.session_highlights?.text || ""}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
            aiGenerated={true}
          />
        ) : (
          <ObjectRichTextEditor
            mainFieldName={"case_summary"}
            fieldName={"session_highlights"}
            name={"Case History"}
            sessionStatus={sessionStatus}
            aiGenerated={false}
            initialText={""}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
          />
        )}
      </div>
      {/* <div className="my-3 ">
        {autoGenPopup ? (
          <ProgressSummaryRichText
            sessionStatus={sessionStatus}
            initialText={case_summary?.progress_summary?.text || ""}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
            aiGenerated={true}
          />
        ) : (
          <ProgressSummaryRichText
            sessionStatus={sessionStatus}
            aiGenerated={false}
            initialText={""}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
          />
        )}
      </div>
      <div className="my-3">
        {autoGenPopup ? (
          <NextStepsRichText
            sessionStatus={sessionStatus}
            initialText={case_summary?.next_steps?.text || ""}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
            aiGenerated={true}
          />
        ) : (
          <NextStepsRichText
            sessionStatus={sessionStatus}
            aiGenerated={false}
            initialText={""}
            isWebsocketDataRecieved={isWebsocketDataRecieved}
          />
        )}
      </div> */}

      {/* <KeyPointsRichText isWebsocketDataRecieved={isWebsocketDataRecieved} initialText={case_summary} aiGenerated={false}/> */}
    </div>
  );
}

export default CaseSummary;
