import React from 'react'

const ThankYouTab = () => {
    window.onbeforeunload = function() {
        return "You might lose all the progress if you refresh. Are you sure?";
    }
    return (
        <div>
            <div className='d-flex align-items-center justify-content-center gap-3 py-3 rounded-top px-3' style={{ background: "#DCD0EB" }}>
                <p className='font-inter font-w500 text-black' style={{ color: "#21231E", fontSize: "24px" }}>Thank you for your submission!</p>
            </div>
            <div className='d-flex flex-column align-items-center justify-content-center py-5'>
                <img src='/assets/img/OBJECTS.png' alt='' />
                <p className='font-inter text-center py-5 px-3' style={{ color: "#454745", fontSize: "16px" }}>
                    Your profile is currently under review by our team. We appreciate your patience during this process. You will receive a notification via email within 48 hours once the review is complete. We look forward to having you as a valued expert on our platform.
                </p>
            </div>
        </div>
    )
}

export default ThankYouTab