import React from "react";
import CourseListingFilters from "./components/CourseListingFilters";
import CourseCard from "./components/CourseCard";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../context";
import { getCountryCode, getCurrencyCode } from "../core/commonFun";
import { useEffect } from "react";
import {
  course_clubs,
  course_price_filter,
  course_time_filter,
  course_web_lists,
} from "../core/_request";
import LoadingSpinner from "../LoadingSpinner";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Skeleton,
  Grid,
} from "@mui/material";
import { Button, Offcanvas, Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./Listing.css";

const Listing = () => {
  const [headerClubsData, setHeaderClubsData] = useState([]);
  const [club_Filter_Data_selected, setClub_Filter_Data_selected] = useState(
    []
  );
  const [culb_current_id, setCulb_current_id] = useState([]);
  const [final_selected, setFinal_selected] = useState([]);
  const [myCourseData, setMyCourseData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [price_Filter_Data_selected, setPrice_Filter_Data_selected] = useState(
    []
  );
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isdataAvailable, setIsdataAvailable] = useState();
  const [isclubremove, setIsclubremove] = useState(false);
  const [ispriceremove, setIspriceremove] = useState(false);
  const [columns, setColumn] = useState(3);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 768) {
        setColumn(1);
      }
    };
    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the initial state based on the window size
    handleResize();

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    course_clubs().then((resp) => {
      setHeaderClubsData(resp?.data?.clubs);
    });
  }, []);

  useEffect(() => {
    bindCourseData();
    setIsclubremove(false);
    setIspriceremove(false);
  }, [isclubremove, ispriceremove, final_selected]);

  const handleChange_club_Filter = (event) => {
    if (event.currentTarget?.checked) {
      const { checked, value } = event.currentTarget;
      var Json_val = JSON.parse(value);
      setCulb_current_id([...culb_current_id, Json_val]);
      setClub_Filter_Data_selected([...culb_current_id, Json_val]);
      setFinal_selected({ club: club_Filter_Data_selected });
    } else {
      setMyCourseData([]);
      const { checked, value } = event.currentTarget;
      var Json_val = JSON.parse(value);
      setClub_Filter_Data_selected();
      let filteredArray = club_Filter_Data_selected?.filter(
        (item) => item.id !== Json_val.id
      );
      setClub_Filter_Data_selected(filteredArray);
      setFinal_selected((prevState) => {
        return { ...prevState, club: filteredArray };
      });
      setCulb_current_id(filteredArray);
      setIsclubremove(true);
    }
  };

  const handlePriceChange = (event) => {
    if (event.currentTarget?.checked) {
      const { checked, value } = event.currentTarget;
      var Json_val = JSON.parse(value);
      setPrice_Filter_Data_selected(Json_val);
      setFinal_selected({ price: price_Filter_Data_selected });
    } else {
      setMyCourseData([]);
      const { checked, value } = event.currentTarget;
      var Json_val = JSON.parse(value);
      // let filteredPriceArray = price_Filter_Data_selected?.filter(item => item.id !== Json_val.id);
      setPrice_Filter_Data_selected(null);
      setFinal_selected((prevState) => {
        return { ...prevState, price: null };
      });
    }
  };

  const handel_remove_club = (item) => {
    setMyCourseData([]);
    setClub_Filter_Data_selected();
    let filteredArray = club_Filter_Data_selected?.filter(
      (o) => o.id !== item.id
    );
    setClub_Filter_Data_selected(filteredArray);
    setFinal_selected((prevState) => {
      return { ...prevState, club: filteredArray };
    });
    setCulb_current_id(filteredArray);
    //setIsclubremove(true);
    setShow(false);
  };

  const handel_remove_price = () => {
    setMyCourseData([]);
    // let filteredArray = price_Filter_Data_selected?.filter(o => o.id !== item.id);
    setPrice_Filter_Data_selected(null);
    setFinal_selected((prevState) => {
      return { ...prevState, price: null };
    });
    setIspriceremove(true);
    //bindCourseData();
    // console.log("price_Filter_Data_selected", price_Filter_Data_selected);
  };

  const handel_Clear_Filter = () => {
    setCulb_current_id([]);
    setPrice_Filter_Data_selected(null);
    setFinal_selected((prevState) => {
      return { ...prevState, club: null };
    });
    setFinal_selected((prevState) => {
      return { ...prevState, price: null };
    });
    handleClose();
    bindCourseData();
  };

  const handel_Apply_Filter = () => {
    handleClose();
    bindCourseData();
  };

  const handleChange_Allclub_Filter = (event) => {
    if (event.currentTarget?.checked) {
      const { checked, value } = event.currentTarget;
      var Json_val = JSON.parse(value);
      setCulb_current_id([...culb_current_id, Json_val]);
      setClub_Filter_Data_selected([]);
    } else {
      setMyCourseData([]);
      const { checked, value } = event.currentTarget;
      var Json_val = JSON.parse(value);
      setClub_Filter_Data_selected();
      let filteredArray = club_Filter_Data_selected?.filter(
        (item) => item.id !== Json_val.id
      );
      setClub_Filter_Data_selected(filteredArray);
      setFinal_selected((prevState) => {
        return { ...prevState, club: filteredArray };
      });
      setCulb_current_id(filteredArray);
    }
  };

  const bindCourseData = () => {
    var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
    setIsLoading(true);
    if (
      price_Filter_Data_selected?.length === 0 ||
      price_Filter_Data_selected === null
    ) {
      const body = {
        price: price_Filter_Data_selected === "0-0",
        clubs: culb_current_id?.map((o) => o?.id),
        user_id: user_id ?? null,
        version: "v3",
      };
      course_web_lists(body).then((resp) => {
        setMyCourseData(resp?.data?.courses);
        setIsLoading(false);
      });
    } else {
      //console.log("culb_current_id", culb_current_id);
      const body = {
        // "clubs": (culb_current_id != 0 ? [culb_current_id] : [])
        clubs: culb_current_id?.map((o) => o?.id),
        price: price_Filter_Data_selected?.name === "Free" ? "0-0" : "1-999999",
        user_id: user_id ?? null,
        version: "v3",
      };
      course_web_lists(body).then((resp) => {
        setMyCourseData(resp?.data?.courses);
        setIsLoading(false);
      });
    }
  };

  const onClickCourse = (id) => {
    navigate(`/program/details/${id}`);
  };

  return (
    <div className="mx-3 mx-md-0" style={{ minHeight: "100vh" }}>
      <Helmet>
        <title>Explore Our Mental Wellness Programs | United We Care</title>
      </Helmet>
      <div className="mx-3 mx-md-0">
        {isLoading ? (
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 mt-4 pt-2">
            <Skeleton
              variant="rounded"
              width="20%"
              height="30px"
              className="rounded rounded-3 d-none d-md-block"
            />
            <Skeleton
              variant="rounded"
              width="80%"
              height="30px"
              className="rounded rounded-3 d-block d-md-none"
            />
            <Skeleton
              variant="rounded"
              width="30%"
              height="40px"
              className="rounded rounded-3 d-none d-md-block"
            />
            <Skeleton
              variant="rounded"
              width="100%"
              height="30px"
              className="rounded rounded-3 d-block d-md-none"
            />
          </div>
        ) : (
          <>
            <h1 className="font-inter text-center font-w600 mt-4 fs-2 lh-lg">
              Our Wellness Programs
            </h1>
            <h3 className="font-inter text-center font-w500 fs-5 lh-1">
              Find a Program That Best Suits Your Needs
            </h3>
          </>
        )}
      </div>

      <div className="d-none d-md-flex align-items-center gap-2 px-4 my-md-5">
        {isLoading ? (
          <>
            <Skeleton
              variant="rounded"
              width={120}
              height={40}
              className="rounded rounded-pill"
            />
          </>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center rounded-5 gap-2 px-2 py-1 me-5"
            style={{
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              border: "1px solid var(--gray-300, #D0D5DD)",
            }}
          >
            <img src="assets/img/filter_icon.png" alt="" />
            <p
              className="font-inter font-w600"
              style={{ color: "#344054F", fontSize: "18px" }}
            >
              Filter
            </p>
          </div>
        )}
        {/* {
                    final_selected && final_selected?.map((item, index) =>
                        <span onClick={(e) => { handel_remove_club(item) }} className='d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6' style={{ color: "#000", border: "1px solid var(--gray-300, #D0D5DD)", cursor: "pointer", whiteSpace: "nowrap", marginRight: 3, display: '-ms-inline-flexbox', padding: 2, lineHeight: 2, boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}>{item?.name}   &nbsp;  <img src='/assets/img/Vector(33).png' alt='Filter' style={{ height: 10, width: 10 }} /></span>
                    )}
                {
                    final_selected && final_selected?.price && final_selected?.price?.name &&
                    <span onClick={(e) => { handel_remove_price(final_selected?.price) }} className=' d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6' style={{ background: "#333131", color: "#fff", border: "1px solid #333131", cursor: "pointer", whiteSpace: "nowrap", marginRight: 3, display: '-ms-inline-flexbox', padding: 2, lineHeight: 2 }}> <img src='/assets/img/closeIcon.png' alt='Filter' style={{ height: 10, width: 10 }} /> &nbsp; {final_selected?.price?.name}</span>
                } */}

        {culb_current_id &&
          culb_current_id?.map((item, index) => (
            <span
              onClick={(e) => {
                handel_remove_club(item);
              }}
              className=" d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6 w-auto gap-2"
              style={{
                background: "var(--white, #FFF)",
                color: "#000",
                border: "1px solid var(--gray-300, #D0D5DD)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                cursor: "pointer",
                whiteSpace: "nowrap",
                marginRight: 3,
                display: "-ms-inline-flexbox",
                padding: 2,
                lineHeight: 2,
              }}
            >
              {item?.name} &nbsp;
              <img
                src="/assets/img/Vector(33).png"
                alt="Filter"
                style={{ height: 10, width: 10 }}
              />
            </span>
          ))}
        {
          // price_Filter_Data_selected && price_Filter_Data_selected?.map((item, index) =>
          price_Filter_Data_selected && price_Filter_Data_selected?.name && (
            <span
              onClick={(e) => {
                handel_remove_price(price_Filter_Data_selected);
              }}
              className=" d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6 w-auto gap-2"
              style={{
                background: "var(--white, #FFF)",
                color: "#000",
                border: "1px solid var(--gray-300, #D0D5DD)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                cursor: "pointer",
                whiteSpace: "nowrap",
                marginRight: 3,
                display: "-ms-inline-flexbox",
                padding: 2,
                lineHeight: 2,
              }}
            >
              {price_Filter_Data_selected?.name}
              <img
                src="/assets/img/Vector(33).png"
                alt="Filter"
                style={{ height: 10, width: 10 }}
              />{" "}
              &nbsp;
            </span>
          )
          // )
        }
      </div>
      <div className="d-flex flex-column flex-md-row my-md-3">
        <Link
          onClick={handleShow}
          className="row d-md-none text-decoration-none my-5 gap-2 px-3"
          style={{ color: "#344054 !important" }}
        >
          <div
            className="d-flex align-items-center justify-content-center rounded-5 gap-2 px-2 py-1 w-auto"
            style={{
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              border: "1px solid var(--gray-300, #D0D5DD)",
            }}
          >
            <img src="assets/img/Vector(31).png" alt="" />
            <p
              className="font-inter font-w600"
              style={{ color: "#344054", fontSize: "18px" }}
            >
              Filter
            </p>
          </div>

          {culb_current_id &&
            culb_current_id?.map((item, index) => (
              <span
                onClick={(e) => {
                  handel_remove_club(item);
                }}
                className=" d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6 w-auto gap-2"
                style={{
                  background: "var(--white, #FFF)",
                  color: "#000",
                  border: "1px solid var(--gray-300, #D0D5DD)",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  marginRight: 3,
                  display: "-ms-inline-flexbox",
                  padding: 2,
                  lineHeight: 2,
                }}
              >
                {item?.name} &nbsp;
                <img
                  src="/assets/img/Vector(33).png"
                  alt="Filter"
                  style={{ height: 10, width: 10 }}
                />
              </span>
            ))}
          {
            // price_Filter_Data_selected && price_Filter_Data_selected?.map((item, index) =>
            price_Filter_Data_selected && price_Filter_Data_selected?.name && (
              <span
                onClick={(e) => {
                  handel_remove_price(price_Filter_Data_selected);
                }}
                className=" d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6 w-auto gap-2"
                style={{
                  background: "var(--white, #FFF)",
                  color: "#000",
                  border: "1px solid var(--gray-300, #D0D5DD)",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  marginRight: 3,
                  display: "-ms-inline-flexbox",
                  padding: 2,
                  lineHeight: 2,
                }}
              >
                {price_Filter_Data_selected?.name}
                <img
                  src="/assets/img/Vector(33).png"
                  alt="Filter"
                  style={{ height: 10, width: 10 }}
                />{" "}
                &nbsp;
              </span>
            )
            // )
          }
        </Link>

        <Offcanvas
          className="courseListingFilterMobile"
          placement="bottom"
          show={show}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton>
            <div className="d-flex align-items-center justify-content-center rounded-5 gap-2 px-2 py-1">
              <img src="assets/img/Vector(31).png" alt="" />
              <p
                className="font-inter font-w600"
                style={{ color: "#344054", fontSize: "18px" }}
              >
                Filter
              </p>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Tabs
              defaultActiveKey="Club-Filter"
              id="justify-tab-example"
              className="mb-3 d-flex flex-row flex-nowrap"
              justify
            >
              <Tab
                eventKey="Club-Filter"
                title="CATEGORIES"
                className="filterTab"
              >
                <div
                  className="d-flex flex-column align-items-baseline"
                  style={{
                    scrollBehavior: "smooth",
                    overflowY: "scroll",
                    height: "100%",
                  }}
                >
                  {isLoading ? (
                    <div style={{ marginLeft: "15px" }}>
                      <Grid container spacing={2}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                            width: "100%",
                            padding: "20px 0",
                          }}
                        >
                          {Array.from(new Array(22)).map((_, index) => (
                            <Grid
                              item
                              key={index}
                              sx={{
                                minWidth: "100%",
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                              }}
                            >
                              <Skeleton
                                variant="rectangle"
                                width="5vw"
                                height={20}
                                sx={{ borderRadius: "3px" }}
                              />
                              <Skeleton
                                variant="rounded"
                                width="50vw"
                                height={20}
                              />
                            </Grid>
                          ))}
                        </div>
                      </Grid>
                    </div>
                  ) : (
                    headerClubsData?.map((item, index) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`Club_Filter${item.id}`}
                            value={JSON.stringify(item)}
                            checked={club_Filter_Data_selected?.some(
                              (o) => o.id === item?.id
                            )}
                            onChange={handleChange_club_Filter}
                          />
                        }
                        label={item?.name}
                        htmlFor={`Club_Filter${item.id}`}
                      />
                    ))
                  )}
                </div>
              </Tab>
              <Tab
                eventKey="Time-Filter"
                title="ACCESSIBILITY"
                className="filterTab"
              >
                <div className="d-flex flex-column">
                  {isLoading ? (
                    <>
                      <Grid container spacing={2}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                            width: "100%",
                            padding: "20px 15px",
                          }}
                        >
                          {Array.from(new Array(22)).map((_, index) => (
                            <Grid
                              item
                              key={index}
                              sx={{
                                minWidth: "100%",
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                              }}
                            >
                              <Skeleton
                                variant="rectangle"
                                width="5vw"
                                height={20}
                                sx={{ borderRadius: "3px" }}
                              />
                              <Skeleton
                                variant="rounded"
                                width="50vw"
                                height={20}
                              />
                            </Grid>
                          ))}
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`free_Filter0`}
                            value={JSON.stringify({ id: 0, name: "Free" })}
                            checked={price_Filter_Data_selected?.id === 0}
                            onChange={handlePriceChange}
                          />
                        }
                        label="Free"
                        htmlFor={`free_Filter0`}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`paid_Filter1`}
                            value={JSON.stringify({ id: 1, name: "Paid" })}
                            checked={price_Filter_Data_selected?.id === 1}
                            onChange={handlePriceChange}
                          />
                        }
                        label="Paid"
                        htmlFor={`paid_Filter1`}
                      />
                    </>
                  )}
                </div>
              </Tab>
              {/* <Tab eventKey="price" title="Price" className='font-inter fs-6 font-w400'>
                                <div style={{ scrollBehavior: 'smooth', overflowY: 'scroll', height: 480 }}>
                                    {price_Filter_Data && price_Filter_Data.map((item, index) =>
                                        <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                            <input
                                                id={`price_Filter${item.id}`}
                                                name='Price'
                                                value={JSON.stringify(item)}
                                                type="checkbox"
                                                checked={price_Filter_Data_selected?.id === item?.id}
                                                onChange={handleChange_price_Filter}
                                                style={{ zoom: 1.3 }}
                                            />&nbsp;&nbsp;
                                            <label for={`price_Filter${item.id}`} className='fs-6'>{item.name}</label>
                                        </div>
                                    )}
                                </div>
                            </Tab> */}
            </Tabs>
          </Offcanvas.Body>
          <div
            className="d-flex justify-content-around"
            style={{ borderTop: "1px solid lightgray" }}
          >
            <button
              onClick={handel_Clear_Filter}
              className="rounded-5 border-0 text-black font-inter fs-6 col-5 px-4 py-3"
              style={{
                backgroundColor: "#fff",
                flex: 1,
              }}
            >
              Clear All
            </button>
            <button
              onClick={handel_Apply_Filter}
              className="rounded-0 border-0 text-white font-inter fs-6 px-4 col-5 py-3"
              style={{
                background: "var(--button-primary-color)",
                flex: 1,
              }}
            >
              Apply
            </button>
          </div>
        </Offcanvas>
        <div className="col-md-3 px-3 d-none d-md-flex flex-column align-items-baseline">
          {isLoading ? (
            <div style={{ marginLeft: "30px" }}>
              <Skeleton
                variant="rounded"
                width="10vw"
                height={20}
                sx={{ margin: "10px 0" }}
              />
              <Grid container spacing={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    width: "100%",
                    padding: "30px 23px",
                  }}
                >
                  {Array.from(new Array(12)).map((_, index) => (
                    <Grid
                      item
                      key={index}
                      sx={{
                        minWidth: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <Skeleton variant="rectangle" width="1vw" height={20} />
                      <Skeleton variant="rounded" width="10vw" height={20} />
                    </Grid>
                  ))}
                </div>
              </Grid>

              <Skeleton
                variant="rounded"
                width="10vw"
                height={20}
                sx={{ margin: "10px 0" }}
              />
              <Grid container spacing={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    width: "100%",
                    padding: "30px 23px",
                  }}
                >
                  {Array.from(new Array(2)).map((_, index) => (
                    <Grid
                      item
                      key={index}
                      sx={{
                        minWidth: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <Skeleton variant="rectangle" width="1vw" height={20} />
                      <Skeleton variant="rounded" width="10vw" height={20} />
                    </Grid>
                  ))}
                </div>
              </Grid>
            </div>
          ) : (
            <div>
              <FormGroup className="mx-5">
                <FormLabel
                  className="font-inter text-uppercase font-w600 text-black"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  Categories
                </FormLabel>
                {/* <FormControlLabel
                                className='mt-2'
                                control={<Checkbox
                                    id={`Club_Filter0`}
                                    value={JSON.stringify({ id: 0, name: 'All' })}
                                    checked={club_Filter_Data_selected?.id === 0}
                                    onChange={handleChange_club_Filter}
                                />}
                                htmlFor={`Club_Filter0`}
                                label="All"
                            /> */}
                {headerClubsData?.map((item, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`Club_Filter${item.id}`}
                        value={JSON.stringify(item)}
                        checked={club_Filter_Data_selected?.some(
                          (o) => o.id === item?.id
                        )}
                        onChange={handleChange_club_Filter}
                      />
                    }
                    label={item?.name}
                    htmlFor={`Club_Filter${item.id}`}
                  />
                ))}

                <FormLabel
                  className="font-inter text-uppercase font-w600 text-black my-3"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  ACCESSIBILITY
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={`free_Filter0`}
                      value={JSON.stringify({ id: 0, name: "Free" })}
                      checked={price_Filter_Data_selected?.id === 0}
                      onChange={handlePriceChange}
                    />
                  }
                  label="Free"
                  htmlFor={`free_Filter0`}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id={`paid_Filter1`}
                      value={JSON.stringify({ id: 1, name: "Paid" })}
                      checked={price_Filter_Data_selected?.id === 1}
                      onChange={handlePriceChange}
                    />
                  }
                  label="Paid"
                  htmlFor={`paid_Filter1`}
                />
              </FormGroup>
            </div>
          )}
        </div>
        <div className="col-md-9 row m-0">
          {isLoading ? (
            <Grid container spacing={2} style={{ margin: "0" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(${columns}, 1fr)`,
                  gap: "15px",
                  width: "100%",
                  margin: "0 10px",
                }}
              >
                {Array.from(new Array(6)).map((_, index) => (
                  <Grid
                    item
                    xs={6}
                    md={4}
                    lg={3}
                    key={index}
                    sx={{ minWidth: "100%" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(0,0,0,0.09)",
                        margin: "10px 0",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                    >
                      <div
                        className="rounded-3"
                        style={{
                          aspectRatio: "16/9",
                          objectFit: "fill",
                          marginTop: "-5px",
                        }}
                      >
                        <Skeleton variant="rounded" width="100%" height={200} />
                      </div>
                      <h2
                        className="font-w600 font-inter fs-4 my-2  CourseListing--Card__Title"
                        style={{
                          color: "#101828",
                          lineHeight: "32px",
                          marginTop: "-10px",
                        }}
                      >
                        <Skeleton variant="rounded" width="90%" height={40} />
                      </h2>

                      <div className="d-flex flex-row justify-content-between my-3">
                        <Skeleton variant="rounded" width="5vw" height={30} />
                        <div className="d-flex flex-row gap-2">
                          <Skeleton variant="rounded" width="5vw" height={30} />
                          <Skeleton variant="circular" width={30} height={30} />
                        </div>
                      </div>
                      <Skeleton variant="rounded" width="100%" height={50} />
                    </div>
                  </Grid>
                ))}
              </div>
            </Grid>
          ) : (
            myCourseData?.map((courseData, id) => (
              <div className="col-xxl-4 col-lg-6 mb-3">
                <CourseCard courseData={courseData} />
              </div>
            ))
          )}
          {myCourseData?.length === 0 ||
            (myCourseData == undefined && (
              <p className="font-inter fs-4 my-3 text-center">
                No Results Found!
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Listing;
