import React, { useState, useEffect, useRef } from "react";
import {
  Navbar,
  Nav,
  Button,
  NavDropdown,
  Container,
  Offcanvas,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../auth";
import { SignInDrawer } from "./SignInDrawer";
import { AppContext } from "../context";
import { useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./Header.css";
import LoggedInNavbar from "./dashboard/Components/Dashboard/Navbar";
import { validate_token } from "./core/_request";
import { Avatar, IconButton, InputBase, Paper } from "@mui/material";
import Cookies from "js-cookie";
import { useGlobalStore } from "../store/useGlobalStore";

const searchPlaceholder = ["an Expert", "a Program", "a Blog"];

function Header2() {
  const navigate = useNavigate();
  const { currentUser, setCurrentUser, userType, login, logout } = useAuth();
  const { isAuthenticated, user, userLogin, userLogout } = useGlobalStore();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [changingPlaceholder, setChangingPlaceholder] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [b2BImage, setB2BImage] = useState();
  const [hasRedirected, setHasRedirected] = useState(false);

  const stellaAPIKey = process.env.REACT_APP_STELLAAPIKEY;
  const baseURL = "https://odoo.unitedwecare.ca";
  const cookie_token = Cookies.get("authToken");
  const authUserType = Cookies.get("authUserRole");

  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState(null);

  const search = useLocation().search;
  const token_url = new URLSearchParams(search).get("token");
  const searchParams = new URLSearchParams(location.search);

  // const headerSearch = (e) => {
  // debugger
  // if (e.key === 'Enter') {
  //   window.location.href = `https://www.unitedwecare.com/search/?query=${searchText}`
  // } else {
  //   setSearchText(e.target.value);
  // }
  // }

  // useEffect(() => {
  //   const decodeAndReplaceURL = (url) => {
  //     const decodedUrl = decodeURIComponent(url);
  //     const [baseUrl, queryParams] = decodedUrl.split('?');
  //     // const updatedQueryParams = queryParams.replace('?', '&');
  //     // const updatedUrl = `${baseUrl}?${updatedQueryParams}`;
  //     // window.location.replace(`${updatedUrl}`); 
  //     navigate(`?${queryParams}`)
  //   };

  //   const originalUrl = window.location.href;
  //   decodeAndReplaceURL(originalUrl);
  // }, []);

  useEffect(() => {
    const profileData = localStorage.getItem("profile");
    // console.log("profileData", profileData);

    const parsedProfile = JSON.parse(profileData);
    //console.log("parsedProfile", parsedProfile);

    const headerImage = parsedProfile?.header_image;
    // console.log("headerImage", headerImage);

    if (headerImage) {
      setB2BImage(headerImage);
    }
  }, [b2BImage]);

  const onHeaderSearchIcon = () => {
    navigate(`/search/?query=${searchText}`);
  };

  const handleEnterKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      onHeaderSearchIcon(); // Trigger the search function
    }
  };

  //const [show, setShow] = useState(false);
  const onLogout = () => {
    logout();
    setHasRedirected(false);
    Cookies.remove("authUserRole", {
      domain: ".unitedwecare.com",
      path: "/",
      // secure: true,
      HttpsOnly: true,
    });
    Cookies.remove("authToken", {
      domain: ".unitedwecare.com",
      path: "/",
      // secure: true,
      HttpsOnly: true,
    });
    Cookies.remove("authToken");
    window.location.href = "https://www.unitedwecare.com/";
  };

  useEffect(() => {
    const timer = () => {
      setChangingPlaceholder((prevIndex) => {
        if (prevIndex === searchPlaceholder.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      });
    };
    setInterval(timer, 5000);

    //cleanup function in order clear the interval timer
    //when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, []);

  const togglePopup = (state) => {
    setMenuOpen(false);
    setShowLloginPopup(state);
  };

  const redirectUser = (currentUrl, authUserType) => {
    // Define the redirection mapping based on the user type

    const userTypeArray = authUserType?.split(',')?.map(type => type.trim());
    const primaryUserType = userTypeArray[0];

    const redirectionMapping = {
      user: {
        login: '/user-dashboard',
        logout: 'https://unitedwecare.com/',
        default: (url) => url
      },
      expert: {
        login: '/expert/dashboard',
        logout: 'https://unitedwecare.com/',
        default: '/expert/dashboard'
      },
      copilot: {
        login: 'https://copilot.unitedwecare.com/auth',
        logout: 'https://www.unitedwecare.com/clinical-co-pilot/',
        default: 'https://copilot.unitedwecare.com/auth'
      }
    };

    // Get the appropriate redirect URL based on user type
    const getRedirectUrl = () => {
      const urlParts = currentUrl?.split('/');
      const baseeUrl = urlParts[0] + '//' + urlParts[2];
      const pathname = urlParts[3] || '';
      console.log("pathname", primaryUserType);
      console.log("currentUrl", currentUrl);
      console.log("baseeUrl", baseeUrl);

      if (currentUrl.includes('/logout')) {
        return redirectionMapping[primaryUserType].logout;
      } else if (urlParts[2] === 'accounts.unitedwecare.com') {
        return redirectionMapping[primaryUserType].login;
      } else if (urlParts[2] === 'my.unitedwecare.com' && urlParts[3] === 'profession') {
        return redirectionMapping[primaryUserType].default;
      } else if (urlParts[2] === 'stella.unitedwecare.com' || urlParts[2] === 'programs.unitedwecare.com') {
        return redirectionMapping[primaryUserType].default;
      } else if (urlParts[2] === 'stella.unitedwecare.com' && urlParts[3].includes('payload')) {
        return redirectionMapping[primaryUserType].default;
      } else if (currentUrl === '/' || currentUrl == '/user-dashboard') { // Check if the path starts with '?Profession'
        return redirectionMapping[primaryUserType].login; // Redirect to login/dashboard page
      } else {
        return redirectionMapping[primaryUserType].default;
      }
    };

    // Redirect to the computed URL
    // debugger
    if (primaryUserType === "copilot") {
      window.location.href = getRedirectUrl();
    } else {
      navigate(getRedirectUrl());
    }
  };

  const validateTokenFunction = () => {
    if (cookie_token && !currentUser) {
      validate_token(cookie_token).then((resp) => {
        if (resp?.ResponseCode === 200) {
          login(resp?.data);
          setCurrentUser(resp?.data?.userdetails);
          userLogin(resp?.data?.userdetails);
          // if (currentLocation === "/" && authUserType === "user") {
          //   navigate("/user-dashboard");
          // }
          // // else if (currentLocation === "/" && authUserType == "expert") {
          // //   navigate('/expert/dashboard')
          // // } else if (currentLocation === "/" && authUserType == "copilot expert") {
          // //   navigate('/expert/dashboard')
          // // } else if (currentLocation === "/" && authUserType == "copilot expert") {
          // //   navigate('/expert/dashboard')
          // // }

          // else {
          //   searchParams.delete("token");
          //   const searchString = searchParams.toString();
          //   const paramsArray = searchString.split("&");
          //   const filteredParamsArray = paramsArray.filter(
          //     (param) => !param.startsWith("token=")
          //   );
          //   const queryString = filteredParamsArray
          //     .join("&")
          //     .replace(/\+/g, "%20");
          //   const updatedURL = `${location.pathname}?${queryString}${location.hash}`;

          //   window.location.href = updatedURL;
          // }

          let now = new Date();
          let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
          let firstName = resp?.data?.userdetails?.Name.split(" ")
            .slice(0, 1)
            .join("");
          document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
          document.cookie = "userType=expert/user;";
        }
      }).catch((error) => {
        console.error("Error in validate_token with token_url:", error);
      });
    }
  }

  useEffect(() => {
    if (cookie_token && !isAuthenticated) {
      validateTokenFunction()?.then(() => {
        if (isAuthenticated && !hasRedirected) {
          const currentUrl = window.location.pathname;
          redirectUser(currentUrl, authUserType);
          setHasRedirected(true);
        }
      });
    }
  }, [cookie_token, isAuthenticated, hasRedirected]);

  useEffect(() => {
    console.log("isAuthenticated:", isAuthenticated);
    console.log("hasRedirected:", hasRedirected);

    if (isAuthenticated && !hasRedirected) {
      console.log("Redirecting user...");
      const currentUrl = window.location.pathname;
      redirectUser(currentUrl, authUserType);
      setHasRedirected(true);
    }
  }, [isAuthenticated, hasRedirected]);

  const handleLogin = () => {
    const currentUrl = encodeURIComponent(window.location.href);
    window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
  }

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // useEffect(() => {
  //   validate_token(token_url).then((resp) => {
  //     if (resp?.ResponseCode === 200) {
  //       localStorage.setItem("Authorization", resp?.data?.usertoken);

  //       localStorage.setItem(
  //         "profile",
  //         JSON.stringify(resp?.data?.userdetails)
  //       );
  //       if (
  //         resp?.data?.userdetails.header_image &&
  //         window.location.pathname === "/"
  //       ) {
  //         navigate("/user-dashboard");
  //       } else {
  //         searchParams.delete("token");
  //         // Convert the searchParams object back to a string
  //         const searchString = searchParams.toString();
  //         // Split the parameters
  //         const paramsArray = searchString.split("&");

  //         // Filter out the token parameter
  //         const filteredParamsArray = paramsArray.filter(
  //           (param) => !param.startsWith("token=")
  //         );

  //         // Reconstruct the query string
  //         const queryString = filteredParamsArray
  //           .join("&")
  //           .replace(/\+/g, "%20");

  //         // Create the updated URL

  //         const updatedURL = `${location.pathname}?${queryString}${location.hash}`;

  //         window.location.href = updatedURL;
  //         // window.location.href = window.location.href = window.location.origin + window.location.pathname;
  //       }

  //       let now = new Date();
  //       let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
  //       let firstName = resp?.data?.userdetails?.Name.split(" ")
  //         .slice(0, 1)
  //         .join("");
  //       document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com/us;path=/;`;
  //       document.cookie = "userType=expert/user;";
  //       console.log(
  //         "resp?.data?.userdetails?.Name",
  //         resp?.data?.userdetails?.Name
  //       );
  //     }
  //   });
  // }, []);

  const handleClose = () => setMenuOpen(false);

  useEffect(() => {
    if (
      previousLocation &&
      previousLocation.pathname == "/onboarding-stella" &&
      location.pathname !== "/onboarding-stella"
    ) {
      // Alert or perform any other action when the user navigates from the previous location
      //console.log("Kimochi!!")

      const headers = {
        "Content-Type": "application/json",
        "X-API-Key": stellaAPIKey,
      };

      const sessionID = sessionStorage.getItem("stella_s_id");

      const body = {
        senderId: sessionID,
      };

      const getStellaClose = async () => {
        try {
          //setIsLoading(true);

          const response = await fetch(
            `${baseURL}/new-uwc/stella/close/session`,
            {
              method: "POST",
              headers,
              body: JSON.stringify(body),
            }
          );

          if (!response.ok) {
            // Handle error response
            throw new Error("Error fetching languages");
          }

          const jsonData = await response.json();
          console.log("RESPONSE", response);

          if (response.status == 200) {
            console.log("CLEAR");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      getStellaClose();
    }

    // Save the current location as the previous location
    setPreviousLocation(location);
  }, [location, previousLocation]);

  const [showEmployersDropdown, setShowEmployersDropdown] = useState(false);
  const [showHealthcareDropdown, setShowHealthcareDropdown] = useState(false);

  const handleEmployersMouseEnter = () => {
    setShowEmployersDropdown(true);
  };

  const handleEmployersMouseLeave = () => {
    setShowEmployersDropdown(false);
  };

  const handleHealthcareMouseEnter = () => {
    setShowHealthcareDropdown(true);
  };

  const handleHealthcareMouseLeave = () => {
    setShowHealthcareDropdown(false);
  };

  // const urlSplit = window.location.href.split("?")[0].split("/").includes("user-dashboard");

  return (
    <>
      {(!currentUser && (
        <div
          style={{
            background: "#FFFFFF",
            boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.09)",
          }}
          className="header-container kareify-Menu-header"
        >
          <Navbar key={"sm"} expand={"sm"} className={`col-12 Header--2 p-0`}>
            <div
              className="d-flex align-items-center justify-content-between col-12 my-2 my-md-3"
              style={{ padding: "0 5%" }}
            >
              {/* logo Desktop */}
              <Navbar.Brand className="d-none d-md-flex gap-4 align-items-center kareify-logo-container p-0">
                <a
                  href="https://www.unitedwecare.com/"
                  className="text-decoration-none"
                >
                  <img
                    alt=""
                    src="/assets/img/kareify/UWC logo.png"
                    width={150}
                    className="d-block d-sm-none img-fluid"
                    style={{ objectFit: "contain" }}
                  />
                  <img
                    alt=""
                    src="/assets/img/kareify/UWC logo.png"
                    width="326"
                    height="50"
                    fill="currentColor"
                    className="bi bi-search me-3 d-none d-md-block"
                    viewBox="0 0 16 16"
                    style={{ objectFit: "contain" }}
                  />
                </a>
              </Navbar.Brand>
              {/* logo Mobile */}
              <Navbar.Brand className="d-block d-md-none">
                <a
                  href="https://www.unitedwecare.com/"
                  className="text-decoration-none"
                >
                  <img
                    src="/assets/img/kareify/UWC logo.png"
                    alt=""
                    width={300}
                    style={{ objectFit: "contain" }}
                  />
                </a>
              </Navbar.Brand>

              {b2BImage && (
                <img
                  src={b2BImage}
                  alt=""
                  width={110}
                  height={65}
                  style={{ objectFit: "contain" }}
                />
              )}

              {/* Angel One Logo */}
              {b2BImage && (
                <Navbar.Brand className="d-block d-md-none">
                  <a href="" className="text-decoration-none">
                    <img
                      src={b2BImage}
                      alt=""
                      style={{ objectFit: "contain" }}
                    />
                  </a>
                </Navbar.Brand>
              )}
              <>
                <Navbar.Toggle
                  aria-controls={"offcanvasNavbar-expand-sm"}
                  onClick={toggleMenu}
                />
                <Navbar.Offcanvas
                  id={"offcanvasNavbar-expand-sm"}
                  aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                  placement="top"
                  // style={{ backgroundColor: "#C4E5F9" }}
                  className="header-offcanvas"
                  restoreFocus={false}
                  show={menuOpen}
                  onHide={handleClose}
                >
                  <Offcanvas.Header closeButton>
                    <Navbar.Brand className="d-block d-md-none">
                      <a
                        href="https://www.unitedwecare.com/"
                        className="text-decoration-none"
                      >
                        <img
                          src="/assets/img/kareify/UWC logo.png"
                          alt=""
                          width={300}
                          style={{ objectFit: "contain" }}
                        />
                      </a>
                    </Navbar.Brand>
                  </Offcanvas.Header>

                  <Nav className="header2--offcanvas-body offcanvas-body align-items-center align-items-md-center justify-content-center px-4 navbar-nav">
                    {/* Mobile View Start */}
                    {/* <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Employers"
                      className="font-inter text-decoration-none lh-lg d-block d-md-none mx-1 dropdown-header"
                      // menuVariant="dark"
                    >
                      <div className="kareify-menu-companySize-text">
                        Company Size
                      </div>
                      <NavDropdown.Item href="https://www.unitedwecare.com/employers/">
                        Small Business
                        <br />
                        <span className="small-business-text">
                          Less than 100 employees
                        </span>
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/employers-large-business/">
                        Med/Large Business
                        <br />
                        <span className="small-business-text">
                          100+ employees
                        </span>
                      </NavDropdown.Item>
                      <div className="kareify-menu-companySize-text">
                        By Industry
                      </div>
                      <NavDropdown.Item href="https://www.unitedwecare.com/business-services/">
                        Business Services
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/consumer-services/">
                        Consumer Services
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/technology/">
                        Technology
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/retail/">
                        Retail
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/finance/">
                        Finance
                      </NavDropdown.Item>
                    </NavDropdown> */}
                    <Paper
                      component="form"
                      className="d-flex d-md-none"
                      sx={{ p: '2px 4px', alignItems: 'center', width: 300, border: '0.5px solid #cccccc', borderRadius: '10px', boxShadow: 'none !important' }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={`Search ${searchPlaceholder[changingPlaceholder]}`}
                        inputProps={{ 'aria-label': 'search google maps' }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={handleEnterKeyDown}
                      />
                      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => navigate(`/search/?query=${searchText}`)}>
                        <SearchIcon />
                      </IconButton>
                      {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                  <DirectionsIcon />
                </IconButton> */}
                    </Paper>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/enterprise/"
                    >
                      Enterprise
                    </Nav.Link>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/insurance/"
                    >
                      Insurance
                    </Nav.Link>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/alliance/"
                    >
                      Alliance Partners
                    </Nav.Link>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/healthcare-systems/"
                    >
                      Healthcare
                    </Nav.Link>

                    {/* <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Healthcare"
                      className="font-inter text-decoration-none lh-lg d-block d-md-none mx-1 dropdown-header"
                      // menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/healthcare-systems/">
                        Healthcare Systems
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/healthcare-hospitals/">
                        Healthcare & Hospitals
                      </NavDropdown.Item>
                    </NavDropdown> */}
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/about-us/"
                    >
                      About Us
                    </Nav.Link>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/blog/"
                    >
                      Blog
                    </Nav.Link>

                    <div style={{ padding: "15px", width: "100%" }}>
                      <Button
                        id="header_login"
                        className="text-white border-0 rounded-3 font-inter d-block d-md-none"
                        style={{
                          backgroundColor: "#73589B",
                          height: "45px",
                          width: '100%',
                          fontSize: "18px",
                          fontWeight: 500,
                          padding: '10p 20px'
                        }}
                        onClick={() => {
                          // togglePopup(true);
                          handleLogin();
                        }}
                      >
                        Login
                      </Button>
                    </div>

                    {/* Mobile View End */}
                    {/* Desktop View Start */}
                    {/* <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Employers"
                      className="kareify-Menu-header-text font-inter text-decoration-none lh-lg d-none d-md-block mx-1 dropdown-header"
                      show={showEmployersDropdown}
                      onMouseEnter={handleEmployersMouseEnter}
                      onMouseLeave={handleEmployersMouseLeave}
                      // menuVariant="dark"
                    >
                      <div className="kareify-menu-companySize-text">
                        Company Size
                      </div>
                      <NavDropdown.Item href="https://www.unitedwecare.com/employers/">
                        Small Business
                        <br />
                        <span className="small-business-text">
                          Less than 100 employees
                        </span>
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/employers-large-business/">
                        Med/Large Business
                        <br />
                        <span className="small-business-text">
                          100+ employees
                        </span>
                      </NavDropdown.Item>
                      <div className="kareify-menu-companySize-text">
                        By Industry
                      </div>
                      <NavDropdown.Item href="https://www.unitedwecare.com/business-services/">
                        Business Services
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/consumer-services/">
                        Consumer Services
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/technology/">
                        Technology
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/retail/">
                        Retail
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/finance/">
                        Finance
                      </NavDropdown.Item>
                    </NavDropdown> */}
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/enterprise/"
                    >
                      Enterprise
                    </Nav.Link>
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/insurance/"
                    >
                      Insurance
                    </Nav.Link>
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/alliance/"
                    >
                      Alliance Partners
                    </Nav.Link>
                    {/* <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Healthcare"
                      className="kareify-Menu-header-text font-inter text-decoration-none lh-lg d-none d-md-block mx-1 dropdown-header"
                      show={showHealthcareDropdown}
                      onMouseEnter={handleHealthcareMouseEnter}
                      onMouseLeave={handleHealthcareMouseLeave}
                      // menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/healthcare-systems/">
                        Healthcare Systems
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/healthcare-hospitals/">
                        Healthcare & Hospitals
                      </NavDropdown.Item>
                    </NavDropdown> */}
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/healthcare-systems/"
                    >
                      Healthcare
                    </Nav.Link>
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/about-us/"
                    >
                      About Us
                    </Nav.Link>
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/blog/"
                    >
                      Blog
                    </Nav.Link>
                    {/* <Nav.Link onClick={() => {togglePopup(true);toggleMenu();}} className="text-white d-block d-md-none"><Link className="text-decoration-none lh-lg"><Button className='text-black border-0 rounded-4' style={{ backgroundColor: "#EDEFEB" }}>Login</Button></Link></Nav.Link> */}
                    {/* Desktop View End */}
                    {/* {currentUser && (<Nav.Link className="mx-2 d-none d-md-block"><Link to="/onboarding-stella" className='text-decoration-none lh-lg'>Stella</Link></Nav.Link>)} */}

                    {/* <Nav.Link onClick={() => {togglePopup(true);toggleMenu();}} className="text-white"><Link className="text-decoration-none lh-lg"><Button className='text-black border-0 rounded-4' style={{ backgroundColor: "#EDEFEB" }}>Login</Button></Link></Nav.Link> */}
                  </Nav>
                  {/* <Modal
                    show={ShowLloginPopup}
                    onHide={() => togglePopup(false)}
                    // dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    className="m-0 signInModal"
                    style={{ height: "100vh" }}
                    centered={false}
                  >
                    <SignInDrawer /> 
                  </Modal> */}

                  <Offcanvas
                    show={ShowLloginPopup}
                    onHide={() => {
                      togglePopup(false)
                      // handleLogin();
                    }}
                    placement="end"
                    className="m-0 signInModal"
                  >
                    <SignInDrawer />
                  </Offcanvas>
                </Navbar.Offcanvas>
              </>
              <div className="align-items-center gap-3 d-none d-md-flex">
                <Paper
                  component="form"
                  className="d-none d-md-flex"
                  sx={{ p: '2px 4px', alignItems: 'center', width: 300, border: '0.5px solid #cccccc', borderRadius: '10px', boxShadow: 'none !important' }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={`Search ${searchPlaceholder[changingPlaceholder]}`}
                    inputProps={{ 'aria-label': 'search google maps' }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={handleEnterKeyDown}
                  />
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => navigate(`/search/?query=${searchText}`)}>
                    <SearchIcon />
                  </IconButton>
                  {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                  <DirectionsIcon />
                </IconButton> */}
                </Paper>
                <Nav.Link
                  onClick={() => {
                    // togglePopup(true);
                    handleLogin();
                  }}
                  className="text-white"
                >
                  <Link className="text-decoration-none lh-lg">
                    {(!currentUser && cookie_token) ?
                      <Avatar />
                      :
                      <Button
                        id="header_login"
                        className="text-white border-0 rounded-3 px-4 font-inter"
                        style={{
                          backgroundColor: "#73589B",
                          height: "45px",
                          fontSize: "18px",
                          fontWeight: 500,
                        }}
                      >
                        Login
                      </Button>
                    }
                  </Link>
                </Nav.Link>
              </div>
            </div>
            {/* <div className="d-flex d-md-none flex-column align-items-center justify-content-center col-12">
              <Form
                className="d-flex d-md-none align-items-center justify-content-center rounded-2 col-11 my-2"
                style={{ border: "0.5px solid #541CF5", height: "45px" }}
              >
                <InputGroup>
                  <FormControl
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={handleEnterKeyDown}
                    type="search"
                    className="ms-2 border-0 headerSearch"
                    style={{ borderRight: "none" }}
                    placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`}
                  />
                  <SearchIcon
                    sx={{
                      color: "#541CF5",
                      display: "block",
                      marginY: "auto",
                      marginRight: "10px",
                    }}
                    onClick={onHeaderSearchIcon}
                  />
                </InputGroup>
              </Form>
            </div> */}
          </Navbar>
        </div>
      )) || <LoggedInNavbar onLogout={onLogout} />}
    </>
  );
}

export default Header2;
