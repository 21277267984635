import React from "react";
import "./Booking.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from "@mui/material/DialogTitle";

const ReadMoreModal = ({open, setOpen}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="Read-More-Modal"
      >
      {/* <DialogTitle id="read-more-dialog-title">
      <div className="close-button">
      <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            
          >
            <CloseIcon />
          </IconButton>
      </div>
          
        </DialogTitle> */}
        <DialogContent>
          <div className="p-2">
            <p>
            When you book a service with us, we use the most current insurance
            eligibility information to provide an upfront estimate of your
            financial responsibility. However, it's important to note that
            insurance payments are subject to final approval by your insurance
            provider. Occasionally, after your service has been rendered,
            insurance companies may revise the payment amount for various
            reasons, such as policy updates, claims processing adjustments, or
            discrepancies in the initial claim.
            </p>
            <br />
            <p>
            Should there be a revision in
            the insurance payment that affects the balance you owe, please rest
            assured that we will inform you at the earliest opportunity. Our
            team is here to provide detailed explanations, assist with any
            billing questions, and discuss options that may be available to you.
            </p>
            <br />
            <p>
            Our goal is to make this process as transparent and stress-free as
            possible, allowing you to focus on what truly matters - your
            wellness journey. For any questions or concerns about insurance
            payments, billing processes, or if you need further clarification,
            please don't hesitate to reach out to us. Your peace of mind is our
            top priority.
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReadMoreModal;
