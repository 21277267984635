import React, { useEffect, useState, useContext } from "react";
//SpeechRecognition
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { AppContext } from "../../../context";
import { getTranscript, getTranslation } from "../APIS/clientAPIS";
import { useAuth } from "../../../auth";
import StopIcon from "@mui/icons-material/Stop";

function AutogeneratedReviewBar({ tick, cross }) {
  const [isRecording, setIsRecording] = useState(false);

  const [audioRequestData, setAudioRequestData] = useState(null);
  const [showMic, setShowMic] = useState(true);
  const [showStopMic, setShowStopMic] = useState(false);
  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const [autoGenPopup, setAutoGenPopup] = useState(false);
  const [base64String, setBase64String] = useState(null);
  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const recorderControls = useAudioRecorder();
  const { expertDashboardData } = useContext(AppContext);
  const { currentUser } = useAuth();

  useEffect(() => {
    //console.log("expertDashboardData", expertDashboardData);
    const sendAudioData = async () => {
      const base64String = await convertBlobToBase64(
        recorderControls.recordingBlob
      );
      // console.log("BLOB", recorderControls.recordingBlob);
      getTranscript(
        expertDashboardData.session_data.appointment_details.appointment_id,
        expertDashboardData.session_data.appointment_details.user_id,
        expertDashboardData.session_data.appointment_details.expert_id,
        expertDashboardData.session_data.appointment_details.appointment_date,
        expertDashboardData.session_data.appointment_details.booking_date,
        base64String
      ).then((res) => {
        //console.log("RES", res)
        getTranslation(
          expertDashboardData.session_data.appointment_details.appointment_id,
          expertDashboardData.session_data.appointment_details.user_id,
          expertDashboardData.session_data.appointment_details.expert_id,
          expertDashboardData.session_data.appointment_details.appointment_date,
          expertDashboardData.session_data.appointment_details.booking_date,
          res.transcript
        );
      });
    };
    if (
      recorderControls.recordingBlob !== undefined &&
      recorderControls.recordingBlob !== null
    ) {
      sendAudioData();
    }

    // console.log("finalTranscript", finalTranscript);
    // console.log("interimTranscript", interimTranscript);
    // console.log("transcript", transcript);
  }, [recorderControls.recordingBlob]);

  //console.log("listening", listening)

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // console.log("reader.result", reader.result)
        resolve(reader.result.split("data:audio/webm;codecs=opus;base64,")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleRecordingStart = () => {
    recorderControls.startRecording();
    //SpeechRecognition.startListening();
    setIsRecording(true);
  };

  const handleRecordingStop = async () => {
    recorderControls.stopRecording();
    setIsRecording(false);
    setIsRecordingStopped(true);
    //SpeechRecognition.stopListening();
  };

  const handleRecordingComplete = async (blob) => {
    setAudioRequestData(blob);
  };

  return (
    <div className="p-2 d-flex flex-wrap align-items-center">
      <div
        style={{ backgroundColor: "white" }}
        className="p-1 my-2 rounded-2 d-inline mx-2"
      >
        <button
          className=" border-0 mx-1"
          style={{ backgroundColor: "white" }}
          onClick={tick}
        >
          <img src="/assets/img/elements/typcn_tick.png" />
        </button>
        <button className=" border-0 mx-1" style={{ backgroundColor: "white" }}>
          <img src="/assets/img/elements/mdi_thumbs-down.png" />
        </button>
        <button className=" border-0 mx-1" style={{ backgroundColor: "white" }}>
          {isRecording ? (
            <button className="border-0 mx-1" onClick={handleRecordingStop}>
              <StopIcon style={{ color: "#777777", border: "none" }} />
            </button>
          ) : (
            <img
              src="/assets/img/elements/Mic.png"
              onClick={handleRecordingStart}
            />
          )}
        </button>
        <button className=" border-0 mx-1" style={{ backgroundColor: "white" }}>
          <img src="/assets/img/elements/tabler_refresh.png" />
        </button>
        <button
          className=" border-0 mx-1"
          style={{ backgroundColor: "white" }}
          onClick={cross}
        >
          <img src="/assets/img/elements/carbon_close-filled.png" />
        </button>
      </div>
      {/* hidden for development */}
      <div
        className=" d-flex flex-wrap p-2"
        style={{ backgroundColor: "rgb(249, 245, 255)" }}
      >
        <p>Did we do a better job this time?</p>
        <div>
          <button className=" border-0 mx-1" style={{ backgroundColor: "white" }}>
            <span
              style={{ backgroundColor: "white" }}
              className="fw-bold p-1 mx-2"
            >
              Better
            </span>
          </button>
          <button className=" border-0 mx-1" style={{ backgroundColor: "white" }}>
            <span style={{ backgroundColor: "white" }} className="fw-bold  p-1">
              Worse
            </span>
          </button>
          <button className=" border-0 mx-1" style={{ backgroundColor: "white" }}>
            <span
              style={{ backgroundColor: "white" }}
              className="fw-bold  p-1 mx-2"
            >
              Same
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AutogeneratedReviewBar;
