import React, { useEffect, useState } from "react";
import AppointmentCard from "../AppointmentCard";
import { MySubscriptionService } from "./core/_request";
import { useAuth } from "../../auth";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Skeleton } from "@mui/material";
import './UserDashboardChat.css'

const MyAppointments = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    MySubscriptionService().then((resp) => {
      // debugger
      //console.log("MySubscriptionService resp", resp);
      if (resp.ResponseCode == 401) {
        // logout();
        // navigate("/login");
        // setShowLloginPopup(true);
        const currentUrl = encodeURIComponent(window.location.href);
        window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
      } else setData(resp.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      //assign interval to a variable to clear it.
      MySubscriptionService()
        .then((resp) => {
          //console.log("MySubscriptionService resp", resp);
          if (resp.ResponseCode == 401) {
            logout();
            // navigate("/login");
            // setShowLloginPopup(true);
            const currentUrl = encodeURIComponent(window.location.href);
            window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
          } else setData(resp.data);
        })
        .catch(function (error) {
          console.log(error);
          setData(null);
        });
    }, 6 * 1000);
    return () => clearInterval(intervalId); //This is important
  }, [data]);

  const onBookAnAppointment = () => {
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>My Appointments Dashboard - United We Care</title>
      </Helmet>
      <div
        className=""
        style={{
          background: "#fff",
          padding: 0,
          margin: 0,
        }}
      >
        <div className="container pt-5">
          {/* {
          loading ? (<div>
            <Skeleton variant="rounded" width="20%" height={25} className="rounded rounded-3 mb-5"/>
          </div>) : (<div>
            <h1 className="fs-3 mb-5">Appointments</h1>
          </div>)
        } */}
          <div className="d-flex flex-column gap-3 mb-4">
            {/* <div>
              <h1 className="fs-3 mb-5">Appointments</h1>
            </div> */}
            {loading
              ? Array.from(new Array(6)).map((_, index) => (
                <div>
                  <div
                    className="container bg-white d-flex align-items-center justify-content-between py-2 px-4 w-100"
                    style={{
                      boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12",
                    }}
                  >
                    <div className="d-flex flex-column flex-md-row align-items-center gap-3 gap-md-5 w-100">
                      <div
                        className="d-flex flex-row flex-md-column align-items-baseline justify-content-between gap-3 gap-md-2 content"
                        style={{ width: "max-content" }}
                      >
                        <Skeleton
                          variant="text"
                          width={80}
                          height={25}
                          sx={{ borderRadius: "20px" }}
                          className="d-none d-md-block"
                        />
                        <Skeleton
                          variant="rounded"
                          width={80}
                          height={22}
                          sx={{ borderRadius: "20px" }}
                          className="d-block d-md-none"
                        />
                        <p
                          className="fs-6 font-w600 text-black"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Skeleton
                            variant="rounded"
                            width={80}
                            height={22}
                            sx={{ borderRadius: "5px" }}
                          />
                        </p>
                        <p className="fs-6 font-w600 text-black">
                          <Skeleton
                            variant="rounded"
                            width={80}
                            height={22}
                            sx={{ borderRadius: "5px" }}
                          />
                        </p>
                        <Skeleton
                          variant="rounded"
                          width={80}
                          height={22}
                          sx={{ borderRadius: "5px" }}
                        />
                      </div>
                      <div className="vr d-none d-md-block"></div>
                      <div className="d-flex gap-3 align-items-center py-md-2 w-100">
                        <Skeleton variant="circular" width={100} height={100} />
                        <div className="d-md-flex flex-column justify-content-center gap-2 d-none">
                          <Skeleton
                            variant="rounded"
                            width="10vw"
                            height={25}
                          />
                          <Skeleton
                            variant="rounded"
                            width="10vw"
                            height={25}
                          />
                        </div>
                        <div className="d-flex d-md-none flex-column justify-content-center gap-2 ">
                          <Skeleton
                            variant="rounded"
                            width="30vw"
                            height={25}
                          />
                          <Skeleton
                            variant="rounded"
                            width="30vw"
                            height={25}
                          />
                        </div>
                      </div>
                      <div className="d-md-none flex-row justify-content-between align-items-center w-100 d-flex">
                        <div>
                          <Skeleton
                            variant="rounded"
                            width={100}
                            height={40}
                            sx={{ borderRadius: "20px" }}
                          />
                        </div>
                        <div>
                          <Skeleton
                            variant="rounded"
                            width={57}
                            height={36}
                            sx={{ borderRadius: "20px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-md-flex flex-row gap-3 align-items-center d-none justify-content-end">
                      <div>
                        <Skeleton
                          variant="rounded"
                          width={100}
                          height={36}
                          sx={{ borderRadius: "20px" }}
                        />
                      </div>
                      <div>
                        <Skeleton
                          variant="rounded"
                          width={100}
                          height={36}
                          sx={{ borderRadius: "20px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
              : data?.length > 0 ? (
                <>
                  {data?.map((item, index) => (
                    <AppointmentCard key={index} item={item} />
                  ))}
                </>
              ) : (
                <div
                  className="d-flex flex-column align-items-center gap-5"
                  style={{ height: "40vh" }}
                >
                  <p className="font-inter fs-5">No Appointment Found</p>
                  <Button
                    className="col-md-3 font-inter fs-5"
                    onClick={onBookAnAppointment}
                    style={{
                      background: "var(--button-primary-color)",
                      color: "#FFF",
                      border: "1px solid #73589b",
                    }}
                  >
                    Book Your First Session
                  </Button>
                </div>
              )}
            {/* {(data?.length > 0 && (
                  <>
                    {data?.map((item, index) => (
                      <AppointmentCard key={index} item={item} />
                    ))}
                    <p className="text-center py-5">
                      --------- No more Appointments ---------
                    </p>
                  </>
                )) || (
                  <div
                    className="d-flex flex-column align-items-center gap-5"
                    style={{ height: "40vh" }}
                  >
                    <p className="font-inter fs-5">No Appointment Found</p>
                    <Button
                      className="col-md-3 font-inter fs-5"
                      onClick={onBookAnAppointment}
                      style={{ background: "#fff", color: "#73589b", border: '1px solid #73589b' }}
                    >
                      Book Your First Session
                    </Button>
                  </div>
                )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAppointments;
