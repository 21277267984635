import React from "react";
import Sidebar from "../../components/dashboard/Components/Dashboard/Sidebar";
import Insurance from "../../components/dashboard/Components/Dashboard/Insurance";

const MyInsurance = () => {
  return (
    <div className="d-flex ">
      <div className="col-2 d-none d-lg-inline" style={{padding:"0 12px"}}>
        <Sidebar activeItem="My Insurance" />
      </div>

      <div className="w-100">
        <Insurance />
      </div>
    </div>
  );
};

export default MyInsurance;
