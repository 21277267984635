import React from 'react'

const PlanDetailsStats = () => {
    return (
        <div className='Stats--Area d-flex align-items-center gap-8 relative'>
            <img style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }} src='/assets/img/planDetails/StatsBG.png' />
            <div className='d-flex align-items-center gap-4 relative justify-content-center'>
                <div className='Stat--Item col-md-3'>
                    <h6>80%</h6>
                    <div>
                        <p>of people reported improved mental well-being with us</p>
                    </div>
                </div>

                <div className='Stat--Item col-md-3'>
                    <h6>75%</h6>
                    <div>
                        <p>of people reported less stress with us</p>
                    </div>
                </div>

                <div className='Stat--Item col-md-3'>
                    <h6>75%</h6>
                    <div>
                        <p>of people reported improved sleeping patterns</p>
                    </div>
                </div>

                <div className='Stat--Item Rating'>
                    <h6>4.6 <img src='assets/img/clr-star.png' alt='star' /></h6>
                    <div className='d-flex align-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" viewBox="0 0 64 65" fill="none">
                            <path d="M59.6963 33.1602C59.6963 31.1 59.5114 29.119 59.168 27.2173H31.8042V38.4692H47.4407C46.7539 42.0878 44.6937 45.1517 41.6034 47.2119V54.5283H51.0328C56.5267 49.457 59.6963 42.0085 59.6963 33.1602Z" fill="#4285F4" />
                            <path d="M31.8047 61.5542C39.6494 61.5542 46.2262 58.9657 51.0334 54.5283L41.6039 47.2119C39.0155 48.9552 35.7138 50.0117 31.8047 50.0117C24.2506 50.0117 17.8323 44.914 15.5343 38.0466H5.86719V45.5479C10.6479 55.0302 20.4471 61.5542 31.8047 61.5542Z" fill="#34A853" />
                            <path d="M15.5339 38.0202C14.9528 36.2769 14.6094 34.428 14.6094 32.4999C14.6094 30.5717 14.9528 28.7228 15.5339 26.9796V19.4783H5.86673C3.88576 23.3874 2.75 27.7984 2.75 32.4999C2.75 37.2014 3.88576 41.6123 5.86673 45.5215L13.3944 39.6578L15.5339 38.0202Z" fill="#FBBC05" />
                            <path d="M31.8047 15.0144C36.0836 15.0144 39.8871 16.4936 42.9246 19.3462L51.2447 11.0261C46.1998 6.32457 39.6494 3.44556 31.8047 3.44556C20.4471 3.44556 10.6479 9.96956 5.86719 19.4782L15.5343 26.9795C17.8323 20.1121 24.2506 15.0144 31.8047 15.0144Z" fill="#EA4335" />
                        </svg>

                        <div>
                            <p>Google</p>
                            <span>average rating</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PlanDetailsStats