import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import axios from "axios";
import { Config } from "../../../core/_request";
import { useState } from "react";

const NotCompleted = ({
  appointment_id,
  open,
  handleClose,
  status,
  setMessage,
  handleOpenSnackbar,
  setStatus,
}) => {
  const [feedbackQuestion, setFeedbackQuestion] = useState([]);
  const [qaList, setQaList] = useState({});
  const [answer, setAnswer] = useState("");
  const [answerData, setAnswerData] = useState("");

  useEffect(() => {
    getSessionNotCompleted();
  }, []);

  useEffect(() => {
    setAnswer("");
    setAnswerData("");
    if (appointment_id !== 0 && appointment_id && appointment_id !== "") {
      sessionFeedbacksByUserId();
    }
    setQaList([]);
  }, [appointment_id]);


  const sessionFeedbacksByUserId = () => {
    if (status === "read") {
      let userId = JSON.parse(localStorage.getItem("profile"))?.UserId;
      let body = {
        user_id: userId,
        appointment_id: appointment_id,
        user_type: "expert",
      };
      axios
        .post(
          `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/expert-feedback-details`,
          body,
          Config()
        )
        .then((resp) => resp.data)
        .then((resp) => {
          if (resp.ResponseCode === 200) {
            if (resp?.data[0]?.qa_list[0]?.type === "Other") {
              setAnswer(resp?.data[0]?.qa_list[0]?.answer);
              setAnswerData(resp?.data[0]?.qa_list[0]?.type);
            } else {
              setAnswerData(resp?.data[0]?.qa_list[0]?.answer);
            }
          }
        });
    }
  };
  const getSessionNotCompleted = () => {
    let userId = JSON.parse(localStorage.getItem("profile"))?.UserId;
    let body = {
      user_type: "expert",
      user_id: userId,
      session_status: "incompleted",
    };
    axios
      .post(
        `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/session-feedback-form`,
        body,
        Config()
      )
      .then((resp) => resp.data)
      .then((resp) => {
        if (resp.ResponseCode === 200) {
          setFeedbackQuestion(resp?.data?.template_data);
          // setSessionManagement(resp.data[0])
        }
      });
  };
  const submit = () => {
    if (Object.keys(qaList).length < 1) {
      setMessage("Please fill the answer !");
      setStatus("error");
      handleOpenSnackbar();
      return;
    }
    let userId = JSON.parse(localStorage.getItem("profile"))?.UserId;
    let body = {
      user_type: "expert",
      user_id: userId,
      appointment_id: appointment_id,
      session_status: "incompleted",
      qa_list: Object.values(qaList),
    };
    axios
      .post(
        `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/feedback-form-data`,
        body,
        Config()
      )
      .then((resp) => resp.data)
      .then((resp) => {
        if (resp.ResponseCode === 200) {
          handleOpenSnackbar();
          setMessage(resp.data.message);
          setStatus("success");
        }
      });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      PaperProps={{
        style: { width: "497px", height: "452px", borderRadius: "10px" },
      }}
      style={{ width: "100%" }}
    >
      <DialogTitle
        className="text-white"
        style={{
          backgroundColor: "#73589b",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "55px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "18px",
          lineHeight: "130%",
          color: "#FFFFFF",
        }}
      >
        Session Not Completed
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            marginTop: "12px",
            marginBottom: "8px",
            lineHeight: "130%",
          }}
        >
          {feedbackQuestion[0]?.question}
        </div>
        {feedbackQuestion[0]?.options?.map((option, index) => (
          <div
            key={index}
            style={{
              // display: "flex",
              alignItems: "center",
              marginBottom: "8px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 200,
              fontSize: "18px",
              // marginTop: "10px",
            }}
          >
            <input
              type="radio"
              id={`futureSessionRecommendation-${index}`}
              name="futureSessionRecommendation"
              checked={
                option?.name === answerData
                  ? true
                  : option?.name === answerData + ":"
                  ? true
                  : false
              }
              style={{
                width: "14px",
                height: "14px",
                color: "#73589b",
              }}
              onChange={() => {
                setQaList({
                  0: {
                    question_id: feedbackQuestion[0]?.question_id,
                    answer: option?.name,
                  },
                });
                setAnswerData(
                  option?.name === "Other:" ? "Other" : option?.name
                );
              }}
            />
            <label
              htmlFor={`futureSessionRecommendation-${index}`}
              style={{
                marginLeft: "8px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "17px",
                color: "#000",
              }}
            >
              {option.name}
            </label>
            {option?.name === "Other:" && answerData === "Other" ? (
              <>
                <TextField
                  fullWidth
                  value={answer}
                  variant="standard"
                  onChange={(event) => {
                    setQaList({
                      0: {
                        question_id: feedbackQuestion[0]?.question_id,
                        answer: event?.target?.value,
                        answer_value: "Other",
                      },
                    });
                    setAnswer(event?.target?.value);
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
                      width: "100%",
                    },
                  }}
                  placeholder="Type here"
                  style={{
                    marginTop: "16px",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "15px",
                  }}
                />
              </>
            ) : (
              ""
            )}
          </div>
        ))}
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-start" }}>
        {status === "read" ? (
          ""
        ) : (
          <Button
            onClick={submit}
            style={{
              backgroundColor: "#73589b",
              textTransform: "none",
              color: "white",
              padding: "12px 22px",
              borderRadius: "8px",
              marginLeft: "15px",
              position: "absolute",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontsize: "18px",
              lineHeight: "130%",
              marginBottom: "40px",
              width: "90.53px",
              height: "35.89px",
            }}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default NotCompleted;
