import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper';

const PlanDetailsReviewSlide = () => {
    return (
        <div className='Reviews--Area'>
            <img src="/assets/img/planDetails/ReviewCarouselBG.png" />
            <h6>Because users love us ❤️</h6>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>

                    <div className='swiper--content'>
                        <div className='Desc--Section'>
                            <p>A very useful website for those who require right direction in their lives. I have had a great experience with it and would definitely recommend everyone to work things out here in this website with experienced counselling and help.</p>

                            <div>
                                Marie Poirot<br />
                            </div>
                        </div>
                        <div className='Img--Section'>
                            <img src='/assets/img/planDetails/Review1.png' />

                            <img className='quote' src="/assets/img/planDetails/chat-quote.png" />
                            <svg width="207" height="207" viewBox="0 0 207 207" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M203.849 190.56L190.697 190.56C190.438 190.56 190.227 190.77 190.227 191.03L190.227 204.181C190.227 204.441 190.438 204.651 190.697 204.651L203.849 204.651C204.108 204.651 204.318 204.441 204.318 204.181L204.318 191.03C204.318 190.77 204.108 190.56 203.849 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 152.985L190.697 152.985C190.438 152.985 190.227 153.195 190.227 153.454L190.227 166.606C190.227 166.865 190.438 167.075 190.697 167.075L203.849 167.075C204.108 167.075 204.318 166.865 204.318 166.606L204.318 153.454C204.318 153.195 204.108 152.985 203.849 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 115.409L190.697 115.409C190.438 115.409 190.227 115.619 190.227 115.878L190.227 129.03C190.227 129.289 190.438 129.5 190.697 129.5L203.849 129.5C204.108 129.5 204.318 129.289 204.318 129.03L204.318 115.878C204.318 115.619 204.108 115.409 203.849 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 77.8329L190.697 77.8329C190.438 77.8329 190.227 78.0432 190.227 78.3026L190.227 91.4541C190.227 91.7135 190.438 91.9238 190.697 91.9238L203.849 91.9238C204.108 91.9238 204.318 91.7135 204.318 91.4541L204.318 78.3026C204.318 78.0432 204.108 77.8329 203.849 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 40.2572L190.697 40.2572C190.438 40.2572 190.227 40.4675 190.227 40.7269L190.227 53.8784C190.227 54.1379 190.438 54.3481 190.697 54.3481L203.849 54.3481C204.108 54.3481 204.318 54.1379 204.318 53.8784L204.318 40.7269C204.318 40.4675 204.108 40.2572 203.849 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 2.68143L190.697 2.68143C190.438 2.68143 190.227 2.8917 190.227 3.15113L190.227 16.3026C190.227 16.562 190.438 16.7723 190.697 16.7723L203.849 16.7723C204.108 16.7723 204.318 16.562 204.318 16.3026L204.318 3.15113C204.318 2.8917 204.108 2.68143 203.849 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 190.56L153.121 190.56C152.862 190.56 152.652 190.77 152.652 191.03L152.652 204.181C152.652 204.441 152.862 204.651 153.121 204.651L166.273 204.651C166.532 204.651 166.743 204.441 166.743 204.181L166.743 191.03C166.743 190.77 166.532 190.56 166.273 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 152.985L153.121 152.985C152.862 152.985 152.652 153.195 152.652 153.454L152.652 166.606C152.652 166.865 152.862 167.075 153.121 167.075L166.273 167.075C166.532 167.075 166.743 166.865 166.743 166.606L166.743 153.454C166.743 153.195 166.532 152.985 166.273 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 115.409L153.121 115.409C152.862 115.409 152.652 115.619 152.652 115.878L152.652 129.03C152.652 129.289 152.862 129.5 153.121 129.5L166.273 129.5C166.532 129.5 166.743 129.289 166.743 129.03L166.743 115.878C166.743 115.619 166.532 115.409 166.273 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 77.8329L153.121 77.8329C152.862 77.8329 152.652 78.0432 152.652 78.3026L152.652 91.4541C152.652 91.7135 152.862 91.9238 153.121 91.9238L166.273 91.9238C166.532 91.9238 166.743 91.7135 166.743 91.4541L166.743 78.3026C166.743 78.0432 166.532 77.8329 166.273 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 40.2572L153.121 40.2572C152.862 40.2572 152.652 40.4675 152.652 40.7269L152.652 53.8784C152.652 54.1379 152.862 54.3481 153.121 54.3481L166.273 54.3481C166.532 54.3481 166.743 54.1379 166.743 53.8784L166.743 40.7269C166.743 40.4675 166.532 40.2572 166.273 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 2.68143L153.121 2.68143C152.862 2.68143 152.652 2.8917 152.652 3.15113L152.652 16.3026C152.652 16.562 152.862 16.7723 153.121 16.7723L166.273 16.7723C166.532 16.7723 166.743 16.562 166.743 16.3026L166.743 3.15113C166.743 2.8917 166.532 2.68143 166.273 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 190.56L115.546 190.56C115.286 190.56 115.076 190.77 115.076 191.03L115.076 204.181C115.076 204.441 115.286 204.651 115.546 204.651L128.697 204.651C128.957 204.651 129.167 204.441 129.167 204.181L129.167 191.03C129.167 190.77 128.957 190.56 128.697 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 152.985L115.546 152.985C115.286 152.985 115.076 153.195 115.076 153.454L115.076 166.606C115.076 166.865 115.286 167.075 115.546 167.075L128.697 167.075C128.957 167.075 129.167 166.865 129.167 166.606L129.167 153.454C129.167 153.195 128.957 152.985 128.697 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 115.409L115.546 115.409C115.286 115.409 115.076 115.619 115.076 115.878L115.076 129.03C115.076 129.289 115.286 129.5 115.546 129.5L128.697 129.5C128.957 129.5 129.167 129.289 129.167 129.03L129.167 115.878C129.167 115.619 128.957 115.409 128.697 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 77.8329L115.546 77.8329C115.286 77.8329 115.076 78.0432 115.076 78.3026L115.076 91.4541C115.076 91.7135 115.286 91.9238 115.546 91.9238L128.697 91.9238C128.957 91.9238 129.167 91.7135 129.167 91.4541L129.167 78.3026C129.167 78.0432 128.957 77.8329 128.697 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 40.2572L115.546 40.2572C115.286 40.2572 115.076 40.4675 115.076 40.7269L115.076 53.8784C115.076 54.1379 115.286 54.3481 115.546 54.3481L128.697 54.3481C128.957 54.3481 129.167 54.1379 129.167 53.8784L129.167 40.7269C129.167 40.4675 128.957 40.2572 128.697 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 2.68143L115.546 2.68143C115.286 2.68143 115.076 2.8917 115.076 3.15113L115.076 16.3026C115.076 16.562 115.286 16.7723 115.546 16.7723L128.697 16.7723C128.957 16.7723 129.167 16.562 129.167 16.3026L129.167 3.15113C129.167 2.8917 128.957 2.68143 128.697 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 190.56L77.9701 190.56C77.7107 190.56 77.5004 190.77 77.5004 191.03L77.5004 204.181C77.5004 204.441 77.7107 204.651 77.9701 204.651L91.1216 204.651C91.381 204.651 91.5913 204.441 91.5913 204.181L91.5913 191.03C91.5913 190.77 91.381 190.56 91.1216 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 152.985L77.9701 152.985C77.7107 152.985 77.5004 153.195 77.5004 153.454L77.5004 166.606C77.5004 166.865 77.7107 167.075 77.9701 167.075L91.1216 167.075C91.381 167.075 91.5913 166.865 91.5913 166.606L91.5913 153.454C91.5913 153.195 91.381 152.985 91.1216 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 115.409L77.9701 115.409C77.7107 115.409 77.5004 115.619 77.5004 115.878L77.5004 129.03C77.5004 129.289 77.7107 129.5 77.9701 129.5L91.1216 129.5C91.381 129.5 91.5913 129.289 91.5913 129.03L91.5913 115.878C91.5913 115.619 91.381 115.409 91.1216 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 77.8329L77.9701 77.8329C77.7107 77.8329 77.5004 78.0432 77.5004 78.3026L77.5004 91.4541C77.5004 91.7135 77.7107 91.9238 77.9701 91.9238L91.1216 91.9238C91.381 91.9238 91.5913 91.7135 91.5913 91.4541L91.5913 78.3026C91.5913 78.0432 91.381 77.8329 91.1216 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 40.2572L77.9701 40.2572C77.7107 40.2572 77.5004 40.4675 77.5004 40.7269L77.5004 53.8784C77.5004 54.1379 77.7107 54.3481 77.9701 54.3481L91.1216 54.3481C91.381 54.3481 91.5913 54.1379 91.5913 53.8784L91.5913 40.7269C91.5913 40.4675 91.381 40.2572 91.1216 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 2.68143L77.9701 2.68143C77.7107 2.68143 77.5004 2.8917 77.5004 3.15113L77.5004 16.3026C77.5004 16.562 77.7107 16.7723 77.9701 16.7723L91.1216 16.7723C91.381 16.7723 91.5913 16.562 91.5913 16.3026L91.5913 3.15113C91.5913 2.8917 91.381 2.68143 91.1216 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 190.56L40.3944 190.56C40.135 190.56 39.9247 190.77 39.9247 191.03L39.9247 204.181C39.9247 204.441 40.135 204.651 40.3944 204.651L53.5459 204.651C53.8053 204.651 54.0156 204.441 54.0156 204.181L54.0156 191.03C54.0156 190.77 53.8053 190.56 53.5459 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 152.985L40.3944 152.985C40.135 152.985 39.9247 153.195 39.9247 153.454L39.9247 166.606C39.9247 166.865 40.135 167.075 40.3944 167.075L53.5459 167.075C53.8053 167.075 54.0156 166.865 54.0156 166.606L54.0156 153.454C54.0156 153.195 53.8053 152.985 53.5459 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 115.409L40.3944 115.409C40.135 115.409 39.9247 115.619 39.9247 115.878L39.9247 129.03C39.9247 129.289 40.135 129.5 40.3944 129.5L53.5459 129.5C53.8053 129.5 54.0156 129.289 54.0156 129.03L54.0156 115.878C54.0156 115.619 53.8053 115.409 53.5459 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 77.8329L40.3944 77.8329C40.135 77.8329 39.9247 78.0432 39.9247 78.3026L39.9247 91.4541C39.9247 91.7135 40.135 91.9238 40.3944 91.9238L53.5459 91.9238C53.8053 91.9238 54.0156 91.7135 54.0156 91.4541L54.0156 78.3026C54.0156 78.0432 53.8053 77.8329 53.5459 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 40.2572L40.3944 40.2572C40.135 40.2572 39.9247 40.4675 39.9247 40.7269L39.9247 53.8784C39.9247 54.1379 40.135 54.3481 40.3944 54.3481L53.5459 54.3481C53.8053 54.3481 54.0156 54.1379 54.0156 53.8784L54.0156 40.7269C54.0156 40.4675 53.8053 40.2572 53.5459 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 2.68143L40.3944 2.68143C40.135 2.68143 39.9247 2.8917 39.9247 3.15113L39.9247 16.3026C39.9247 16.562 40.135 16.7723 40.3944 16.7723L53.5459 16.7723C53.8053 16.7723 54.0156 16.562 54.0156 16.3026L54.0156 3.15113C54.0156 2.8917 53.8053 2.68143 53.5459 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 190.56L2.81873 190.56C2.5593 190.56 2.34903 190.77 2.34903 191.03L2.34903 204.181C2.34903 204.441 2.5593 204.651 2.81873 204.651L15.9702 204.651C16.2297 204.651 16.4399 204.441 16.4399 204.181L16.4399 191.03C16.4399 190.77 16.2296 190.56 15.9702 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 152.985L2.81873 152.985C2.5593 152.985 2.34903 153.195 2.34903 153.454L2.34903 166.606C2.34903 166.865 2.5593 167.075 2.81873 167.075L15.9702 167.075C16.2297 167.075 16.4399 166.865 16.4399 166.606L16.4399 153.454C16.4399 153.195 16.2296 152.985 15.9702 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 115.409L2.81873 115.409C2.5593 115.409 2.34903 115.619 2.34903 115.878L2.34903 129.03C2.34903 129.289 2.5593 129.5 2.81873 129.5L15.9702 129.5C16.2297 129.5 16.4399 129.289 16.4399 129.03L16.4399 115.878C16.4399 115.619 16.2296 115.409 15.9702 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 77.8329L2.81873 77.8329C2.5593 77.8329 2.34903 78.0432 2.34903 78.3026L2.34903 91.4541C2.34903 91.7135 2.5593 91.9238 2.81873 91.9238L15.9702 91.9238C16.2297 91.9238 16.4399 91.7135 16.4399 91.4541L16.4399 78.3026C16.4399 78.0432 16.2296 77.8329 15.9702 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 40.2572L2.81873 40.2572C2.5593 40.2572 2.34903 40.4675 2.34903 40.7269L2.34903 53.8784C2.34903 54.1379 2.5593 54.3481 2.81873 54.3481L15.9702 54.3481C16.2297 54.3481 16.4399 54.1379 16.4399 53.8784L16.4399 40.7269C16.4399 40.4675 16.2296 40.2572 15.9702 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 2.68143L2.81873 2.68143C2.5593 2.68143 2.34903 2.8917 2.34903 3.15113L2.34903 16.3026C2.34903 16.562 2.5593 16.7723 2.81873 16.7723L15.9702 16.7723C16.2297 16.7723 16.4399 16.562 16.4399 16.3026L16.4399 3.15113C16.4399 2.8917 16.2296 2.68143 15.9702 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>

                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='swiper--content'>
                        <div className='Desc--Section'>
                            <p>Really user friendly interface and world class content. Really helpful
                                content, I would definitely recommend it to everyone. I experienced no
                                bugs while using this app. Which is a remarkable feat.
                            </p>
                            <div>
                                Ronnie<br />
                            </div>
                        </div>
                        <div className='Img--Section'>
                            <img src='/assets/img/planDetails/unsplash_8BcVHmAHtlw.png' />

                            <img className='quote' src="/assets/img/planDetails/chat-quote.png" />
                            <svg width="207" height="207" viewBox="0 0 207 207" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M203.849 190.56L190.697 190.56C190.438 190.56 190.227 190.77 190.227 191.03L190.227 204.181C190.227 204.441 190.438 204.651 190.697 204.651L203.849 204.651C204.108 204.651 204.318 204.441 204.318 204.181L204.318 191.03C204.318 190.77 204.108 190.56 203.849 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 152.985L190.697 152.985C190.438 152.985 190.227 153.195 190.227 153.454L190.227 166.606C190.227 166.865 190.438 167.075 190.697 167.075L203.849 167.075C204.108 167.075 204.318 166.865 204.318 166.606L204.318 153.454C204.318 153.195 204.108 152.985 203.849 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 115.409L190.697 115.409C190.438 115.409 190.227 115.619 190.227 115.878L190.227 129.03C190.227 129.289 190.438 129.5 190.697 129.5L203.849 129.5C204.108 129.5 204.318 129.289 204.318 129.03L204.318 115.878C204.318 115.619 204.108 115.409 203.849 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 77.8329L190.697 77.8329C190.438 77.8329 190.227 78.0432 190.227 78.3026L190.227 91.4541C190.227 91.7135 190.438 91.9238 190.697 91.9238L203.849 91.9238C204.108 91.9238 204.318 91.7135 204.318 91.4541L204.318 78.3026C204.318 78.0432 204.108 77.8329 203.849 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 40.2572L190.697 40.2572C190.438 40.2572 190.227 40.4675 190.227 40.7269L190.227 53.8784C190.227 54.1379 190.438 54.3481 190.697 54.3481L203.849 54.3481C204.108 54.3481 204.318 54.1379 204.318 53.8784L204.318 40.7269C204.318 40.4675 204.108 40.2572 203.849 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 2.68143L190.697 2.68143C190.438 2.68143 190.227 2.8917 190.227 3.15113L190.227 16.3026C190.227 16.562 190.438 16.7723 190.697 16.7723L203.849 16.7723C204.108 16.7723 204.318 16.562 204.318 16.3026L204.318 3.15113C204.318 2.8917 204.108 2.68143 203.849 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 190.56L153.121 190.56C152.862 190.56 152.652 190.77 152.652 191.03L152.652 204.181C152.652 204.441 152.862 204.651 153.121 204.651L166.273 204.651C166.532 204.651 166.743 204.441 166.743 204.181L166.743 191.03C166.743 190.77 166.532 190.56 166.273 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 152.985L153.121 152.985C152.862 152.985 152.652 153.195 152.652 153.454L152.652 166.606C152.652 166.865 152.862 167.075 153.121 167.075L166.273 167.075C166.532 167.075 166.743 166.865 166.743 166.606L166.743 153.454C166.743 153.195 166.532 152.985 166.273 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 115.409L153.121 115.409C152.862 115.409 152.652 115.619 152.652 115.878L152.652 129.03C152.652 129.289 152.862 129.5 153.121 129.5L166.273 129.5C166.532 129.5 166.743 129.289 166.743 129.03L166.743 115.878C166.743 115.619 166.532 115.409 166.273 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 77.8329L153.121 77.8329C152.862 77.8329 152.652 78.0432 152.652 78.3026L152.652 91.4541C152.652 91.7135 152.862 91.9238 153.121 91.9238L166.273 91.9238C166.532 91.9238 166.743 91.7135 166.743 91.4541L166.743 78.3026C166.743 78.0432 166.532 77.8329 166.273 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 40.2572L153.121 40.2572C152.862 40.2572 152.652 40.4675 152.652 40.7269L152.652 53.8784C152.652 54.1379 152.862 54.3481 153.121 54.3481L166.273 54.3481C166.532 54.3481 166.743 54.1379 166.743 53.8784L166.743 40.7269C166.743 40.4675 166.532 40.2572 166.273 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 2.68143L153.121 2.68143C152.862 2.68143 152.652 2.8917 152.652 3.15113L152.652 16.3026C152.652 16.562 152.862 16.7723 153.121 16.7723L166.273 16.7723C166.532 16.7723 166.743 16.562 166.743 16.3026L166.743 3.15113C166.743 2.8917 166.532 2.68143 166.273 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 190.56L115.546 190.56C115.286 190.56 115.076 190.77 115.076 191.03L115.076 204.181C115.076 204.441 115.286 204.651 115.546 204.651L128.697 204.651C128.957 204.651 129.167 204.441 129.167 204.181L129.167 191.03C129.167 190.77 128.957 190.56 128.697 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 152.985L115.546 152.985C115.286 152.985 115.076 153.195 115.076 153.454L115.076 166.606C115.076 166.865 115.286 167.075 115.546 167.075L128.697 167.075C128.957 167.075 129.167 166.865 129.167 166.606L129.167 153.454C129.167 153.195 128.957 152.985 128.697 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 115.409L115.546 115.409C115.286 115.409 115.076 115.619 115.076 115.878L115.076 129.03C115.076 129.289 115.286 129.5 115.546 129.5L128.697 129.5C128.957 129.5 129.167 129.289 129.167 129.03L129.167 115.878C129.167 115.619 128.957 115.409 128.697 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 77.8329L115.546 77.8329C115.286 77.8329 115.076 78.0432 115.076 78.3026L115.076 91.4541C115.076 91.7135 115.286 91.9238 115.546 91.9238L128.697 91.9238C128.957 91.9238 129.167 91.7135 129.167 91.4541L129.167 78.3026C129.167 78.0432 128.957 77.8329 128.697 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 40.2572L115.546 40.2572C115.286 40.2572 115.076 40.4675 115.076 40.7269L115.076 53.8784C115.076 54.1379 115.286 54.3481 115.546 54.3481L128.697 54.3481C128.957 54.3481 129.167 54.1379 129.167 53.8784L129.167 40.7269C129.167 40.4675 128.957 40.2572 128.697 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 2.68143L115.546 2.68143C115.286 2.68143 115.076 2.8917 115.076 3.15113L115.076 16.3026C115.076 16.562 115.286 16.7723 115.546 16.7723L128.697 16.7723C128.957 16.7723 129.167 16.562 129.167 16.3026L129.167 3.15113C129.167 2.8917 128.957 2.68143 128.697 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 190.56L77.9701 190.56C77.7107 190.56 77.5004 190.77 77.5004 191.03L77.5004 204.181C77.5004 204.441 77.7107 204.651 77.9701 204.651L91.1216 204.651C91.381 204.651 91.5913 204.441 91.5913 204.181L91.5913 191.03C91.5913 190.77 91.381 190.56 91.1216 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 152.985L77.9701 152.985C77.7107 152.985 77.5004 153.195 77.5004 153.454L77.5004 166.606C77.5004 166.865 77.7107 167.075 77.9701 167.075L91.1216 167.075C91.381 167.075 91.5913 166.865 91.5913 166.606L91.5913 153.454C91.5913 153.195 91.381 152.985 91.1216 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 115.409L77.9701 115.409C77.7107 115.409 77.5004 115.619 77.5004 115.878L77.5004 129.03C77.5004 129.289 77.7107 129.5 77.9701 129.5L91.1216 129.5C91.381 129.5 91.5913 129.289 91.5913 129.03L91.5913 115.878C91.5913 115.619 91.381 115.409 91.1216 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 77.8329L77.9701 77.8329C77.7107 77.8329 77.5004 78.0432 77.5004 78.3026L77.5004 91.4541C77.5004 91.7135 77.7107 91.9238 77.9701 91.9238L91.1216 91.9238C91.381 91.9238 91.5913 91.7135 91.5913 91.4541L91.5913 78.3026C91.5913 78.0432 91.381 77.8329 91.1216 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 40.2572L77.9701 40.2572C77.7107 40.2572 77.5004 40.4675 77.5004 40.7269L77.5004 53.8784C77.5004 54.1379 77.7107 54.3481 77.9701 54.3481L91.1216 54.3481C91.381 54.3481 91.5913 54.1379 91.5913 53.8784L91.5913 40.7269C91.5913 40.4675 91.381 40.2572 91.1216 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 2.68143L77.9701 2.68143C77.7107 2.68143 77.5004 2.8917 77.5004 3.15113L77.5004 16.3026C77.5004 16.562 77.7107 16.7723 77.9701 16.7723L91.1216 16.7723C91.381 16.7723 91.5913 16.562 91.5913 16.3026L91.5913 3.15113C91.5913 2.8917 91.381 2.68143 91.1216 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 190.56L40.3944 190.56C40.135 190.56 39.9247 190.77 39.9247 191.03L39.9247 204.181C39.9247 204.441 40.135 204.651 40.3944 204.651L53.5459 204.651C53.8053 204.651 54.0156 204.441 54.0156 204.181L54.0156 191.03C54.0156 190.77 53.8053 190.56 53.5459 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 152.985L40.3944 152.985C40.135 152.985 39.9247 153.195 39.9247 153.454L39.9247 166.606C39.9247 166.865 40.135 167.075 40.3944 167.075L53.5459 167.075C53.8053 167.075 54.0156 166.865 54.0156 166.606L54.0156 153.454C54.0156 153.195 53.8053 152.985 53.5459 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 115.409L40.3944 115.409C40.135 115.409 39.9247 115.619 39.9247 115.878L39.9247 129.03C39.9247 129.289 40.135 129.5 40.3944 129.5L53.5459 129.5C53.8053 129.5 54.0156 129.289 54.0156 129.03L54.0156 115.878C54.0156 115.619 53.8053 115.409 53.5459 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 77.8329L40.3944 77.8329C40.135 77.8329 39.9247 78.0432 39.9247 78.3026L39.9247 91.4541C39.9247 91.7135 40.135 91.9238 40.3944 91.9238L53.5459 91.9238C53.8053 91.9238 54.0156 91.7135 54.0156 91.4541L54.0156 78.3026C54.0156 78.0432 53.8053 77.8329 53.5459 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 40.2572L40.3944 40.2572C40.135 40.2572 39.9247 40.4675 39.9247 40.7269L39.9247 53.8784C39.9247 54.1379 40.135 54.3481 40.3944 54.3481L53.5459 54.3481C53.8053 54.3481 54.0156 54.1379 54.0156 53.8784L54.0156 40.7269C54.0156 40.4675 53.8053 40.2572 53.5459 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 2.68143L40.3944 2.68143C40.135 2.68143 39.9247 2.8917 39.9247 3.15113L39.9247 16.3026C39.9247 16.562 40.135 16.7723 40.3944 16.7723L53.5459 16.7723C53.8053 16.7723 54.0156 16.562 54.0156 16.3026L54.0156 3.15113C54.0156 2.8917 53.8053 2.68143 53.5459 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 190.56L2.81873 190.56C2.5593 190.56 2.34903 190.77 2.34903 191.03L2.34903 204.181C2.34903 204.441 2.5593 204.651 2.81873 204.651L15.9702 204.651C16.2297 204.651 16.4399 204.441 16.4399 204.181L16.4399 191.03C16.4399 190.77 16.2296 190.56 15.9702 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 152.985L2.81873 152.985C2.5593 152.985 2.34903 153.195 2.34903 153.454L2.34903 166.606C2.34903 166.865 2.5593 167.075 2.81873 167.075L15.9702 167.075C16.2297 167.075 16.4399 166.865 16.4399 166.606L16.4399 153.454C16.4399 153.195 16.2296 152.985 15.9702 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 115.409L2.81873 115.409C2.5593 115.409 2.34903 115.619 2.34903 115.878L2.34903 129.03C2.34903 129.289 2.5593 129.5 2.81873 129.5L15.9702 129.5C16.2297 129.5 16.4399 129.289 16.4399 129.03L16.4399 115.878C16.4399 115.619 16.2296 115.409 15.9702 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 77.8329L2.81873 77.8329C2.5593 77.8329 2.34903 78.0432 2.34903 78.3026L2.34903 91.4541C2.34903 91.7135 2.5593 91.9238 2.81873 91.9238L15.9702 91.9238C16.2297 91.9238 16.4399 91.7135 16.4399 91.4541L16.4399 78.3026C16.4399 78.0432 16.2296 77.8329 15.9702 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 40.2572L2.81873 40.2572C2.5593 40.2572 2.34903 40.4675 2.34903 40.7269L2.34903 53.8784C2.34903 54.1379 2.5593 54.3481 2.81873 54.3481L15.9702 54.3481C16.2297 54.3481 16.4399 54.1379 16.4399 53.8784L16.4399 40.7269C16.4399 40.4675 16.2296 40.2572 15.9702 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 2.68143L2.81873 2.68143C2.5593 2.68143 2.34903 2.8917 2.34903 3.15113L2.34903 16.3026C2.34903 16.562 2.5593 16.7723 2.81873 16.7723L15.9702 16.7723C16.2297 16.7723 16.4399 16.562 16.4399 16.3026L16.4399 3.15113C16.4399 2.8917 16.2296 2.68143 15.9702 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>

                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='swiper--content'>
                        <div className='Desc--Section'>
                            <p>It was my first session & after this session i am able to shift my mind
                                or thoughts from my past to present, now m trying to think what i have
                                & how i can move forward gradually. Thanks "Life Coach" for a right
                                direction.
                            </p>

                            <div>
                                Emilie<br />
                            </div>
                        </div>
                        <div className='Img--Section'>
                            <img src='/assets/img/planDetails/unsplash_ChylazdaLkU.png' />

                            <img className='quote' src="/assets/img/planDetails/chat-quote.png" />
                            <svg width="207" height="207" viewBox="0 0 207 207" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M203.849 190.56L190.697 190.56C190.438 190.56 190.227 190.77 190.227 191.03L190.227 204.181C190.227 204.441 190.438 204.651 190.697 204.651L203.849 204.651C204.108 204.651 204.318 204.441 204.318 204.181L204.318 191.03C204.318 190.77 204.108 190.56 203.849 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 152.985L190.697 152.985C190.438 152.985 190.227 153.195 190.227 153.454L190.227 166.606C190.227 166.865 190.438 167.075 190.697 167.075L203.849 167.075C204.108 167.075 204.318 166.865 204.318 166.606L204.318 153.454C204.318 153.195 204.108 152.985 203.849 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 115.409L190.697 115.409C190.438 115.409 190.227 115.619 190.227 115.878L190.227 129.03C190.227 129.289 190.438 129.5 190.697 129.5L203.849 129.5C204.108 129.5 204.318 129.289 204.318 129.03L204.318 115.878C204.318 115.619 204.108 115.409 203.849 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 77.8329L190.697 77.8329C190.438 77.8329 190.227 78.0432 190.227 78.3026L190.227 91.4541C190.227 91.7135 190.438 91.9238 190.697 91.9238L203.849 91.9238C204.108 91.9238 204.318 91.7135 204.318 91.4541L204.318 78.3026C204.318 78.0432 204.108 77.8329 203.849 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 40.2572L190.697 40.2572C190.438 40.2572 190.227 40.4675 190.227 40.7269L190.227 53.8784C190.227 54.1379 190.438 54.3481 190.697 54.3481L203.849 54.3481C204.108 54.3481 204.318 54.1379 204.318 53.8784L204.318 40.7269C204.318 40.4675 204.108 40.2572 203.849 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 2.68143L190.697 2.68143C190.438 2.68143 190.227 2.8917 190.227 3.15113L190.227 16.3026C190.227 16.562 190.438 16.7723 190.697 16.7723L203.849 16.7723C204.108 16.7723 204.318 16.562 204.318 16.3026L204.318 3.15113C204.318 2.8917 204.108 2.68143 203.849 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 190.56L153.121 190.56C152.862 190.56 152.652 190.77 152.652 191.03L152.652 204.181C152.652 204.441 152.862 204.651 153.121 204.651L166.273 204.651C166.532 204.651 166.743 204.441 166.743 204.181L166.743 191.03C166.743 190.77 166.532 190.56 166.273 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 152.985L153.121 152.985C152.862 152.985 152.652 153.195 152.652 153.454L152.652 166.606C152.652 166.865 152.862 167.075 153.121 167.075L166.273 167.075C166.532 167.075 166.743 166.865 166.743 166.606L166.743 153.454C166.743 153.195 166.532 152.985 166.273 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 115.409L153.121 115.409C152.862 115.409 152.652 115.619 152.652 115.878L152.652 129.03C152.652 129.289 152.862 129.5 153.121 129.5L166.273 129.5C166.532 129.5 166.743 129.289 166.743 129.03L166.743 115.878C166.743 115.619 166.532 115.409 166.273 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 77.8329L153.121 77.8329C152.862 77.8329 152.652 78.0432 152.652 78.3026L152.652 91.4541C152.652 91.7135 152.862 91.9238 153.121 91.9238L166.273 91.9238C166.532 91.9238 166.743 91.7135 166.743 91.4541L166.743 78.3026C166.743 78.0432 166.532 77.8329 166.273 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 40.2572L153.121 40.2572C152.862 40.2572 152.652 40.4675 152.652 40.7269L152.652 53.8784C152.652 54.1379 152.862 54.3481 153.121 54.3481L166.273 54.3481C166.532 54.3481 166.743 54.1379 166.743 53.8784L166.743 40.7269C166.743 40.4675 166.532 40.2572 166.273 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 2.68143L153.121 2.68143C152.862 2.68143 152.652 2.8917 152.652 3.15113L152.652 16.3026C152.652 16.562 152.862 16.7723 153.121 16.7723L166.273 16.7723C166.532 16.7723 166.743 16.562 166.743 16.3026L166.743 3.15113C166.743 2.8917 166.532 2.68143 166.273 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 190.56L115.546 190.56C115.286 190.56 115.076 190.77 115.076 191.03L115.076 204.181C115.076 204.441 115.286 204.651 115.546 204.651L128.697 204.651C128.957 204.651 129.167 204.441 129.167 204.181L129.167 191.03C129.167 190.77 128.957 190.56 128.697 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 152.985L115.546 152.985C115.286 152.985 115.076 153.195 115.076 153.454L115.076 166.606C115.076 166.865 115.286 167.075 115.546 167.075L128.697 167.075C128.957 167.075 129.167 166.865 129.167 166.606L129.167 153.454C129.167 153.195 128.957 152.985 128.697 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 115.409L115.546 115.409C115.286 115.409 115.076 115.619 115.076 115.878L115.076 129.03C115.076 129.289 115.286 129.5 115.546 129.5L128.697 129.5C128.957 129.5 129.167 129.289 129.167 129.03L129.167 115.878C129.167 115.619 128.957 115.409 128.697 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 77.8329L115.546 77.8329C115.286 77.8329 115.076 78.0432 115.076 78.3026L115.076 91.4541C115.076 91.7135 115.286 91.9238 115.546 91.9238L128.697 91.9238C128.957 91.9238 129.167 91.7135 129.167 91.4541L129.167 78.3026C129.167 78.0432 128.957 77.8329 128.697 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 40.2572L115.546 40.2572C115.286 40.2572 115.076 40.4675 115.076 40.7269L115.076 53.8784C115.076 54.1379 115.286 54.3481 115.546 54.3481L128.697 54.3481C128.957 54.3481 129.167 54.1379 129.167 53.8784L129.167 40.7269C129.167 40.4675 128.957 40.2572 128.697 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 2.68143L115.546 2.68143C115.286 2.68143 115.076 2.8917 115.076 3.15113L115.076 16.3026C115.076 16.562 115.286 16.7723 115.546 16.7723L128.697 16.7723C128.957 16.7723 129.167 16.562 129.167 16.3026L129.167 3.15113C129.167 2.8917 128.957 2.68143 128.697 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 190.56L77.9701 190.56C77.7107 190.56 77.5004 190.77 77.5004 191.03L77.5004 204.181C77.5004 204.441 77.7107 204.651 77.9701 204.651L91.1216 204.651C91.381 204.651 91.5913 204.441 91.5913 204.181L91.5913 191.03C91.5913 190.77 91.381 190.56 91.1216 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 152.985L77.9701 152.985C77.7107 152.985 77.5004 153.195 77.5004 153.454L77.5004 166.606C77.5004 166.865 77.7107 167.075 77.9701 167.075L91.1216 167.075C91.381 167.075 91.5913 166.865 91.5913 166.606L91.5913 153.454C91.5913 153.195 91.381 152.985 91.1216 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 115.409L77.9701 115.409C77.7107 115.409 77.5004 115.619 77.5004 115.878L77.5004 129.03C77.5004 129.289 77.7107 129.5 77.9701 129.5L91.1216 129.5C91.381 129.5 91.5913 129.289 91.5913 129.03L91.5913 115.878C91.5913 115.619 91.381 115.409 91.1216 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 77.8329L77.9701 77.8329C77.7107 77.8329 77.5004 78.0432 77.5004 78.3026L77.5004 91.4541C77.5004 91.7135 77.7107 91.9238 77.9701 91.9238L91.1216 91.9238C91.381 91.9238 91.5913 91.7135 91.5913 91.4541L91.5913 78.3026C91.5913 78.0432 91.381 77.8329 91.1216 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 40.2572L77.9701 40.2572C77.7107 40.2572 77.5004 40.4675 77.5004 40.7269L77.5004 53.8784C77.5004 54.1379 77.7107 54.3481 77.9701 54.3481L91.1216 54.3481C91.381 54.3481 91.5913 54.1379 91.5913 53.8784L91.5913 40.7269C91.5913 40.4675 91.381 40.2572 91.1216 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 2.68143L77.9701 2.68143C77.7107 2.68143 77.5004 2.8917 77.5004 3.15113L77.5004 16.3026C77.5004 16.562 77.7107 16.7723 77.9701 16.7723L91.1216 16.7723C91.381 16.7723 91.5913 16.562 91.5913 16.3026L91.5913 3.15113C91.5913 2.8917 91.381 2.68143 91.1216 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 190.56L40.3944 190.56C40.135 190.56 39.9247 190.77 39.9247 191.03L39.9247 204.181C39.9247 204.441 40.135 204.651 40.3944 204.651L53.5459 204.651C53.8053 204.651 54.0156 204.441 54.0156 204.181L54.0156 191.03C54.0156 190.77 53.8053 190.56 53.5459 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 152.985L40.3944 152.985C40.135 152.985 39.9247 153.195 39.9247 153.454L39.9247 166.606C39.9247 166.865 40.135 167.075 40.3944 167.075L53.5459 167.075C53.8053 167.075 54.0156 166.865 54.0156 166.606L54.0156 153.454C54.0156 153.195 53.8053 152.985 53.5459 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 115.409L40.3944 115.409C40.135 115.409 39.9247 115.619 39.9247 115.878L39.9247 129.03C39.9247 129.289 40.135 129.5 40.3944 129.5L53.5459 129.5C53.8053 129.5 54.0156 129.289 54.0156 129.03L54.0156 115.878C54.0156 115.619 53.8053 115.409 53.5459 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 77.8329L40.3944 77.8329C40.135 77.8329 39.9247 78.0432 39.9247 78.3026L39.9247 91.4541C39.9247 91.7135 40.135 91.9238 40.3944 91.9238L53.5459 91.9238C53.8053 91.9238 54.0156 91.7135 54.0156 91.4541L54.0156 78.3026C54.0156 78.0432 53.8053 77.8329 53.5459 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 40.2572L40.3944 40.2572C40.135 40.2572 39.9247 40.4675 39.9247 40.7269L39.9247 53.8784C39.9247 54.1379 40.135 54.3481 40.3944 54.3481L53.5459 54.3481C53.8053 54.3481 54.0156 54.1379 54.0156 53.8784L54.0156 40.7269C54.0156 40.4675 53.8053 40.2572 53.5459 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 2.68143L40.3944 2.68143C40.135 2.68143 39.9247 2.8917 39.9247 3.15113L39.9247 16.3026C39.9247 16.562 40.135 16.7723 40.3944 16.7723L53.5459 16.7723C53.8053 16.7723 54.0156 16.562 54.0156 16.3026L54.0156 3.15113C54.0156 2.8917 53.8053 2.68143 53.5459 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 190.56L2.81873 190.56C2.5593 190.56 2.34903 190.77 2.34903 191.03L2.34903 204.181C2.34903 204.441 2.5593 204.651 2.81873 204.651L15.9702 204.651C16.2297 204.651 16.4399 204.441 16.4399 204.181L16.4399 191.03C16.4399 190.77 16.2296 190.56 15.9702 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 152.985L2.81873 152.985C2.5593 152.985 2.34903 153.195 2.34903 153.454L2.34903 166.606C2.34903 166.865 2.5593 167.075 2.81873 167.075L15.9702 167.075C16.2297 167.075 16.4399 166.865 16.4399 166.606L16.4399 153.454C16.4399 153.195 16.2296 152.985 15.9702 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 115.409L2.81873 115.409C2.5593 115.409 2.34903 115.619 2.34903 115.878L2.34903 129.03C2.34903 129.289 2.5593 129.5 2.81873 129.5L15.9702 129.5C16.2297 129.5 16.4399 129.289 16.4399 129.03L16.4399 115.878C16.4399 115.619 16.2296 115.409 15.9702 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 77.8329L2.81873 77.8329C2.5593 77.8329 2.34903 78.0432 2.34903 78.3026L2.34903 91.4541C2.34903 91.7135 2.5593 91.9238 2.81873 91.9238L15.9702 91.9238C16.2297 91.9238 16.4399 91.7135 16.4399 91.4541L16.4399 78.3026C16.4399 78.0432 16.2296 77.8329 15.9702 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 40.2572L2.81873 40.2572C2.5593 40.2572 2.34903 40.4675 2.34903 40.7269L2.34903 53.8784C2.34903 54.1379 2.5593 54.3481 2.81873 54.3481L15.9702 54.3481C16.2297 54.3481 16.4399 54.1379 16.4399 53.8784L16.4399 40.7269C16.4399 40.4675 16.2296 40.2572 15.9702 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 2.68143L2.81873 2.68143C2.5593 2.68143 2.34903 2.8917 2.34903 3.15113L2.34903 16.3026C2.34903 16.562 2.5593 16.7723 2.81873 16.7723L15.9702 16.7723C16.2297 16.7723 16.4399 16.562 16.4399 16.3026L16.4399 3.15113C16.4399 2.8917 16.2296 2.68143 15.9702 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>

                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='swiper--content'>
                        <div className='Desc--Section'>
                            <p>
                                A very well made app. Easy interface and fulfills all my requirements.
                                Great therapists and counselors and an amazing initiative.
                            </p>

                            <div>
                                John Mathew<br />
                            </div>
                        </div>
                        <div className='Img--Section'>
                            <img src='/assets/img/planDetails/unsplash_FBRRH5jfkAg.png' />

                            <img className='quote' src="/assets/img/planDetails/chat-quote.png" />
                            <svg width="207" height="207" viewBox="0 0 207 207" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M203.849 190.56L190.697 190.56C190.438 190.56 190.227 190.77 190.227 191.03L190.227 204.181C190.227 204.441 190.438 204.651 190.697 204.651L203.849 204.651C204.108 204.651 204.318 204.441 204.318 204.181L204.318 191.03C204.318 190.77 204.108 190.56 203.849 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 152.985L190.697 152.985C190.438 152.985 190.227 153.195 190.227 153.454L190.227 166.606C190.227 166.865 190.438 167.075 190.697 167.075L203.849 167.075C204.108 167.075 204.318 166.865 204.318 166.606L204.318 153.454C204.318 153.195 204.108 152.985 203.849 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 115.409L190.697 115.409C190.438 115.409 190.227 115.619 190.227 115.878L190.227 129.03C190.227 129.289 190.438 129.5 190.697 129.5L203.849 129.5C204.108 129.5 204.318 129.289 204.318 129.03L204.318 115.878C204.318 115.619 204.108 115.409 203.849 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 77.8329L190.697 77.8329C190.438 77.8329 190.227 78.0432 190.227 78.3026L190.227 91.4541C190.227 91.7135 190.438 91.9238 190.697 91.9238L203.849 91.9238C204.108 91.9238 204.318 91.7135 204.318 91.4541L204.318 78.3026C204.318 78.0432 204.108 77.8329 203.849 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 40.2572L190.697 40.2572C190.438 40.2572 190.227 40.4675 190.227 40.7269L190.227 53.8784C190.227 54.1379 190.438 54.3481 190.697 54.3481L203.849 54.3481C204.108 54.3481 204.318 54.1379 204.318 53.8784L204.318 40.7269C204.318 40.4675 204.108 40.2572 203.849 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 2.68143L190.697 2.68143C190.438 2.68143 190.227 2.8917 190.227 3.15113L190.227 16.3026C190.227 16.562 190.438 16.7723 190.697 16.7723L203.849 16.7723C204.108 16.7723 204.318 16.562 204.318 16.3026L204.318 3.15113C204.318 2.8917 204.108 2.68143 203.849 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 190.56L153.121 190.56C152.862 190.56 152.652 190.77 152.652 191.03L152.652 204.181C152.652 204.441 152.862 204.651 153.121 204.651L166.273 204.651C166.532 204.651 166.743 204.441 166.743 204.181L166.743 191.03C166.743 190.77 166.532 190.56 166.273 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 152.985L153.121 152.985C152.862 152.985 152.652 153.195 152.652 153.454L152.652 166.606C152.652 166.865 152.862 167.075 153.121 167.075L166.273 167.075C166.532 167.075 166.743 166.865 166.743 166.606L166.743 153.454C166.743 153.195 166.532 152.985 166.273 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 115.409L153.121 115.409C152.862 115.409 152.652 115.619 152.652 115.878L152.652 129.03C152.652 129.289 152.862 129.5 153.121 129.5L166.273 129.5C166.532 129.5 166.743 129.289 166.743 129.03L166.743 115.878C166.743 115.619 166.532 115.409 166.273 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 77.8329L153.121 77.8329C152.862 77.8329 152.652 78.0432 152.652 78.3026L152.652 91.4541C152.652 91.7135 152.862 91.9238 153.121 91.9238L166.273 91.9238C166.532 91.9238 166.743 91.7135 166.743 91.4541L166.743 78.3026C166.743 78.0432 166.532 77.8329 166.273 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 40.2572L153.121 40.2572C152.862 40.2572 152.652 40.4675 152.652 40.7269L152.652 53.8784C152.652 54.1379 152.862 54.3481 153.121 54.3481L166.273 54.3481C166.532 54.3481 166.743 54.1379 166.743 53.8784L166.743 40.7269C166.743 40.4675 166.532 40.2572 166.273 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 2.68143L153.121 2.68143C152.862 2.68143 152.652 2.8917 152.652 3.15113L152.652 16.3026C152.652 16.562 152.862 16.7723 153.121 16.7723L166.273 16.7723C166.532 16.7723 166.743 16.562 166.743 16.3026L166.743 3.15113C166.743 2.8917 166.532 2.68143 166.273 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 190.56L115.546 190.56C115.286 190.56 115.076 190.77 115.076 191.03L115.076 204.181C115.076 204.441 115.286 204.651 115.546 204.651L128.697 204.651C128.957 204.651 129.167 204.441 129.167 204.181L129.167 191.03C129.167 190.77 128.957 190.56 128.697 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 152.985L115.546 152.985C115.286 152.985 115.076 153.195 115.076 153.454L115.076 166.606C115.076 166.865 115.286 167.075 115.546 167.075L128.697 167.075C128.957 167.075 129.167 166.865 129.167 166.606L129.167 153.454C129.167 153.195 128.957 152.985 128.697 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 115.409L115.546 115.409C115.286 115.409 115.076 115.619 115.076 115.878L115.076 129.03C115.076 129.289 115.286 129.5 115.546 129.5L128.697 129.5C128.957 129.5 129.167 129.289 129.167 129.03L129.167 115.878C129.167 115.619 128.957 115.409 128.697 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 77.8329L115.546 77.8329C115.286 77.8329 115.076 78.0432 115.076 78.3026L115.076 91.4541C115.076 91.7135 115.286 91.9238 115.546 91.9238L128.697 91.9238C128.957 91.9238 129.167 91.7135 129.167 91.4541L129.167 78.3026C129.167 78.0432 128.957 77.8329 128.697 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 40.2572L115.546 40.2572C115.286 40.2572 115.076 40.4675 115.076 40.7269L115.076 53.8784C115.076 54.1379 115.286 54.3481 115.546 54.3481L128.697 54.3481C128.957 54.3481 129.167 54.1379 129.167 53.8784L129.167 40.7269C129.167 40.4675 128.957 40.2572 128.697 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 2.68143L115.546 2.68143C115.286 2.68143 115.076 2.8917 115.076 3.15113L115.076 16.3026C115.076 16.562 115.286 16.7723 115.546 16.7723L128.697 16.7723C128.957 16.7723 129.167 16.562 129.167 16.3026L129.167 3.15113C129.167 2.8917 128.957 2.68143 128.697 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 190.56L77.9701 190.56C77.7107 190.56 77.5004 190.77 77.5004 191.03L77.5004 204.181C77.5004 204.441 77.7107 204.651 77.9701 204.651L91.1216 204.651C91.381 204.651 91.5913 204.441 91.5913 204.181L91.5913 191.03C91.5913 190.77 91.381 190.56 91.1216 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 152.985L77.9701 152.985C77.7107 152.985 77.5004 153.195 77.5004 153.454L77.5004 166.606C77.5004 166.865 77.7107 167.075 77.9701 167.075L91.1216 167.075C91.381 167.075 91.5913 166.865 91.5913 166.606L91.5913 153.454C91.5913 153.195 91.381 152.985 91.1216 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 115.409L77.9701 115.409C77.7107 115.409 77.5004 115.619 77.5004 115.878L77.5004 129.03C77.5004 129.289 77.7107 129.5 77.9701 129.5L91.1216 129.5C91.381 129.5 91.5913 129.289 91.5913 129.03L91.5913 115.878C91.5913 115.619 91.381 115.409 91.1216 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 77.8329L77.9701 77.8329C77.7107 77.8329 77.5004 78.0432 77.5004 78.3026L77.5004 91.4541C77.5004 91.7135 77.7107 91.9238 77.9701 91.9238L91.1216 91.9238C91.381 91.9238 91.5913 91.7135 91.5913 91.4541L91.5913 78.3026C91.5913 78.0432 91.381 77.8329 91.1216 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 40.2572L77.9701 40.2572C77.7107 40.2572 77.5004 40.4675 77.5004 40.7269L77.5004 53.8784C77.5004 54.1379 77.7107 54.3481 77.9701 54.3481L91.1216 54.3481C91.381 54.3481 91.5913 54.1379 91.5913 53.8784L91.5913 40.7269C91.5913 40.4675 91.381 40.2572 91.1216 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 2.68143L77.9701 2.68143C77.7107 2.68143 77.5004 2.8917 77.5004 3.15113L77.5004 16.3026C77.5004 16.562 77.7107 16.7723 77.9701 16.7723L91.1216 16.7723C91.381 16.7723 91.5913 16.562 91.5913 16.3026L91.5913 3.15113C91.5913 2.8917 91.381 2.68143 91.1216 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 190.56L40.3944 190.56C40.135 190.56 39.9247 190.77 39.9247 191.03L39.9247 204.181C39.9247 204.441 40.135 204.651 40.3944 204.651L53.5459 204.651C53.8053 204.651 54.0156 204.441 54.0156 204.181L54.0156 191.03C54.0156 190.77 53.8053 190.56 53.5459 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 152.985L40.3944 152.985C40.135 152.985 39.9247 153.195 39.9247 153.454L39.9247 166.606C39.9247 166.865 40.135 167.075 40.3944 167.075L53.5459 167.075C53.8053 167.075 54.0156 166.865 54.0156 166.606L54.0156 153.454C54.0156 153.195 53.8053 152.985 53.5459 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 115.409L40.3944 115.409C40.135 115.409 39.9247 115.619 39.9247 115.878L39.9247 129.03C39.9247 129.289 40.135 129.5 40.3944 129.5L53.5459 129.5C53.8053 129.5 54.0156 129.289 54.0156 129.03L54.0156 115.878C54.0156 115.619 53.8053 115.409 53.5459 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 77.8329L40.3944 77.8329C40.135 77.8329 39.9247 78.0432 39.9247 78.3026L39.9247 91.4541C39.9247 91.7135 40.135 91.9238 40.3944 91.9238L53.5459 91.9238C53.8053 91.9238 54.0156 91.7135 54.0156 91.4541L54.0156 78.3026C54.0156 78.0432 53.8053 77.8329 53.5459 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 40.2572L40.3944 40.2572C40.135 40.2572 39.9247 40.4675 39.9247 40.7269L39.9247 53.8784C39.9247 54.1379 40.135 54.3481 40.3944 54.3481L53.5459 54.3481C53.8053 54.3481 54.0156 54.1379 54.0156 53.8784L54.0156 40.7269C54.0156 40.4675 53.8053 40.2572 53.5459 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 2.68143L40.3944 2.68143C40.135 2.68143 39.9247 2.8917 39.9247 3.15113L39.9247 16.3026C39.9247 16.562 40.135 16.7723 40.3944 16.7723L53.5459 16.7723C53.8053 16.7723 54.0156 16.562 54.0156 16.3026L54.0156 3.15113C54.0156 2.8917 53.8053 2.68143 53.5459 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 190.56L2.81873 190.56C2.5593 190.56 2.34903 190.77 2.34903 191.03L2.34903 204.181C2.34903 204.441 2.5593 204.651 2.81873 204.651L15.9702 204.651C16.2297 204.651 16.4399 204.441 16.4399 204.181L16.4399 191.03C16.4399 190.77 16.2296 190.56 15.9702 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 152.985L2.81873 152.985C2.5593 152.985 2.34903 153.195 2.34903 153.454L2.34903 166.606C2.34903 166.865 2.5593 167.075 2.81873 167.075L15.9702 167.075C16.2297 167.075 16.4399 166.865 16.4399 166.606L16.4399 153.454C16.4399 153.195 16.2296 152.985 15.9702 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 115.409L2.81873 115.409C2.5593 115.409 2.34903 115.619 2.34903 115.878L2.34903 129.03C2.34903 129.289 2.5593 129.5 2.81873 129.5L15.9702 129.5C16.2297 129.5 16.4399 129.289 16.4399 129.03L16.4399 115.878C16.4399 115.619 16.2296 115.409 15.9702 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 77.8329L2.81873 77.8329C2.5593 77.8329 2.34903 78.0432 2.34903 78.3026L2.34903 91.4541C2.34903 91.7135 2.5593 91.9238 2.81873 91.9238L15.9702 91.9238C16.2297 91.9238 16.4399 91.7135 16.4399 91.4541L16.4399 78.3026C16.4399 78.0432 16.2296 77.8329 15.9702 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 40.2572L2.81873 40.2572C2.5593 40.2572 2.34903 40.4675 2.34903 40.7269L2.34903 53.8784C2.34903 54.1379 2.5593 54.3481 2.81873 54.3481L15.9702 54.3481C16.2297 54.3481 16.4399 54.1379 16.4399 53.8784L16.4399 40.7269C16.4399 40.4675 16.2296 40.2572 15.9702 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 2.68143L2.81873 2.68143C2.5593 2.68143 2.34903 2.8917 2.34903 3.15113L2.34903 16.3026C2.34903 16.562 2.5593 16.7723 2.81873 16.7723L15.9702 16.7723C16.2297 16.7723 16.4399 16.562 16.4399 16.3026L16.4399 3.15113C16.4399 2.8917 16.2296 2.68143 15.9702 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>

                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='swiper--content'>
                        <div className='Desc--Section'>
                            <p>
                                What a brilliant app that lets you meet coaches at the convenience of a
                                click. The choice of coaches is good, experienced and class apart.
                                Highly recommended.
                            </p>

                            <div>
                                Tanya Sachdeva<br />
                            </div>
                        </div>
                        <div className='Img--Section'>
                            <img src='/assets/img/planDetails/unsplash_cOUtDKBKD2g.png' />

                            <img className='quote' src="/assets/img/planDetails/chat-quote.png" />
                            <svg width="207" height="207" viewBox="0 0 207 207" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M203.849 190.56L190.697 190.56C190.438 190.56 190.227 190.77 190.227 191.03L190.227 204.181C190.227 204.441 190.438 204.651 190.697 204.651L203.849 204.651C204.108 204.651 204.318 204.441 204.318 204.181L204.318 191.03C204.318 190.77 204.108 190.56 203.849 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 152.985L190.697 152.985C190.438 152.985 190.227 153.195 190.227 153.454L190.227 166.606C190.227 166.865 190.438 167.075 190.697 167.075L203.849 167.075C204.108 167.075 204.318 166.865 204.318 166.606L204.318 153.454C204.318 153.195 204.108 152.985 203.849 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 115.409L190.697 115.409C190.438 115.409 190.227 115.619 190.227 115.878L190.227 129.03C190.227 129.289 190.438 129.5 190.697 129.5L203.849 129.5C204.108 129.5 204.318 129.289 204.318 129.03L204.318 115.878C204.318 115.619 204.108 115.409 203.849 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 77.8329L190.697 77.8329C190.438 77.8329 190.227 78.0432 190.227 78.3026L190.227 91.4541C190.227 91.7135 190.438 91.9238 190.697 91.9238L203.849 91.9238C204.108 91.9238 204.318 91.7135 204.318 91.4541L204.318 78.3026C204.318 78.0432 204.108 77.8329 203.849 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 40.2572L190.697 40.2572C190.438 40.2572 190.227 40.4675 190.227 40.7269L190.227 53.8784C190.227 54.1379 190.438 54.3481 190.697 54.3481L203.849 54.3481C204.108 54.3481 204.318 54.1379 204.318 53.8784L204.318 40.7269C204.318 40.4675 204.108 40.2572 203.849 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 2.68143L190.697 2.68143C190.438 2.68143 190.227 2.8917 190.227 3.15113L190.227 16.3026C190.227 16.562 190.438 16.7723 190.697 16.7723L203.849 16.7723C204.108 16.7723 204.318 16.562 204.318 16.3026L204.318 3.15113C204.318 2.8917 204.108 2.68143 203.849 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 190.56L153.121 190.56C152.862 190.56 152.652 190.77 152.652 191.03L152.652 204.181C152.652 204.441 152.862 204.651 153.121 204.651L166.273 204.651C166.532 204.651 166.743 204.441 166.743 204.181L166.743 191.03C166.743 190.77 166.532 190.56 166.273 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 152.985L153.121 152.985C152.862 152.985 152.652 153.195 152.652 153.454L152.652 166.606C152.652 166.865 152.862 167.075 153.121 167.075L166.273 167.075C166.532 167.075 166.743 166.865 166.743 166.606L166.743 153.454C166.743 153.195 166.532 152.985 166.273 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 115.409L153.121 115.409C152.862 115.409 152.652 115.619 152.652 115.878L152.652 129.03C152.652 129.289 152.862 129.5 153.121 129.5L166.273 129.5C166.532 129.5 166.743 129.289 166.743 129.03L166.743 115.878C166.743 115.619 166.532 115.409 166.273 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 77.8329L153.121 77.8329C152.862 77.8329 152.652 78.0432 152.652 78.3026L152.652 91.4541C152.652 91.7135 152.862 91.9238 153.121 91.9238L166.273 91.9238C166.532 91.9238 166.743 91.7135 166.743 91.4541L166.743 78.3026C166.743 78.0432 166.532 77.8329 166.273 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 40.2572L153.121 40.2572C152.862 40.2572 152.652 40.4675 152.652 40.7269L152.652 53.8784C152.652 54.1379 152.862 54.3481 153.121 54.3481L166.273 54.3481C166.532 54.3481 166.743 54.1379 166.743 53.8784L166.743 40.7269C166.743 40.4675 166.532 40.2572 166.273 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 2.68143L153.121 2.68143C152.862 2.68143 152.652 2.8917 152.652 3.15113L152.652 16.3026C152.652 16.562 152.862 16.7723 153.121 16.7723L166.273 16.7723C166.532 16.7723 166.743 16.562 166.743 16.3026L166.743 3.15113C166.743 2.8917 166.532 2.68143 166.273 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 190.56L115.546 190.56C115.286 190.56 115.076 190.77 115.076 191.03L115.076 204.181C115.076 204.441 115.286 204.651 115.546 204.651L128.697 204.651C128.957 204.651 129.167 204.441 129.167 204.181L129.167 191.03C129.167 190.77 128.957 190.56 128.697 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 152.985L115.546 152.985C115.286 152.985 115.076 153.195 115.076 153.454L115.076 166.606C115.076 166.865 115.286 167.075 115.546 167.075L128.697 167.075C128.957 167.075 129.167 166.865 129.167 166.606L129.167 153.454C129.167 153.195 128.957 152.985 128.697 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 115.409L115.546 115.409C115.286 115.409 115.076 115.619 115.076 115.878L115.076 129.03C115.076 129.289 115.286 129.5 115.546 129.5L128.697 129.5C128.957 129.5 129.167 129.289 129.167 129.03L129.167 115.878C129.167 115.619 128.957 115.409 128.697 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 77.8329L115.546 77.8329C115.286 77.8329 115.076 78.0432 115.076 78.3026L115.076 91.4541C115.076 91.7135 115.286 91.9238 115.546 91.9238L128.697 91.9238C128.957 91.9238 129.167 91.7135 129.167 91.4541L129.167 78.3026C129.167 78.0432 128.957 77.8329 128.697 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 40.2572L115.546 40.2572C115.286 40.2572 115.076 40.4675 115.076 40.7269L115.076 53.8784C115.076 54.1379 115.286 54.3481 115.546 54.3481L128.697 54.3481C128.957 54.3481 129.167 54.1379 129.167 53.8784L129.167 40.7269C129.167 40.4675 128.957 40.2572 128.697 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 2.68143L115.546 2.68143C115.286 2.68143 115.076 2.8917 115.076 3.15113L115.076 16.3026C115.076 16.562 115.286 16.7723 115.546 16.7723L128.697 16.7723C128.957 16.7723 129.167 16.562 129.167 16.3026L129.167 3.15113C129.167 2.8917 128.957 2.68143 128.697 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 190.56L77.9701 190.56C77.7107 190.56 77.5004 190.77 77.5004 191.03L77.5004 204.181C77.5004 204.441 77.7107 204.651 77.9701 204.651L91.1216 204.651C91.381 204.651 91.5913 204.441 91.5913 204.181L91.5913 191.03C91.5913 190.77 91.381 190.56 91.1216 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 152.985L77.9701 152.985C77.7107 152.985 77.5004 153.195 77.5004 153.454L77.5004 166.606C77.5004 166.865 77.7107 167.075 77.9701 167.075L91.1216 167.075C91.381 167.075 91.5913 166.865 91.5913 166.606L91.5913 153.454C91.5913 153.195 91.381 152.985 91.1216 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 115.409L77.9701 115.409C77.7107 115.409 77.5004 115.619 77.5004 115.878L77.5004 129.03C77.5004 129.289 77.7107 129.5 77.9701 129.5L91.1216 129.5C91.381 129.5 91.5913 129.289 91.5913 129.03L91.5913 115.878C91.5913 115.619 91.381 115.409 91.1216 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 77.8329L77.9701 77.8329C77.7107 77.8329 77.5004 78.0432 77.5004 78.3026L77.5004 91.4541C77.5004 91.7135 77.7107 91.9238 77.9701 91.9238L91.1216 91.9238C91.381 91.9238 91.5913 91.7135 91.5913 91.4541L91.5913 78.3026C91.5913 78.0432 91.381 77.8329 91.1216 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 40.2572L77.9701 40.2572C77.7107 40.2572 77.5004 40.4675 77.5004 40.7269L77.5004 53.8784C77.5004 54.1379 77.7107 54.3481 77.9701 54.3481L91.1216 54.3481C91.381 54.3481 91.5913 54.1379 91.5913 53.8784L91.5913 40.7269C91.5913 40.4675 91.381 40.2572 91.1216 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 2.68143L77.9701 2.68143C77.7107 2.68143 77.5004 2.8917 77.5004 3.15113L77.5004 16.3026C77.5004 16.562 77.7107 16.7723 77.9701 16.7723L91.1216 16.7723C91.381 16.7723 91.5913 16.562 91.5913 16.3026L91.5913 3.15113C91.5913 2.8917 91.381 2.68143 91.1216 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 190.56L40.3944 190.56C40.135 190.56 39.9247 190.77 39.9247 191.03L39.9247 204.181C39.9247 204.441 40.135 204.651 40.3944 204.651L53.5459 204.651C53.8053 204.651 54.0156 204.441 54.0156 204.181L54.0156 191.03C54.0156 190.77 53.8053 190.56 53.5459 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 152.985L40.3944 152.985C40.135 152.985 39.9247 153.195 39.9247 153.454L39.9247 166.606C39.9247 166.865 40.135 167.075 40.3944 167.075L53.5459 167.075C53.8053 167.075 54.0156 166.865 54.0156 166.606L54.0156 153.454C54.0156 153.195 53.8053 152.985 53.5459 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 115.409L40.3944 115.409C40.135 115.409 39.9247 115.619 39.9247 115.878L39.9247 129.03C39.9247 129.289 40.135 129.5 40.3944 129.5L53.5459 129.5C53.8053 129.5 54.0156 129.289 54.0156 129.03L54.0156 115.878C54.0156 115.619 53.8053 115.409 53.5459 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 77.8329L40.3944 77.8329C40.135 77.8329 39.9247 78.0432 39.9247 78.3026L39.9247 91.4541C39.9247 91.7135 40.135 91.9238 40.3944 91.9238L53.5459 91.9238C53.8053 91.9238 54.0156 91.7135 54.0156 91.4541L54.0156 78.3026C54.0156 78.0432 53.8053 77.8329 53.5459 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 40.2572L40.3944 40.2572C40.135 40.2572 39.9247 40.4675 39.9247 40.7269L39.9247 53.8784C39.9247 54.1379 40.135 54.3481 40.3944 54.3481L53.5459 54.3481C53.8053 54.3481 54.0156 54.1379 54.0156 53.8784L54.0156 40.7269C54.0156 40.4675 53.8053 40.2572 53.5459 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 2.68143L40.3944 2.68143C40.135 2.68143 39.9247 2.8917 39.9247 3.15113L39.9247 16.3026C39.9247 16.562 40.135 16.7723 40.3944 16.7723L53.5459 16.7723C53.8053 16.7723 54.0156 16.562 54.0156 16.3026L54.0156 3.15113C54.0156 2.8917 53.8053 2.68143 53.5459 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 190.56L2.81873 190.56C2.5593 190.56 2.34903 190.77 2.34903 191.03L2.34903 204.181C2.34903 204.441 2.5593 204.651 2.81873 204.651L15.9702 204.651C16.2297 204.651 16.4399 204.441 16.4399 204.181L16.4399 191.03C16.4399 190.77 16.2296 190.56 15.9702 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 152.985L2.81873 152.985C2.5593 152.985 2.34903 153.195 2.34903 153.454L2.34903 166.606C2.34903 166.865 2.5593 167.075 2.81873 167.075L15.9702 167.075C16.2297 167.075 16.4399 166.865 16.4399 166.606L16.4399 153.454C16.4399 153.195 16.2296 152.985 15.9702 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 115.409L2.81873 115.409C2.5593 115.409 2.34903 115.619 2.34903 115.878L2.34903 129.03C2.34903 129.289 2.5593 129.5 2.81873 129.5L15.9702 129.5C16.2297 129.5 16.4399 129.289 16.4399 129.03L16.4399 115.878C16.4399 115.619 16.2296 115.409 15.9702 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 77.8329L2.81873 77.8329C2.5593 77.8329 2.34903 78.0432 2.34903 78.3026L2.34903 91.4541C2.34903 91.7135 2.5593 91.9238 2.81873 91.9238L15.9702 91.9238C16.2297 91.9238 16.4399 91.7135 16.4399 91.4541L16.4399 78.3026C16.4399 78.0432 16.2296 77.8329 15.9702 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 40.2572L2.81873 40.2572C2.5593 40.2572 2.34903 40.4675 2.34903 40.7269L2.34903 53.8784C2.34903 54.1379 2.5593 54.3481 2.81873 54.3481L15.9702 54.3481C16.2297 54.3481 16.4399 54.1379 16.4399 53.8784L16.4399 40.7269C16.4399 40.4675 16.2296 40.2572 15.9702 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 2.68143L2.81873 2.68143C2.5593 2.68143 2.34903 2.8917 2.34903 3.15113L2.34903 16.3026C2.34903 16.562 2.5593 16.7723 2.81873 16.7723L15.9702 16.7723C16.2297 16.7723 16.4399 16.562 16.4399 16.3026L16.4399 3.15113C16.4399 2.8917 16.2296 2.68143 15.9702 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>

                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='swiper--content'>
                        <div className='Desc--Section'>
                            <p>
                                Really makes you feel that you can share anything with the counsellor
                                it's a very convenient app to use. During these times I really needed a
                                counsellor to talk to and I got a very good one all thanks to this app.
                            </p>

                            <div>
                                Connor<br />
                            </div>
                        </div>
                        <div className='Img--Section'>
                            <img src='/assets/img/planDetails/unsplash_BI91NrppE38.png' alt='user' />

                            <img className='quote' src="/assets/img/planDetails/chat-quote.png" />
                            <svg width="207" height="207" viewBox="0 0 207 207" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M203.849 190.56L190.697 190.56C190.438 190.56 190.227 190.77 190.227 191.03L190.227 204.181C190.227 204.441 190.438 204.651 190.697 204.651L203.849 204.651C204.108 204.651 204.318 204.441 204.318 204.181L204.318 191.03C204.318 190.77 204.108 190.56 203.849 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 152.985L190.697 152.985C190.438 152.985 190.227 153.195 190.227 153.454L190.227 166.606C190.227 166.865 190.438 167.075 190.697 167.075L203.849 167.075C204.108 167.075 204.318 166.865 204.318 166.606L204.318 153.454C204.318 153.195 204.108 152.985 203.849 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 115.409L190.697 115.409C190.438 115.409 190.227 115.619 190.227 115.878L190.227 129.03C190.227 129.289 190.438 129.5 190.697 129.5L203.849 129.5C204.108 129.5 204.318 129.289 204.318 129.03L204.318 115.878C204.318 115.619 204.108 115.409 203.849 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 77.8329L190.697 77.8329C190.438 77.8329 190.227 78.0432 190.227 78.3026L190.227 91.4541C190.227 91.7135 190.438 91.9238 190.697 91.9238L203.849 91.9238C204.108 91.9238 204.318 91.7135 204.318 91.4541L204.318 78.3026C204.318 78.0432 204.108 77.8329 203.849 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 40.2572L190.697 40.2572C190.438 40.2572 190.227 40.4675 190.227 40.7269L190.227 53.8784C190.227 54.1379 190.438 54.3481 190.697 54.3481L203.849 54.3481C204.108 54.3481 204.318 54.1379 204.318 53.8784L204.318 40.7269C204.318 40.4675 204.108 40.2572 203.849 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M203.849 2.68143L190.697 2.68143C190.438 2.68143 190.227 2.8917 190.227 3.15113L190.227 16.3026C190.227 16.562 190.438 16.7723 190.697 16.7723L203.849 16.7723C204.108 16.7723 204.318 16.562 204.318 16.3026L204.318 3.15113C204.318 2.8917 204.108 2.68143 203.849 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 190.56L153.121 190.56C152.862 190.56 152.652 190.77 152.652 191.03L152.652 204.181C152.652 204.441 152.862 204.651 153.121 204.651L166.273 204.651C166.532 204.651 166.743 204.441 166.743 204.181L166.743 191.03C166.743 190.77 166.532 190.56 166.273 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 152.985L153.121 152.985C152.862 152.985 152.652 153.195 152.652 153.454L152.652 166.606C152.652 166.865 152.862 167.075 153.121 167.075L166.273 167.075C166.532 167.075 166.743 166.865 166.743 166.606L166.743 153.454C166.743 153.195 166.532 152.985 166.273 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 115.409L153.121 115.409C152.862 115.409 152.652 115.619 152.652 115.878L152.652 129.03C152.652 129.289 152.862 129.5 153.121 129.5L166.273 129.5C166.532 129.5 166.743 129.289 166.743 129.03L166.743 115.878C166.743 115.619 166.532 115.409 166.273 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 77.8329L153.121 77.8329C152.862 77.8329 152.652 78.0432 152.652 78.3026L152.652 91.4541C152.652 91.7135 152.862 91.9238 153.121 91.9238L166.273 91.9238C166.532 91.9238 166.743 91.7135 166.743 91.4541L166.743 78.3026C166.743 78.0432 166.532 77.8329 166.273 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 40.2572L153.121 40.2572C152.862 40.2572 152.652 40.4675 152.652 40.7269L152.652 53.8784C152.652 54.1379 152.862 54.3481 153.121 54.3481L166.273 54.3481C166.532 54.3481 166.743 54.1379 166.743 53.8784L166.743 40.7269C166.743 40.4675 166.532 40.2572 166.273 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M166.273 2.68143L153.121 2.68143C152.862 2.68143 152.652 2.8917 152.652 3.15113L152.652 16.3026C152.652 16.562 152.862 16.7723 153.121 16.7723L166.273 16.7723C166.532 16.7723 166.743 16.562 166.743 16.3026L166.743 3.15113C166.743 2.8917 166.532 2.68143 166.273 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 190.56L115.546 190.56C115.286 190.56 115.076 190.77 115.076 191.03L115.076 204.181C115.076 204.441 115.286 204.651 115.546 204.651L128.697 204.651C128.957 204.651 129.167 204.441 129.167 204.181L129.167 191.03C129.167 190.77 128.957 190.56 128.697 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 152.985L115.546 152.985C115.286 152.985 115.076 153.195 115.076 153.454L115.076 166.606C115.076 166.865 115.286 167.075 115.546 167.075L128.697 167.075C128.957 167.075 129.167 166.865 129.167 166.606L129.167 153.454C129.167 153.195 128.957 152.985 128.697 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 115.409L115.546 115.409C115.286 115.409 115.076 115.619 115.076 115.878L115.076 129.03C115.076 129.289 115.286 129.5 115.546 129.5L128.697 129.5C128.957 129.5 129.167 129.289 129.167 129.03L129.167 115.878C129.167 115.619 128.957 115.409 128.697 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 77.8329L115.546 77.8329C115.286 77.8329 115.076 78.0432 115.076 78.3026L115.076 91.4541C115.076 91.7135 115.286 91.9238 115.546 91.9238L128.697 91.9238C128.957 91.9238 129.167 91.7135 129.167 91.4541L129.167 78.3026C129.167 78.0432 128.957 77.8329 128.697 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 40.2572L115.546 40.2572C115.286 40.2572 115.076 40.4675 115.076 40.7269L115.076 53.8784C115.076 54.1379 115.286 54.3481 115.546 54.3481L128.697 54.3481C128.957 54.3481 129.167 54.1379 129.167 53.8784L129.167 40.7269C129.167 40.4675 128.957 40.2572 128.697 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M128.697 2.68143L115.546 2.68143C115.286 2.68143 115.076 2.8917 115.076 3.15113L115.076 16.3026C115.076 16.562 115.286 16.7723 115.546 16.7723L128.697 16.7723C128.957 16.7723 129.167 16.562 129.167 16.3026L129.167 3.15113C129.167 2.8917 128.957 2.68143 128.697 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 190.56L77.9701 190.56C77.7107 190.56 77.5004 190.77 77.5004 191.03L77.5004 204.181C77.5004 204.441 77.7107 204.651 77.9701 204.651L91.1216 204.651C91.381 204.651 91.5913 204.441 91.5913 204.181L91.5913 191.03C91.5913 190.77 91.381 190.56 91.1216 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 152.985L77.9701 152.985C77.7107 152.985 77.5004 153.195 77.5004 153.454L77.5004 166.606C77.5004 166.865 77.7107 167.075 77.9701 167.075L91.1216 167.075C91.381 167.075 91.5913 166.865 91.5913 166.606L91.5913 153.454C91.5913 153.195 91.381 152.985 91.1216 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 115.409L77.9701 115.409C77.7107 115.409 77.5004 115.619 77.5004 115.878L77.5004 129.03C77.5004 129.289 77.7107 129.5 77.9701 129.5L91.1216 129.5C91.381 129.5 91.5913 129.289 91.5913 129.03L91.5913 115.878C91.5913 115.619 91.381 115.409 91.1216 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 77.8329L77.9701 77.8329C77.7107 77.8329 77.5004 78.0432 77.5004 78.3026L77.5004 91.4541C77.5004 91.7135 77.7107 91.9238 77.9701 91.9238L91.1216 91.9238C91.381 91.9238 91.5913 91.7135 91.5913 91.4541L91.5913 78.3026C91.5913 78.0432 91.381 77.8329 91.1216 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 40.2572L77.9701 40.2572C77.7107 40.2572 77.5004 40.4675 77.5004 40.7269L77.5004 53.8784C77.5004 54.1379 77.7107 54.3481 77.9701 54.3481L91.1216 54.3481C91.381 54.3481 91.5913 54.1379 91.5913 53.8784L91.5913 40.7269C91.5913 40.4675 91.381 40.2572 91.1216 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M91.1216 2.68143L77.9701 2.68143C77.7107 2.68143 77.5004 2.8917 77.5004 3.15113L77.5004 16.3026C77.5004 16.562 77.7107 16.7723 77.9701 16.7723L91.1216 16.7723C91.381 16.7723 91.5913 16.562 91.5913 16.3026L91.5913 3.15113C91.5913 2.8917 91.381 2.68143 91.1216 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 190.56L40.3944 190.56C40.135 190.56 39.9247 190.77 39.9247 191.03L39.9247 204.181C39.9247 204.441 40.135 204.651 40.3944 204.651L53.5459 204.651C53.8053 204.651 54.0156 204.441 54.0156 204.181L54.0156 191.03C54.0156 190.77 53.8053 190.56 53.5459 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 152.985L40.3944 152.985C40.135 152.985 39.9247 153.195 39.9247 153.454L39.9247 166.606C39.9247 166.865 40.135 167.075 40.3944 167.075L53.5459 167.075C53.8053 167.075 54.0156 166.865 54.0156 166.606L54.0156 153.454C54.0156 153.195 53.8053 152.985 53.5459 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 115.409L40.3944 115.409C40.135 115.409 39.9247 115.619 39.9247 115.878L39.9247 129.03C39.9247 129.289 40.135 129.5 40.3944 129.5L53.5459 129.5C53.8053 129.5 54.0156 129.289 54.0156 129.03L54.0156 115.878C54.0156 115.619 53.8053 115.409 53.5459 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 77.8329L40.3944 77.8329C40.135 77.8329 39.9247 78.0432 39.9247 78.3026L39.9247 91.4541C39.9247 91.7135 40.135 91.9238 40.3944 91.9238L53.5459 91.9238C53.8053 91.9238 54.0156 91.7135 54.0156 91.4541L54.0156 78.3026C54.0156 78.0432 53.8053 77.8329 53.5459 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 40.2572L40.3944 40.2572C40.135 40.2572 39.9247 40.4675 39.9247 40.7269L39.9247 53.8784C39.9247 54.1379 40.135 54.3481 40.3944 54.3481L53.5459 54.3481C53.8053 54.3481 54.0156 54.1379 54.0156 53.8784L54.0156 40.7269C54.0156 40.4675 53.8053 40.2572 53.5459 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M53.5459 2.68143L40.3944 2.68143C40.135 2.68143 39.9247 2.8917 39.9247 3.15113L39.9247 16.3026C39.9247 16.562 40.135 16.7723 40.3944 16.7723L53.5459 16.7723C53.8053 16.7723 54.0156 16.562 54.0156 16.3026L54.0156 3.15113C54.0156 2.8917 53.8053 2.68143 53.5459 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 190.56L2.81873 190.56C2.5593 190.56 2.34903 190.77 2.34903 191.03L2.34903 204.181C2.34903 204.441 2.5593 204.651 2.81873 204.651L15.9702 204.651C16.2297 204.651 16.4399 204.441 16.4399 204.181L16.4399 191.03C16.4399 190.77 16.2296 190.56 15.9702 190.56Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 152.985L2.81873 152.985C2.5593 152.985 2.34903 153.195 2.34903 153.454L2.34903 166.606C2.34903 166.865 2.5593 167.075 2.81873 167.075L15.9702 167.075C16.2297 167.075 16.4399 166.865 16.4399 166.606L16.4399 153.454C16.4399 153.195 16.2296 152.985 15.9702 152.985Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 115.409L2.81873 115.409C2.5593 115.409 2.34903 115.619 2.34903 115.878L2.34903 129.03C2.34903 129.289 2.5593 129.5 2.81873 129.5L15.9702 129.5C16.2297 129.5 16.4399 129.289 16.4399 129.03L16.4399 115.878C16.4399 115.619 16.2296 115.409 15.9702 115.409Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 77.8329L2.81873 77.8329C2.5593 77.8329 2.34903 78.0432 2.34903 78.3026L2.34903 91.4541C2.34903 91.7135 2.5593 91.9238 2.81873 91.9238L15.9702 91.9238C16.2297 91.9238 16.4399 91.7135 16.4399 91.4541L16.4399 78.3026C16.4399 78.0432 16.2296 77.8329 15.9702 77.8329Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 40.2572L2.81873 40.2572C2.5593 40.2572 2.34903 40.4675 2.34903 40.7269L2.34903 53.8784C2.34903 54.1379 2.5593 54.3481 2.81873 54.3481L15.9702 54.3481C16.2297 54.3481 16.4399 54.1379 16.4399 53.8784L16.4399 40.7269C16.4399 40.4675 16.2296 40.2572 15.9702 40.2572Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9702 2.68143L2.81873 2.68143C2.5593 2.68143 2.34903 2.8917 2.34903 3.15113L2.34903 16.3026C2.34903 16.562 2.5593 16.7723 2.81873 16.7723L15.9702 16.7723C16.2297 16.7723 16.4399 16.562 16.4399 16.3026L16.4399 3.15113C16.4399 2.8917 16.2296 2.68143 15.9702 2.68143Z" stroke="white" stroke-width="1.76136" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>

                    </div>
                </SwiperSlide>

            </Swiper>
        </div>
    )
}

export default PlanDetailsReviewSlide